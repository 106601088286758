import React from 'react';
import { UC, UnoComponent } from '../../../@uno/core';
import { EntityListView } from './entity-list.view.comp';
import { BaseEntity, EntityConstants, Router, } from '../../../@uno/api';

// MULTI ENTITY - Tree VIEW
@UnoComponent({
    id: 'eTree', //EntityConstants.ListViewTypes.Tree.id,
})
export class EntityTreeView extends EntityListView {

    buildContent() {
        let entities = this.getEntities();
        const navs = entities.map((e: BaseEntity) => {
            e = EntityConstants.build(e);
            const name = e.getName();
            const id = e.getID();

            return { id: id, label: name, to: Router.getViewRoute(e) };
        })
        return (
            <UC.Navigation navs={navs} />
        );
    }

}
