import React from 'react';
import { EntityView } from "../../@uno-entity/components/entity-view.comp";
import { BaseEntity, Common, EntityConstants, EntityProp } from '../../@uno/api';
import { UC, UnoCompEvents, UnoComponent } from "../../@uno/core";
import { WorkbookService } from '../services/workbook.service';

// A Worksheet - VIEW
@UnoComponent({
    id: 'WorkbookView',
    label: 'View A Workbook',
    props: [],
    getDesign: () => { return (<div>View A Workbook - Design</div>); },
    getPreview: () => { return (<div>View A Workbook - Preview</div>); },
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
})
export class WorkbookView extends EntityView {

    buildEntityProp(prop: EntityProp, noLabel?: boolean): JSX.Element | undefined {
        let propViz: any;
        switch (prop.id) {
            case 'description':
                propViz = super.buildEntityProp(prop, true);
                break;
            case 'sheets':
                propViz = super.buildEntityProp(prop, true);
                break;
        }
        return propViz;
    }

    buildTitle(): JSX.Element | null {
        return (
            <>
                <WorkbookActors workbookID={this.getEntityId()} />
                {super.buildTitle()}
            </>
        );
    }

}

class WorkbookActors extends React.Component<any, any> {

    private _handlerActiveWB: any;

    constructor(props: any) {
        super(props);
        this.state = { ...this.props };
    }

    componentDidMount(): void {
        this._handlerActiveWB = setInterval(
            async () => {
                const activePersons = await WorkbookService.setActiveWB(this.props.workbookID);
                this.setState({ activePersons: activePersons });
            },
            1000 * 10
        );
    }

    componentWillUnmount() {
        clearInterval(this._handlerActiveWB);
    }

    render() {
        const persons = this.state.activePersons;
        const personVizs = persons?.map(
            (p: BaseEntity, index: number) => {
                const colorVal = 255 - 2 * (index + 1);
                p = EntityConstants.build(p);
                // console.log(`A person: `, p);
                const title = p.getName();
                const name = title.split(' ').map(word => { return word.charAt(0).toUpperCase(); }).join('');
                return (
                    <div
                        style={{
                            display: 'inline', border: '3px solid blue',
                            width: '30px', height: '30px', borderRadius: '50%',
                            padding: '2px', margin: '5px',
                            userSelect: 'none',
                            cursor: 'default',
                        }}
                        title={title} key={Common.getUniqueKey()}>
                        {name}
                    </div>
                );
            }
        );
        // console.log(`Refreshing active persons: `, persons, personVizs);
        return (
            <div style={{ float: 'right' }}>
                {personVizs}
            </div>
        );
    }
}