import React from 'react';
import { EntityCategoryService } from '../../../@uno-app/service/entity.category.service';
import { BaseEntity, EntityConstants, EntityProp, } from '../../../@uno/api';
import { UC, UnoComponent, Common, Images } from '../../../@uno/core';
import { BasePropEditor } from '../prop-base.comp';

@UnoComponent({ id: 'PropEntityEditor' })
export class PropEntityEditor extends BasePropEditor {
    selectedEntity: BaseEntity | undefined;

    constructor(props: any) {
        super(props);
        // this.profiler.log(`Entity Input Prop - ${this.getEProp().id},  Category: `, this.getCategory());
        if (this.state.setVal === undefined) {
            this.state = { ...this.state, setVal: false };
        }
        if (this.getCategory()) {
            // this.profiler.log(`Setting entity prop category: `, this.getCategory());
            // this.profiler.log(`With default value: `, this.getDefaultValue());
            this.setDefaultValue();
        }
        const catID = this.getCategoryID();
        // this.profiler.log(`Creating Entity Editor for prop : ${Common.stringify(this.getEProp())} , Category: ${catID}: `, this.getCategory());
        if (catID && !this.getCategory()) {
            this.setDefaultValue();
            EntityCategoryService.getCategory(catID)
                .then(
                    category => {
                        // this.category = category;
                        // this.setDefaultValue();
                        this.setState({ category: category });
                    }
                );
        }
    }

    buildComp() {
        return this.buildInputView();
    }


    buildValue() {
        if (this.selectedEntity) {
            const propEntityCategory = this.getCategory();
            const PropEntityView = this.getViewer();
            const otherProps = this.getViewerProps();
            return (
                <PropEntityView
                    entity={this.selectedEntity}
                    categoryID={propEntityCategory}
                    category={this.getCategory()}
                    otherProps={otherProps}
                    entityScreen={this.getViewerScreen()}
                    key={Common.getUniqueKey('prop_entity_view_')}
                />
            );
        } else {
            return <UC.Empty />;
        }

    }

    buildInputView() {
        if (this.getExtraParams()?.editor?.always) {
            return (this.buildInputEditor());
        }

        const actions: Array<any> = [];
        let InputView = <UC.Empty />;

        if (this.state.setVal) {
            if (!this.isEntityInlineTypeProp()) { // Inline form will have it's own cancel button.
                actions.push(
                    { id: 'cancel', label: 'Cancel', icon: Images.Icon.close, action: this.handleSetEntityDone }
                );
            }
            InputView = this.buildInputEditor();
        } else {
            if (this.selectedEntity) {
                actions.push({ id: 'clear', label: 'Clear', icon: Images.Icon.clear, action: () => { this.handleSelected(undefined); } })
            }
            actions.push(
                { id: 'set', label: this.selectedEntity ? 'Change' : 'Set', icon: Images.Icon.edit, action: this.handleSetEntity }
            );
            InputView = this.buildValue();
        }

        return (
            <>
                {this.buildErrorMsg()}
                <div className=''>
                    <span style={{
                        //padding: '10px'
                    }}>
                        {InputView}
                    </span>
                    <UC.Navigation navs={actions} orientation='h' isToolbar={false} key={Common.getUniqueKey('nav')} />
                </div>
            </>
        );

    }

    buildInputEditor() {
        const propEntityCategory = this.getCategoryID();
        if (!propEntityCategory) {
            return this.buildCategorySelector();
        }
        const category = this.getCategory() || EntityCategoryService.getAppCategory(propEntityCategory, this.getAppID());
        // this.profiler.log(`Input Entity of Category ID - ${this.getCategoryID()} `, propEntityCategory);
        const Editor = this.getEditor();
        const otherProps = this.getEditorProps();
        otherProps.onSaveCancel = this.handleSetEntityDone;
        otherProps.hideTitle = true;

        this.profiler.log('Prop Entity Search View: ', category, otherProps,);
        if (!Editor) {
            return (<h2>Problem finding the Input widget for: {propEntityCategory}</h2>);
        }
        // this.profiler.log('Selected Entity: ', this.selectedEntity);
        return (
            <>
                <Editor
                    // categoryID={propEntityCategory}
                    category={category}
                    otherProps={otherProps}
                    entity={this.selectedEntity}
                    entityScreen={this.getEditorScreen()}
                    key={Common.getUniqueKey('prop_entity_edit_')}
                />
            </>
        );
    }

    buildCategorySelector() {
        return (
            <UC.CategorySelector
                key={Common.getUniqueKey()}
                appID={this.getAppID()}
                entityProp={{ id: 'Select Category' }}
                otherProps={{
                    onPropChanged: async (prop: EntityProp, catID: any) => {
                        const category = await EntityCategoryService.getCategory(catID, true, this.getAppID());
                        this.reRender({ category: category });
                    }
                }} />
        );
    }

    // load selected entity
    setDefaultValue() {
        // this.profiler.log(`Setting selected entity: `, this.getDefaultValue());
        const catID = this.getCategoryID();
        let dv: any = this.getDefaultValue();
        if (Common.checkType.String(dv)) {
            try {
                dv = Common.parse(dv);
            } catch (e) { }
        }
        if (Common.checkType.Object(dv)) {
            const defVal = EntityConstants.build(dv);
            if (catID && defVal.getCategoryID() === catID) {
                this.selectedEntity = defVal;
                // this.profiler.log('Input Entity: ', this.selectedEntity);
            }
        }
    }


    getDefaultValue() {
        let defValue = super.getDefaultValue();
        if (this.selectedEntity) {
            defValue = this.selectedEntity;
        }
        if (defValue instanceof Object) {
            defValue = Common.stringify(defValue);
        }
        return defValue;
    }

    handleSetEntity = () => {
        //this.showSearchWidget = true;
        this.setState({ setVal: true })
    }

    handleSetEntityDone = () => {
        // this.showSearchWidget = false;
        const propChangeHandler = this.getPropChangedHandler();
        if (propChangeHandler) {
            // this.profiler.log('Setting Entity Prop: ', this.getEPropID(), this.getCategoryID(), this.getEProp(), this.selectedEntity);
            // this.profiler.log('Prop attrs: ', this.props.entityProp, this.props.category, this.state.entityProp, this.state.entityPropDef);
            if (this.selectedEntity) {
                propChangeHandler(this.getEProp(), this.selectedEntity);
            } else {
                propChangeHandler(this.getEProp(), undefined);
            }
        }
        this.setState({ setVal: false });
    }

    handleSelected = (entity: BaseEntity | undefined) => {
        this.selectedEntity = entity;
        this.handleSetEntityDone();
    }

    getViewerProps(): any {
        const viewerProps = {
            ...this.getOtherProps(),
            canEdit: false,
            canSelect: false,
            propLayout: this.getPropLayout(),
            propLabelPosition: this.getPropLabelPosition(),
        };

        const propExtras = this.getExtraParams();
        viewerProps.extras = viewerProps.extras ? { ...viewerProps.extras, ...propExtras } : propExtras;

        return viewerProps;
    }

    getEditorProps(): any {
        const editorProps = this.getViewerProps();
        editorProps.canSelect = true;
        editorProps.onSelected = this.handleSelected;
        editorProps.stt_enabled = this.state.stt_enabled;
        editorProps.writer_enabled = this.state.writer_enabled;

        return editorProps;
    }

    getEditor() {
        return UC.EntitySearch;
    }

    getViewer() {
        return UC.EntityQuickView;
    }

    getEditorScreen() {
        let editorScreen = this.getOtherProps()?.editorScreen;
        if (!editorScreen) {
            editorScreen = this.getExtraParams()?.editorScreen;
        }
        return editorScreen;
    }

    getViewerScreen() {
        let viewerScreen = this.getOtherProps()?.viewerScreen;
        if (!viewerScreen) {
            viewerScreen = this.getExtraParams()?.viewerScreen;
        }
        return viewerScreen;
    }

}

@UnoComponent({ id: 'PropInlineEntityEditor' })
export class PropInlineEntityEditor extends PropEntityEditor {
    constructor(props: any) {
        super(props);
        // this.profiler.log(`Inline Prop Editor : `, this.getCategory());
    }

    handleOnInlineSave = (entity: BaseEntity) => {
        if (!entity.getID()) {
            entity.createID();
        }
        // this.profiler.log('Saving inline - ', entity);
        this.handleSelected(entity)
    }

    getViewerProps(): any {
        const viewerProps =
        {
            ...super.getViewerProps(),
            canSelect: false,
            hideTitle: true,
            canViewMain: false,
        };
        return viewerProps;
    }

    getEditorProps(): any {
        const editorProps = {
            ...this.getViewerProps(),
            hideTitle: true,
            canViewFull: false,
            onInlineSave: this.handleOnInlineSave,
            extras: { inline: true },
        };
        return editorProps;
    }

    getEditor() {
        return UC.EntityEdit;
    }

    getViewer() {
        return UC.EntityView;
    }

}
