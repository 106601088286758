import React from 'react';
import { EntityCategoryService } from '../../@uno-app/service/entity.category.service';
import { BasePropEditor, BasePropViewer } from '../../@uno-entity-prop/components/prop-base.comp';
import { EntityTableView } from '../../@uno-entity/components/list-view/entity-table.view.comp';
import { BaseEntity, Common, EntityCategory, EntityConstants } from '../../@uno/api';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent } from '../../@uno/core';
import { FilterResults } from './entity-filter-result.comp';

const ChartTypes = {
    BAR_CHART: { id: 'barchart', label: 'Bar Chart' },
    // LIST: { id: 'list', label: 'Unordered List' },
    PIE_CHART: { id: 'piechart', label: 'Pie Chart' },
    DOUGHNUT_CHART: { id: 'doughnut', label: 'Doughnut Chart' },
}

const DEFAULT_CHART_TYPE = ChartTypes.DOUGHNUT_CHART;

@UnoComponent({
    id: 'EntityAggregator',
    label: 'Entity Aggregator',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Entity.id,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    props: [
        {
            groupID: 'Aggregation',
            id: 'noTable', label: 'Hide Entity Records?',
            dataType: EntityConstants.PropType.BOOLEAN,
            defaultValue: true,
        },
        {
            groupID: 'Aggregation',
            id: 'title',
            label: 'Title',
            dataType: EntityConstants.PropType.STRING,
        },
        {
            groupID: 'Aggregation',
            id: 'entity_type',
            label: 'Entity Type',
            dataType: EntityConstants.PropType.STRING,
            editor: 'CategorySelector',
            validators: [],
        },
        {
            groupID: 'Attributes',
            id: 'attributes',
            label: 'Attributes',
            dataType: EntityConstants.PropType.JSON,
            editor: 'AggregatorFieldEditor',
            // viewer: 'AggregatorFieldViewer',
            multiplicity: -1,
            //extras: { catID: 'entity_type', multiSelect: false, },
            noQV: true,
        },
        {
            groupID: 'Filter Conditions',
            id: 'conditions',
            label: 'Conditions',
            dataType: EntityConstants.PropType.JSON, editor: 'FilterEditor',
            extras: { catID: 'entity_type', },
        },
        {
            groupID: 'Filter Conditions',
            id: 'filter',
            label: 'The Named Filter',
            dataType: EntityConstants.PropType.ENTITY, category: Common.CategoryID.FilterDef,
        },
    ],
    getDesign: () => { return (<div>Entity Aggregator - Design</div>); },
})
export class EntityAggregator extends FilterResults {
    componentDidMount(): void {
        super.componentDidMount();
        this.reRender({ noPageNav: true });
    }

    getViewer() {
        return AggregatorView;
    }

    getPageLimit() {
        return Number.MAX_SAFE_INTEGER;
    }

    getOtherProps() {
        const oProps = super.getOtherProps();
        const _props: any = this.props;
        const agAttribs = Common.safeParse(_props.attributes);
        if (agAttribs) {
            oProps.agAttribs = agAttribs;
        }
        oProps.noTable = _props.noTable;
        return oProps;
    }
}


class AggregatorView extends EntityTableView {

    buildContent() {
        if (this.getOtherProps()?.noTable === false) {
            return super.buildContent();
        } else {
            const aggregates = this.calculateAggregates();
            const aggregatesViews = aggregates?.map(aggregate => {
                return this.buildAggregateValue(aggregate);
            });
            return aggregatesViews;
        };
    }

    buildEntities() {
        const entities = this.state.entities;
        // console.log(`Aggregator Views - Entities: `, this.getEntities()?.length, entities?.length, this.getCategoryID())
        const entitiesViews = super.buildEntities();
        entitiesViews.push(this.buildAggregates());
        return entitiesViews;
    }

    getViewProps(quickView = false, category: EntityCategory = this.getCategory(),) {
        if (!category) {
            category = EntityCategoryService.getAppCategory(this.getCategoryID());
        }
        const viewProps = super.getViewProps(quickView, category);
        const attribs = this.getAgAttribs();

        // console.log(`Finding ViewProps for aggregator: `, attribs, viewProps, category);
        if (attribs?.length >= 0 && viewProps?.length > 0) {
            // console.log(viewProps.map(vp => vp.id));
            const attribProps = attribs.map(
                (attr: any) => {
                    const pID = attr.field;
                    // console.log(`AgField - `, pID);
                    let attrProp = undefined;
                    viewProps.forEach(
                        vp => {
                            if (vp.id === pID) {
                                attrProp = vp;
                            }
                        }
                    );
                    return attrProp;
                }
            );

            // console.log(`AgProps - `, attribProps);
            return attribProps;
        } else {
            return viewProps;
        }

    }

    buildAggregateValue = (aggregate: any) => {
        const gbAggregates = aggregate.gbAggregates;
        const gbLabel = aggregate.gbLabel
        if (gbLabel && gbAggregates?.length > 0) {
            console.log('Aggregate: ', aggregate);
            return (
                <UC.ChartJsViz
                    key={Common.getUniqueKey('piechart_')}
                    type={aggregate.attr?.chartType || DEFAULT_CHART_TYPE.id}
                    data={
                        {
                            labels: gbAggregates.map((gba: any) => { return gba.key }),
                            datasets: [
                                {
                                    label: aggregate.agLabel,
                                    data: gbAggregates.map((gba: any) => { return gba.val }),
                                    borderWidth: 1
                                }
                            ]
                        }
                    }
                    config={{
                        canvas: { border: 'none', shadow: 'none', margin: '5' }
                    }}
                    showChoices={false}
                />
            );

        } else {
            return (
                <UC.VSection key={Common.getUniqueKey()} cols='2' rigidCols={true}>
                    <div className='right'>{aggregate.agLabel}</div>
                    <div className='right'>
                        {
                            aggregate.agValue ?
                                //aggregate.agValue
                                <UC.PropValue
                                    defaultValue={aggregate.agValue}
                                    entityProp={aggregate.prop}
                                />
                                : ' '
                        }
                    </div>
                </UC.VSection>
            );
        }
    }

    buildAggregates(aggregates: Array<any> = this.calculateAggregates()) {
        const cells = aggregates?.map(
            (aggregate: any) => {
                return (
                    <td key={this.getUniqueKey()}>
                        {this.buildAggregateValue(aggregate)}
                    </td>
                );
            }
        );

        return (
            <tr key={this.getUniqueKey()}>
                {/* <td>&nbsp;</td> */}
                {cells}
            </tr>
        );

    }

    calculateAggregates(): Array<any> {
        const attribs = this.getAgAttribs() ? this.getAgAttribs() : [];
        // console.log('Attributes: ', attribs);
        return attribs?.map(
            (attr: any) => {
                const OVERALL = 'OVERALL';
                const aggregateRawData: any = {};
                aggregateRawData[OVERALL] = [];

                const pID = attr.field;
                const prop = this.getCategoryPropByID(pID);
                const pLabel = prop ? (prop.label ? prop.label : prop.id) : pID;
                const type: string = attr.type;
                let agLabel = attr.label;

                if (attr.label && attr.label.trim().length > 0) {
                    agLabel = attr.label;
                } else {
                    agLabel = type ? `${type.toUpperCase()} of ${pLabel}` : '';
                }

                const groupByID = attr.groupBy;
                const groupByProp = this.getCategoryPropByID(groupByID);
                const gbLabel = groupByProp ? (groupByProp.label ? groupByProp.label : groupByProp.id) : groupByID;

                if (type) {
                    // agLabel = type;
                    const vals: Array<number> = this.getEntities().map((e: BaseEntity) => {
                        let fieldvalue = e.getValue(pID);
                        try {
                            if (Common.checkType.Number(fieldvalue)) {
                                fieldvalue = fieldvalue;
                            } else if (Common.checkType.String(fieldvalue)) {
                                fieldvalue = Number.parseFloat(fieldvalue);
                            } else {
                                fieldvalue = Number.parseFloat(fieldvalue.toString());
                            }
                        } catch (e) {
                            fieldvalue = 0;
                        }
                        // add to aggregateRawData
                        aggregateRawData[OVERALL].push(fieldvalue);

                        const groupByValue = Common.safeParse(e.getValue(groupByID));
                        let gbID: any = undefined;
                        if (Common.checkType.String(groupByValue)) {
                            gbID = groupByValue;
                        } else {
                            gbID = EntityConstants.build(groupByValue).getName();
                        }

                        const gbRawData = aggregateRawData[gbID] ? aggregateRawData[gbID] : [];
                        gbRawData.push(fieldvalue);
                        aggregateRawData[gbID] = gbRawData;

                        // for overall aggregation
                        return fieldvalue;
                    });

                }

                let agValue = 0;
                const gbAggregates: Array<any> = [];
                Object.keys(aggregateRawData).forEach(aKey => {
                    const rawVals = aggregateRawData[aKey];
                    const aggregateValue = this.getAggregatedValue(type, rawVals);
                    if (aKey === OVERALL) {
                        agValue = aggregateValue;
                    } else {
                        gbAggregates.push({ 'key': aKey, val: aggregateValue });
                    }
                })

                const aggregate = { attr, prop, pLabel, agLabel, agValue, gbLabel, gbAggregates, };
                return aggregate;
            }
        );

    }

    getAggregatedValue(type: string, vals: number[]) {
        let aggregatedValue = 0;
        switch (type) {
            case EntityConstants.Aggregation.COUNT.id:
                // agLabel = EntityConstants.Aggregation.COUNT.label;
                aggregatedValue = vals.length;
                break;
            case EntityConstants.Aggregation.SUM.id:
                // agLabel = EntityConstants.Aggregation.SUM.label;
                aggregatedValue = Common.Math.sum(vals);
                break;
            case EntityConstants.Aggregation.AVERAGE.id:
                // agLabel = EntityConstants.Aggregation.AVERAGE.label;
                aggregatedValue = Common.Math.average(vals);
                break;
            case EntityConstants.Aggregation.MINIMUM.id:
                // agLabel = EntityConstants.Aggregation.MINIMUM.label;
                aggregatedValue = vals.sort()[0];
                break;
            case EntityConstants.Aggregation.MAXIMUM.id:
                // agLabel = EntityConstants.Aggregation.MAXIMUM.label;
                aggregatedValue = vals.sort()[vals.length - 1];
                break;
        }
        return aggregatedValue;
    }

    getAgAttribs() {
        return this.getOtherProps()?.agAttribs;
    }

    getOtherProps() {
        const oProps = super.getOtherProps();
        if (oProps) {
            oProps.noActions = true;
        }
        return oProps;
    }
}

@UnoComponent(
    { id: 'AggregatorFieldEditor', }
)
export class AggregatorFieldEditor extends BasePropEditor {
    // editedValue: any = this.getDefaultValue() ? Common.safeParse(this.getDefaultValue()) : {};

    getEditedValue() {
        if (!this.editedValue) {
            this.editedValue = this.getDefaultValue() ? Common.safeParse(this.getDefaultValue()) : {};
        }
        return this.editedValue;
    }

    buildInput() {
        return (
            <>
                <UC.VSection
                    cols='2'
                    key={Common.getUniqueKey()}
                    styles={{
                        borderBottom: '2px solid gray',
                        margin: '10px 0px',
                        padding: '5px'
                    }}
                    cellStyles={{
                        padding: '5px',
                    }}
                >
                    <div>Label: </div>{this.buildLabelInput()}
                    <div>Prepare: </div>{this.buildTypeSelector()}
                    <div>Of: </div>{this.buildFieldSelector()}
                    <div>Group By: </div>{this.buildGroupBySelector()}
                    <div>Chart Type: </div>{this.buildChartTypeSelector()}
                </UC.VSection>
            </>
        );
    }

    buildFieldSelector() {
        const prop = { id: 'Field', label: 'Field', editor: 'PropertySelector', extras: { catID: 'entity_type', multiSelect: false, }, }
        const otherProps = this.getOtherProps() ? { ...this.getOtherProps() } : {};
        otherProps.onPropChanged = (p: any, v: any) => {
            this.setFieldValue(v);
        }
        // otherProps.hideLabel = false;
        let entity = this.getEntity() ? this.getEntity() : this.getOtherProps()?.primaryEntity;

        return (
            <UC.PropComp
                key={Common.getUniqueKey()}
                entityProp={prop}
                entity={entity}
                otherProps={otherProps}
                defaultValue={this.getEditedValue()?.field}
                appID={this.getAppID()}
                editing={true}
            />
        );
    }

    buildGroupBySelector() {
        const prop = { id: 'Group_By', label: 'Group By', editor: 'PropertySelector', extras: { catID: 'entity_type', multiSelect: false, }, }
        const otherProps = this.getOtherProps() ? { ...this.getOtherProps() } : {};
        otherProps.onPropChanged = (p: any, v: any) => {
            this.setGroupByValue(v);
        }
        // otherProps.hideLabel = false;
        let entity = this.getEntity() ? this.getEntity() : this.getOtherProps()?.primaryEntity;

        return (
            <UC.PropComp
                key={Common.getUniqueKey()}
                entityProp={prop}
                entity={entity}
                otherProps={otherProps}
                defaultValue={this.getEditedValue()?.groupBy}
                appID={this.getAppID()}
                editing={true}
            />
        );
    }

    buildTypeSelector() {
        const options = this.getTypeOptions(this.getEditedValue()?.type)
        // console.log(`Aggregator Field: `, this.getEditedValue(), options);

        return (
            <UC.SelectBox
                label='Type'
                options={options}
                onSelect={(opt: any) => {
                    this.setAggregationType(opt.id);
                }}
                key={Common.getUniqueKey()}
            />
        );
    }

    buildChartTypeSelector() {
        const options = this.getChartTypeOptions(this.getEditedValue()?.chartType)
        // console.log(`Aggregator Field: `, this.getEditedValue(), options);

        return (
            <UC.SelectBox
                label='Chart Type'
                options={options}
                onSelect={(opt: any) => {
                    this.setAggregationChartType(opt.id);
                }}
                key={Common.getUniqueKey()}
            />
        );
    }

    buildLabelInput() {
        const prop = { id: 'Label', }
        const otherProps = this.getOtherProps() ? { ...this.getOtherProps() } : {};
        otherProps.onPropChanged = (p: any, v: any) => {
            this.setLabelValue(v);
        }
        otherProps.hideLabel = false;

        let entity = this.getEntity() ? this.getEntity() : this.getOtherProps()?.primaryEntity;

        return (
            <UC.PropValue
                key={Common.getUniqueKey()}
                entityProp={prop}
                entity={entity}
                otherProps={otherProps}
                defaultValue={this.getEditedValue()?.label}
                appID={this.getAppID()}
                editing={true}
            />
        );
    }

    setFieldValue(newValue: any) {
        this.getEditedValue().field = newValue;
        // alert('Prop Selected - ' + this.getEditedValue().field);
        this.setPropValue(this.getEditedValue());
    }

    setGroupByValue(newValue: any) {
        this.getEditedValue().groupBy = newValue;
        // alert('Prop Selected - ' + this.getEditedValue().field);
        this.setPropValue(this.getEditedValue());
    }

    setLabelValue(newValue: any) {
        this.getEditedValue().label = newValue;
        // alert('Prop Selected - ' + this.getEditedValue().field);
        this.setPropValue(this.getEditedValue());
    }

    setAggregationType(newValue: any) {
        this.getEditedValue().type = newValue;
        // alert('Agg Type Selected - ' + this.getEditedValue().field);
        this.setPropValue(this.getEditedValue());
    }

    setAggregationChartType(newValue: any) {
        this.getEditedValue().chartType = newValue;
        // alert('Agg Chart Type Selected - ' + this.getEditedValue().field);
        this.setPropValue(this.getEditedValue());
    }


    getTypeOptions(selectedType = this.getEditedValue()?.type) {
        let options: Array<any> = Object.values(EntityConstants.Aggregation).map(
            (opt: any) => {
                opt = { ...opt };
                if (opt.id === selectedType) {
                    opt.isSelected = true;
                } else {
                    opt.isSelected = false;
                }
                return opt;
            });

        // console.log(`Selected Type Options: `, selectedType, options);
        return options;
    }

    getChartTypeOptions(selectedChartType = this.getEditedValue()?.type) {
        let options: Array<any> = Object.values(ChartTypes).map(
            (opt: any) => {
                opt = { ...opt };
                if (opt.id === selectedChartType) {
                    opt.isSelected = true;
                } else {
                    opt.isSelected = false;
                }
                return opt;
            });

        // console.log(`Selected Type Options: `, selectedType, options);
        return options;
    }
}

@UnoComponent(
    { id: 'AggregatorFieldViewer', }
)
export class AggregatorFieldViewer extends BasePropViewer {
    buildValue() {
        return (
            <span style={this.getStyles()}>
                <h2>AggregatorField Viewer</h2>
            </span>
        );
    }
}

