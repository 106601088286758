import React from 'react';
import { SessionManager } from '../../../@uno-app/service/session.service';
import { BaseEntity, EntityConstants } from '../../../@uno/api';
import { UC, UnoComponent, Images, Common, Router } from '../../../@uno/core';
import { AuthorizationService } from '../../service/auth.service';
import { EntityCompProps } from '../entity-base.comp';
import { EntityEdit } from '../entity-edit.comp';

// ENTITY - NEW, EDIT
@UnoComponent({
    id: 'Permissions',
    label: 'Permissions',
    props: [
    ],
    getDesign: () => { return (<div>Permissions - Design</div>); }
})
export class Permissions extends EntityEdit {
    private permissionEntity: any;
    private editingPermission = false;

    render() {
        const session = SessionManager.activeSession;
        if (!session || !this.getEntityCreatedBy() || (session?.pid !== this.getEntityCreatedBy())) {
            // can't set entity level permission here
            return <UC.Empty />;
        }

        let permissionEditor: any = null;
        if (this.editingPermission && this.permissionEntity) {
            permissionEditor = (
                <>
                    <UC.Section
                        title={this.getTitle()}
                        onClose={() => {
                            this.hideEditingPermission();
                        }}
                    >
                        <UC.EntityEdit
                            categoryID={this.getCategoryID()}
                            entity={this.permissionEntity}
                            otherProps={{
                                onSaveComplete: (permission: BaseEntity) => {
                                    this.hideEditingPermission();
                                }
                            }}
                        />
                    </UC.Section>
                    <hr />
                </>
            );
        }

        return (
            <>
                {this.buildPermissionTool()}
                {permissionEditor}
            </>
        );
    }

    hideEditingPermission = async () => {
        console.log(`Hide Permissions`);
        this.editingPermission = false;
        this.permissionEntity = undefined;
        this.setState({});
    }

    showEditingPermission = async () => {
        console.log(`To Show Permissions`);
        const permission = await this.initPermissions();
        if (permission) {
            this.editingPermission = true;
            console.log(`Showing Permissions`);
            this.setState({});
        }
    }

    initPermissions = async () => {
        const eProps = this.getEntityProps();
        if (eProps && eProps.appID && eProps.categoryID && eProps.action && eProps.entityID) {
            const input: any = { ...eProps };
            let permission = await AuthorizationService.find(input);
            if (permission) {
                this.permissionEntity = permission;
            }
        }
        return this.permissionEntity;
    }

    buildPermissionTool = (title: string = this.getTitle()) => {
        return (
            <img
                src={Images.Icon.permission}
                className='tool-icon'
                title={title} alt={title}
                onClick={
                    () => {
                        if (this.editingPermission) {
                            this.hideEditingPermission();
                        }
                        else {
                            this.showEditingPermission();
                        }
                    }
                } />
        );
    }

    getTitle() {
        const entityProps = this.getEntityProps();
        return `Permissions: categoryID - ${entityProps.categoryID}, action - ${entityProps.action}`;
    }

    getEntityCreatedBy = () => {
        let entity: BaseEntity = this.getEntityProps()?.entity;
        if (entity) {
            entity = EntityConstants.build(entity);
            return entity.getCreatedByID();
        } else {
            return undefined;
        }
    }

    getEntityAction = () => {
        const entityProps = this.getEntityProps();
        return entityProps?.action;
    }

    getEntityProps = () => {
        const entityProps: EntityCompProps = this.getOtherProps();
        return entityProps;
    }

    getAction(): string {
        return Router.AppAction.PERMISSION;
    }

    getCategoryID() {
        return Common.CategoryID.Resource;
    }
}