import { App } from './@uno-app/components/uno-app.comp';
import UnoAppModule from './@uno-app/uno-app.module';
import { UnoModule } from './@uno/core';
import { BootableModule } from './@uno/react/platform';
import UnoWorkbookModule from './@uno-workbook/uno-workbook.module';
import CHCAppModule from './app_chc/chc.module';
import Uno3rdPartyModule from './@uno-3rd_party/uno-3rd_party.module';

// The Index Module...

@UnoModule(
    {
        id: 'UnoIndexModule',
        imports: [
            UnoAppModule,
            CHCAppModule,
            UnoWorkbookModule,
            Uno3rdPartyModule,
        ],
        components: [
        ],
        bootstrap: [App]
    }
)
export default class UnoIndexModule extends BootableModule {

    getInitProps() {
        return {};
    }

    unoOnBoot(updateStatus: Function): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                resolve({});
            }
        );
    }
}

