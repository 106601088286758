import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Common, EntityConstants } from '../../../@uno/api';
import { DesignerConstants, UC, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';

Chart.register(ChartDataLabels);

const ChartType = {
    BAR_CHART: { id: 'barchart', label: 'Bar Chart' },
    // LIST: { id: 'list', label: 'Unordered List' },
    PIE_CHART: { id: 'piechart', label: 'Pie Chart' },
    DOUGHNUT_CHART: { id: 'doughnut', label: 'Doughnut Chart' },
}

type Dimension = { l?: number, t?: number, w: number, h: number };
type RGB = { r: number, g: number, b: number, a?: number };

@UnoComponent(
    {
        id: 'ChartJsViz',
        label: 'Chart.js Vizualizer',
        paletteable: true,
        props: [
            {
                id: 'type', label: 'Chart Type', editor: 'OptionSelector',
                extras: {
                    options: Object.values(ChartType).sort((a: any, b: any) => { return a.label > b.label ? 1 : -1 }),
                }
            },
            { id: 'width', label: 'Width' },
            { id: 'height', label: ' Height' },
            { id: 'data', label: ' Data', dataType: EntityConstants.PropType.JSON },
            { id: 'config', label: 'Configurations', dataType: EntityConstants.PropType.JSON },
        ],
        group: DesignerConstants.PaletteGroup.Integration.id,
    }
)
export class ChartJsVizComp extends UnoCoreBaseComp {
    private vizID = Common.getUniqueKey('chartJs_viz_');
    private dim = { w: 100, h: 100 };

    private sampleData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            borderWidth: 1
        }]
    };

    buildComp(): JSX.Element {

        const chartOptions = Object.values(ChartType).map(v => {
            return {
                ...v,
                action: (type: any) => {
                    const newType = v.id;
                    this.setState({ type: newType });
                    this.refreshVisualization(newType);
                },
                isSelected: (v.id === this.state.type),
            }
        });

        return (
            <div>
                <div id={this.vizID} key={this.vizID}>
                    {/* <canvas width={this.dim.w} height={this.dim.h}></canvas> */}
                </div>
                {
                    this.state.showChoices ?
                        <UC.Section styleClasses='right' styles={{ margin: '10px' }}>Chart Type: <UC.SelectBox options={chartOptions} /></UC.Section>
                        : undefined
                }
            </div>
        );

    }

    componentDidMount(): void {
        this.refreshVisualization();
        window.onresize = () => {
            this.refreshVisualization();
        }
    }

    refreshVisualization(type = this.state.type) {
        // console.log('Refreshing Chart: ', type);
        switch (type) {
            /*
            case ChartType.LIST.id:
                this.buildUnorderedList();
                break;
            */
            case ChartType.BAR_CHART.id:
                this.buildBarChart();
                break;
            case ChartType.PIE_CHART.id:
                this.buildPieChart();
                break;
            case ChartType.DOUGHNUT_CHART.id:
                this.buildDoughnutChart();
                break;
        }
    }

    buildUnorderedList = (data: Array<any> = this.getData(), vizContainer = this.getVizContainer(), config = this.getConfig()) => {
        if (!vizContainer) {
            return;
        }
    }

    buildPieChart = (data: any = this.getData(), vizContainer = this.getVizContainer(), config = this.getConfig()) => {
        this.buildChart('pie', data, vizContainer, config);
    }

    buildBarChart = (data: any = this.getData(), vizContainer = this.getVizContainer(), config = this.getConfig()) => {
        this.buildChart('bar', data, vizContainer, config);
    }

    buildDoughnutChart = (data: any = this.getData(), vizContainer = this.getVizContainer(), config = this.getConfig()) => {
        this.buildChart('doughnut', data, vizContainer, config);
    }

    buildChart = (type: any = 'pie', data: any = this.getData(), vizContainer = this.getVizContainer(), config = this.getConfig()) => {
        if (!vizContainer) {
            return;
        }

        const options = {
            scales: {
                /*
                y: {
                    beginAtZero: true
                }
                */
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    align: 'start',
                },
                datalabels: {
                    formatter: (value: any, ctx: any) => {
                        // console.log('Format data label: ', value, Common.checkType.String(value));
                        let sum = 0;
                        const dataArr = ctx.chart.data.datasets[0].data;
                        dataArr.forEach(
                            (data: any) => {
                                sum += data;
                            }
                        );
                        const percentage = (value * 100 / sum).toFixed(0) + '%';
                        return percentage;
                    },
                    color: function (ctx: any) {
                        // return ctx.dataset.borderColor;
                        return '#FFFFFF';
                    }
                }
            }
        };

        const context: any = this.addCanvas(vizContainer, config.canvas);
        new Chart(context,
            {
                type: type,
                data: data,
                options: options,
            }
        );
    }

    addCanvas = (vizContainer = this.getVizContainer(), config: any) => {
        let canvas;

        if (vizContainer) {
            let canvases = vizContainer.getElementsByTagName('canvas');
            if (canvases.length > 0) {
                for (let c of canvases) {
                    vizContainer.removeChild(c);
                }
            }
            canvas = document.createElement('canvas');
            vizContainer.appendChild(canvas);

            // set container styles as per configurations.
            const style = vizContainer.style;
            const containerWidth = vizContainer.offsetWidth;
            const containerHeight = vizContainer.offsetHeight;

            const extras = (config.padding + config.margin + config.border + config.shadow) * 2;
            const canvasWidth = ((containerWidth > 0) ? containerWidth : config.dim.w) * 1 - extras;
            const canvasHeight = canvasWidth;

            // style.height = `${config.dim.h + (config.padding * 2)}px`;
            // style.width = `${config.dim.w + (config.padding * 2)}px`;
            style.width = `${canvasWidth}px`;
            style.height = `${canvasHeight}px`;
            style.backgroundColor = config.backColor;
            style.padding = `${config.padding}px`;
            style.margin = `${config.margin}px`;
            style.border = config.border;
            style.boxShadow = config.shadow;

            // canvas?.setAttribute('height', config.dim.h);
            // canvas?.setAttribute('width', config.dim.w);
        }
        return canvas;
    }

    getVizContainer = () => {
        return document.getElementById(this.vizID);
    }

    getData = () => {
        const data = this.state.data;
        return Common.safeParse(data ? data : this.sampleData);
    }

    getConfig = () => {
        const defaultConfig = {
            canvas: { dim: { w: this.dim.w, h: this.dim.h }, padding: 5, margin: 20, border: '2px solid blue', shadow: '4px 4px green', backColor: 'white' },
            label: { font: 'Arial Black', fontSize: 15, color: 'blue', padding: 10 },
            color: { base: { r: 0, g: 255, b: 200 }, dark: { r: 0, g: 0, b: 0 }, light: { r: 255, g: 255, b: 255 } }
        }

        const config = this.state.config ? Common.safeParse(this.state.config) : defaultConfig;
        config.canvas = { ...defaultConfig.canvas, ...config.canvas };
        config.label = { ...defaultConfig.label, ...config.label };
        config.color = { ...defaultConfig.color, ...config.color };

        // console.log('Visualization Configurations: ', config);
        return config;
    }

    getColorRange = (size: number, dark = this.getConfig().color.dark, light = this.getConfig().color.light) => {
        const bHex = 16;
        const getHex = (rgb: RGB) => {
            return rgb.r.toString(bHex) + rgb.g.toString(bHex) + rgb.b.toString(bHex);
        }

        const darkNum = parseInt(getHex(dark), bHex);
        const lightNum = parseInt(getHex(light), bHex);

        const lower = Math.min(lightNum, darkNum);
        const higher = Math.max(lightNum, darkNum);

        const interval = (higher - lower) / size;
        const range = [];
        for (let i = 0; i < size; i++) {
            const color = Math.round(lower + i * interval);
            range.push(`#${color.toString(bHex)}`);
        }
        return range;
    }
}