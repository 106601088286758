import React from 'react';
import { Link } from 'react-router-dom';
import { DesignerConstants, Router, UnoCompEvents, UnoComponent } from '../../@uno/core';
import { ENTITY_COMP_PROPS } from './entity-base.comp';
import { EntityView } from './entity-view.comp';
import { Common, EntityConstants, FilterConstants } from '../../@uno/api';
import { Profiler } from '../../@uno/api/common.service';

@UnoComponent({
    id: 'EntityQuickView',
    label: 'Entity Quick View',
    paletteable: true,
    props: [
        { groupID: 'Entity', id: 'entityID', label: 'Entity ID', },
        ...ENTITY_COMP_PROPS,
    ],
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    group: DesignerConstants.PaletteGroup.Entity.id,
})
export class EntityQuickView extends EntityView {
    render() {
        if (this.redirectTo) {
            return this.buildRedirect();
        } else if (this.getEntity()) { // fast forward
            // this.profiler.log(`Render quick view`, true, this.getCategory()?.id, this.getEntity());
            return this.buildContent();
        } else {
            return super.render();
        }
    }

    buildContent() {
        let entity = this.getEntity();
        if (entity) {

            entity = EntityConstants.build(entity);
            const thisProps: any = this.props;
            let QV = undefined;
            if (thisProps.noLink) {
                QV = entity.getName()
            } else {
                QV = (
                    <Link to={Router.getViewRoute(entity)} className='entity-quickview'>
                        {entity.getName()}
                    </Link>
                );
            }
            return (
                <span className={` ${this.getStyleClasses()}`} style={this.getStyles()} key={Common.getUniqueKey()}>
                    {QV}
                </span>
            )
        } else {
            return super.buildContent();
        }
    }

    canViewMain() {
        console.log('View Quick: Main View access not allowed')
        return false;
    }

    async loadLayout(action = this.getAction()) {
        // do nothing
    }

    async loadEntity(id: string, callback?: any) {
        if (this.getEntity()) {
            return;
        }

        if (this.isQuickView) {
            // Identify only name
            const fields: any = {
                name: 1,
            };

            const filters = [
                FilterConstants.setCategory(this.getCategoryID()),
                FilterConstants.create('_id', id),
                FilterConstants.create('app_id', this.getAppID()),
            ];
            const res = await FilterConstants.find(filters, { limit: 1, fields: fields }, this.getAppID(), this.getSession());
            if (res?.length > 0) {
                this.handleEntityLoaded(res[0], callback);
            }
        } else {
            super.loadEntity(id, callback);
        }
    }

}
