import React from "react";
import { Common, EntityProp } from "../../@uno/api";
import { UC, UnoComponent } from "../../@uno/core";

@UnoComponent({ id: 'DataSetSelector', label: 'Data-Set Selector' })
export class DataSetSelector extends React.Component<any, any> {
    private dataSet: any;
    private currentStage = 1;
    private finishStage = 2;

    render() {
        let stageViz = <UC.Empty />;
        switch (this.currentStage) {
            case 1:
                stageViz = this.buildDataSetSelector();
                break;
            case 2:
                stageViz = this.buildFieldSelector();
                break;
        }

        return (
            <>
                {this.buildStageIndicator()}
                <hr />
                {stageViz}
                <hr />
                {this.buildStageNavigator()}
            </>
        );
    }

    buildStageIndicator = () => {
        const stages: Array<any> = [<b>Stages </b>];
        for (let i = 1; i <= this.finishStage; i++) {
            stages.push(<>({i})</>);
            if (i !== this.finishStage) {
                stages.push(<> -- </>);
            }
        }
        return <div>{stages}</div>
    }

    buildStageNavigator = () => {
        // console.log('Current Stage - ', this.currentStage, ' Finish stage - ', this.finishStage);

        const stageNavs: Array<any> = [];
        if (this.currentStage > 1 && this.currentStage <= this.finishStage) {
            stageNavs.push({
                id: 'Previous',
                action: this.movePrevious,
            });
        }

        if (this.currentStage < this.finishStage) {
            stageNavs.push({
                id: 'Next',
                action: this.moveNext,
            });
        }

        if (this.currentStage === this.finishStage) {
            stageNavs.push({
                id: 'Finish',
                action: () => {
                    if(this.props.onFinish){
                        this.props.onFinish(this.dataSet);
                    }
                    alert(' Data Set Selection Finished: ');
                }
            });
        }

        // console.log(`Stage Navs: `, stageNavs);

        return (<UC.Navigation navs={stageNavs} orientation='h' key={Common.getUniqueKey()} />);
    }

    moveNext = () => {
        this.currentStage++;
        this.setState({});
    }

    movePrevious = () => {
        this.currentStage--;
        this.setState({});
    }

    buildDataSetSelector = () => {
        const eProp = this.props.eProp;
        return <UC.PropEditor
            // category={propCategory}
            entityProp={eProp}
            // defaultValue={defaultValue}
            otherProps={{
                onPropChanged: (eProp: EntityProp, entity: any) => {
                    // console.log('data-set Prop', eProp, entity);
                    this.dataSet = entity;
                    this.moveNext();
                },
            }} />;
    }

    buildFieldSelector = () => {
        return (
            <>
                <UC.VSection title='Select Fields' cols='2'>
                    <div>Fields</div>
                    <div>Sample Result</div>
                </UC.VSection>
            </>
        );
    }
}