import React from 'react';
import { Router } from '../../../@uno/api';
import { EntityConstants } from '../../../@uno/api/entity.service';
import { UC, UnoComponent } from '../../../@uno/core';
import { EntityViewTypes } from '../../service/entity-view.service';
import { EntityBaseComp } from '../entity-base.comp';

// ENTITY - LIST EDIT 
@UnoComponent({
    id: EntityConstants.ListEditTypes.Grid.id,
})
export class EntityListEdit extends EntityBaseComp {

    protected recordsToList: number = -1;
    protected recordsToSkip: number = 0;

    async doLoadMore() {
        if (this.state.entity) {
            await this.loadEntities(this.state.entity);
        }
    }

    render() {
        if (this.getEntities().length > 0) {
            return this.buildContent();
        } else if (!this.getCategory()) {
            return (<UC.Loading target={this.reRender} />);
        } else {
            return this.buildContent();
        }
    }

    buildContent() {
        const rows: Array<any> = [];
        const eEdits = this.buildEntities();
        let laidOut = 0;
        let colCnt = (this.getOtherProps()?.cols) ? Number(this.getOtherProps().cols) : 1;

        while (laidOut < eEdits.length) {
            let cellWidth = Math.floor(12 / colCnt);
            const cells: Array<any> = [];
            let j = 0;
            while (j < colCnt || laidOut < eEdits.length) {
                cells.push(
                    <div className={`col col-${cellWidth} col-sm-${cellWidth}`}>
                        {eEdits[laidOut]}
                    </div>
                );
                // increase counters.
                j++; laidOut++;
            }
            rows.push(<div className='row'>{cells}</div>)
        }

        return (<>{rows}</>);
    }

    getPrinterComp() {
        return null;
        // return EntityListEdit;
    }

    componentWillUnmount() {
        // EM.unregister(this.createCompletedListenerID);
    }

    isListOptionSelected(id: number) {
        return (this.recordsToList === id);
    }

    isSkipOptionSelected(id: number) {
        return (this.recordsToSkip === id);
    }

    buildPaginationOptions() {
        const listOptions: Array<any> = [
            { id: -1, label: 'All', isSelected: this.isListOptionSelected(-1) },
            { id: 1, label: '1- One', isSelected: this.isListOptionSelected(1) },
            { id: 2, label: '2- Two', isSelected: this.isListOptionSelected(2) },
            { id: 3, label: '3 - Three', isSelected: this.isListOptionSelected(3) },
            { id: 4, label: '4 - Four', isSelected: this.isListOptionSelected(4) },
        ];
        const skipOptions: Array<any> = [
            { id: 0, label: '0 - Zero', isSelected: this.isSkipOptionSelected(0) },
            { id: 1, label: '1- One', isSelected: this.isSkipOptionSelected(1) },
            { id: 2, label: '2- Two', isSelected: this.isSkipOptionSelected(2) },
            { id: 3, label: '3 - Three', isSelected: this.isSkipOptionSelected(3) },
            { id: 4, label: '4 - Four', isSelected: this.isSkipOptionSelected(4) },
        ];
        return (
            <div>
                <div style={{ float: 'right', }}>
                    <span><b>Listing Options:: </b></span>
                    Skip: <UC.SelectBox options={skipOptions} onSelect={this.skipRecords} />
                    Show: <UC.SelectBox options={listOptions} onSelect={this.showRecords} />
                </div>
                <p style={{ clear: 'both' }}> </p>
            </div>
        )
    }

    buildEntities(): Array<any> {
        // console.log('re-render list - ', this.state.entities?.length);
        const EditComp = this.getEntityEditComp();
        const editEntities = this.getEntities().map(
            (entity: any, index: number) => {
                let otherProps = { ...this.getOtherProps() };
                if (this.getOtherProps().onSelected) {
                    otherProps.onSelected = () => {
                        // console.log('List Item selected - ', index, entity)
                        this.getOtherProps().onSelected(entity, index);
                    }
                }
                const key = this.getUniqueKey();
                //console.log('Rendering list item - ', entity, this.getCategory());

                return (
                    <EditComp
                        entity={entity}
                        key={key}
                        categoryID={this.getCategoryID()}
                        otherProps={otherProps}
                        appID={this.getAppID()}
                        entityScreen={this.getEntityScreen()}
                    />
                );
            }
        );
        return editEntities;
    }

    getEntityEditComp = () => {
        return UC.EntityEdit;
    }

    getEntityScreen() {
        const props: any = this.props;
        if (props?.eScreen) {
            return props.eScreen;
        } else {
            return this.getOtherProps()?.entityScreen;
        }
    }

    getAction(): string {
        return Router.CatAction.LIST;
    }

    showRecords = (opt: any) => {
        if (opt) {
            this.recordsToList = parseInt(opt.id);
            // console.log(`Show - ${this.recordsToList} records at a time.`);
            this.setState({});
        }
    }

    skipRecords = (opt: any) => {
        if (opt) {
            this.recordsToSkip = parseInt(opt.id);
            // console.log(`Skip - ${this.recordsToList} records.`);
            this.setState({});
        }
    }

    getEntities() {
        let entitiesList = (this.state.entities) ? this.state.entities : [];
        if (this.recordsToList !== -1) {
            // console.log('No of records to show = ', this.recordsToList);
            return entitiesList.slice(this.recordsToSkip, (this.recordsToList + this.recordsToSkip));
        }
        return entitiesList;
    }

    isQuickEdit() {
        if (this.getOtherProps()?.editType === EntityViewTypes.QUICK) {
            return true;
        }
        return false;
    }

}
