import React from 'react';
import { UnoComponent, UC } from '../../../core';
import './accordion.css';

@UnoComponent(
    {
        id: 'Accordion'
    }
)
export class Accordion extends React.Component<any, any>{
    isCollapsed = false;

    constructor(props: any) {
        super(props);
        this.state = { ...this.props };
        this.isCollapsed = (this.props.collapsed === true);
    }

    render(): React.ReactNode {
        return (
            <div className='accordion'>
                {this.buildHeader()}
                {
                    this.isCollapsed ? <UC.Empty />
                        : (
                            <>
                                {this.buildBody()}
                                {/* this.buildFooter() */}
                            </>
                        )
                }
            </div>
        );
    }

    buildHeader() {
        return (
            <div className='accordion-header' >
                {
                    this.props.icon ? (
                        <div className='accordion-icon'>
                            <img src={this.props.icon} />
                        </div>
                    ) : <UC.Empty />
                }

                <div className='accordion-title' >
                    {this.props.title}
                </div>

                {
                    this.props.toolbar ? (
                        <div className='accordion-toolbar'>
                            {this.props.toolbar}
                        </div>
                    ) : <UC.Empty />
                }

                <div className='accordion-toggler' onClick={this.toggle}>V</div>
            </div>
        );
    }

    buildBody() {
        return (
            <div className='accordion-body' >
                {this.props.children}
            </div>
        );
    }

    buildFooter() {
        return (
            <div className='accordion-footer'>
                Footer
            </div>
        );
    }

    toggle = () => {
        this.isCollapsed = !this.isCollapsed;
        this.setState({});
    }
}