import { UnoModule } from '../../@uno/core';
import { TextFromImageComp } from './components/text-from-image.comp';
// The Module...

@UnoModule(
    {
        id: 'OCRModule',
        imports: [
        ],
        components: [
            TextFromImageComp,
        ],
    }
)
export default class OCRModule {

}

