import { EntityMain } from '../../../@uno-entity/components/entity-main.comp';
import { UnoComponent } from '../../../@uno/core';
import layout from '../layouts/plugin-status-main-layout.json';

// PluginConfig - VIEW
const ID = 'UnoPluginStatusMain';
@UnoComponent({
    id: ID,
    label: 'Plugin Status - Main'
})
export class UnoPluginStatusMain extends EntityMain {

    constructor(props: any) {
        super(props);
        this.state = {
            ...this.state,
            layout: layout,
            otherProps: { ...this.state.otherProps, noActions: true, },
        }
        // console.log('Plugin Config View - Layout: ', layout);
    }

    canProfile(): boolean {
        return false;
    }
}