import React from 'react';
import { CalendarOps, Common } from '../../@uno/api';
import { UC, UnoComponent } from '../../@uno/core';
import { ClosedArea, GeoLocation, GeoLocationService } from '../service/geo-location.service';

const getUniqueKey = (): string => {
    return Common.getUniqueKey();
}

@UnoComponent({ id: 'Poly' })
export class Poly extends React.Component<any, any> {
    // overall
    private locations: Array<GeoLocation> = [];
    private locationSourceFile: string = '';
    private navigatedArea: ClosedArea | undefined = undefined;
    private lastPointIndex = 0;

    private closedAreas: Array<ClosedArea> = [];

    // Intermediate messages...
    private processing: boolean = true;
    private procMessage: any = 'Processing...';

    /*
     Component building
    */
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        console.log(`Rendering Polygon. Processing - ${this.processing}, Message - ${this.procMessage}`);

        if (this.processing) {
            return (
                <UC.Dialog>
                    <h1>
                        {this.procMessage}
                    </h1>
                </UC.Dialog>
            );
        }

        return (
            <div>
                {this.buildResult()}
            </div>
        )
    }

    componentDidMount() {
        if (this.props.locations && this.props.locations.length > 0) {
            console.log('Location are specified as input.')
            this.locations = this.props.locations;
            // just to let the mounting process complete.
            this.initProcessing();
        } else {
            this.setProcessing(false);
        }
    }

    private setProcessing(ON: boolean, msg?: string) {
        this.processing = ON;
        this.procMessage = msg;
        if (this.procMessage) {
            console.log(this.procMessage);
        }
        this.setState({});
    }

    private buildResult() {
        const navArea = this.navigatedArea;
        if (!navArea || !navArea.points || navArea.points.length === 0) {
            return (<UC.Empty />);
        }
        return (
            <div className='row'>
                <div className='col col-s-8 col-8'>
                    {this.buildActionOptions()}
                    <hr />
                    <UC.PolyCanvas
                        navigatedArea={navArea}
                        closedAreas={this.closedAreas}
                    />
                </div>
                <div className='col col-s-4 col-4'>
                    {this.buildSummary()}
                    {/* this.displayPointsAndArea(this.navigatedPoints) */}
                </div>
            </div>
        );
    }

    private buildSummary() {
        let totalLoopArea = 0;
        const loopAreas = this.closedAreas.map(
            (cArea: ClosedArea, idx: number) => {
                totalLoopArea += cArea.area;
                const pointTimeStamps = cArea.points.map((p: any) => new Date(p.time));
                const entry = CalendarOps.getMin(pointTimeStamps);
                const exit = CalendarOps.getMax(pointTimeStamps);
                const pointIndices = cArea.points.map((p: any) => (p.seqId + ', '));
                return (
                    <div className='entity-view'>
                        <div>
                            <b>Enclosed area # {idx + 1}: </b>{cArea.area} sq. meters (approx)
                        </div>
                        <div>
                            <b>Entry Time: </b>{CalendarOps.formatFullDateTime(entry)}
                        </div>
                        <div>
                            <b>Exit Time: </b>{CalendarOps.formatFullDateTime(exit)}
                        </div>
                        <div>
                            <b>Border Points: </b>{pointIndices}
                        </div>
                    </div>
                );

            }
        );
        return (
            <div>
                <h3>Overall Area = {this.navigatedArea?.area} sq. meters (approx)</h3>
                <div>Number of data points = {this.navigatedArea?.points.length}</div>
                <div>Length traversed = {this.navigatedArea?.pathLength} meters</div>
                <div>Average p-2-p distance = {this.navigatedArea?.avP2PDistance} meters</div>
                <h4>Number of enclosed areas: {this.closedAreas.length}</h4>
                <h4>Grand Total of enclosed areas: {totalLoopArea} sq. meters (approx)</h4>
                {loopAreas}
            </div>
        );
    }

    private buildActionOptions() {

        const navigationActions: Array<any> = [
            { id: `<< First`, action: () => { this.navigate(0) } },
            { id: `< Previous`, action: () => { this.navigate(this.lastPointIndex - 1) } },
            { id: `Next >`, action: () => { this.navigate(this.lastPointIndex + 1) } },
            { id: `Last >>`, action: () => { this.navigate() } },
        ]
        return [
            this.buildNavRange(),
            <UC.Navigation navs={navigationActions} orientation='h' />,
        ];
    }

    private buildNavRange() {
        return (
            <div key={getUniqueKey()}>
                <h3>Navigate to </h3>
                Point # <input type='number' min='1' max={this.locations.length} onChange={
                    (evt) => {
                        const newIndex = Number(evt.target.value);
                        if (typeof newIndex === Common.Datatype.Number) {
                            this.lastPointIndex = newIndex - 1;
                        }
                        // console.log(evt.target.value);
                    }
                }
                    defaultValue={this.lastPointIndex + 1}
                />
                <button onClick={() => { this.navigate(this.lastPointIndex); }}>Go</button> or
            </div>
        );
    }

    private initProcessing() {
        this.locations = GeoLocation.filterDuplicates(this.locations);
        // console.log(`this.locations: `, this.locations);
        const msg = `Analyzing ${this.locations.length} Location data-points.`
        this.setProcessing(true, msg);

        setTimeout(
            () => {
                this.locations = this.locations.map(
                    (l: GeoLocation, index) => {
                        l = Object.assign(new GeoLocation(), l);
                        l.seqId = (index + 1);
                        return l.toMeters();
                    }
                );
                // navigate to last point
                this.navigate();
            },
            1
        );
    }

    // analysis entry point
    private navigate(index: number = this.locations.length) {
        if (index < 0) {
            index = 0;
        } else if (index >= this.locations.length) {
            index = this.locations.length - 1;
        }

        this.lastPointIndex = index;

        const navigatedPoints = this.locations.slice(0, this.lastPointIndex + 1);
        this.setProcessing(true, `Processing: ${(navigatedPoints.length)} points`);
        GeoLocationService.processPoints(navigatedPoints)
            .then(
                (result: any) => {
                    if (result && result.length > 0) {
                        const loops: ClosedArea[] = result;
                        this.navigatedArea = loops[0];
                        if (loops.length > 1) {
                            this.closedAreas = loops.slice(1);
                        }
                    }
                    this.setProcessing(false, 'Processing COMPLETE!');
                }
            );
    }

}

@UnoComponent({ id: 'ClosedAreaFinder' })
export class ClosedAreaFinder extends React.Component<any, any>{
    private locationSourceFile: string = '';

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const points = this.props.locations;
        let Finder = <UC.Empty />
        if (points && points.length > 0) {
            Finder = (<Poly locations={points} key={Common.getUniqueKey()} />);
        } else {
            Finder = (
                <div>
                    {this.buildLocationFilter()}
                </div>
            );
        }

        return (
            <div>
                <h2>Enclosed Area Finder</h2>
                {Finder}
                <hr />
                {this.buildTracks()}
            </div>
        )
    }

    private buildLocationFilter() {
        if (this.props.locations) {
            return (<UC.Empty />);
        }
        return (
            <div>
                <h3>Specify Locations</h3>
                <span>Load GPX/CSV file: </span>
                <input type='file' onChange={this.onDataFileSelected} />
                <h6>Last Source File : {this.locationSourceFile}</h6>
            </div>
        );
    }

    private buildTracks() {
        const tracks = this.state.tracks;
        const PolyOfTracks = tracks?.map(
            (trackLocs: Array<GeoLocation>) => {
                return (<Poly locations={trackLocs} key={Common.getUniqueKey()} />);
            }
        );
        return PolyOfTracks;
    }

    /*
    Local file-data loading
    */
    private onDataFileSelected = (event: any) => {
        const selectedFile: any = event.target.files[0];
        if (selectedFile && FileReader) {
            const fileName: string = selectedFile.name;
            this.locationSourceFile = fileName;
            const fileExt: string = fileName.split('.').reduce((p: any, n: any) => { return n; });

            console.log(`Selected file name: [${fileName}]. Extension: ${fileExt}`);
            const reader = new FileReader();
            reader.onload = (ev: any) => {
                let content: string = ev.target.result;
                switch (fileExt) {
                    case 'gpx':
                        const tracks = this.processGPXContent(content);
                        this.setState({ tracks: tracks });
                        break;
                    default:
                        alert(`Selected file [${fileName}] is not among supported formats`);
                        this.locationSourceFile = '';
                }
            };
            reader.readAsText(selectedFile);
        }
    }

    private processGPXContent = (content: string) => {
        const tracks: Array<Array<GeoLocation>> = [];
        if (DOMParser) {
            let parser: DOMParser = new DOMParser();
            try {
                const xmlDoc: Document = parser.parseFromString(content, 'text/xml');
                const rootNode = xmlDoc.getRootNode().firstChild;
                // console.log('Root Node: ', rootNode);
                if (rootNode?.nodeName !== 'gpx') {
                    alert('Not a GPX file.');
                    return;
                }

                const trackElems = xmlDoc.getElementsByTagName('trk');
                for (let ti = 0; ti < trackElems.length; ti++) {
                    const trackLocs = this.processTrack(trackElems[ti]);
                    tracks.push(trackLocs);
                }

                // alert('File content loaded in the console');
            } catch (e: any) {
                alert('Problem parsing file content. \n' + e.message);
            }
        }
        return tracks;
    }

    processTrack(track: Element) {
        const trackGeoLocations: Array<GeoLocation> = [];
        const points = track.getElementsByTagName('trkpt');
        for (let i = 0; i < points.length; i++) {
            const p = points[i];
            const loc = new GeoLocation();
            loc.seqId = (i + 1);
            const lat = p.getAttribute('lat');
            if (lat) {
                loc.lat = Number(lat);
            }
            const long = p.getAttribute('lon');
            if (long) {
                loc.long = Number(long);
            }

            p.childNodes.forEach(
                ch => {
                    switch (ch.nodeName) {
                        case 'ele':
                            const ele = ch.textContent;
                            if (ele) {
                                loc.elevation = Number(ele);
                            }
                            break;
                        case 'time':
                            const time = ch.textContent;
                            if (time) {
                                loc.time = time;
                            }
                            break;
                    }
                }
            );

            if (loc.lat && loc.long) {
                // loc.toMeters();
                trackGeoLocations.push(loc);
            }
        }
        return trackGeoLocations;

    }


}