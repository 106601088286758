import React from 'react';
import { UC, UnoComponent } from '../../@uno/core';

@UnoComponent({id:'CHCHome'})
export class CHCHome extends React.Component<any, any>{

    render() {
        return (
            <UC.ClosedAreaFinder />
        );
    }
}
