import React from 'react';
import { Common, UnoComponent } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { EntityConstants } from '../../api';

@UnoComponent({
    id: 'IFrame',
    props: [
        { id: 'src' },
        { id: 'width' },
        { id: 'height' },
        { id: 'allowFullScreen', dataType: EntityConstants.PropType.BOOLEAN },
        // { id: 'otherProps', label: 'Other Props', dataType: EntityConstants.PropType.JSON },
    ],
    paletteable: true,
})
export class IFrame extends UnoCoreBaseComp {

    buildComp() {
        let src: string = this.state.src || '';
        let width: number = this.state.width || '100px';
        let height: number = this.state.height || '100px';
        let allowFullScreen = Common.safeParse(this.state.allowFullScreen);

        return (
            <iframe
                key={Common.getUniqueKey(src)}
                src={src}
                width={width}
                height={height}
                style={{ ...Common.safeParse(this.state.styles) }}

                allowFullScreen={(allowFullScreen !== undefined) ? allowFullScreen : false}
            />
        );
    }
}