import React from 'react';
import { Common, EntityConstants, RemoteService } from '../../../api';
import { DesignerConstants, UC, UnoComponent } from '../../../core';
import { UnoCoreBaseComp } from '../uno-core.base.comp';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

@UnoComponent({
    id: 'PDFViewer',
    label: 'PDF Viewer',
    // icon: Images.Icon.section,
    isContainer: false,
    paletteable: true,
    props: [
        { id: 'url', label: 'URL' },
        { id: 'file', label: 'File', dataType: EntityConstants.PropType.FILE },
    ],
    group: DesignerConstants.PaletteGroup.Viewer.id,
    // serialNo: 101,
})
export class PDFViewer extends UnoCoreBaseComp {

    buildComp() {
        const url = this.getURL();
        console.log('The PDF URL: ', url);
        const numPages = this.state.numPages || 0;

        return (
            <div key={Common.getUniqueKey('pdf_viewer_')} >
                {/* 
                <h4>View PDF</h4>
                <div>URL: {this.state.url}</div>
                <div>File: {Common.stringify(this.state.file)}</div> 
                */}
                <UC.ScreenReader />
                <div
                    style={
                        {
                            maxHeight: '90vh',
                            overflow: 'scroll',
                        }
                    }
                >
                    {
                        url ?
                            <Document
                                file={url}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                options={{}}
                            >
                                {
                                    Array.from(
                                        new Array(numPages),
                                        (el, index) => (
                                            <div style={{ border: '1px solid', margin: '5px' }}>
                                                <Page
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                    width={Common.Window.screen.availWidth - 100}
                                                    renderTextLayer={true}
                                                    renderAnnotationLayer={false}
                                                // renderMode='none'
                                                />
                                            </div>
                                        )
                                    )
                                }
                            </Document>
                            : undefined
                    }
                </div>
            </div>
        );
    }

    getURL = () => {
        const file = this.state.file;
        let url = this.state.url;
        if (!url && file) {
            if (file.downlink) {
                url = RemoteService.getFullURL(file.downlink);
            } else {
                this.loadFile();
            }
        }
        return url;
    }

    loadFile = async () => {
        let file = this.state.file;
        if (file) {
            file = await EntityConstants.build(file).reload();
            this.reRender({ file: file });
        }
    }

    onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        if (this.state.numPages === numPages) {
            return;
        }
        console.log('Loaded pages: ', numPages)
        this.reRender({ numPages: numPages });
    }
}
