import React from 'react';
import { EntityCategoryService } from '../../@uno-app/service/entity.category.service';
import { PropMultipleEditor } from '../../@uno-entity-prop/components/editors/prop-multiple-editor.comp';
import { BaseEntity, EntityConstants, EntityProp } from "../../@uno/api";
import { UC, UnoComponent } from "../../@uno/core";

// WSColumnsEdit - EDIT
@UnoComponent({
    id: 'WSColumnsEdit',
    label: 'Edit Worksheet Columns',
    props: [],
    getDesign: () => { return (<div>Edit Worksheet Columns - Design</div>); },
    getPreview: () => { return (<div>Edit Worksheet Columns - Preview</div>); }
})
export class WSColumnsEdit extends PropMultipleEditor {

    records: Array<BaseEntity> = [];

    constructor(props: any) {
        super(props);

        const rows = this.getPropValue();
        if (rows) {
            this.records = rows.map(
                (row: any) => {
                    return EntityConstants.build(row);
                }
            );
        }
    }

    render() {
        return (
            <div className='entity-list'>
                <table>
                    <thead>{this.buildColumns()}</thead>
                    <tbody>{this.buildRows()}</tbody>
                </table>
            </div>
        );
    }

    buildColumns() {
        const props = this.getCatProps();
        const cols = props.map(
            (prop: EntityProp) => {
                return (
                    <th key={this.getUniqueKey()}>
                        <span title={prop.description ? prop.description : prop.label}> {prop.label} </span>
                    </th>
                );
            }
        );
        return <tr key={this.getUniqueKey()}>{cols}</tr>;
    }

    getCatProps() {
        const categroyID = this.getCategoryID();
        let category = this.getCategory();
        if (!category && categroyID) {
            category = EntityCategoryService.getAppCategory(categroyID, this.getAppID());
        }
        // console.log('WS Column Category: ', category);
        const props = category?.props;
        return props ? props : [];
    }

    buildRow(rowEntity: BaseEntity) {
        const cells = this.getCatProps().map((prop: EntityProp) => {
            const value = rowEntity.getValue(prop.id);
            return (
                <td>
                    <UC.PropComp
                        key={this.getUniqueKey()}
                        entityProp={prop}
                        categoryID={this.getCategoryID()}
                        defaultValue={value}
                        entity={this.getEntity()}
                        otherProps={{
                            hideLabel: true, onPropChanged: (p: EntityProp, nv: any) => {
                                rowEntity.setValue(p.id, nv);
                                this.setPropValue(this.records);
                            }
                        }}
                        appID={this.getAppID()}
                        editing={!prop.readonly}
                    />
                </td>
            );
        });
        return <tr key={this.getUniqueKey()}>{cells}</tr>;
    }

    buildRows() {
        return this.records?.map((row: any) => {
            return this.buildRow(row);
        });
    }
}