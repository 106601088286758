import { UnoComponent, UnoComponentManager, UnoCompEvents, DesignerConstants, ComponentDef as CDef, ContentConfig, DragDropManager } from './defs/uno-component';
import { UnoModule, UnoModuleManager } from './defs/uno-module';
import { Common, EM, CalendarConstants, CalendarOps, RemoteService, Router, printPDF, Images } from './api';
import { UnoCoreModule } from './core/uno-core.module'
import { UnoCoreBaseComp } from './core/components/uno-core.base.comp';
// load Uno Core Module
export { UnoCoreModule, }

export const UC: any = UnoComponentManager.UNO_COMPS;
export type ComponentDef = CDef;

export const DDM = DragDropManager.getInstance();

export {
    Common, EM, CalendarConstants, CalendarOps, RemoteService, Router,
    UnoModuleManager, UnoModule,
    UnoComponentManager, UnoComponent, DesignerConstants, ContentConfig, UnoCompEvents,
    printPDF, Images,
    UnoCoreBaseComp,
};