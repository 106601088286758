import { BaseEntity, EntityConstants, RemoteService, Router } from '../../@uno/api';
import { Common, EM, } from '../../@uno/core';
import { SessionManager } from './session.service';

export const AppConstant = {
    Attr: {
        AppConfig: {
            PUBLIC_ACTIONS: 'public_actions',
            OTHER_CONFIG: 'other_config',
        },
    }
}

export type AppInfo = {
    id: string;
    title?: string;
    subtitle?: string;
    contact?: string;
    logo?: any;
    theme?: any;
}

class AppInfoServiceImpl {
    private theActiveApp: AppInfo | undefined = undefined;

    constructor() {
        // trial
        //console.log('Loading APIs.');
        RemoteService.get('api/load').then(
            resp => {
                resp.json().then((json: any) => {
                    const apiText = Common.stringify(json);
                    // console.log('Loaded API Text ', apiText);
                    try {
                        const api = Common.safeParse(apiText);
                        if (api) {
                            // console.log(`Test APIs: `);
                            const sum = api.add(3, 2, 5);
                            // console.log('Sum : ', sum);
                        }
                    } catch (e) { }
                });
            }
        );
    }

    getActiveApp = () => { return this.theActiveApp }

    setActiveApp(appInfo: AppInfo) {
        if (appInfo && (!this.theActiveApp || this.theActiveApp.id !== appInfo.id)) {
            this.theActiveApp = appInfo;
            EM.emit(Common.Event.APP_SWITCHED, this.theActiveApp);
        }
    }

    loadApp(id?: string, status: string = 'active'): Promise<AppInfo> {
        return new Promise(
            async (resolve, reject) => {
                if (this.theActiveApp && this.theActiveApp.id === id) {
                    resolve(this.theActiveApp);
                    return;
                }
                const json = await RemoteService.getData(await RemoteService.post('app/find', { appID: id, session: SessionManager.activeSession, status: status }));
                if (json) {
                    this.setActiveApp(json.app);
                    if (this.theActiveApp) {
                        resolve(this.theActiveApp);
                    } else {
                        reject(`No App Info found with id: ${id}`);
                    }
                } else {
                    reject(`No App Info found with id: ${id}`);
                }
            }
        );
    }

    async getStyles(appID: any, session: any = SessionManager.activeSession) {
        const appStyles = await RemoteService.getData(await RemoteService.post(`app/styles`, { appID: appID, session: session }));
        if (appStyles?.styles) {
            return appStyles.styles;
        } else {
            return '';
        }
    }


    async getAppConfig(appID: any, session: any = SessionManager.activeSession) {
        if (!appID) {
            return undefined;
        }
        const input = { appID: appID, session: session }
        // console.log(`Find App Config `, input);
        const appConfig = await RemoteService.getData(await RemoteService.post(`app/config`, input));
        return appConfig;
    }

    async getPublicActions(appID: any) {
        let appConfig: BaseEntity = await this.getAppConfig(appID);
        appConfig = appConfig ? EntityConstants.build(appConfig) : appConfig;
        const publicActions: Array<string> = appConfig?.getValue(AppConstant.Attr.AppConfig.PUBLIC_ACTIONS);
        // console.log(`${appID} - Public Actions: `, publicActions);
        return publicActions;
    }

    async isSelfSignUpEnabled(appID: any) {
        const publicActions = await this.getPublicActions((appID));
        if (!publicActions || publicActions.includes(Router.AppAction.SIGNUP)) {
            return true;
        } else {
            return false;
        }
    }

    async isSignInEnabled(appID: any) {
        const publicActions = await this.getPublicActions((appID));
        if (!publicActions || publicActions.includes(Router.AppAction.SIGNIN)) {
            return true;
        } else {
            return false;
        }
    }

    async getSupportedLoginModes(appID: any) {
        let appConfig: BaseEntity = await this.getAppConfig(appID);
        appConfig = appConfig ? EntityConstants.build(appConfig) : appConfig;
        const otherConfig = Common.safeParse(appConfig?.getValue(AppConstant.Attr.AppConfig.OTHER_CONFIG));
        return otherConfig?.loginModes || [];
    }
}

export const AppInfoService = new AppInfoServiceImpl();