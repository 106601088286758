import { UnoModule } from '../@uno/core';
import { BootableModule } from '../@uno/react/platform';
import { EntityModule } from '../@uno-entity/entity.module';
import { AppLayout } from './components/uno-app-layout.comp';
import { App, Hello } from './components/uno-app.comp';
import { Content } from './components/uno-content.comp';
import { Footer } from './components/uno-footer.comp';
import { Header } from './components/uno-header.comp';
import { AppHome } from './components/uno-home.comp';
import { RouteContent } from './components/uno-route-content.comp';
import { PrimaryMenu } from './components/uno-primary-menu.comp';
import { Profile } from './components/uno-profile.comp';
import { UnoAppBaseComp } from './components/uno-app.base.comp';
import { MenuComp } from './components/uno-menu.comp';
import { AccessControlledActions } from './components/access-controlled-actions.comp';
import { UnoPluginModule } from '../@uno-plugin/uno-plugin.module';

// The Module...

@UnoModule(
    {
        id: 'UnoAppModule',

        imports: [
            EntityModule,
            UnoPluginModule,
        ],

        components: [
            UnoAppBaseComp,
            Header,
            Profile,
            PrimaryMenu,
            MenuComp,
            Content,
            RouteContent,
            Footer,

            App,
            AppLayout,
            AppHome,
            Hello,
            AccessControlledActions,
        ],
        // bootstrap: [App]
    }
)
export default class UnoAppModule extends BootableModule {

    getInitProps() {
        return {};
    }

    unoOnBoot(updateStatus: Function): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                resolve({});
            }
        );
    }
}

