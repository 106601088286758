import React from 'react';
import { EntityConstants } from '../../api/entity.service';
import { printPDF } from '../../api/pdf.service';
import { UC, UnoComponent, Images, Common, EM, RemoteService, } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';

// ENTITY - NEW, EDIT
@UnoComponent({
    id: 'Printer',
    label: 'Printer',
    props: [
        { id: 'label', label: 'Label' },
        { id: 'compID', label: 'Target Component ID' },
        // { id: 'viewID' },
        // { id: 'printer', editor: 'ComponentSelector' },
        // { id: 'otherProps', dataType: EntityConstants.PropType.JSON, },
    ],
    // getDesign: (config?: any, clbk?: any, buildChildren?: Function) => { return (<div>Print</div>); },
    paletteable: true,
})
export class Printer extends UnoCoreBaseComp {
    private loadingAndPrinting = false;
    private _handler_PRINTABLE_CONTENT_RECEIVED: any;

    componentDidMount(): void {
        super.componentDidMount();

        this._handler_PRINTABLE_CONTENT_RECEIVED =
            EM.register(
                Common.Event.PRINTABLE_CONTENT_RECEIVED,
                async (data: any) => {

                    const reportName = `report_${new Date().getTime()}`;
                    let contentNode = data.contentNode;
                    const content = data.content;
                    console.log(`To Print ${reportName}.pdf: `, data);

                    if (contentNode) {
                        if (!contentNode.style?.width || !contentNode.style?.height) { // need to wrap in a div
                            const wrapperDiv = document.createElement('div');
                            wrapperDiv.style.width = '8in'; // A4 size;

                            wrapperDiv.appendChild(contentNode.cloneNode(true));
                            contentNode = wrapperDiv;
                            console.log('To Print a wrapped content: ', contentNode);
                        }

                        await printPDF(undefined, reportName, contentNode);
                        Common.showMessage(`Printed.`);
                    } else if (content) {
                        const styles = Common.Styles.getRules();
                        let styleDefs = '';
                        styles?.forEach(s => {
                            styleDefs += s.cssText + ' ';
                        })
                        const template = `
                                ${content}
                            `;

                        const resp = await RemoteService.post('report/printPDF', { template: template, });
                        resp.blob().then(
                            blob => {
                                console.log('PDF file size: ', blob.size);
                                const URL = window.URL ? window.URL : window.webkitURL;
                                const fileURL = URL.createObjectURL(blob);
                                // window.open(url);

                                var a = document.createElement('a');
                                document.body.appendChild(a);
                                a.style.display = 'none';
                                a.href = fileURL;
                                a.download = `${reportName}.pdf`;
                                a.click();
                                a.remove();
                                URL.revokeObjectURL(fileURL);

                                console.log('Printed: ', fileURL);
                                Common.showMessage(`Printed.`);
                            }
                        );
                    }
                }
            );
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        EM.unregister(this._handler_PRINTABLE_CONTENT_RECEIVED);
    }

    buildComp() {
        if (this.loadingAndPrinting) {
            return this.buildPrintable();
        } else {
            const printAction = {
                id: 'Print',
                label: this.state.label ? this.state.label : 'Print',
                icon: Images.Icon.printer,
                action: async () => {
                    const viewID = this.props.viewID;
                    // console.log(`Printable view ID: `, viewID);
                    const printableCompID = Common.safeParse(this.state.compID);
                    /*
                    if (Common.isSmallScreen()) {
                        if (printableCompID) {
                            Common.showMessage('Printing...');
                            console.log('Printable Comp ID: ', printableCompID);
                            EM.emit(`${Common.Event.PRINTABLE_CONTENT_REQUESTED}_${printableCompID}`, { compID: printableCompID });
                        } else if (viewID) {
                            this.printContent(viewID);
                        } else {
                            this.loadingAndPrinting = true;
                            this.setState({});
                        }
                    } else 
                    */
                    if (printableCompID || viewID) { // native printing
                        const printable = document.getElementById(printableCompID || viewID);
                        if (printable) {
                            const content = printable.innerHTML;
                            const WinPrint = window.open('', '', `left=0,top=0,width=${window.screen.availWidth},height=${window.screen.availHeight},toolbar=0,scrollbars=0,status=0`);
                            if (WinPrint) {
                                const printDoc = WinPrint.document;
                                const ssList = document.styleSheets;
                                let ssText = '';

                                for (let ss of ssList) {
                                    try {
                                        const rules = ss.cssRules || [];
                                        for (let rule of rules) {
                                            ssText = ssText + '\n' + rule.cssText;
                                        }
                                    } catch (e) {
                                        console.log('Print error: ', e);
                                    }
                                }


                                const pdStyle = printDoc.createElement('style');
                                pdStyle.innerHTML = ssText;
                                printDoc.head.appendChild(pdStyle);

                                const pdSS = printDoc.styleSheets.item(0);
                                // console.log('StyleSheets: ', ssList, pdSS);
                                printDoc.body.innerHTML = content;

                                printDoc.close();
                                WinPrint.focus();
                                await Common.pause(1);
                                WinPrint.print();
                                // WinPrint.close();
                            }
                        }
                    }
                }
            };
            return <UC.Navigation navs={[printAction]} isToolbar={(Common.isSmallScreen() || this.state.label) ? false : true} />
            /*
            return (
                <img className='tool-icon' src={Images.Icon.printer} alt={printAction.id} onClick={printAction.action} title={printAction.id} />
            );
            */
        }
    }

    private buildPrintable() {
        let ThePrinterView = this.props.printer;
        if (Common.checkType.String(ThePrinterView)) { // component ID is specified
            ThePrinterView = UC[ThePrinterView];
        }
        let otherProps = this.props.otherProps;
        if (otherProps && Common.checkType.String(otherProps)) { // props in string format
            otherProps = Common.parse(otherProps);
        }

        if (ThePrinterView) {
            const id = Common.getUniqueKey();
            const contentID = `printable_${id}`;

            const handlePrint = () => {
                this.printContent(contentID);
            };

            return (
                <UC.Dialog onClose={this.donePrinting}>
                    <button onClick={handlePrint}>Print</button>
                    <div id={contentID}>
                        {(ThePrinterView) ? <ThePrinterView {...otherProps} /> : <div>Nothing to Print</div>}
                    </div>
                </UC.Dialog>
            );
        } else {
            this.donePrinting();
            return (undefined);
        }
    }

    printContent = (eleID: string) => {
        printPDF(eleID, eleID);
        /*
        const content = document.getElementById(eleID)?.innerHTML;
        const header = document.head.innerHTML;
        const printWindow = window.open('');
        if (content && printWindow) {
            const printDoc = printWindow.document;
            printDoc.body.innerHTML = content;
            printDoc.head.innerHTML = header;
            printWindow.print();
        } else {
            window.print();
        }
        */
    };

    donePrinting = async () => {
        // Common.showMessage('Printed');
        this.loadingAndPrinting = false;
        this.setState({});
    }
}