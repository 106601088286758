declare var FB:any;

export const FBLogin = {
    init: async () => {
        window.fbAsyncInit = function () {
            FB?.init({
                appId: '2231242150402662',
                cookie: true,
                xfbml: true,
                // version: '{api-version}'
            });

            FB?.AppEvents.logPageView();

        };

        (function (d, s, id) {
            var js:any, fjs:any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
}
