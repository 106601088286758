import React from 'react';
import { Common, DesignerConstants, UC, UnoComponent, UnoCoreBaseComp } from '../../@uno/core';
import { AppInfo } from '../service/app.info.service';
import { AppScreenService } from '../service/app.screen.service';

@UnoComponent({
    id: 'AppHome',
    label: 'App Home Page',
    /*
    getPreview: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Home Page - PREVIEW</div>);
    },
    getDesign: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Home Page - DESIGN</div>);
    },
    */
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class AppHome extends UnoCoreBaseComp {

    componentDidMount() {
        super.componentDidMount();
        const app: AppInfo = this.getApp();
        if (app) {
            AppScreenService.getHomeScreen(app.id).then(screen => {
                if (screen) {
                    // this.homeScreen = screen;
                    this.setState({ screen: screen });
                }
            });
        }
    }

    buildComp() {
        //console.log(`Init Header Props: `, this.props.app);
        const app: AppInfo = this.getApp();
        if (!app) {
            return undefined;
        }

        if (this.state.screen) {
            return this.buildHomeScreen();
        }
        return (
            <div >
                {/* 
                <p>
                    <Link to={`/${app.id}/uno_screen_def/create`}>Create</Link> a custom home screen.
                </p>
                <p>
                    Then set it as the Home Screen on <Link to={`/${app.id}/uno_app_config/edit/${app.id}`}>App Config</Link>.
                </p>
                <UC.Header appID={app.id} />
                <UC.Profile appID={app.id} />
                */}
                <UC.Loading target={this.reRender} />
            </div>
        );
    }

    private getApp(): AppInfo {
        return (this.props.app) ? this.props.app : undefined;
    }

    buildHomeScreen() {
        const layout = this.state.screen;
        if (!layout) {
            return undefined;
        }
        layout.props = { appID: this.getApp().id, otherProps: {} };

        // this.profiler.log(`Building comp ${this.constructor.name}. Layout =  `, layout);
        return (
            <UC.LayoutRenderer
                config={layout}
                mode={DesignerConstants.Mode.LIVE}
                key={Common.getUniqueKey()}
            />
        );
    }
}
