import React from 'react';
import { EntityConstants } from '../../../@uno/api/entity.service';
import { UC, UnoComponent } from '../../../@uno/core';
import { EntityListEdit } from './entity-list.edit.comp';

// MULTI ENTITY - CAROUSEL EDIT
@UnoComponent({ 
    id: EntityConstants.ListEditTypes.Carousel.id,
})
export class EntityCaraouselEdit extends EntityListEdit {

    buildContent() {
        return (
            <UC.Carousel>
                {this.buildEntities()}
            </UC.Carousel>
        );
    }

}
