import React from 'react';
import { UC, UnoComponent, EM, Common, } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';

const DEFAULT_TIMEOUT = 5;
@UnoComponent({ id: 'ShowMessage' })
export class ShowMessage extends UnoCoreBaseComp {
    private _messageDisplayer: any;
    private _messageCloser: any;
    private _routeChangeRequestHandler: any;
    private _routeChangedHandler: any;

    private mQueue: Array<any> = [];
    private timeOutDefault = DEFAULT_TIMEOUT;

    componentDidMount() {
        super.componentDidMount();
        this._messageDisplayer = EM.register(Common.Event.SHOW_MESSAGE, (message: any) => {
            if (message.clear) {
                this.mQueue.splice(0);
            }

            this.mQueue.unshift(message);
            this.reRender();
        });

        this._messageCloser = EM.register(Common.Event.HIDE_MESSAGE, async (clbk?: Function) => {
            if (clbk) {
                try { clbk(); } catch (e) { }
            }

            this.mQueue.splice(0);
            this.reRender();
        });

        this._routeChangeRequestHandler = EM.register(Common.Event.ROUTE_CHANGE_REQUESTED, (route: string) => {
            console.log('Route to : ', route);
            this.setRedirectTo(route);
        });

        this._routeChangedHandler = EM.register(Common.Event.ROUTE_CHANGED, (route: string) => {
            // EM.emit(Common.Event.SHOW_MESSAGE, `Routed to : ${route}`);
        });
    }
    componentWillUnmount() {
        EM.unregister(this._messageDisplayer);
        EM.unregister(this._routeChangeRequestHandler);
        EM.unregister(this._routeChangedHandler);
        super.componentWillUnmount();
    }

    remove = (idx: number) => {
        this.mQueue.splice(idx, 1);
        this.reRender();
    }

    buildComp() {
        if (this.mQueue && this.mQueue.length > 0) {
            const mViews = this.mQueue.map(
                (m, idx) => {
                    return (
                        <UC.Section
                            onTimedOut={() => { this.remove(idx); }}
                            timeout={m.timeOut || DEFAULT_TIMEOUT}
                            key={Common.getUniqueKey()}
                        // onClose={() => { this.remove(idx); }}
                        // title='Updates...'
                        >

                            <UC.Chip styleClasses='message-view' onClose={() => { }}>
                                <div
                                    // className='message-view'
                                    key={Common.getUniqueKey()}
                                    dangerouslySetInnerHTML={{ __html: m.message }}
                                    style={{ whiteSpace: 'nowrap', padding: '2px 60px 2px 20px' }}
                                />
                            </UC.Chip>
                        </UC.Section>
                    );
                }
            );
            return (
                <div style={{ position: 'absolute', bottom: '0px', left: '0px', zIndex: '1000' }}>
                    {mViews}
                </div>
            )
        } else {
            return (<UC.Empty />);
        }
    }
}
