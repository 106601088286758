import React from 'react';
import { Link } from 'react-router-dom';
import { Common, UC, Images, UnoComponent } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { EntityConstants } from '../../api';

const REATTEMPT_LIMIT: number = 5;

@UnoComponent({
    id: 'Loading',
    props: [
        { id: 'timeout', label: 'Time (seconds)', dataType: EntityConstants.PropType.NUMBER },
        { id: 'icon', label: 'Icon', },
    ],
    paletteable: true,
})
export class Loading extends UnoCoreBaseComp {
    private attempCnt = 0;
    private timedOut = false;
    private _handler_timeout: any;

    componentDidMount() {
        super.componentDidMount();

        const timeout = this.state.timeout >= 0 ? this.state.timeout : 5; // 5 seconds.
        this._handler_timeout = (timeout > 0) ?
            setInterval(() => {
                if (this.attempCnt >= REATTEMPT_LIMIT) {
                    clearInterval(this._handler_timeout);
                } else {
                    let targetAction = Common.safeParse(this.props.target);
                    if (targetAction && Common.checkType.Function(targetAction)) {
                        targetAction();
                        this.attempCnt++;
                    }
                }
            }, 1000 * timeout)
            : undefined;
    }

    componentWillUnmount(): void {
        clearInterval(this._handler_timeout);
        super.componentWillUnmount();
    }

    buildComp() {
        if (this.timedOut) {
            return <UC.Refresh key={Common.getUniqueKey()} {...this.props} />;
        } else {
            return (
                <>
                    <img
                        src={this.state.icon || Images.Icon.loading}
                        className={`tool-icon ${this.getStyleClasses()}`}
                        style={this.getStyles()}
                    />
                </>
            );
        }
    }
}

@UnoComponent({
    id: 'Refresh',
    props: [
        { id: 'title', label: 'Title', },
        { id: 'icon', label: 'Icon', },
    ],
    paletteable: true,
})
export class Refresh extends UnoCoreBaseComp {
    buildComp() {
        const icon = (
            <img
                src={this.state.icon || Images.Icon.refresh}
                title={this.state.title ? this.state.title : 'Refresh'}
                className={`tool-icon ${this.getStyleClasses()}`}
                style={this.getStyles()}
            />
        );

        let targetAction = Common.safeParse(this.props.target);
        if (targetAction && Common.checkType.Function(targetAction)) {
            return (
                <span onClick={
                    () => {
                        // console.log('Invoke Refresh target');
                        targetAction();
                    }
                }>
                    {icon}
                </span>
            );
        } else {
            return (
                <Link to={window.location} onClick={() => { window.location.reload() }}>{icon}</Link>
            );
        }

    }
}