// import * as moment from 'moment';
import moment, { unitOfTime } from 'moment-timezone';

const DEFAULT_SLOT_DURATION = 60;
const TIME_TO_START_SESSION = 60;
const DEFAULT_CANCEL_BOOKED_LEAD_TIME = 4;
const DEFAULT_TIME_ZONE = 'Asia/Kolkata';

export type Offset = { val: number, unit: unitOfTime.DurationConstructor };

const ALL_TIME_ZONES = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
];

const SLOT_CHOICES: Array<{ id: string, values: Array<string> }> = [

    {
        id: 'Early Morning',
        values: [
            '12:00 AM', '01:00 AM', '02:00 AM', '03:00 AM', '04:00 AM', '05:00 AM'
        ]
    },
    {
        id: 'Morning',
        values: [
            '06:00 AM', '07:00 AM', '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM'
        ]
    },
    {
        id: 'Afternoon',
        values: [
            '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM',
        ]
    },
    {
        id: 'Late Evening',
        values: [
            '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM', '11:00 PM',
        ],
    },
    /*
    {
        id: 'Working Hours',
        values: [
            '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM',
        ],
    }
    */
];


export class Week {
    firstDay: Date = new Date();
    lastDay: Date = new Date();
}

export const CalendarConstants = {
    CHOICES: SLOT_CHOICES,
    TIME_ZONES: ALL_TIME_ZONES.sort(),
    DEFAULT_TIME_ZONE: DEFAULT_TIME_ZONE,
    DEFAULT_DURATION: DEFAULT_SLOT_DURATION,
    TIME_TO_START_SESSION: TIME_TO_START_SESSION,
    FULL_DATE_FORMAT: 'dddd, Do MMMM, YYYY',
    FULL_DATE_TIME_FORMAT: 'dddd, Do MMMM, YYYY hh:mm:ss A z',
    DATE_FORMAT: 'dddd, DD MMM YY',
    SQL_DATE_FORMAT: 'YYYY-MM-DD',
    SQL_DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
    TIME_FULL_FORMAT: 'hh:mm A z',
    TIME_FORMAT: 'HH:mm',
    CANCEL_LEAD_TIME: DEFAULT_CANCEL_BOOKED_LEAD_TIME,
}


class CalendarOpsImpl {
    Constants = CalendarConstants;
    MOMENT = moment;

    offset = (date: Date = new Date(), by: Array<Offset>) => {
        let time = moment(date);
        by.forEach(
            (offBy: Offset) => {
                time = time.add(offBy.val, offBy.unit);
            }
        );
        console.log(date, by, time.toDate());
        return time.toDate();
    }

    isPastDate = (date: Date) => {
        const today = new Date();
        date = new Date(date);
        if (date.getTime() < today.getTime()) {
            return true;
        }
        return false;
    }

    getMin = (dates: Array<Date>) => {
        const output = dates.reduce(
            (prev: Date, current: Date) => {
                prev = new Date(prev);
                current = new Date(current);
                if (prev && prev < current) {
                    return prev;
                } else {
                    return current;
                }
            }
        );
        return output;
    }

    getMax = (dates: Array<Date>) => {
        const output = dates.reduce(
            (prev: Date, current: Date) => {
                prev = new Date(prev);
                current = new Date(current);
                if (current && current > prev) {
                    return current;
                } else {
                    return prev;
                }
            }
        );
        return output;
    }

    getDateRange = (from: Date, to: Date) => {
        const start = this.getMin([from, to]);
        const end = this.getMax([from, to]);
        const current = new Date(start);
        const dates: Array<Date> = [];
        while (current.getTime() <= end.getTime()) {
            dates.push(new Date(current));
            current.setDate(current.getDate() + 1);
        }
        return dates;
    }

    format = (date: Date, theFormat: string = CalendarConstants.DATE_FORMAT, timeZone: string = DEFAULT_TIME_ZONE) => {
        let time = moment(date);
        if (timeZone) {
            time = time.tz(timeZone);
        }
        return time.format(theFormat);
    }

    formatTime = (date: Date, timeZone: string = CalendarConstants.DEFAULT_TIME_ZONE) => {
        return this.format(date, CalendarConstants.TIME_FORMAT, timeZone);
    }

    formatFullTime = (date: Date, timeZone: string = CalendarConstants.DEFAULT_TIME_ZONE) => {
        return this.format(date, CalendarConstants.TIME_FULL_FORMAT, timeZone);
    }

    formatDate = (date: Date, timeZone: string = CalendarConstants.DEFAULT_TIME_ZONE) => {
        return this.format(date, CalendarConstants.DATE_FORMAT, timeZone);
    }

    formatSQLDateTime = (date: Date, timeZone: string = DEFAULT_TIME_ZONE) => {
        return this.format(date, CalendarConstants.SQL_DATETIME_FORMAT, timeZone);
    }

    formatSQLDate = (date: Date, timeZone: string = DEFAULT_TIME_ZONE) => {
        return this.format(date, CalendarConstants.SQL_DATE_FORMAT, timeZone);
    }

    formatFullDate = (date: Date, timeZone: string = DEFAULT_TIME_ZONE) => {
        return this.format(date, CalendarConstants.FULL_DATE_FORMAT, timeZone);
    }

    formatFullDateTime = (date: Date, timeZone: string = DEFAULT_TIME_ZONE) => {
        return this.format(date, CalendarConstants.FULL_DATE_TIME_FORMAT, timeZone);
    }

    buildWeek = (seedDate?: Date): Week => {
        // console.log('Seed Date for the week: ', seedDate);
        const week = new Week();
        if (!seedDate) {
            seedDate = new Date();
        }
        const firstDay = new Date(seedDate);
        firstDay.setDate(firstDay.getDate() - firstDay.getDay());
        // console.log('First day of the week: ', firstDay);
        const lastDay = new Date(firstDay);
        lastDay.setDate(firstDay.getDate() + 6);
        // console.log('Last day of the week: ', lastDay);
        console.log('week: ', firstDay, lastDay);
        week.firstDay = firstDay;
        week.lastDay = lastDay;
        return week;
    }

    enumerateWeek = (theWeek: Week): Array<Date> => {
        const daysOfWeek: Array<Date> = [];
        for (
            const theDayOfWeek: Date = new Date(theWeek.firstDay);
            theDayOfWeek <= theWeek.lastDay;
            theDayOfWeek.setDate(theDayOfWeek.getDate() + 1)
        ) {
            const theDay = new Date(theDayOfWeek);
            daysOfWeek.push(theDay);
        }
        return daysOfWeek;
    }

    buildWeeks = (fromDate: Date, noOfWeeks: number = 3): Array<Week> => {
        let seedDate = new Date(fromDate);
        const weeks: Array<Week> = [];
        for (let i = 0; i < noOfWeeks; i++) {
            const week = this.buildWeek(seedDate ? seedDate : new Date());
            // console.log('Week  : ', week);
            weeks.push(week);
            seedDate = new Date(week.lastDay);
            seedDate.setDate(week.lastDay.getDate() + 1);
            // console.log('Seeting Seed Date for the next week: ', seedDate);
        }
        // console.log('Weeks : ', this.weeks);
        return weeks;
    }

    getNowTime = () => {
        return CalendarOps.format(new Date(), CalendarConstants.TIME_FORMAT);
    }

    getNowDate = () => {
        return CalendarOps.format(new Date(), CalendarConstants.SQL_DATE_FORMAT);
    }

    getNowDateTime = () => {
        return `${this.getNowDate()} ${this.getNowTime()}`;
    }

    getDifference = (first: Date, second: Date) => {
        let a = moment(this.getMax([first, second]));
        let b = moment(this.getMin([first, second]));

        const years = a.diff(b, 'year');
        b.add(years, 'years');

        const months = a.diff(b, 'months');
        b.add(months, 'months');

        const days = a.diff(b, 'days');
        b.add(days, 'days');

        const hours = a.diff(b, 'hours');
        b.add(hours, 'hours');

        const minutes = a.diff(b, 'minutes');
        b.add(minutes, 'minutes');

        const seconds = a.diff(b, 'seconds');
        b.add(seconds, 'seconds');

        const diff = { years: years, months: months, days: days, hours: hours, minutes: minutes, seconds: seconds };
        // console.log(`Diff between ${first} and ${second} is `, diff);
        return diff;
    }


    parseAge = (age: string) => {
        const ageObj: { years: number, months: number, days: number, error: Array<string> } = { years: 0, months: 0, days: 0, error: [] };

        //console.log(`Input Age - ${age}`);
        const ageParts = age.split(new RegExp('[\\s\.]+'));
        //console.log('Age Parts : ', ageParts);
        ageParts.forEach((ap: string, index: number) => {
            ap = ap.trim();
            let value: any = ap;
            let unit = (index === 0) ? 'y' : (index === 1) ? 'm' : 'd';

            const unitPattern = new RegExp('[ymdYMD]');
            const units = unitPattern.exec(ap);
            if (units) {
                value = ap.substr(0, units.index);
                unit = units[0].toLocaleLowerCase();
            }

            try {
                value = parseFloat(value);
                //console.log(`[${ap}] - Part Units:`, value, unit);
                switch (unit) {
                    case 'y':
                        ageObj.years = value;
                        break;
                    case 'm':
                        ageObj.months = value;
                        break;
                    case 'd':
                        ageObj.days = value;
                        break;
                    default:
                        ageObj.error.push(`Invalid part - ${ap}`);
                }
            } catch (e) {
                ageObj.error.push(`Invalid part - ${ap}`);
            }

        });
        return ageObj;
    }

    getDOB = (age: string) => {
        const parsedAge = this.parseAge(age);
        if (parsedAge.error?.length > 0) {
            return undefined;
        } else {
            const now = CalendarOps.MOMENT(new Date());
            const dob = now.add(-1 * parsedAge.days, 'day').add(-1 * parsedAge.months, 'month').add(-1 * parsedAge.years, 'year');
            return this.formatSQLDate(dob.toDate());
        }
    }

    toStartTimeOfDay = (dt: any = new Date(), tz = CalendarOps.Constants.DEFAULT_TIME_ZONE) => {
        return CalendarOps.MOMENT(dt).tz(tz).startOf('day').toDate().getTime();
    }

    toTimeOfDay = (dt: any = new Date(), tz = CalendarOps.Constants.DEFAULT_TIME_ZONE) => {
        return new Date(CalendarOps.formatSQLDateTime(CalendarOps.MOMENT(dt).tz(tz).toDate())).getTime();
    }

    timeToMS = (time: string, tz = CalendarOps.Constants.DEFAULT_TIME_ZONE) => {
        const today = CalendarOps.formatSQLDate(new Date());
        const todayStartMS = this.toStartTimeOfDay();
        const timeMS = CalendarOps.MOMENT.tz(`${today} ${time}`, tz).toDate().getTime();
        const valMS = (timeMS - todayStartMS);
        return valMS;
    }

    msToTime = (ms: number, fullFormat: boolean = false, tz = CalendarOps.Constants.DEFAULT_TIME_ZONE) => {
        const todayStartMS = this.toStartTimeOfDay();
        const time = CalendarOps.MOMENT(todayStartMS + ms).tz(tz).toDate();
        // console.log('MS to Time: ', ms, time, todayStartMS);
        if (fullFormat) {
            return CalendarOps.formatFullTime(time);
        } else {
            return CalendarOps.formatTime(time);
        }
    }
}

export const CalendarOps = new CalendarOpsImpl();

// CalendarOps.getDifference(new Date(), new Date('1977/03/01'));