import { TriggerService } from '../../@uno-entity/service/entity-trigger.service';
import { RemoteService, EntityProp, EntityConstants, Common } from '../../@uno/api';
import { Profiler } from '../../@uno/api/common.service';
import { BaseEntity, EntityCategory } from '../../@uno/api/entity.service';
import { EventHandlerCategory } from '../../@uno/defs/uno-component';
import { AppInfoService } from './app.info.service';
import { SessionManager } from './session.service';

const PropDefConstants = {
    Props: {
        ID: { id: 'prop_id' },
        DATATYPE: { id: 'data_type' },
        CATEGORY: { id: 'entity_type' },
        MULTIPLICITY: { id: 'multiplicity' },
        VALIDATORS: { id: 'validators' },
        DEFAULT_VALUE: { id: 'default_value' },

        EXTRAS: { id: 'extra' },
        GROUP_ID: { id: 'groupID' },
        SERIAL_NUMBER: { id: 'serialNo' },
        EDITOR: { id: 'editor' },
        VIEWER: { id: 'viewer' },

        NO_NEW: { id: 'not_on_create' },
        NO_EDIT: { id: 'not_on_edit' },
        NO_QV: { id: 'not_on_qv' },
        NO_SEARCH: { id: 'not_on_search' },
        QUICK_SEARCH: { id: 'quick_search' },
        TRANSALATE: { id: 'translate' },
        HIDDEN: { id: 'hidden' },
        DISABLED: { id: 'disabled' },
        DEPENDS_ON_PROPS: { id: 'dependsOnProps' },
    }
};

const dummyCategories = [
    'do',
    'TCPC',
    'Events',
    'Handlers',
];

class EntityCategoryServiceImpl {
    profiler = Profiler.init('EntityCategoryService');
    private appCategories: any = {};

    constructor() {
        this.profiler.disabled = true;
    }

    async getCategory(categoryId: string, useCache: boolean = true, APP_ID?: string): Promise<any> {
        if (!categoryId) {
            return undefined;
        }
        let reloadCache = true;
        let appID = this.getAppId(APP_ID);
        if (useCache && appID) {
            const category = this.getAppCategory(categoryId, appID);
            if (category) {
                return category;
            } else {
                // this.profiler.log('Category not found in app cache: ', categoryId, appID);
                for (let dummy of dummyCategories) {
                    if (categoryId.startsWith(dummy)) {
                        reloadCache = false;
                        // this.profiler.log('Looks like a dummy or temporary category: ', categoryId, appID);
                        break;
                    }
                }
            }
        }

        // look up into database
        if (reloadCache && appID) {
            this.profiler.log(`Reloading categories: `, appID, categoryId);
            const categories = await this.reloadCategories(appID);
            this.profiler.log(`Reloaded categories: `, APP_ID, categoryId, categories);
            for (let cat of categories) {
                if (cat.id === categoryId) {
                    return cat;
                }
            }
        }

        // if reaching here, no category with given id found in the given app;
        this.profiler.log('No Category not found in app : ', categoryId, appID);
        dummyCategories.push(categoryId);
        return undefined;
    }

    async reloadCategories(APP_ID?: string) {
        const appID = this.getAppId(APP_ID);
        if (!appID) {
            return [];
        }

        const categories: Array<EntityCategory> = await RemoteService.getData(await RemoteService.post('app/category/list', { appID: appID, session: SessionManager.activeSession })) || [];
        if (categories?.length > 0) {
            for (let i = 0; i < categories.length; i++) {
                let category = categories[i];
                this.addAppCategory(category, appID);
                // categories.splice(i, 1, category);
            }
        }

        await this.addAppCategory(EventHandlerCategory, appID);
        return categories;
    }

    private getAppId(APP_ID: string | undefined) {
        let appID = APP_ID || AppInfoService.getActiveApp()?.id;
        return appID;
    }

    getAppCategories(APP_ID?: string) {
        let appID = this.getAppId(APP_ID);
        if (!appID) {
            return [];
        }

        let categories = this.appCategories[appID];
        if (!categories) {
            categories = {};
            this.appCategories[appID] = categories;
        }
        return categories;
    }

    getAppCategory(categoryId: string, appID?: string) {
        return this.getAppCategories(appID)[categoryId];
    }

    async addAppCategory(category: EntityCategory, appID?: string) {
        this.getAppCategories(appID)[category.id] = category;
        /*
        TriggerService.send({ category: category, appID: appID, event: 'CACHE_CATEGORY_REQUESTED' })
            .then(
                result => {
                    if (!result.error) {
                        category = { ...result.category };
                    }
                    // this.profiler.log('Adding Category to Cache: ', appID, category);
                    this.getAppCategories(appID)[category.id] = category;
                }
            );
        */
        return category;
    }

    findEProp(catID: any, propID: any, appID?: string) {
        let eProp: any = undefined;
        return new Promise<EntityProp>(
            (resolve, reject) => {
                if (catID && propID) {
                    this.getCategory(catID, true, appID).then(
                        category => {
                            eProp = EntityConstants.getPropByID(category?.props, propID);
                            resolve(eProp);
                        }
                    );
                } else {
                    resolve(eProp)
                }
            }
        );
    }

    getEntityProp = (propDef: BaseEntity): EntityProp | undefined => {
        propDef = EntityConstants.build(propDef); // to be on safer side.
        const id = propDef.getValue(PropDefConstants.Props.ID.id);

        let extras: any = propDef.getValue(PropDefConstants.Props.EXTRAS.id);
        if (extras && typeof extras === Common.Datatype.String) {
            // this.profiler.log(`${id} - Prop Extra: `, extras);
            extras = Common.parse(extras);
        }

        let theEntityProp: EntityProp = {
            id: id,
            label: propDef.getName() ? propDef.getName() : id,
            description: propDef.getDescription(),

            dataType: propDef.getValue(PropDefConstants.Props.DATATYPE.id, EntityConstants.PropType.DEFAULT),
            category: propDef.getValue(PropDefConstants.Props.CATEGORY.id, undefined),
            multiplicity: propDef.getValue(PropDefConstants.Props.MULTIPLICITY.id, 1),
            defaultValue: propDef.getValue(PropDefConstants.Props.DEFAULT_VALUE.id),
            extras: extras,
            validators: propDef.getValue(PropDefConstants.Props.VALIDATORS.id, []),

            groupID: propDef.getValue(PropDefConstants.Props.GROUP_ID.id),
            serialNo: propDef.getValue(PropDefConstants.Props.SERIAL_NUMBER.id, 0),
            editor: propDef.getValue(PropDefConstants.Props.EDITOR.id),
            viewer: propDef.getValue(PropDefConstants.Props.VIEWER.id),

            noNew: propDef.getValue(PropDefConstants.Props.NO_NEW.id, false),
            noEdit: propDef.getValue(PropDefConstants.Props.NO_EDIT.id, false),
            noQV: propDef.getValue(PropDefConstants.Props.NO_QV.id, false),
            noSearch: propDef.getValue(PropDefConstants.Props.NO_SEARCH.id, false),
            quickSearch: propDef.getValue(PropDefConstants.Props.QUICK_SEARCH.id, false),
            translate: propDef.getValue(PropDefConstants.Props.TRANSALATE.id, false),

            hidden: propDef.getValue(PropDefConstants.Props.HIDDEN.id, false),
            disabled: propDef.getValue(PropDefConstants.Props.DISABLED.id, false),
            dependsOnProps: propDef.getValue(PropDefConstants.Props.DEPENDS_ON_PROPS.id, []),

        };

        if (theEntityProp.id === undefined) {
            return undefined;
        }

        // this.profiler.log('The Transformed EntityProp: ', theEntityProp);
        return theEntityProp;
    }

}

export const EntityCategoryService = new EntityCategoryServiceImpl();