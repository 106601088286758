import { UnoModule } from '../../../defs/uno-module';
import { Accordion } from './accordion.comp';
import { Dialog } from './dialog.comp';
import { Iterator } from './iterator.comp';
import { Section } from './section.comp';
import { VSection } from './vsection.comp';

@UnoModule(
    {
        id: 'UnoContainers',
        imports: [

        ],
        components: [
            Accordion,
            Dialog,
            Section,
            VSection,
            Iterator,
        ],
    }
)
export class UnoContainersModule {

}