import { UnoModule } from '../@uno/core';
import { WorkbooksView } from './components/worksbooks.view.comp';
import { WorksheetView } from './components/worksheet.view.comp';
import { WorksheetsView } from './components/worksheets.view.comp';
import { WorksheetEdit } from './components/worksheet.edit.comp';
import { WSColumnsEdit } from './components/ws.columns.edit.comp';
import { UnoSheet } from './components/uno-sheet.comp';
import { DataSetSelector } from './components/data-set.selector.comp';
import { WorkbookView } from './components/workbook.view.comp';
// The Module...

@UnoModule(
    {
        id: 'UnoWorkbookModule',
        imports: [
        ],
        components: [
            WorkbookView,
            WorkbooksView,
            WorksheetsView,
            WorksheetView,
            WorksheetEdit,
            WSColumnsEdit,
            UnoSheet,
            DataSetSelector,
        ],
    }
)
export default class UnoWorkbookModule {

}

