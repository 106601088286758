import { Images, UnoModule } from '../core';
import icon_h3dots from './icons/3dots.png';
import icon_AND from './icons/AND.png';
import icon_NOR from './icons/NOR.png';
import icon_OR from './icons/OR.png';
import icon_add_condition from './icons/add_condition.png';
import icon_carousel from './icons/carousel.png';
import icon_clear from './icons/clear.png';
import icon_close from './icons/close.jpg';
import icon_component from './icons/component.jpg';
import icon_copy from './icons/copy.png';
import icon_delete from './icons/delete.png';
import icon_design from './icons/design.png';
import icon_details from './icons/details.png';
import icon_edit_password from './icons/edit-password.png';
import icon_edit from './icons/edit.png';
import icon_first from './icons/first.png';
import icon_fullscreen from './icons/full_screen.jpg';
import icon_translate from './icons/google_translate.png';
import icon_grid from './icons/grid.png';
import icon_hMenu from './icons/h_menu.png';
import icon_history from './icons/history.png';
import icon_home from './icons/home.png';
import icon_HTML from './icons/html.jpg';
import icon_tool from './icons/icon_tool.png';
import icon_last from './icons/last.png';
import icon_loading from './icons/loading.gif';
import icon_maximize from './icons/maximize.png';
import icon_mic_off from './icons/mic_off.jpg';
import icon_mic_on from './icons/mic_on.jpg';
import icon_minimize from './icons/minimize.png';
import icon_move from './icons/move.png';
import icon_move_down from './icons/move_down.png';
import icon_move_up from './icons/move_up.jpg';
import icon_multi_select from './icons/multi-select.png';
import icon_next from './icons/next.png';
import icon_paste from './icons/paste.png';
import icon_permission from './icons/permission.png';
import icon_plus from './icons/plus.png';
import icon_preview from './icons/preview.jpg';
import icon_previous from './icons/previous.jpg';
import icon_printer from './icons/printer.jpg';
import icon_profile from './icons/profile.png';
import icon_razor_pay from './icons/razor_pay_icon.png';
import icon_refresh from './icons/refresh.jpg';
import icon_uno_logo from './icons/retiuno-logo.png';
import icon_save from './icons/save.png';
import icon_search from './icons/search.jpg';
import icon_section from './icons/section.png';
import icon_signin from './icons/signin.png';
import icon_signout from './icons/signout.png';
import icon_signup from './icons/signup.jpg';
import icon_sort_ascending from './icons/sort_ascending.png';
import icon_sort_descending from './icons/sort_descending.png';
import icon_style from './icons/style.png';
import icon_switch_apps from './icons/switch_apps.png';
import icon_table from './icons/table.png';
import icon_text from './icons/text.png';
import icon_tickmark from './icons/tickmark.png';
import icon_tree from './icons/tree.png';
import icon_unsorted from './icons/unsorted.png';
import icon_v3dots from './icons/v3dots.png';
import icon_vMenu from './icons/v_menu.png';
import icon_vsection from './icons/v_section.png';
import icon_view_profile from './icons/view-profile.png';
import icon_view_as from './icons/view_as.png';
import icon_writepad from './icons/write-pad.png';
import icon_show_pwd from './icons/show_pwd.png';
import icon_hide_pwd from './icons/hide_pwd.png';
import icon_reader from './icons/reader.png';
import icon_export from './icons/export.png';
import icon_import from './icons/import.webp';

import icon_facebook from './icons/social/facebook.png';
import icon_linkedin from './icons/social/linkedin.png';
import icon_twitter from './icons/social/twitter.png';
import icon_instagram from './icons/social/instagram.png';

import icon_version from './icons/version-history.webp';
import icon_help from './icons/help.webp';

const UnoIcons: any = {
    reader: icon_reader,
    component: icon_component,
    first: icon_first,
    previous: icon_previous,
    next: icon_next,
    last: icon_last,
    hMenu: icon_hMenu,
    vMenu: icon_vMenu,
    tool: icon_tool,
    loading: icon_loading,
    refresh: icon_refresh,
    printer: icon_printer,

    tick: icon_tickmark,
    yes: icon_tickmark,
    h3dots: icon_h3dots,
    v3dots: icon_v3dots,
    setDefault: icon_tickmark,

    close: icon_close,
    cancel: icon_close,
    no: icon_close,

    export: icon_export,
    import: icon_import,

    maximize: icon_maximize,
    minimize: icon_minimize,
    section: icon_section,
    vSection: icon_vsection,
    editPassword: icon_edit_password,
    home: icon_home,
    profile: icon_profile,
    unoLogo: icon_uno_logo,
    signin: icon_signin,
    signout: icon_signout,
    signup: icon_signup,
    switchApp: icon_switch_apps,
    viewProfile: icon_view_profile,
    carousel: icon_carousel,
    details: icon_details,
    edit: icon_edit,
    writepad: icon_writepad,
    grid: icon_grid,
    multiSelect: icon_multi_select,
    plus: icon_plus,
    preview: icon_preview,
    save: icon_save,
    search: icon_search,
    sortAsc: icon_sort_ascending,
    sortDesc: icon_sort_descending,
    table: icon_table,
    tree: icon_tree,
    unsorted: icon_unsorted,
    viewAs: icon_view_as,
    view: icon_view_as,
    history: icon_history,
    version: icon_version,
    permission: icon_permission,
    addCondition: icon_add_condition,
    AND: icon_AND,
    clear: icon_clear,
    copy: icon_copy,
    delete: icon_delete,
    design: icon_design,
    fullscreen: icon_fullscreen,
    moveDown: icon_move_down,
    moveUp: icon_move_up,
    move: icon_move,
    NOR: icon_NOR,
    OR: icon_OR,
    paste: icon_paste,
    style: icon_style,
    text: icon_text,
    HTML: icon_HTML,
    Razorpay: icon_razor_pay,

    translate: icon_translate,
    mic_on: icon_mic_on,
    mic_off: icon_mic_off,
    show_pwd: icon_show_pwd,
    hide_pwd: icon_hide_pwd,

    help: icon_help,
    // social
    social: {
        facebook: icon_facebook,
        instagram: icon_instagram,
        twitter: icon_twitter,
        linkedin: icon_linkedin,
    }
}

const initIcons = () => {
    Object.keys(UnoIcons).forEach(key => {
        Images.Icon[key] = UnoIcons[key];
    });

    // console.log('Icons: ', Images.Icon);
}

// Initialize icons
initIcons();

// The Module...

@UnoModule(
    {
        id: 'UnoIconsModule',
        imports: [
        ],
        components: [

        ],
    }
)
export class UnoIconsModule {
}

