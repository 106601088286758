import React from "react";
import { Common } from "../../api";
import { UnoComponent } from "../../core";

@UnoComponent({ id: 'SessionTimer' })
export class SessionTimer extends React.Component {
    private timer: any;

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({});
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        let timeOutIn: number = 0;

        const sessionStart = localStorage.getItem(Common.LS_SESSION);
        if (sessionStart) {
            const now = new Date().getTime();
            const inactiveFor = (now - Number.parseInt(sessionStart)) / 1000;
            timeOutIn = Math.round(Common.SESSION_VALIDITY_PERIOD - inactiveFor);
        }

        let timeOutMsg = (timeOutIn <= 0) ? `Session Expired.` : `Session expires in ${timeOutIn} seconds`;
        return (<h1>{timeOutMsg}</h1>);;
    }
}
