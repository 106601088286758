import React from 'react';
import { UnoCoreBaseComp } from '../uno-core.base.comp';
import { QRCodeSVG } from 'qrcode.react';
import { DesignerConstants, UnoComponent } from '../../../core';

@UnoComponent({
    id: 'QRCode',
    label: 'QR Code',
    group: DesignerConstants.PaletteGroup.Other.id,
    props: [
        { groupID: 'General', id: 'url', label: 'URL' }
    ],
    paletteable: true,
})
export class UnoQRCode extends UnoCoreBaseComp {

    buildComp(): JSX.Element | null | undefined {
        return (
            <QRCodeSVG
                value={this.getValue()}
                style={this.getStyles()}
                className={this.getStyleClasses()}
            />
        );
    }

    getValue() {
        return this.state.url || window.document.URL;
    }
}