import { SessionManager } from '../../@uno-app/service/session.service';
import { BaseEntity, EntityConstants } from '../../@uno/api/entity.service';
import { RemoteService } from '../../@uno/api/remote.service';

class HistoryServiceImpl {

    find(entityID: any, categoryID: any, appID?: any) {
        console.log(`Find history for appID - ${appID}, category - ${categoryID}, entity - ${entityID}`);
        return new Promise<Array<BaseEntity>>(
            (resolve, reject) => {
                RemoteService
                    .post('history/find', { appID: appID, categoryId: categoryID, entityId: entityID,session: SessionManager.activeSession })
                    .then(result => {
                        result.json().then((records: Array<BaseEntity>) => {
                            console.log(`Found ${records?.length} history for appID - ${appID}, category - ${categoryID}, entity - ${entityID}`);
                            records = (records) ? records.map(r => {
                                return EntityConstants.build(r);
                            }) : [];
                            resolve(records);
                        });
                    }).catch(e => {
                        resolve([]);
                    });
            }
        );
    }
}

export const HistoryService = new HistoryServiceImpl();
