import React from 'react';
import { UC, UnoComponent, EM, Common, Images, DesignerConstants } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { EntityConstants } from '../../api';

declare var google: any;

@UnoComponent(
    {
        id: 'Translator',
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Layout.id,
        props: [
            { id: 'hideReader', label: 'Hide the Screen Reader?', dataType: EntityConstants.PropType.BOOLEAN, },
            { id: 'hideLanguage', label: 'Hide the Language Selector?', dataType: EntityConstants.PropType.BOOLEAN, },
        ],
        getDesign: () => { return (<div>Translator - Design</div>); },
        getPreview: () => { return (<div>Translator - Preview</div>); }
    }
)
export class Translator extends UnoCoreBaseComp {
    private originalLang = 'en';
    _closeSkipTranslateTimer: any;
    languageNames = new Intl.DisplayNames([this.originalLang], { type: 'language' });

    translatorID = Common.getUniqueKey('translate_element_');
    supportedLanguages: any = {
        // original: 'Show Original',
        ...Common.Language.Supported,
    }

    componentDidMount() {
        super.componentDidMount();
        const thisWindow: any = Common.Window;
        thisWindow.initTranslateElement = this.initTranslateElement;
        Common.addScript('https://translate.google.com/translate_a/element.js?cb=initTranslateElement');
        // console.log('Added Google Translate Script: ', thisWindow.initTranslateElement);
        this._closeSkipTranslateTimer = setInterval(this.closeSkipTranslate, 1000);

        this.doTranslate(this.getDefaultLanguage());
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        clearInterval(this._closeSkipTranslateTimer);
    }

    buildComp() {
        return (
            <span className='notranslate' key={Common.getUniqueKey('locales_')} style={{ display: 'inline' }} >
                <div
                    id={this.translatorID}
                    style={{ width: '0px', height: '0px', overflow: 'hidden', display: 'none' }}
                />
                {this.state.hideLanguage ? undefined : this.buildLocaleChoices()}
                {this.state.hideReader ? undefined : <UC.ScreenReader />}
            </span>
        )
    }

    buildLocaleChoices() {
        const lang = navigator.language;
        if (lang) {
            const locale = new Intl.Locale(lang);
            const ln = locale.language;
            // console.log('Browser Language: ', ln, lang, this.languageNames.of(lang), locale,);
            if (!this.supportedLanguages[ln]) {
                this.supportedLanguages[ln] = this.languageNames.of(lang);
            }
        }

        const addLanguage = (id: string, label: string): any => {
            return {
                id: id, label: label, action: (language: any) => {
                    this.doTranslate(language.id);
                    // this.reRender();
                }
            }
        }

        const buildLocales = () => {
            const lns = Object.keys(this.supportedLanguages);
            // console.log('Building locale navs: ', lns);
            return lns.map(id => {
                const option = addLanguage(id, this.supportedLanguages[id]);
                if (id === 'original') {
                    option.icon = Images.Icon.view;
                    option.action = () => {
                        Common.Language.setDefaultLanguage(this.getAppID(),);
                        this.doTranslate();
                    }
                }
                return option;
            });
        }

        const translationNavs = [
            {
                id: 'View In Language...',
                label: ' ',
                icon: Images.Icon.translate,
                children: buildLocales(),
            }
        ];

        return (
            <UC.Navigation navs={translationNavs} orientation='h' visibleChildCount={50} />
        );

    }

    doTranslate = (language: string = this.getDefaultLanguage()) => {
        const combos = document.getElementsByClassName('goog-te-combo');
        // console.log(`Translate to '${language}' from '${this.getDefaultLanguage()}'`);
        if (combos?.length > 0) {
            const select: any = combos[0];
            if (select.value !== language) {
                select.value = language;
                select.dispatchEvent(new Event('change'));
                Common.Language.setDefaultLanguage(this.getAppID(), language);
            }
            this.closeSkipTranslate();
        }
    }

    getDefaultLanguage = () => {
        let langID = Common.Language.getDefaultLanguage(this.getAppID());
        if (!langID) {
            langID = this.originalLang;
        }
        return langID;
    }

    initTranslateElement = () => {
        // console.log('Init Translation: ', google);
        if (google) {
            try {
                new google.translate.TranslateElement({ pageLanguage: 'en' }, this.translatorID);
                this.closeSkipTranslate();
            } catch (e) {
                console.log('Google Translate Script not loaded.');
            }
            // this.reRender();
        }
    }

    closeSkipTranslate = () => {
        const bodyEle = document.body;
        bodyEle.style.top = '0px';

        const skipTranslates = bodyEle.getElementsByTagName('div');
        if (skipTranslates?.length > 0) {
            for (let i = 0; i < skipTranslates.length; i++) {
                const skipT = skipTranslates.item(i);
                if (skipT && skipT.id !== 'root' && skipT.parentElement === bodyEle) {
                    skipT.style.visibility = 'hidden';
                    skipT.style.display = 'none';
                    skipT.style.width = '1px';
                    skipT.style.height = '1px'
                }
            }
        }
    }
}