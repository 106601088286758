import React from 'react';
import { BaseEntity, EntityConstants } from '../../../@uno/api';
import { Images, UC, UnoComponent } from '../../../@uno/core';
import { HistoryService } from '../../service/history.service';

const HistoryPropIDs = {
    TS: 'timestamp',
    DELTA: 'delta',
    ACTOR: 'actor'

}

// ENTITY - NEW, EDIT
@UnoComponent({
    id: 'ChangeHistory',
    label: 'Change History',
    props: [
    ],
    getDesign: () => { return (<div>History - Design</div>); }
})
export class ChangeHistory extends React.Component<{ categoryID: string, entityID: string, appID: string }, any> {
    showing = false;
    loaded = false;
    records: Array<BaseEntity> = [];

    render() {
        return this.viewHistory();

        // return <>{this.showing ? this.viewHistory() : this.buildTool()}</>
    }

    buildTool = (title: string = this.getTitle()) => {
        return (
            <img
                src={Images.Icon.history}
                className='tool-icon'
                title={title} alt={title}
                onClick={
                    () => {
                        this.toggleView();
                    }
                } />
        );
    }

    viewHistory = () => {
        return this.loaded ? this.viewRecords() : <UC.Loading target={() => { this.setState({}) }} />;
        /*
        return (
            <>
                <UC.Dialog onClose={() => { this.toggleView(); }} title='Change History' >
                    {this.loaded ? this.viewRecords() : <UC.Loading />}
                </UC.Dialog>
            </>
        );
        */
    }

    viewRecords = () => {
        const recordViews = this.records.map(
            r => {
                const actor = EntityConstants.build(r.getValue(HistoryPropIDs.ACTOR));
                const time = new Date(r.getValue(HistoryPropIDs.TS));
                const deltaObj = r.getValue(HistoryPropIDs.DELTA);
                console.log(`Delta: `, deltaObj);
                const deltaView = (deltaObj && deltaObj.delta) ? (
                    deltaObj.delta.map(
                        (d: any) => {
                            return (
                                <div>
                                    <b>{d.propID}</b> : <i>{d.oldVal}</i> &gt;&gt; <i>{d.newVal}</i>
                                </div>
                            );
                        }
                    )
                ) : (<></>);
                console.log(`Delta: `, deltaObj);
                return (
                    <div>
                        <div>
                            <b>{deltaObj.opType}</b>  at <i>{time.toString()}</i> by <b>{actor.getName()}</b>
                        </div>
                        <div>
                            {deltaView}
                        </div>
                        <hr />
                    </div>
                );
            }
        );
        return (
            <>{recordViews}</>
        )
    }

    getTitle() {
        return `History`;
    }

    toggleView = () => {
        this.showing = !this.showing;
        if (this.showing) {
            // reload
            this.loadRecords();
        } else {
            this.loaded = false;
        }
        this.setState({});
    }

    loadRecords = async () => {
        this.records = await HistoryService.find(this.props.entityID, this.props.categoryID, this.props.appID);

        this.records.sort(
            (a, b) => {
                return a.getValue(HistoryPropIDs.TS) < b.getValue(HistoryPropIDs.TS) ? 1 : -1;
            }
        );
        this.loaded = true;
        this.setState({});
    }

}