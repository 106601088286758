import React, { CSSProperties } from 'react';
import { AppInfoService } from '../../../@uno-app/service/app.info.service';
import { AppScreenService } from '../../../@uno-app/service/app.screen.service';
import { BaseEntity, EM, EntityConstants, EntityProp, Images } from '../../../@uno/api';
import { EntityCategory } from '../../../@uno/api/entity.service';
import { Common, ComponentDef, DesignerConstants, UC, UnoComponent, UnoComponentManager } from '../../../@uno/core';

interface ActionDef { id: string, label?: string, icon?: any }

const Action = {
    SAVE: { id: 'save', label: 'Save Config', icon: Images.Icon.save },
    SET_NAME: { id: 'set_name', label: 'Set Name', icon: Images.Icon.component },
    FULL_SCREEN: { id: 'full_screen', label: 'Full Screen', icon: Images.Icon.fullscreen },
    ADD: { id: 'add', label: 'Add Child', icon: Images.Icon.plus },
    REMOVE: { id: 'remove', label: 'Remove', icon: Images.Icon.delete },
    COPY: { id: 'copy', label: 'Copy', icon: Images.Icon.copy },
    PASTE: { id: 'paste', label: 'Paste', icon: Images.Icon.paste },
    SET_STYLES: { id: 'set_styles', label: 'Set Styles', icon: Images.Icon.styles },
    MOVE: { id: 'move', label: 'Move', icon: Images.Icon.move },
    MOVE_CHOICES: {
        UP: { id: 'move_up', label: 'Up/Left', icon: Images.Icon.moveUp },
        DOWN: { id: 'move_down', label: 'Down/Right', icon: Images.Icon.moveDown },
    },
    MODE: { id: 'mode', label: 'Mode', icon: undefined },
    MODE_CHOICES: {
        PREVIEW: { id: DesignerConstants.Mode.PREVIEW, label: 'Preview', icon: Images.Icon.preview },
        DESIGN: { id: DesignerConstants.Mode.DESIGN, label: 'Design', icon: Images.Icon.design },
    },
    SETTINGS: { id: 'settings', label: 'Settings', icon: undefined },
}

const Constants = {
    STYLE_DRAG_OVER: 'drag-over',
    EVT_ACTIVE_COMP_CHANGED: 'event_active_comp_changed',
}

const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget?.classList.add(Constants.STYLE_DRAG_OVER);
}

const handleDragEnd = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget?.classList.remove(Constants.STYLE_DRAG_OVER);
}

const activeComp: { element: any, config: any } = { element: undefined, config: undefined };

const setActiveComp = (element: any, config: any) => {
    if (activeComp.config === config) {
        // trying the set the same comp as active once again.
        return;
    }

    activeComp.element?.classList.remove(Constants.STYLE_DRAG_OVER);
    element?.classList.add(Constants.STYLE_DRAG_OVER);

    activeComp.element = element;
    activeComp.config = config;
    EM.emit(Constants.EVT_ACTIVE_COMP_CHANGED, activeComp.config);
}

const getViewByType = (type: any) => {
    return getTypeViewConfig(type).view;
}

const getTypeViewConfig = (id: string) => {
    let tvc: any = TypeViewConfig.Section;
    Object.values(TypeViewConfig)
        .forEach(val => {
            if (val.id === id) {
                tvc = val;
            }
        });
    // console.log(`id - ${id}, TVC - `, tvc);
    return tvc;
}

// Generic content placeholder in the layout.
class SectionConfig {
    static genID = () => { return Common.getUniqueKey('s_'); }

    id: string;
    label?: string;
    type: string = TypeViewConfig.Section.id;
    styles?: any;
    children?: Array<any>;
    props?: any;
    inheritedProps?: any;
    isContainer: boolean = false;

    constructor(label?: string, isContainer = false) {
        this.id = SectionConfig.genID();
        this.label = label;
        this.type = TypeViewConfig.Section.id;
        this.isContainer = isContainer;
    }
}

class DragDropHandler {
    private static _instance: DragDropHandler | undefined;
    private data: { config?: SectionConfig, remover?: Function } = {};
    private constructor() {

    }

    static getInstance = () => {
        if (!DragDropHandler._instance) {
            DragDropHandler._instance = new DragDropHandler();
        }
        return DragDropHandler._instance;
    }

    resetDragDrop = () => {
        this.data.config = undefined;
        this.data.remover = undefined;
    }

    initDrag = (config: any, remover: Function) => {
        this.data.config = config;
        this.data.remover = remover;
    }

    finishDrop = (targetConfig: any, adder: Function) => {
        if (targetConfig === this.data.config) {
            alert('Dropped on itself. Cancel');
        } else if (this.data.config && adder) {
            const duplicate = { ...this.data.config, };
            duplicate.id = SectionConfig.genID();
            adder(duplicate);
            if (this.data.remover) {
                this.data.remover();
            }
        }
        this.resetDragDrop();
    }
}

class SectionView extends React.Component<{ config?: SectionConfig, actionClbk?: Function, mode?: string, parentID?: any, editing?: any }, any> {
    config: any;

    editingFullScreen = false;
    editingStyles = false;
    DDH = DragDropHandler.getInstance();

    activeCompChangedHandler: any;

    constructor(props: any) {
        super(props);
        if (this.props?.config) {
            this.config = this.props?.config;
        } else {
            this.config = new SectionConfig('', false);
        }
        this.state = { ...this.props, config: this.config, }
    }

    componentDidMount() {
        this.activeCompChangedHandler = EM.register(Constants.EVT_ACTIVE_COMP_CHANGED,
            (config: SectionConfig) => {
                if (this.isDesignMode()) {
                    this.setState({});
                }
            }
        );
    }

    componentWillUnmount() {
        EM.unregister(this.activeCompChangedHandler);
    }

    render() {
        // console.log(`Render Mode: ${this.getMode()} :: Config: ${Common.stringify(this.config)} `);
        if (this.editingFullScreen) {
            return (
                <Designer
                    config={this.config}
                    onSave={this.handleSaveEditingSeparate}
                    mode={this.state.mode}
                    key={Common.getUniqueKey()}
                />
            );
        }
        return this.buildContent();
    }

    isActive = () => {
        return this.config === activeComp.config;
    }

    buildContent() {
        let content = this.buildComponent();
        if (this.isDesignMode()) {
            content = (
                <div className={`designer-section ${this.isActive() ? Constants.STYLE_DRAG_OVER : ''}`}
                    // title={cLabel}
                    key={Common.getUniqueKey()}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragEnd}

                    onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (this.isContainer()) {
                            this.DDH.finishDrop(this.config, this.addChildConfig);
                        }
                    }}

                    style={{ display: 'flex', }}
                >
                    <div style={{ width: '100%' }} onClick={this.handleClickOnComp}>
                        {
                            this.isActive() ? (
                                <div
                                    draggable={this.isDesignMode()}
                                    onDragStart={
                                        (e) => {
                                            this.DDH.initDrag(this.config, this.removeSection);
                                        }
                                    }
                                >|||</div>) : null
                        }
                        {content}
                    </div>
                    {
                        (this.isActive()) ?
                            (
                                <>
                                    {this.buildActionOptions()}
                                    {this.buildStyleEditor()}
                                </>
                            ) : null
                    }

                </div>
            );
        }
        //return (<UC.Section title={cLabel} {...minimized}>{content}</UC.Section>);
        return content;

    }

    buildActionOptions() {
        let actionOptions: any = null;
        if (!this.isDesignMode() && this.state.editing === false) {
            actionOptions = (null);
        } else {
            const cLabel = this.config.label;
            actionOptions = (
                <UC.Navigation navs={this.getActionOptions()}
                    orientation='v'
                    isToolbar={true}
                />
            );
        }
        return actionOptions;
    }

    buildStyleEditor() {
        if (!this.editingStyles) {
            return null;
        }

        const styleEProp: EntityProp = {
            id: 'The Styles',
            dataType: EntityConstants.PropType.ENTITY_INLINE,
            category: Common.CategoryID.StyleDef,
        }
        const otherProps = {
            onPropChanged: this.saveStyles,
        };

        return (
            <UC.PropComp
                entityProp={styleEProp}
                defaultValue={this.getStyles()}
                otherProps={otherProps}
                editing={true}
                key={Common.getUniqueKey()}
            />

        )
    }

    buildComponent() {
        return this.buildChildren();
    }

    buildChildren = (mode = this.getMode()) => {
        const defaultStyles: CSSProperties = {};

        let children: any = this.config.children?.map(
            (childConfig: SectionConfig, cIndex: number) => {
                const getLabel = () => {
                    return childConfig.label ? childConfig.label : childConfig.id;
                }

                childConfig.inheritedProps = { ...childConfig.inheritedProps, ...this.config.inheritedProps, ...this.config.props };
                let childStyles: CSSProperties = { ...defaultStyles, ...childConfig.styles };

                let TheView: any = childConfig.type ? getViewByType(childConfig.type) : TypeViewConfig.Component.view;
                // console.log(`Render Child in Mode: ${mode} :: Config: `, childConfig, TheView);
                const childView = (
                    <TheView
                        config={childConfig}
                        actionClbk={this.handleChildAction}
                        mode={mode}
                        parentID={this.config.id}
                        editing={this.state.editing}
                        key={Common.getUniqueKey()}
                    />
                );
                // console.log('Parent - ', this.config.id, ' Child - ', childConfig.id,);
                // insert placeholder
                let InsertAbove = <UC.Empty />;
                if (this.isDesignMode() && this.isContainer()) {
                    InsertAbove = (
                        <div
                            className='designer-section'
                            style={{ opacity: '0.5', minHeight: '5px' }}
                            key={Common.getUniqueKey()}
                            onDragLeave={handleDragEnd}
                            onDragOver={handleDragOver}
                            onDrop={(e) => {
                                this.DDH.finishDrop(this.config, (cConfig: any) => { this.addChildConfig(cConfig, cIndex); });
                                //alert('Drop above - ' + getLabel());
                            }}

                        > </div>
                    );
                }
                return (
                    <div>
                        {InsertAbove}
                        {childView}
                    </div>
                );
            }
        );
        // console.log(`${this.config.id} :: Renderding section children: `, count);
        return children;
    }

    isContainer = () => {
        return UnoComponentManager.isContainerComp(this.config.compID);
    }

    handleClickOnComp = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setActiveComp(e.currentTarget, this.config);
        // alert(`${this.config.id + ' - ' + this.config.compID} is active`);
    }

    handleSaveEditingSeparate = (editedConfig: any) => {
        this.editingFullScreen = false;
        this.config = editedConfig;
        this.refresh();
    }

    isPreviewMode(mode = this.getMode()) {
        return mode === DesignerConstants.Mode.PREVIEW;
    }

    isLiveMode(mode = this.getMode()) {
        return mode === DesignerConstants.Mode.LIVE;
    }

    isDesignMode(mode = this.getMode()) {
        return mode === DesignerConstants.Mode.DESIGN;
    }

    getMode = () => {
        return this.state.activeMode ? this.state.activeMode : this.state.mode;
    }

    getParentID = () => {
        return this.state.parentID;
    }

    getStyles() {
        return (this.config.styles) ? EntityConstants.build(this.config.styles) : undefined;
    }

    saveStyles = (eProp?: any, entity?: any) => {
        this.config.styles = entity;
        this.editingStyles = false;
        console.log('Saving styles - ', Common.stringify(entity));
        this.triggerActionCallback(Action.SET_STYLES.id);
    }

    getChildIndex = (id: any): number => {
        let matched = -1;
        this.config.children?.forEach(
            (child: any, index: number) => {
                if (child.id === id) {
                    matched = index;
                }
            }
        );
        return matched;
    }

    getConfigLabel = (config: any) => {
        return config.label ? config.label : config.id
    }

    triggerActionCallback = (action: string) => {
        if (this.props.actionClbk && action) {
            // console.log(`Trigger ${action} callback on - ${this.getConfigLabel(this.config)} `);
            this.props.actionClbk(this.config, action);
        }
        this.refresh();
    }

    handleChildAction = (childConfig: any, action: string) => {
        if (!this.config.children) {
            this.config.children = [];
        }
        const children = this.config.children;
        let index = this.getChildIndex(childConfig.id);

        // console.log(`Parent - ${this.getConfigLabel(this.config)}, action - ${action}, Child - ${this.getConfigLabel(childConfig)}, at index - ${index}`);
        switch (action) {
            case Action.SET_STYLES.id:
                this.refresh();
                break;
            case Action.REMOVE.id:
                if (index >= 0) {
                    children.splice(index, 1);
                }
                break;
            case Action.ADD.id:
                if (index < 0) {
                    children.push(childConfig);
                }
                break;
            case Action.MOVE_CHOICES.UP.id:
                if (index > 0) {
                    const prev = { ...children[index - 1] };
                    const current = { ...children[index] };
                    children[index - 1] = current;
                    children[index] = prev;
                }
                break;
            case Action.MOVE_CHOICES.DOWN.id:
                if (index >= 0 && index < (children.length - 1)) {
                    const next = { ...children[index + 1] };
                    const current = { ...children[index] };
                    children[index + 1] = current;
                    children[index] = next;
                }
                break;
            default:
                if (index >= 0) {
                    children[index] = childConfig;
                }
        }

        if (action !== Action.SAVE.id) {
            this.saveConfig();
        }
    }

    refresh = () => {
        this.setState({ config: this.config });
    }

    getActionLabel = () => {
        return this.config?.label ? this.config.label : undefined; //this.config.id;
    }

    attachAction(actionToAttach: any, parent: string, actionOptions: Array<any>,) {
        actionOptions.forEach(
            (action: any) => {
                if (action.label === parent) {
                    if (!action.children) {
                        action.children = [];
                    }
                    action.children.push(actionToAttach);
                } else {
                    this.attachAction(actionToAttach, parent, action.children ? action.children : []);
                }
            }
        );
    }

    detachAction(actionID: string, actionOptions: Array<any>,) {
        for (let i = 0; i < actionOptions.length; i++) {
            const action: any = actionOptions[i];
            if (action.id === actionID) {
                actionOptions?.splice(i, 1);
            } else {
                this.detachAction(actionID, action.children ? action.children : []);
            }
        }
    }

    // define action options
    enablePreviewMode = () => { this.setState({ previewMode: true, liveMode: false }); }
    enableEditMode = () => { this.setState({ previewMode: false }); }

    setActiveMode = (activeMode: string) => {
        this.setState({ activeMode: activeMode });
    }

    setName = () => {
        const newName: any = prompt('Set Name', this.config.label);
        if (newName !== null) {
            this.config.label = newName;
            this.triggerActionCallback(Action.SET_NAME.id);
        }
    }

    editStyles = () => {
        this.editingStyles = true;
        this.refresh();
    }

    createConfig(type: any) {
        const tvc = getTypeViewConfig(type);
        if (tvc.compID) {
            const compConfig: any = new ComponentConfig('', tvc.compID, tvc.isContainer);
            return compConfig;
        } else if (tvc.config) {
            const Config = tvc.config;
            return new Config();
        }
        return undefined;
    }

    addChild(type: any) {
        // console.log(`Adding child - `, type);
        const child: any = this.createConfig(type);
        if (child) {
            this.addChildConfig(child);
        }
    }

    addChildConfig = (childConfig: any, index: number = -1) => {
        if (childConfig === this.config) {
            alert('Cannot add to itself.');
            return;
        }
        let children: Array<SectionConfig> = this.config.children;
        if (!children) {
            children = [];
        }
        if (index < 0) {
            children.push(childConfig);
        } else {
            children.splice(index, 0, childConfig);
        }
        this.config.children = children;
        // console.log(`Addedd Child: `, childConfig);
        this.saveConfig();

    }

    removeSection = () => {
        const confirmation = window.confirm(`Are you sure to Remove ${this.config.label}?`);
        if (confirmation) {
            this.triggerActionCallback(Action.REMOVE.id);
        }
    }

    copySection = async () => {
        // this.triggerActionCallback(Action.COPY.id);
        const configText = Common.stringify(this.config);
        alert(configText);
        if (configText) {
            await navigator.clipboard.writeText(configText);
            alert('Copied');
        }
    }

    pasteSection = async () => {
        const configText = await navigator.clipboard.readText();
        if (configText) {
            const config = Common.safeParse(configText);
            this.addChildConfig(config);
        }
    }

    editSettings = () => {
        alert('Not implemented.');
    }

    saveConfig = () => {
        this.triggerActionCallback(Action.SAVE.id);
    }

    editFullScreen = () => {
        this.editingFullScreen = true;
        this.refresh();
    }

    moveUp = () => {
        this.triggerActionCallback(Action.MOVE_CHOICES.UP.id);
    }

    moveDown = () => {
        this.triggerActionCallback(Action.MOVE_CHOICES.DOWN.id);
    }

    createActionOption(def: ActionDef, handler?: Function, children?: Array<any>): any {
        if (!this.getParentID()) {
            switch (def.id) {
                case Action.REMOVE.id:
                case Action.MOVE.id:
                case Action.FULL_SCREEN.id:
                    return undefined;
                default:
                // do nothing
            }
        }
        const action: any = { id: def.id };
        action.icon = def.icon;
        action.label = (def.label) ? def.label : action.id;
        action.action = handler;
        action.children = children;
        return action;
    }

    getAddOptions(): Array<any> {
        let options: Array<any> = [];
        Object.values(TypeViewConfig).forEach(val => {
            options.push(this.createActionOption(val, () => { this.addChild(val.id); }));

        });
        return options;
    }

    getModeOptions(): Array<any> {
        let options: Array<any> = [];
        Object.values(Action.MODE_CHOICES).forEach(val => {
            options.push(this.createActionOption(val, () => { this.setActiveMode(val.id); }));

        });
        return options;
    }

    getMoveOptions(): Array<any> {
        return [
            this.createActionOption(Action.MOVE_CHOICES.UP, this.moveUp),
            this.createActionOption(Action.MOVE_CHOICES.DOWN, this.moveDown),
        ];
    }

    getActionOptions(): Array<any> {
        return [
            // Cotent Edit options
            // this.createActionOption(Action.SET_NAME, this.setName),
            this.createActionOption(Action.SETTINGS, this.editSettings),
            this.createActionOption(Action.SET_STYLES, this.editStyles),
            this.createActionOption(Action.FULL_SCREEN, this.editFullScreen),

            // Config Modification options
            this.createActionOption(Action.REMOVE, this.removeSection),
            this.isContainer() ? this.createActionOption(Action.ADD, undefined, this.getAddOptions()) : undefined,
            this.createActionOption(Action.COPY, this.copySection),
            this.createActionOption(Action.PASTE, this.pasteSection),
            // this.createActionOption(Action.MOVE, undefined, this.getMoveOptions()),

            // View Modes
            // this.createActionOption(Action.MODE, undefined, this.getModeOptions()),
            // this.createActionOption(Action.MODE_CHOICES.PREVIEW, () => { this.setActiveMode(Action.MODE_CHOICES.PREVIEW.id); }),
            // this.createActionOption(Action.MODE_CHOICES.DESIGN, () => { this.setActiveMode(Action.MODE_CHOICES.DESIGN.id); }),
        ];
    }

}

// Dynamic Custom Component
class ComponentConfig extends SectionConfig {
    compID?: string;
    screen?: BaseEntity;
    eProps?: any;

    constructor(label?: string, compID?: string, isContainer = false) {
        super(label, isContainer);
        this.type = TypeViewConfig.Component.id;
        this.compID = compID;
    }
}

class ComponentView extends SectionView {

    createActionOption(def: ActionDef, callback?: Function, children?: Array<any>): any {
        switch (def.id) {
            case Action.SETTINGS.id:
                const comp = this.getSelectedComp();
                if (!comp || !comp.props || comp.props.length === 0) {
                    return undefined;
                }
            case Action.ADD.id:
            case Action.MODE.id:
            default:
                return super.createActionOption(def, callback, children);
        }
    }

    buildComponent() {
        // console.log(`Component Config: `, this.config);
        let selectedCompDef: ComponentDef = this.getSelectedComp();
        if (this.isPreviewMode()) {
            return (selectedCompDef?.getPreview) ? selectedCompDef.getPreview(this.config, this.saveConfig, this.buildChildren) : undefined;
        } else if (this.isLiveMode()) {
            return (selectedCompDef?.getLive) ? selectedCompDef.getLive(this.config, this.saveConfig, this.buildChildren) : undefined;
        } else {
            let design = (selectedCompDef?.getDesign) ? selectedCompDef.getDesign(this.config, this.saveConfig, this.buildChildren) : this.buildComponentSelector();
            return (
                <div key={Common.getUniqueKey()}>
                    {design}
                    {this.buildCompPropsEditor(selectedCompDef?.props, this.config.eProps)}
                </div>
            );
        }
    }

    buildComponentSelector() {
        const oProps: any = {
            onPropChanged: this.handleCompIDSelected,
        }
        const selector = (
            <UC.ComponentSelector
                entityProp={{ id: 'Select Component' }}
                otherProps={oProps}
                defaultValue={this.config.compID}
                editing={true}
            />
        );

        return selector;
    }

    handleCompIDSelected = (eProp: any, compID: any) => {
        // console.log(`Selected comp - `, compID);
        if (compID) {
            this.config.id = compID;
            this.config.compID = compID;
            this.config.props = undefined;
            this.setState({ selectedComp: UnoComponentManager.getDef(compID) });
            this.saveConfig();
        }
    }

    buildCompPropsEditor = (props?: Array<any>, defaultValue?: BaseEntity) => {
        if (!props || props.length === 0) {
            return null;
        }

        const propCategory: EntityCategory = {
            id: DesignerConstants.TCPC,
            label: this.config.label + ' : Component Props',
            props: props,
        };
        const eProp: EntityProp = {
            id: 'prop',
            label: propCategory.label,
            dataType: EntityConstants.PropType.ENTITY_INLINE,
            category: propCategory.id,
        };
        if (defaultValue) {
            defaultValue = EntityConstants.build(defaultValue);
        } else {
            defaultValue = EntityConstants.buildEmpty(propCategory.id);
        }

        return (
            <UC.Dialog
                show={this.state.editingProps}
                title={propCategory.label}
                onClose={() => { this.setState({ editingProps: false }) }}
                key={Common.getUniqueKey()}
            >
                {
                    <UC.PropEditor
                        category={propCategory}
                        entityProp={eProp}
                        defaultValue={defaultValue}
                        otherProps={{
                            onPropChanged: (eProp: EntityProp, entity: any) => {
                                this.config.eProps = entity;
                                // console.log('props set - ', this.config.props);
                                this.setState({ config: this.config, editingProps: false });
                            },
                        }}
                    />
                }
            </UC.Dialog>
        );
    }

    getSelectedComp(): ComponentDef {
        const compID = this.config.compID;
        return this.state.selectedComp
            ? this.state.selectedComp
            : UnoComponentManager.getDef(compID ? compID : '');
    }

    editSettings = () => {
        this.setState({ editingProps: true });
    }
}

// Add options

const TypeViewConfig = {
    Text: { id: 'text', label: 'Add Text', icon: Images.Icon.text, compID: 'MultilineEditor' },
    HTML: { id: 'html', label: 'Add HTML', icon: Images.Icon.HTML, compID: 'HTMLEditor' },
    Section: { id: 'section', label: 'Add Section', icon: Images.Icon.section, compID: 'Section', isContainer: true },
    VSection: { id: 'v_section', label: 'Add V-Section', icon: Images.Icon.vSection, compID: 'VSection', isContainer: true },
    Component: { id: 'component', label: 'Add Component', icon: Images.Icon.component, view: ComponentView, config: ComponentConfig },
}

// The Core Layout Designer Component
@UnoComponent({
    id: 'Designer',
    label: 'The Screen',
    props: [
        { id: 'screenDef', label: 'Screen Definition', dataType: EntityConstants.PropType.ENTITY, category: 'uno_screen_def' },
    ],
    getPreview: (config?: any, clbk?: any, buildChildren?: Function) => { return (<div>Screen - Preview</div>) },
    getDesign: (config?: any, clbk?: any, buildChildren?: Function) => { return (<div>Screen - Design</div>) },
})
export class Designer extends React.Component<{ config?: any, onSave?: Function, mode: string, screenDef?: BaseEntity }, any> {
    tryAdvance: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = { ...this.props };
    }

    componentDidMount() {
        let layoutConfig: SectionConfig = new ComponentConfig('', TypeViewConfig.Section.compID, TypeViewConfig.Section.isContainer);
        if (this.props.config) {
            let config: any = this.props.config;
            if (Common.checkType.String(config)) {
                try {
                    layoutConfig = Common.parse(config);
                } catch (e) { }
            } else {
                layoutConfig = config;
            }
        } else if (this.props.screenDef) {
            let screenDef: any = this.props.screenDef;
            if (screenDef && Common.checkType.String(screenDef)) {
                screenDef = Common.safeParse(screenDef);
            }

            AppScreenService.getScreenByID(EntityConstants.build(screenDef).getID(), AppInfoService.getActiveApp()?.id)
                .then(layout => {
                    if (layout) {
                        // console.log(`Mode - ${this.getMode()}, Setting loaded screen def: `, layout)
                        this.setState({ layoutConfig: layout });
                    }
                });
        }
        this.setState({ layoutConfig: layoutConfig });
    }

    render() {
        if (!this.getConfig()) {
            return (<div>Initializing...</div>);
        }

        const TheView: any = this.getConfig().type ? getViewByType(this.getConfig().type) : TypeViewConfig.Component.view;
        console.log('Layout Designer - Root Config   - ', this.state.config, TheView);

        const content = (
            <div key={Common.getUniqueKey()} className='designer-canvas'>
                {/* this.buildActions() */}
                <TheView config={this.getConfig()} mode={this.getMode()} editing={this.isDesignMode()} />
                {/* Put some extra space */}
                <br />
            </div>
        );
        if (this.isDesignMode()) {
            if (this.tryAdvance) {
                return (<UC.LayoutDesigner onSave={this.props.onSave} config={this.getConfig()} />);
            } else {
                return (
                    <UC.Dialog onClose={this.handleOnClose} title='Designer'>
                        <button onClick={() => { this.tryAdvance = true; this.setState({}); }}>Try Advance Editor</button>
                        {content}
                    </UC.Dialog>
                );
            }
        } else {
            return content;
        }
    }

    getConfig() {
        return this.state.layoutConfig;
    }

    getMode() {
        let mode = this.state.mode;
        if (!mode) {
            mode = DesignerConstants.Mode.LIVE;
        }
        return mode;
    }

    isPreviewMode() {
        return this.getMode() !== DesignerConstants.Mode.DESIGN;
    }

    isDesignMode() {
        return this.getMode() === DesignerConstants.Mode.DESIGN;
    }

    handleOnSave = (config: SectionConfig) => {
        if (this.state.onSave) {
            this.state.onSave(config);
        }
        // console.log('Layout Config - ', config);
        // this.setState({ config: config });
        this.setState({ mode: DesignerConstants.Mode.PREVIEW });
    }

    handleOnClose = () => { this.handleOnSave(this.getConfig()); }

    buildActions = () => {
        if (this.isDesignMode()) {
            return (
                <div>
                    <button onClick={this.handleOnClose}>Save</button>
                    <br />
                </div>
            );
        }

        return null;
    }

}

