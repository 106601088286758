import { Common, EM } from '../../@uno/core';
import { UnoCoreBaseComp } from '../../@uno/core/components/uno-core.base.comp';
import { AppInfo, AppInfoService } from '../service/app.info.service';

export class UnoAppBaseComp extends UnoCoreBaseComp {
    private _EH_AppSwitch: any;

    componentDidMount() {
        super.componentDidMount();
        this._EH_AppSwitch = EM.register(Common.Event.APP_SWITCHED, this.handleAppSwitched);

        let appID = this.getAppID();
        let appInfo = this.getAppInfo();
        if (!appID && !appInfo) {
            this.setState({ app: AppInfoService.getAppConfig(appID) });
        }
    }

    handleAppSwitched = async (app: AppInfo) => {
        this.setState({ app: app });
    }

    componentWillUnmount() {
        EM.unregister(this._EH_AppSwitch);
        super.componentWillUnmount();
    }

    getAppInfo(): AppInfo {
        return Common.safeParse(this.state.app || undefined);
    }

    getAppID(): string {
        const appID = this.props.appID || this.state.appID || this.getAppInfo()?.id || (!this.isLiveMode() ? AppInfoService.getActiveApp()?.id : undefined);
        return appID;
    }

}
