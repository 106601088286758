import React from 'react';
import { EntityFilterService } from '../../@uno-filter/service/entity-filter.service';
import { BaseEntity, Common, EntityConstants, FilterConstants } from "../../@uno/api";
import { DesignerConstants, UC, UnoComponent } from "../../@uno/core";
import { EntitySearch, SearchEvent } from './entity-search.comp';

@UnoComponent({
    id: 'EntitySelector',
    label: 'Entity Select Box',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Entity.id,
    props: [
        { id: 'entityCategoryID', label: 'Category', editor: 'CategorySelector' },
        { id: 'filterOptions', label: 'Filter Options', dataType: EntityConstants.PropType.JSON, }
    ],
    events: [SearchEvent.onSelected],
})
export class EntitySelector extends EntitySearch {
    // private entities: Array<BaseEntity> = [];

    async handle_CategoryLoaded() {
        // if (this.getCategoryID()) { this.loadResult(); }
        await this.loadResult();
    }

    loadResult = async () => {
        let conditions = [...this.getDefaultFilters(),];

        const entities = await FilterConstants.find(conditions, this.getFilterOptions(), this.getAppID());
        this.reRender({ entities: entities });
        // console.log('Load Entity Selection Results: ', this.state.filters, conditions, entities);
    }

    buildContent() {
        const options = (this.state.entities || [])?.map(
            (e: any) => {
                // console.log('Is Selected: ', (e.getID() === this.getEntityId()), this.getEntity())
                return {
                    id: e.getID(),
                    label: e.toString(),
                    isSelected: (e.getID() === this.getEntityId()),
                    action: () => {
                        const onSelected = this.getOtherProps()?.onSelected;
                        if (onSelected) {
                            onSelected(e);
                        }

                        Common.notifyEvent(this, SearchEvent.onSelected, { theComp: this, entity: e, data: e, });
                    }
                };
            }
        );

        return (
            <>
                <UC.SelectBox
                    options={options}
                    key={Common.getUniqueKey()}
                />
            </>
        );
    }

    getCategoryID() {
        const _props: any = this.props
        if (_props.entityCategoryID) {
            return _props.entityCategoryID;
        } else {
            super.getCategoryID();
        }
    }

    getFilterOptions() {
        let options = this.state.filterOptions || {};
        if (options) {
            options = Common.safeParse(options)
        }
        return options;
    }
}