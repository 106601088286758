import React from 'react';
import { EntityEdit } from '../../@uno-entity/components/entity-edit.comp';
import { BaseEntity, EntityConstants, EntityProp } from '../../@uno/api';
import { Common, UC, UnoComponent } from "../../@uno/core";
import { WorkbookService } from '../services/workbook.service';

// A Worksheet - EDIT
@UnoComponent({
    id: 'WorksheetEdit',
    label: 'Edit A Worksheet',
    props: [],
    getDesign: () => { return (<div>Edit A Worksheet - Design</div>); },
    getPreview: () => { return (<div>Edit A Worksheet - Preview</div>); }
})
export class WorksheetEdit extends EntityEdit {

    LAST_STAGE: number = 2;
    INIT_STAGE: number = 1;
    headers: Array<any> = [];
    records: Array<any> = [];

    constructor(props: any) {
        super(props);
        this.state = { ...this.state, stage: this.INIT_STAGE };
    }

    buildPropLayout() {
        const stage = this.getStage();
        let thePropLayout = <UC.Empty />;

        if (stage === 1) {
            thePropLayout = this.buildCsvUpload();
        } else if (stage === 2) {
            // console.log('WS Entity: ', entity);
            thePropLayout = super.buildPropLayout();
        }

        return (
            <>
                <h3> Step : {stage} </h3>
                {thePropLayout}
                {this.buildNavActions()}
            </>
        );
    }

    buildNavActions = () => {
        const actions: Array<any> = [];
        if (this.getStage() > this.INIT_STAGE) {
            actions.push({
                id: 'Previous',
                action: () => {
                    this.setState({ stage: this.getStage() - 1 });
                }
            });
        }
        if (this.getStage() < this.LAST_STAGE) {
            actions.push({
                id: 'Next',
                action: () => {
                    this.setState({ stage: this.getStage() + 1 });
                }
            });
        } else {
            actions.push({
                id: 'Finish',
                action: async () => {
                    console.log('WS to Save: ', this.getEntity());
                    await this.validateAndSave();
                }
            });
        }
        return <UC.Navigation key={Common.getUniqueKey()} navs={actions} orientation='h' />
    }

    buildCsvUpload = () => {
        return (
            <>
                <UC.CsvUpload
                    onHeaders={
                        (headers: any) => {
                            // console.log('setting headers from csv');
                            this.headers = headers;
                        }
                    }

                    onRecords={(records: any) => {
                        this.records = records;
                        this.initWorksheet();
                    }}
                />
            </>
        );
    }

    getStage = () => {
        return this.state.stage;
    }

    initWorksheet() {
        // console.log('Init WS Sheet')
        const entity = this.getEntity();
        if (!entity) {
            return;
        } else if (!entity.getID()) {
            entity.createID();
        }
        entity.setValue('name', 'WS1');
        entity.setValue('sheet_type', 'input_sheet');
        entity.setValue('ds_type', 'csv_file');

        const columns = this.headers.map((h: any) => {
            const col = EntityConstants.buildEmpty('ws_column');
            col.setValue('origin_name', h);
            col.setValue('name', h);
            return col;
        });
        entity.setValue('column', columns);
        // console.log('WS Entity: ', entity);
    }

    buildEntityProp(prop: EntityProp, noLabel?: boolean) {
        switch (prop.id) {
            case 'column':
                return super.buildEntityProp(prop, noLabel);
            default:
                return <UC.Empty />;
        }
    }

    buildActionButtons() {
        return <UC.Empty />;
    };

    /*
    getPostSaveRedirect(entity: BaseEntity) {

        const sheetID = entity.getID();
        console.log('WS is saved - ', sheetID);
        WorkbookService.saveCells(sheetID, this.records, this.getAppID()).then(
            () => {
                console.log(sheetID, ' - WS Records saved: ', this.records.length);
            }
        );

        const onSheetAdded = this.getOtherProps()?.onSheetAdded;
        if (onSheetAdded) {
            onSheetAdded(entity);
        }
        return undefined;
    }
    */
}
