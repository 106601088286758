import React from 'react';
import { Link } from 'react-router-dom';
import { BasePropViewer } from '../prop-base.comp';
import { Common, DesignerConstants, UnoComponent } from '../../../@uno/core';

@UnoComponent({
    id: 'AppIDViewer',
    label: 'App ID Viewer',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Viewer.id,
})
export class AppIDViewer extends BasePropViewer {

    buildValue() {
        const value = this.getDefaultValue();
        let appID = this.getEntity()?.getValue(Common.AppManager.PROP_APP_ID);
        if (!appID) {
            appID = value || this.getAppID();
        }
        return (<Link to={`/${appID}`} style={this.getStyles()} target={'_blank'}>  {'' + value} </Link>);

    }
}