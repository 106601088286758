import React from 'react';
import { DesignerConstants, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';
import { Common, EntityConstants } from '../../../@uno/api';
import * as d3 from 'd3';

const ChartType = {
    BAR_CHART: { id: 'barchart', label: 'Bar Chart' },
    LIST: { id: 'list', label: 'Unordered List' },
    PIE_CHART: { id: 'piechart', label: 'Pie Chart' },
}

type Dimension = { l?: number, t?: number, w: number, h: number };
type RGB = { r: number, g: number, b: number, a?: number };

@UnoComponent(
    {
        id: 'D3Viz',
        label: 'D3 Vizualizer',
        paletteable: true,
        props: [
            {
                id: 'type', label: 'Chart Type', editor: 'OptionSelector',
                extras: {
                    options: Object.values(ChartType).sort((a: any, b: any) => { return a.label > b.label ? 1 : -1 }),
                }
            },
            { id: 'width', label: 'Width' },
            { id: 'height', label: ' Height' },
            { id: 'data', label: ' Data', dataType: EntityConstants.PropType.JSON },
            { id: 'config', label: 'Configurations', dataType: EntityConstants.PropType.JSON },
        ],
        group: DesignerConstants.PaletteGroup.Integration.id,
    }
)
export class D3VizComp extends UnoCoreBaseComp {
    private vizID = Common.getUniqueKey('d3_viz_');

    buildComp(): JSX.Element {
        return (
            <span id={this.vizID} key={this.vizID}>
                {/* Visualization Placeholder */}
            </span>
        );
    }

    componentDidMount(): void {
        if (!d3) {
            console.log('Unable to load D3 Library');
            return;
        }

        this.refreshVisualization();
    }

    refreshVisualization() {
        switch (this.state.type) {
            case ChartType.LIST.id:
                this.buildUnorderedList();
                break;
            case ChartType.BAR_CHART.id:
                this.buildBarChart();
                break;
            case ChartType.PIE_CHART.id:
                this.buildPieChart();
                break;
        }
    }

    buildUnorderedList = (data: Array<any> = this.getData(), vizContainer = this.getVizContainer(), config = this.getConfig()) => {
        if (!vizContainer) {
            return;
        }

        vizContainer
            .append('ul')
            .selectAll('ul')
            .data(data)
            .enter()
            .append('li')
            // .append('b')
            .text(
                (d: any) => {
                    let text = (Common.checkType.Object(d) ? Common.stringify(d) : d);
                    return text ? text : '';
                }
            );

    }

    buildPieChart = (data: Array<any> = this.getData(), vizContainer = this.getVizContainer(), config = this.getConfig()) => {
        if (!vizContainer) {
            return;
        }
        const canvasConfig = config.canvas;
        const dim = canvasConfig.dim;
        const radius = Math.min(dim.w, dim.h) / 2 - canvasConfig.padding;
        const innerRadius = (config.chart?.thickness && config.chart.thickness < radius) ? (radius - config.chart.thickness) : 0;
        // console.log('Pie Chart Config: ', config, data);

        const canvas = this.addCanvas(vizContainer, canvasConfig)
            .append('g')
            .attr('transform', `translate(${dim.w / 2}, ${dim.h / 2})`)

        // canvas.style('margin-top', 400).style('overflow', 'visible');

        const formattedData = d3.pie().value((d: any) => { return d })(data);
        const arcGen: any = d3.arc().innerRadius(innerRadius).outerRadius(radius);
        const colorFn: any = d3.scaleOrdinal().range(d3.schemeSet2);
        const colorRange = this.getColorRange(data.length);

        canvas.selectAll()
            .data(formattedData)
            .join('path')
            .attr('d', arcGen)
            .attr('fill', (x: any, i: number) => { return colorRange[i] })
        //.style('opacity', 0.7)

        canvas.selectAll()
            .data(formattedData)
            .join('text')
            .text(x => { return x.value })
            .attr('fill', config.label.color)
            .attr('transform', x => {
                const d = arcGen(x).split(',');
                const xCor = parseInt(d[0].substr(1));
                const yCor = parseInt(d[1]);
                // console.log('x, y: ', xCor, yCor, x);

                return `translate(${arcGen.centroid(x)})`;
            })
        // .style('text-anchor', 'middle')

        // console.log('Pie Chart Created');
    }

    buildBarChart = (data: Array<any> = this.getData(), vizContainer = this.getVizContainer(), config = this.getConfig()) => {
        if (!vizContainer) {
            return;
        }

        // Configurations
        const labelConfig = config.label;
        const canvasConfig = config.canvas;
        const colorConfig = config.color;

        // initialize various factors...
        const maxVal = Math.max(...data);
        const alphaFactor = 1 / maxVal;
        const canvasDim: Dimension = canvasConfig.dim;
        // barSpacing: 10% of canvas width
        const barSpacing = canvasDim.w * 0.01;
        const padding = 2; // 
        // barWidth: space left (after spaces) for bars divided by number of bars
        const barWidth = (canvasDim.w - barSpacing * (data.length + 1)) / data.length;
        // bar(height)scale: the tallest bar to be 90% of canvas height
        let barScale = (canvasDim.h * 0.9) / maxVal;

        const canvas = this.addCanvas(vizContainer, canvasConfig);

        const getBarX = (d: number, i: number) => { return barSpacing + (barWidth + barSpacing) * i; }
        const getBarY = (d: number, i: number) => { return canvasDim.h - d * barScale }

        // bars
        const bars = canvas.selectAll('rect').data(data).enter().append('rect');
        bars
            .attr('x', getBarX)
            .attr('y', getBarY)
            .attr('width', barWidth)
            .attr('height', (d, i) => { return d * barScale })
            .style('fill', (d, i) => {
                const barColor = `rgba(${colorConfig.base.r}, ${colorConfig.base.g}, ${colorConfig.base.b}, ${d * alphaFactor})`;
                // console.log('Bar Value - Color: ', d, barColor);
                return barColor;
            })
            .style('stroke', 'rgb(0,0,0)')
            .style('stroke-width', 1)

        const getLabelX = (d: number, i: number) => { return barWidth / 2 + getBarX(d, i) }
        const getLabelY = (d: number, i: number) => { return getBarY(d, i) - labelConfig.padding }

        const labels = canvas.selectAll('text').data(data).enter().append('text');
        labels
            .attr('x', getLabelX)
            .attr('y', getLabelY)
            .attr('width', barWidth)
            .attr('font-family', labelConfig.font)
            .attr('font-size', labelConfig.fontSize)
            .attr('fill', labelConfig.color)
            .style('text-anchor', 'middle')
            .text(d => d);
    }

    addCanvas = (vizContainer = this.getVizContainer(), config: any) => {
        const svg = vizContainer
            .append('svg')
            .attr('height', config.dim.h + (config.padding * 2))
            .attr('width', config.dim.w + (config.padding * 2))
            .style('background-color', config.backColor)
            .style('padding', config.padding)
            .style('margin-left', config.margin)
            .style('border', config.border)
            .style('box-shadow', config.shadow)
            ;

        return svg;

    }

    getVizContainer = () => {
        return d3.select(`#${this.vizID}`);
    }

    getData = () => {
        return Common.safeParse(this.state.data);
    }

    getConfig = () => {
        const defaultConfig = {
            canvas: { dim: { w: 500, h: 500 }, padding: 5, margin: 20, border: '2px solid blue', shadow: '4px 4px green', backColor: 'white' },
            label: { font: 'Arial Black', fontSize: 15, color: 'blue', padding: 10 },
            color: { base: { r: 0, g: 255, b: 200 }, dark: { r: 0, g: 0, b: 0 }, light: { r: 255, g: 255, b: 255 } }
        }

        const config = this.state.config ? Common.safeParse(this.state.config) : defaultConfig;
        config.canvas = { ...defaultConfig.canvas, ...config.canvas };
        config.label = { ...defaultConfig.label, ...config.label };
        config.color = { ...defaultConfig.color, ...config.color };

        // console.log('Visualization Configurations: ', config);
        return config;
    }

    getColorRange = (size: number, dark = this.getConfig().color.dark, light = this.getConfig().color.light) => {
        const bHex = 16;
        const getHex = (rgb: RGB) => {
            return rgb.r.toString(bHex) + rgb.g.toString(bHex) + rgb.b.toString(bHex);
        }

        const darkNum = parseInt(getHex(dark), bHex);
        const lightNum = parseInt(getHex(light), bHex);

        const lower = Math.min(lightNum, darkNum);
        const higher = Math.max(lightNum, darkNum);

        const interval = (higher - lower) / size;
        const range = [];
        for (let i = 0; i < size; i++) {
            const color = Math.round(lower + i * interval);
            range.push(`#${color.toString(bHex)}`);
        }
        return range;
    }
}