import React from 'react';
import { BaseEntity, EntityCategory, EntityConstants, EntityProp } from '../../../@uno/api';
import { UnoComponent, Images } from '../../../@uno/core';
import { EntityEdit } from '../entity-edit.comp';
import { EntityListEdit } from './entity-list.edit.comp';

// MULTI ENTITY - TABLE EDIT 
@UnoComponent({
    id: EntityConstants.ListEditTypes.Table.id,
})
export class EntityTableEdit extends EntityListEdit {
    protected sortByProp: EntityProp | undefined;
    protected sortAscending: boolean = true;

    buildTableColumns(category?: EntityCategory, quickEdit = false) {
        const editProps = this.getViewProps(false, category);
        // console.log(`Table Header cols - ${editProps?.length}.`, category)
        const columns: Array<any> = editProps.map(
            (prop: EntityProp) => {
                return (
                    <th key={this.getUniqueKey()}>
                        <span title={prop.description ? prop.description : prop.label}> {prop.label} </span>
                        {this.buildSortBy(prop)}
                    </th>
                );
            }
        );
        return (
            <tr key={this.getUniqueKey()}>
                <th style={{ width: '20px' }}><span>&nbsp;</span></th>
                {columns}
            </tr>
        );
    }

    buildSortBy(prop: EntityProp) {
        let sortByIcon = Images.Icon.unsorted;
        switch (prop.dataType) {
            case EntityConstants.PropType.ARRAY:
            case EntityConstants.PropType.IMAGE:
            case EntityConstants.PropType.LAYOUT:
                return this.buildEmptyElement();
        }

        if (this.sortByProp && this.sortByProp.id === prop.id) {
            sortByIcon = (this.sortAscending) ? Images.Icon.sortAsc : Images.Icon.sortDesc;
        }

        return (
            <img src={sortByIcon}
                alt='sort by'
                onClick={() => { this.setSortBy(prop) }}
                style={{ width: '20px', cursor: 'pointer' }} />
        );
    }

    setSortBy(prop: EntityProp) {
        if (this.sortByProp && this.sortByProp.id === prop.id) {
            // change the order.
            this.sortAscending = !this.sortAscending;
        } else {
            // change the sort by prop
            this.sortByProp = prop;
            this.sortAscending = true;
        }
        this.setState({});
    }

    buildContent(): any {
        const editEntities = this.buildEntities();
        return (
            <div className='entity-list'>
                <table>
                    <thead>
                        {this.buildTableColumns(this.getCategory(), true)}
                    </thead>
                    <tbody>
                        {editEntities}
                    </tbody>
                </table>
            </div>
        )
    }

    getEntityEditComp = () => {
        return EntityTableRowEdit;
    }

    getEntities() {
        let entities = super.getEntities();
        const sortBy: any = this.sortByProp;
        if (sortBy) {
            const propDataType = sortBy.dataType;
            entities = entities.sort(
                (first: any, second: any) => {
                    let compared: number = 0;
                    let propValFirst: any = EntityConstants.getValue(sortBy, EntityConstants.build(first));
                    let propValSecond: any = EntityConstants.getValue(sortBy, EntityConstants.build(second));;
                    if (propDataType === EntityConstants.PropType.ENTITY
                        || propDataType === EntityConstants.PropType.ENTITY_INLINE) {
                        propValFirst = EntityConstants.build(propValFirst).toString();
                        propValSecond = EntityConstants.build(propValSecond).toString();
                    }
                    if (propValFirst < propValSecond) {
                        compared = (this.sortAscending) ? -1 : 1;
                    } else if (propValFirst === propValSecond) {
                        compared = 0;
                    } else {
                        compared = (this.sortAscending) ? 1 : -1;
                    }

                    return compared;
                }
            );
        }
        return entities;
    }

}

class EntityTableRowEdit extends EntityEdit {
    getPrintEditID() {
        return undefined;
    }

    canEdit = () => {
        return false;
    }

    buildContent() {
        const cells = this.getViewProps()?.map(p => {
            return (
                <td key={this.getUniqueKey()}>
                    {this.buildEntityProp(p)}
                </td>
            );
        });
        return (
            <tr key={this.getUniqueKey()}>
                <td>
                    {this.buildActionButtons()}
                    {/* this.buildStatusTransition(this.getStatusTransitions()) */}
                </td>
                {cells}
            </tr>
        );
    }

    buildEntityProp(p: EntityProp) {
        let propEdit = null;
        const propVal = this.getPropValue(p);
        /*
        if (this.isBasicType(p)) {
            propEdit = super.buildEntityProp(p, true);
        } else {
            const propType = (p.category) ? p.category : p.dataType
            propEdit = (
                <div>
                    {propType} : {propVal ? propVal.toString() : ''}
                </div>
            );
        }
        */
        if (propVal === undefined || propVal === null) {
            propEdit = <span> &nbsp; </span>;
        } else {
            propEdit = super.buildEntityProp(p, true);
        }
        return propEdit;

    }

}