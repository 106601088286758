// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes zoom_zoom {
    from {
        background-color: red;
    }

    to {
        background-color: yellow;
    }
}

.animate-reader {
    animation-duration: 4s;
    animation-name: zoom_zoom;
    animation-iteration-count: infinite;
}`, "",{"version":3,"sources":["webpack://./src/@uno/core/components/reader/reader-styles.css"],"names":[],"mappings":"AAAA;IACI;QACI,qBAAqB;IACzB;;IAEA;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,mCAAmC;AACvC","sourcesContent":["@keyframes zoom_zoom {\n    from {\n        background-color: red;\n    }\n\n    to {\n        background-color: yellow;\n    }\n}\n\n.animate-reader {\n    animation-duration: 4s;\n    animation-name: zoom_zoom;\n    animation-iteration-count: infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
