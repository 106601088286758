import React from 'react';
import { EntityView } from '../../@uno-entity/components/entity-view.comp';
import { Common, EM } from '../../@uno/api';
import { UC, UnoCompEvents, UnoComponent } from "../../@uno/core";
import { UnoSheetConstants, WorkbookService } from '../services/workbook.service';

// A Worksheet - VIEW
@UnoComponent({
    id: 'WorksheetView',
    label: 'View A Worksheet',
    props: [],
    getDesign: () => { return (<div>View A Worksheet - Design</div>); },
    getPreview: () => { return (<div>View A Worksheet - Preview</div>); },
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
})
export class WorksheetView extends EntityView {
    private propID_DataSet = 'data_set';
    recordsLoaded = false;
    // records: Array<any> = [];
    private _handlerOnViewPortUpdated: any;
    private viewPort: any;

    constructor(props:any){
        super(props);
        console.log(new Date(), `: Initializing sheet: `, this.props);
    }

    componentDidMount() {
        this._handlerOnViewPortUpdated = EM.register(UnoSheetConstants.Event.onViewPortUpdated, async (input: any) => {
            const sheetID = this.getSheetID();
            if (input.sheetID === sheetID) {
                // console.log(`View Port updated for sheet - `, sheetID);
                this.viewPort = input.viewPort;
                // reload the data for the viewport.
                const cells = await this.loadCells();
            }
        });
    }

    componentWillUnmount() {
        EM.unregister(this._handlerOnViewPortUpdated);
    }

    buildContent() {
        if (!this.getEntity()) {
            console.log(new Date(), `: Loading sheet`);
            return <>Loading...</>;
        }

        console.log(new Date(), `: Building sheet - `, this.getSheetID());
        return (
            <>
                {this.buildSheetActions()}
                {this.buildCellsGrid()}
            </>
        );
    }

    buildCellsGrid() {
        const sheetID = this.getSheetID();
        return (
            <>
                <UC.UnoSheet
                    key={Common.getUniqueKey()}
                    // columns={0}
                    // rows={0}
                    id={sheetID}
                    // data={this.state.cells}
                    // columnNames={colNames ? colNames : []}
                    onDataChange={async (editedCells: any) => {
                        editedCells = await WorkbookService.saveCells(sheetID, editedCells, this.getAppID(), this.viewPort);
                        console.log(`Saved Sheet [${sheetID}] Data: `, editedCells);
                        this.loadCells();
                    }} />
            </>
        );
    }

    buildFormulaBar() {

    }

    buildSheetActions() {
        const actions: Array<any> = [];

        const ws = this.getEntity();
        if (ws) {
            const dataSet = ws.getValue(this.propID_DataSet);
            if (!dataSet) {
                actions.push({
                    id: 'Get Data',
                    action: () => {
                        Common.showDialog(
                            {
                                comp: (
                                    <>
                                        <UC.DataSetSelector
                                            eProp={this.getCategoryPropByID(this.propID_DataSet)}
                                            onFinish={this.handleFinishDataSetSelection}
                                        />
                                    </>
                                ),
                                onClose: () => { },
                                title: 'Get Data',
                                screenMode: 'screen-center',
                                styles: {
                                    position: 'absolute',
                                    width: '80%',
                                    left: '10%',
                                    height: '400px',
                                    border: '1px solid black',
                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                },
                                // footer:(<><h1>The Dialog Footer</h1></>)
                            }
                        );
                    }
                });
            }
        }

        return this.buildActions(actions, true);
    }

    handleFinishDataSetSelection = async (dataSet: any) => {
        Common.hideDialog();
        console.log(`Selected DataSet: `, dataSet);
        const ws = await WorkbookService.assignDataSet(this.getSheetID(), dataSet, this.getAppID());
        this.loadCells();
    }

    // utilities...
    loadCells = async () => {
        const sheetID = this.getSheetID();
        if (sheetID) {
            // console.log('Loading cells of the sheet : ', sheetID, this.viewPort);
            const cells = await WorkbookService.loadCells(sheetID, this.getAppID(), this.viewPort);
            // console.log(`Sheet: ${sheetID}. ${this.cells.length} cells loaded.`, this.cells);
            EM.emit(
                UnoSheetConstants.Event.getEventID(UnoSheetConstants.Event.onSheetDataRefresh, sheetID),
                { sheetID: sheetID, cells: cells }
            );
            // console.log(`${cells?.length} Cells loaded for sheet : `, sheetID);
            return cells;
        } else {
            return undefined;
        }
    }

    getSheetID = () => {
        return this.getEntityId();
    }

}
