import React from 'react';
import { EntityConstants, EntityProp } from '../../../api/entity.service';
import { Common, DesignerConstants, UC, UnoComponent } from '../../../core';
import { NavItemDef, NavStyleClasses } from '../navigations/navigation.comp';
import { UnoCoreBaseComp } from './../uno-core.base.comp';

export const SELECT_BOX_PROPS: Array<EntityProp> = [
    { groupID: 'General', id: 'label', label: 'Label', },
    { groupID: 'General', id: 'options', label: 'Options', dataType: EntityConstants.PropType.JSON, validators: [] },
    { groupID: 'General', id: 'multiSelect', label: 'Multi Select?', dataType: EntityConstants.PropType.BOOLEAN },
    { groupID: 'Event', id: 'onSelect', label: 'onSelect (Function)', dataType: EntityConstants.PropType.FUNCTION, validators: [] },
    { groupID: 'Style', id: 'styleClassOverrides', label: 'Style Class Overrides', dataType: EntityConstants.PropType.JSON, },
];

export interface Option extends NavItemDef {
    isDefault?: boolean,
    isSelected?: boolean,
    canSelect?: boolean,
}


const SELECTBOX_STYLE_CLASSES: NavStyleClasses = {
    appNavContent: ' select-box-content ',
    appNavIcon: ' select-box-icon ',
    appNavList: ' select-box-list ',
    itemToggle: ' float-right ',
    orientationH: ' select-box-horizontal',
    orientationV: ' select-box-vertical',
    appNavLink: ' select-box-link ',
    navLinkActive: ' select-box-link-active ',
    navLinkInactive: ' select-box-link-inactive ',
}

@UnoComponent({
    id: 'SelectBox',
    label: 'The Select Box',
    props: SELECT_BOX_PROPS,
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class SelectBox extends UnoCoreBaseComp {

    constructor(props: any) {
        super(props);

        // this.profiler.log('SelectBox Style Classes: ', styleClasses);
        this.state = {
            ...this.state,
            options: Common.safeParse(this.props.options || []),
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.initialize();
    }

    initialize(options: any = this.getOptions()) {
        this.preProcessOptions(options)
            .then(options => {
                const selected = options?.filter(opt => {
                    return (opt.isDefault || opt.isSelected);
                });

                this.reRender({
                    options: options,
                    selected: ((selected?.length > 0) ? selected : this.getSelected()),
                });
            });
    }

    canProfile() {
        return false;
    }

    buildComp() {
        let view = null;
        if (!this.getOptions() || this.getOptions().length === 0) {
            return view;
        }
        if (this.isMultiSelect()) {
            view = this.buildMultiSelect();
        } else {
            view = this.buildSingleSelect();
        }
        return (
            <div style={this.getStyles()} className=' select-box '>
                {view}
            </div>
        )
    }

    buildSingleSelect() {
        const selectLabel = this.state.label || 'Select';
        let selectionRoot: Option = { id: 'selection-root', action: undefined, };

        const selectedOptions = this.getSelected();
        if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
            selectionRoot = { ...selectedOptions[0], ...selectionRoot };
        } else if (selectedOptions) {
            selectionRoot = { ...selectedOptions, ...selectionRoot };
        } else {
            selectionRoot = { label: selectLabel, ...selectionRoot };
        }

        selectionRoot.children = this.getOptions();
        return (
            <UC.Navigation
                navs={[selectionRoot]}
                key={Common.getUniqueKey()}
                styleClasses={this.getStyleOverrides()}
                showNavBar={true}
            />
        );
    }

    buildMultiSelect() {
        return (
            <UC.Navigation
                navs={this.getOptions()}
                key={Common.getUniqueKey()}
                styleClasses={this.getStyleOverrides()}
                showNavBar={true}
            />
        );
    }

    getStyleOverrides() {
        const overrides = { ...this.getDefaultStyleClasses(), ...Common.safeParse(this.state.styleClassOverrides), };
        // this.profiler.log('Select Box Style overrides: ', overrides);
        return overrides;
    }

    getDefaultStyleClasses() {
        return SELECTBOX_STYLE_CLASSES;
    }

    isMultiSelect() {
        return this.state.multiSelect || false;
    }

    getOptions() {
        return Common.safeParse(this.state.options);
    }

    setSelected(option: Option, setSelected?: boolean, notifyClient: boolean = true) {
        let selectedOptions: any = undefined;
        if (this.isMultiSelect()) {
            selectedOptions = this.state.selected || [];
            let isSelected = (option.isSelected !== true);
            if (setSelected !== undefined) {
                isSelected = setSelected;
            }
            this.updateMultiSelect(selectedOptions, option, isSelected);
        } else {
            // option.isSelected = true;
            selectedOptions = option;
        }

        // this.profiler.log('Setting selected options: ', selectedOptions);
        this.reRender({ selected: selectedOptions });

        if (notifyClient) {
            this.doOnSelect(selectedOptions);
        }
    }

    getSelected = () => {
        return this.state.selected;
    }

    doOnSelect = (options: any) => {
        let onSelect: any = this.state.onSelect;
        // this.profiler.log('On Select = ', onSelect);

        if (onSelect && Common.checkType.String(onSelect)) {
            const onSelectObj = Common.parse(onSelect);
            onSelect = onSelectObj.fn;
        }
        if (onSelect && Common.checkType.Function(onSelect)) {
            if (!options) {
                this.profiler.log('Clear Selection.', onSelect);
            }
            onSelect(options);
        }
    }

    private updateMultiSelect(selectedOptions: Array<Option>, option: Option, isSelected?: boolean) {
        option.isSelected = isSelected;

        // remove the given option from selected options, if already present
        for (let i = 0; i < selectedOptions.length; i++) {
            const opt = selectedOptions[i];
            if (opt.id === option.id) {
                selectedOptions.splice(i, 1);
            }
        }

        // if selected, add the given option to selected options
        if (isSelected) {
            selectedOptions.push(option);
        }

        // TODO: go down the hierarchy
        option.children?.forEach(
            (child: Option) => {
                this.updateMultiSelect(selectedOptions, child, isSelected);
            }
        );

        // this.profiler.log('Updated Multi Select: ', option, isSelected, selectedOptions,);
    }

    async preProcessOptions(options: Array<any>) {
        if (Array.isArray(options)) {
            for (let i = 0; i < options.length; i++) {
                const opt = options[i];
                if (!opt) {
                    options.splice(i, 1);
                    continue;
                }
                // if multiSelect
                if (this.isMultiSelect()) {
                    opt.originalBuildView = opt.originalBuildView || opt.buildView;
                    const preSpecifiedBuildView = opt.originalBuildView;

                    opt.buildView = (option: Option) => {
                        let optView: any = <span>{option.label ? option.label : option.id}</span>;
                        if (preSpecifiedBuildView) {
                            optView = preSpecifiedBuildView(option);
                        }
                        if (opt.canSelect === false) {
                            return optView;
                        } else {
                            return (
                                <span>
                                    <input
                                        type='checkbox'
                                        checked={opt.isSelected}
                                        value={option.id}
                                        onChange={
                                            () => {
                                                // nothing is done here.
                                            }
                                        }
                                    />
                                    {optView}
                                </span>
                            );
                        }
                    }
                }

                // set action
                opt.originalAction = opt.originalAction || opt.action;
                const preSpecifiedAction = opt.originalAction;

                opt.action = () => {
                    if (opt.canSelect === false) {
                        return false;
                    }

                    if (preSpecifiedAction) {
                        preSpecifiedAction(opt);
                    }
                    this.setSelected(opt);
                };

                // pre-process children
                if (opt.children && opt.children.length > 0) {
                    opt.children = await this.preProcessOptions(opt.children);
                }
            }
        }

        return options;
    }

}

