import { UnoModule } from '../@uno/core';
import {
    ActionSelector, AppSelector, BooleanEditor, CSSEditor, CategorySelector, ColorEditor, ComponentSelector, CustomPropEditor, DatatypeSelector,
    DateEditor, DateTimeEditor, EmailEditor, FileEditor, FileLoader, SourceCodeEditor, FunctionSelector, HTMLEditor, ImageEditor, JSONEditor, MD5EncryptedEditor,
    MultilineEditor, NumberEditor, OptionSelector, PasswordEditor, PropEditor, PropertySelector, TextEditor, TimeEditor, TypeAheadEditor,
    XMLEditor
} from './components/editors/prop-editor.comp';
import { PropEntityEditor, PropInlineEntityEditor } from './components/editors/prop-entity-editor.comp';
import { PropEntitySelector } from './components/editors/prop-entity-selector.comp';
import { FilterEditor } from './components/editors/prop-filter-editor.comp';
import { PropMultipleEditor } from './components/editors/prop-multiple-editor.comp';
import { Designer } from './components/layout/designer.comp';
import { LayoutEditor, LayoutViewer } from './components/layout/prop-layout.comp';
import { PaymentHandler } from './components/payments/prop-payment.comp';
import { BasePropEditor, BasePropViewer, PropBaseComp, PropComp, PropLabel, PropValue } from './components/prop-base.comp';
import { AppIDViewer } from './components/viewers/app_id-viewer.comp';
import { PropEntityQuickViewer } from './components/viewers/prop-entity-quickviewer.comp';
import { PropEntityViewer, PropInlineEntityViewer } from './components/viewers/prop-entity-viewer.comp';
import { FilterDefViewer } from './components/viewers/prop-filter-def-viewer.comp';
import { PropMultipleViewer } from './components/viewers/prop-multiple-viewer.comp';
import {
    BooleanViewer,
    CSSViewer,
    CategoryViewer,
    CustomPropViewer,
    DateTimeViewer, DateViewer, EmailViewer, FileViewer, SourceCodeViewer, JSONViewer, LinkViewer, MultilineViewer,
    NumberViewer, OptionViewer, PasswordViewer, PropViewer, StandardFunctionViewer, TimeViewer,
    XMLViewer,
    SelectedPropertyViewer
} from './components/viewers/prop-viewer.comp';

// The Module...
@UnoModule(
    {
        id: 'EntityPropModule',
        imports: [

        ],
        components: [
            PropBaseComp,
            PropComp,
            PropLabel,
            PropValue,
            FileLoader,

            BasePropEditor,
            PropEditor,
            BooleanEditor,
            ColorEditor,
            DatatypeSelector,
            DateEditor,
            DateTimeEditor,
            ImageEditor,
            FileEditor,
            MD5EncryptedEditor,
            MultilineEditor,
            HTMLEditor,
            JSONEditor,
            CSSEditor,
            XMLEditor,
            SourceCodeEditor,
            NumberEditor,
            OptionSelector,
            PasswordEditor,
            TextEditor,
            EmailEditor,
            TimeEditor,
            TypeAheadEditor,
            PropEntityEditor,
            PropInlineEntityEditor,
            PropEntitySelector,
            PropMultipleEditor,

            ActionSelector,
            CategorySelector,
            PropertySelector,
            ComponentSelector,
            AppSelector,
            FunctionSelector,
            CustomPropEditor,

            BasePropViewer,
            PropViewer,
            DateTimeViewer,
            DateViewer,
            TimeViewer,
            FileViewer,
            MultilineViewer,
            SourceCodeViewer,
            StandardFunctionViewer,
            NumberViewer,
            BooleanViewer,
            OptionViewer,
            CategoryViewer,
            AppIDViewer,
            SelectedPropertyViewer,
            CustomPropViewer,

            EmailViewer,
            LinkViewer,
            JSONViewer,
            CSSViewer,
            XMLViewer,
            PasswordViewer,
            PropEntityViewer,
            PropInlineEntityViewer,
            PropEntityQuickViewer,
            PropMultipleViewer,

            LayoutEditor,
            LayoutViewer,
            Designer,

            FilterEditor,
            FilterDefViewer,

            PaymentHandler,
        ],
    }
)
export class EntityPropModule { }


