import React from 'react';
import { EntityConstants } from '../../../@uno/api/entity.service';
import { UC, UnoComponent } from '../../../@uno/core';
import { EntityListView } from './entity-list.view.comp';

// MULTI ENTITY - CAROUSEL VIEW
@UnoComponent({ 
    id: EntityConstants.ListViewTypes.Carousel.id, 
})
export class EntityCaraouselView extends EntityListView {

    buildContent() {
        return (
            <UC.Carousel>
                {this.buildEntities()}
            </UC.Carousel>
        );
    }

}
