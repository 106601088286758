import { UnoModule } from '../../@uno/core';
import { ChartJsVizComp } from './component/chart.js-viz.comp';
// The Module...

@UnoModule(
    {
        id: 'ChartJsModule',
        imports: [
        ],
        components: [
            ChartJsVizComp,
        ],
    }
)
export default class ChartJsModule {

}

