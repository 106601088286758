import React from 'react';
import { EntityConstants } from '../../../api';
import { Common, DesignerConstants, Images, UnoCompEvents, UnoComponent } from '../../../core';
import { Section, SectionCompProps } from './section.comp';

@UnoComponent({
    id: 'VSection',
    label: 'Vertical Section',
    help: `
    <ul>
        <li>It is a container component.</li> 
        <li>It elables you to lay the children in columnar layout</li>
    </ul>
    `,

    icon: Images.Icon.vSection,
    props: [
        ...SectionCompProps,
        {
            groupID: 'Table',
            id: 'cols',
            label: 'Column Count',
            description: 'An Integer between 1 and 12, or a comma-separated list of widths',
            dataType: EntityConstants.PropType.STRING,
            validators: [],
        },
        { groupID: 'Table', id: 'rigidCols', label: 'Do NOT override the specified Colum width?', dataType: EntityConstants.PropType.BOOLEAN },
        { groupID: 'Style', id: 'cellStyles', label: 'Styles for Each Cell', dataType: EntityConstants.PropType.JSON },
        { groupID: 'Style', id: 'colStyles', label: 'Column Styles', dataType: EntityConstants.PropType.JSON, },
    ],
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    isContainer: true,
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Frequent.id,
    serialNo: 100,
})
export class VSection extends Section {
    private static DEFAULT_COLS = 1;
    private static GRID_SIZE = 12;
    cols: any = VSection.DEFAULT_COLS;

    constructor(props: any) {
        super(props);
        this.cols = this.props.cols;

        // console.log(`V Section Props: `, this.props);
        /*
        if (this.state.compConfig?.id === 's_5830732') {
            console.log('Viz Card:', { ...this.state }, { ...this.state.compConfig });
        }
        */
    }

    layChildren() {
        const colSpecs = this.getColSpecs();
        // console.log(`VSection - col count = ${colSpecs.length}`, colSpecs);
        let colStyles: any = Common.safeParse(this.state.colStyles) || [];
        if (Array.isArray(colStyles) === false) {
            colStyles = [colStyles];
        }

        let styles: any = Common.safeParse(this.state.cellStyles || {});

        if (!this.isLiveMode()) {
            styles.position = 'relative';
        }

        let children: any = this.props.children;

        let content = children;
        if (children?.map) {
            content = children?.map(
                (child: any, index: number) => {
                    const colIndex = (index % colSpecs.length);
                    // console.log(`VSection column Index - ${colIndex}. `, colSpecs);
                    const width = colSpecs[colIndex];
                    const isZeroWidth = Common.isEqualTo(0, width);
                    const colStyle = colStyles[colIndex] ? colStyles[colIndex] : {};

                    /*
                    if (colSpecs.length === 1) {
                        return child;
                    } else {
                    */
                    return (
                        <div
                            style={{
                                //flex: `0 0 ${colSpecs[colIndex]}`
                                width: width,
                                verticalAlign: 'top',
                                display: (isZeroWidth ? 'none' : (colSpecs.length === 1) ? 'inherit' : 'inline-block'),
                                ...colStyle,
                                ...styles,
                            }}
                            key={Common.getUniqueKey()}
                        >
                            {child}
                        </div>
                    );
                }
                // }
            );
        }
        // console.log(`V Section styles: `, Common.stringify(styles));

        return (
            <>
                {content}
            </>
        );

    }

    getColCount(val: any = this.cols) {
        let cols = VSection.DEFAULT_COLS;
        if (val) {
            cols = Math.floor(Number(val));
            if (cols > VSection.GRID_SIZE) {
                cols = VSection.GRID_SIZE;
            }
        }
        return cols;
    }

    getColSpecs() {
        // console.log('Vsection - ', this.cols, this.props.cols);
        const isSmallScreen = Common.isSmallScreen();
        const notRigid = !(this.state.rigidCols);

        const colsProp = this.cols;
        const colSpecs: Array<any> = [];
        let colCnt = VSection.DEFAULT_COLS;

        const getDefaults = () => {
            colCnt = this.getColCount(colsProp);
            const colWidth = (isSmallScreen && notRigid) ? 100 : Math.floor(100 / colCnt);
            for (let i = 0; i < colCnt; i++) {
                colSpecs.push(`${colWidth}%`);
            }
        }

        if (colsProp) {
            const specs = colsProp.split(`,`);
            if (specs.length <= 1) {
                getDefaults();
            } else {
                specs.forEach((spec: any) => {
                    spec = spec.trim();
                    if (spec.length > 0) {
                        colSpecs.push((isSmallScreen && notRigid) ? '100%' : spec);
                    }
                })
            }
        } else {
            getDefaults();
        }
        return colSpecs;
    }

}
