import { AppInfoService } from '../../@uno-app/service/app.info.service';
import { SessionManager } from '../../@uno-app/service/session.service';
import { Condition, RemoteService } from '../../@uno/api';
import { EntityCategory, EntityConstants } from '../../@uno/api/entity.service';
import { ConditionGroup, } from '../../@uno/api/filter.service';


class EntityFilterServiceImpl {

    save(name: string, definition: any, appID?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            RemoteService.post('filter/save', {
                appID: appID ? appID : AppInfoService.getActiveApp()?.id,
                name: name, definition: definition, session: SessionManager.activeSession
            }).then(res => { res.json().then((json: any) => { resolve(json); }); })
                .catch(err => { reject(err); });
        });
    }

    async find(filterId: string, appID: any = AppInfoService.getActiveApp()?.id) {
        if (!appID) {
            console.log('Invalid AppID to execute an entity filter - ', filterId, appID);
            return undefined;
        }

        const result = await RemoteService.getData(
            await RemoteService.post('filter/find', {
                appID: appID ? appID : AppInfoService.getActiveApp()?.id,
                filterId: filterId, session: SessionManager.activeSession,
            })
        );
        return result;
    }

    async result(conditions: Array<Condition | ConditionGroup>, options?: any, appID?: any, category?: EntityCategory, session = SessionManager.activeSession): Promise<any> {

        // console.log(`Filter Options: `, conditions, options);
        let entities = await RemoteService.getData(
            await RemoteService.post('filter/result',
                {
                    appID: appID ? appID : AppInfoService.getActiveApp()?.id,
                    conditions: conditions,
                    options: options,
                    category: category,
                    session: session,
                }
            )
        );
        entities = entities ? entities.map((entity: any) => {
            return EntityConstants.build(entity);
        }) : undefined;
        return entities;

    }

    async getTotalCount(conditions: Array<Condition | ConditionGroup>, options?: any, appID?: any, session: any = SessionManager.activeSession): Promise<number> {
        const result = await RemoteService.getData(
            await RemoteService.post(
                'filter/count',
                {
                    appID: appID ? appID : AppInfoService.getActiveApp()?.id,
                    conditions: conditions,
                    options: options,
                    session: session,
                }
            )
        );
        return result?.totalCount;
    }

    async build(conditions: Array<Condition | ConditionGroup>, appID?: any, session: any = SessionManager.activeSession): Promise<any> {
        const result = await RemoteService.getData(
            await RemoteService.post(
                'filter/build',
                {
                    appID: appID ? appID : AppInfoService.getActiveApp()?.id,
                    conditions: conditions,
                    session: session,
                }
            )
        );
        return result;
    }
}


export const EntityFilterService = new EntityFilterServiceImpl();