// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.accordion-header {
    display: flex;
    background-color: #FFFFFF;
    border: 1px inset #D2D2D2;
    border-radius: 5px;
    padding: 0 10px;
}

.accordion-icon {
    padding: 0px 2px 0px 10px;
}

.accordion-title {
    padding: 0px 10px 0px 2px;
    flex-grow: 1;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: inherit;
    line-height: 1.8rem;
}

.accordion-toolbar {
    padding: 0px 20px 0px 20px;
}

.accordion-toggler {
    padding: 0px 20px 0px 20px;
    cursor: pointer;
}

.accordion-body {
    padding:20px;
}

.accordion-footer {
    padding:20px;
}`, "",{"version":3,"sources":["webpack://./src/@uno/core/components/containers/accordion.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".accordion {\n    border: 1px solid #D8D8D8;\n    border-radius: 5px;\n    display: flex;\n    flex-direction: column;\n}\n\n.accordion-header {\n    display: flex;\n    background-color: #FFFFFF;\n    border: 1px inset #D2D2D2;\n    border-radius: 5px;\n    padding: 0 10px;\n}\n\n.accordion-icon {\n    padding: 0px 2px 0px 10px;\n}\n\n.accordion-title {\n    padding: 0px 10px 0px 2px;\n    flex-grow: 1;\n    font-size: 1.2rem;\n    font-weight: 500;\n    font-family: inherit;\n    line-height: 1.8rem;\n}\n\n.accordion-toolbar {\n    padding: 0px 20px 0px 20px;\n}\n\n.accordion-toggler {\n    padding: 0px 20px 0px 20px;\n    cursor: pointer;\n}\n\n.accordion-body {\n    padding:20px;\n}\n\n.accordion-footer {\n    padding:20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
