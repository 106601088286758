import React from 'react';
import { UnoCoreBaseComp } from '../uno-core.base.comp';
import { Common, DesignerConstants, EM, Images, UC, UnoComponent } from '../../../core';

@UnoComponent({
    id: 'WindowNav',
    label: 'Window Navigations',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class WindowNav extends UnoCoreBaseComp {
    private routeChangeHandler: any;

    componentDidMount(): void {
        super.componentDidMount();
        this.routeChangeHandler = EM.register(Common.Event.ROUTE_CHANGED, () => { this.reRender(); });
    }

    componentWillUnmount(): void {
        EM.unregister(this.routeChangeHandler);
        super.componentWillUnmount();
    }

    buildComp() {
        return (
            <UC.Section key={Common.getUniqueKey()}>
                <UC.Navigation
                    navs={[
                        {
                            id: 'history_go_back',
                            label: 'Back',
                            icon: Images.Icon.previous,
                            action: () => {
                                window.history.back();
                            }
                        }
                    ]}
                />
            </UC.Section>
        );
    }
}