import { Common, EntityConstants } from '../../../@uno/api';
import { UC, UnoComponent } from '../../../@uno/core';
import { PropEntityViewer } from './prop-entity-viewer.comp';

@UnoComponent({ id: 'PropEntityQuickViewer' })
export class PropEntityQuickViewer extends PropEntityViewer {

    getViewer() {
        return UC.EntityQuickView;
    }

    setDefaultValue() {
        const catID = this.getCategoryID();
        this.selectedEntity = undefined;
        const defValObj = this.getDefaultValue();
        if (defValObj) {
            this.selectedEntity = EntityConstants.build(Common.safeParse(defValObj));
        }
    }
}
