import React from 'react';
import { Common, UC, UnoComponent } from '../../../core';
import { UnoCoreBaseComp } from '../uno-core.base.comp';
import { EntityConstants } from '../../../api';
import VideoRecorder from 'react-video-recorder';

@UnoComponent({
    id: 'VideoRecorder',
    label: 'Video Recorder',
    paletteable: true,
    props: [
        { id: 'onUpload', label: 'On Upload Handler', dataType: EntityConstants.PropType.FUNCTION },
    ],
})
export class VideoRecorderComp extends UnoCoreBaseComp {
    private comp_id = Common.getUniqueKey('video_recorder_');

    buildComp(): JSX.Element | null | undefined {
        return (
            <>
                <VideoRecorder
                    onRecordingComplete={this.addToRecordings}
                />
                {this.buildRecordings()}
            </>
        );
    }


    buildRecordings() {
        let handleOnUpload = this.state.onUpload;
        const recordings = this.getRecordings().map(
            (rec: Blob, idx: number) => {
                const url = URL.createObjectURL(rec);
                return (
                    <UC.VSection cols='80%, 20%'
                        styles={{
                            borderTop: '1px solid',
                            padding: '5px'
                        }}
                    >
                        <audio
                            key={Common.getUniqueKey('recording_')}
                            controls={true}
                            src={url}
                            controlsList='nodownload'
                        />
                        {handleOnUpload ? (
                            <UC.VSection styles={{ textAlign: 'right' }}>
                                <UC.Button action={async () => {
                                    let fileName = `${Common.getUniqueKey('video_')}`;
                                    const userFileName = window.prompt('The Video File Name', fileName);
                                    if (userFileName !== null) {
                                        fileName = userFileName;
                                    }

                                    const recordingFile = new File([rec],
                                        fileName,
                                        {
                                            type: rec.type,
                                        }
                                    );
                                    console.log('Uploading Video File: ', recordingFile, handleOnUpload);
                                    const result = await handleOnUpload(recordingFile);
                                    if (result === true) {
                                        this.setRecordings([]);
                                    }
                                }}>
                                    Upload
                                </UC.Button>
                            </UC.VSection>
                        ) : undefined}
                    </UC.VSection>
                )
            }
        );

        return (
            <div
                key={this.comp_id}
                style={{ margin: '5px', padding: '5px' }}
            >
                {recordings}
            </div>
        );
    }

    addAudioElement = (blob: any) => {
        const container = document.getElementById(this.comp_id);
        if (container) {
            const url = URL.createObjectURL(blob);
            const audio = document.createElement('audio');
            audio.src = url;
            audio.controls = true;
            container.appendChild(audio);
        }
    };

    addToRecordings = (blob: Blob) => {
        const recordings = this.getRecordings();
        recordings.push(blob);
        this.setRecordings(recordings);
    }

    getRecordings = (): Array<Blob> => {
        return this.state.recordings || [];
    }

    setRecordings = (recordings: Array<Blob>) => {
        this.reRender({ recordings: recordings });
    }
}