import React from 'react';
import { Common, DesignerConstants, UC, UnoComponent, UnoComponentManager } from '../../../core';
import { Section } from './section.comp';
import { EntityConstants, EntityProp, Images } from '../../../api';
import { PageLimitOptions, PageNavigation } from '../page-nav.comp';


const NavPosition = {
    Top: { id: 'top', label: 'Above the List' },
    Bottom: { id: 'bottom', label: 'Below the List' },
}


export const IteratorProps: Array<EntityProp> = [
    {
        id: 'dataList',
        label: 'Data List',
        groupID: 'Data',
    },
    {
        id: 'propName',
        label: 'Property Name',
        groupID: 'Data',
    },
    {
        id: 'pageLimit',
        label: 'Page Limit',
        groupID: 'Pagination',
        dataType: EntityConstants.PropType.NUMBER,
        viewer: 'OptionViewer', editor: 'OptionSelector', extras: { options: [...PageLimitOptions] },
    },
    { groupID: 'Pagination', id: 'showPageNav', label: 'Show Pagination?', dataType: EntityConstants.PropType.BOOLEAN },
    {
        groupID: 'Pagination', id: 'pageNavPosition', label: 'Place Pagination at', editor: 'OptionSelector',
        extras: { options: Object.values(NavPosition), },
    },
    { groupID: 'Style', id: 'containerStyles', label: 'Overall Styles', dataType: EntityConstants.PropType.JSON, },
    { groupID: 'Style', id: 'containerClasses', label: 'Overall Style Classes', },
];

@UnoComponent({
    id: 'Iterator',
    label: 'The Iterator',
    isContainer: true,
    props: IteratorProps,
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Frequent.id,
})
export class Iterator extends Section {
    navPositionDefault: any = NavPosition.Bottom.id;

    buildComp(): JSX.Element {
        const Comp = super.buildComp();
        return (
            <>
                {this.buildPageNav(NavPosition.Top.id)}
                {Comp}
                {this.buildPageNav(NavPosition.Bottom.id)}
            </>
        )
    }

    buildContent(styles?: any) {
        if (this.isLiveMode()) {
            return this.buildLive(styles);
        } else {
            return super.buildContent(styles);
        }
    }

    buildLive = (styles?: any) => {
        const { config, buildChildren, mode, props, dataList, } = this.getInputs();
        // console.log('Building Children of an Iterator: ', dataList, this.state);

        const propName = props.propName ? props.propName : '_data';
        const pNameIndex = `${propName}_index`;
        const pNameList = `${propName}_list`;

        let children = undefined;
        if (buildChildren && Array.isArray(dataList)) {
            const { lower, upper, pageLimit, currentPage, pageCount, totalCount } = this.getLimits(dataList);
            // console.log('Page Limit: ', pageLimit, 'Current Page: ', currentPage, 'Page Index Limits: ', lower, upper);

            // console.log('Iterate children using function: ', buildChildren)
            children = dataList?.map(
                (data: any, index: number) => {
                    if (index < lower || index >= upper) {
                        return undefined;
                    }
                    let childrenConfig = config.children?.map(
                        (childConfig: any) => {
                            childConfig = { ...childConfig }; // create a copy;

                            if (!childConfig.inheritedProps) {
                                childConfig.inheritedProps = {};
                            }

                            childConfig.inheritedProps[propName] = data;
                            childConfig.inheritedProps[pNameIndex] = index;
                            childConfig.inheritedProps[pNameList] = dataList;
                            return childConfig
                        }
                    );

                    // console.log('Iterating Children: ', childrenConfig);

                    return (
                        <div
                            key={Common.getUniqueKey('Iterator_')}
                            style={{ ...this.getContentStyles() }}
                            className={` ${this.getStyleClasses()}`}
                        >
                            {buildChildren(childrenConfig, undefined, mode)}
                        </div>
                    );
                }
            );
        }
        return (
            <>
                {children}
            </>
        );
    }

    buildPageNav(position?: string) {
        const dataList = this.getInputs()?.dataList || [];
        const { lower, upper, pageLimit, currentPage, pageCount, totalCount } = this.getLimits(dataList);
        const result = dataList?.filter((data: any, index: number) => {
            return (lower <= index && upper > index);
        });
        // console.log('Build Nav: ', lower, upper, pageLimit, currentPage, pageCount, totalCount, this.state);

        return (
            <PageNavigation
                key={Common.getUniqueKey('page_nav_')}
                position={position}
                noPageNav={!this.showPageNav()}
                pageNavPosition={this.state.pageNavPosition}
                currentPageNo={this.getCurrentPage()}
                pageLimit={this.state.pageLimit}
                enableExport={this.state.enableExport}

                filters={undefined}
                totalCount={dataList?.length}
                result={result}

                doReload={async (currentPageNo: number, limit: number) => {
                    this.reRender({ pageLimit: limit, currentPage: currentPageNo });
                }}
            />
        );
    }

    buildListRange() {
        const { totalCount, pageLimit, currentPage, lower, upper } = this.getLimits();
        if (totalCount >= 0) {
            let from = 1 + (currentPage - 1) * pageLimit;
            if (from > totalCount) {
                from = totalCount;
            }

            const view = (
                <span key={Common.getUniqueKey()}>
                    {`${lower + 1}-${upper} of ${totalCount}`}
                </span>
            );
            return view;
        } else {
            return undefined;
        }
    }

    gotoPage = async (index: number, refresh: boolean = true) => {
        const { pageCount, currentPage } = this.getLimits();
        if (index < 1) {
            index = 1;
        } else if (index > pageCount) {
            index = pageCount;
        }

        if (currentPage !== index) {
            this.reRender({ currentPage: index });
        }
    }

    getInputs() {
        const config = this.props.compConfig;
        const buildChildren = this.props.fnBuildChildren;

        const mode = DesignerConstants.Mode.LIVE;
        const props = { ...UnoComponentManager.getProps(config, mode), ...this.state };

        let dataList = props.dataList;
        if (Common.checkType.String(dataList)) {
            dataList = UnoComponentManager.inflate(dataList, [props], mode);
            // console.log('Data List: ', dataList, props.dataList, props);
        }
        dataList = Common.safeParse(dataList);

        const inputs = { config, buildChildren, mode, props, dataList };
        // console.log('Iterator Inputs: ', inputs);
        return inputs;
    }

    getLimits(dataList: Array<any> = this.getInputs()?.dataList) {
        const totalCount = dataList?.length || 0;
        const pageLimit = Number.parseInt(this.state.pageLimit || totalCount);

        let pageCount = Math.floor(totalCount / pageLimit);
        if ((totalCount % pageLimit) > 0) {
            pageCount++;
        }

        const currentPage = this.getCurrentPage();
        const lower = (currentPage - 1) * pageLimit;

        let upper = lower + pageLimit;
        if (upper > totalCount) {
            upper = totalCount;
        }

        const limits = { totalCount, pageLimit, currentPage, pageCount, lower, upper };
        // console.log('Limits: ', limits);
        return limits;
    }

    getCurrentPage() {
        return Number.parseInt(this.state.currentPage || 1);
    }

    updateList = (dataList: Array<any>) => {
        this.reRender({ dataList: dataList });
    }

    showPageNav(position?: string) {
        const { totalCount } = this.getLimits()
        if (totalCount <= 0) {
            return false;
        } else {
            return new Boolean(this.state.showPageNav || false).valueOf();
        }
    }
}
