import React from 'react';
import { PropMultipleViewer } from '../../@uno-entity-prop/components/viewers/prop-multiple-viewer.comp';
import { UC, UnoComponent } from "../../@uno/core";
import { BaseEntity, Common, EntityConstants, Router, } from "../../@uno/api";
import { SessionManager } from '../../@uno-app/service/session.service';

// Workbooks - VIEW
@UnoComponent({
    id: 'WorkbooksView',
    label: 'View Workbooks',
    props: [],
    getDesign: () => { return (<div>View Workbooks - Design</div>); },
    getPreview: () => { return (<div>View Workbooks - Preview</div>); }
})
export class WorkbooksView extends PropMultipleViewer {

    books: Array<any> = [];
    workbookTabs: Array<any> = [];

    constructor(props: any) {
        super(props);

        this.books = this.getPropValue();
        if (!this.books) {
            this.books = [];
        }

        this.workbookTabs = [{ name: 'Home', active: true, comp: this.getHomeTabComp() }];
    }

    render() {
        const navs = this.getWorkbooksAsNavs();

        return <>
            <UC.VSection cols='100px, 90%'>
                <UC.Navigation navs={navs} key={Common.getUniqueKey()} />
                <UC.TabbedPane tabs={this.workbookTabs} />
            </UC.VSection>
        </>
    }

    getHomeTabComp = () => {
        const homeActions: Array<any> = [
            {
                id: 'Add WB', label: 'Add Workbook', action: () => {
                    const wbName = prompt('Workbook Name');
                    if (wbName) {
                        const newWB = EntityConstants.buildEmpty(this.getEProp().category);
                        newWB.setName(wbName);
                        newWB.save(this.getAppID(), SessionManager.activeSession)
                            .then(
                                (wb: BaseEntity) => {
                                    this.handleBookAdded(wb);
                                }
                            );
                    }
                }
            }
        ];

        return (
            <div>
                <h2>Home</h2>
                <UC.Navigation navs={homeActions} />
            </div>
        );
    }

    getWorkbooksAsNavs = () => {
        return this.books?.map((v: BaseEntity) => {
            v = EntityConstants.build(v);
            return {
                id: v.getID(),
                label: v.getName(),
                action: this.viewWorkbook,
            };
        });
    }

    viewWorkbook = (node: any) => {
        let existing = false;
        this.workbookTabs.forEach(t => {
            t.active = false;
            if (t.id === node.id) {
                t.active = true;
                existing = true;
            }
        });
        if (!existing) {
            this.workbookTabs.push({
                name: node.label,
                id: node.id,
                active: true,
                comp: (
                    <>
                        <UC.EntityView
                            key={node.id}
                            appID={this.getAppID()}
                            categoryID={this.getEProp().category}
                            entityID={node.id}
                            otherProps={{ canEdit: false }}
                        />
                    </>
                ),
            });
        }
        this.setState({});
    }

    handleBookAdded = (wb: BaseEntity) => {
        console.log('WB is Added - ', wb.getID());
        this.addSheetToBook(wb);
        this.attachBookToWorkspace(wb);
    }

    attachBookToWorkspace = (wb: BaseEntity) => {
        // alert(`Attach Book [${wb.toString()}] to Workspace`);
        let entity = this.getEntity();
        if (entity) {
            this.books = [...this.books, EntityConstants.buildTruncatedEntity(wb)];
            entity.setValue(this.getEPropID(), this.books);
            console.log('Attaching...');
            entity.save(entity.getAppID(), SessionManager.activeSession).then(
                () => {
                    // alert(`Attached. Refreshing.`);
                    this.setState({});
                }
            );
        }
    }

    addSheetToBook = (wb: BaseEntity)=>{
        // defer this activity to book view/edit action.
    }
}