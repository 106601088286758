import React from 'react';
import { Common, EntityConstants, Images } from "../../api";
import { UC } from "../../core";
import { UnoCoreBaseComp } from "./uno-core.base.comp";
import { TriggerService } from '../../../@uno-entity/service/entity-trigger.service';

export const NavPosition = {
    Top: { id: 'top', label: 'Above the List' },
    Bottom: { id: 'bottom', label: 'Below the List' },
}

export const NavType = {
    Numbered: { id: 'numbered', label: 'Numbered', },
    Caraousel: { id: 'caraousel', label: 'Caraousel', },
    Scrollable: { id: 'scroll', label: 'Scrollable', },
}

export const PageLimitOptions = [
    { id: '5', label: '05 - Five', },
    { id: '10', label: '10 - Ten', },
    { id: '20', label: '20 - Twenty', },
    { id: '50', label: '50 - Fifty', },
    { id: '100', label: '100 - One Hundred', },
    { id: `${Number.MAX_SAFE_INTEGER}`, label: 'No Limit', },
];

export class PageNavigation extends UnoCoreBaseComp {

    navPositionDefault: any = NavPosition.Bottom.id;

    NavTo = {
        previous: () => { this.gotoPage(this.getCurrentPageNo() - 1); },
        next: () => { this.gotoPage(this.getCurrentPageNo() + 1); },
        first: () => { this.gotoPage(1); },
        last: () => { this.gotoPage(999999999); },
    }

    buildComp() {
        return this.buildPageNav();
    }

    buildPageNav(position: string = this.state.position) {
        const navPosition = this.state.pageNavPosition || this.navPositionDefault;

        const navType = this.getNavType();

        if (!this.showPageNav(position)) {
            // console.log('No Page Navigation: ', this.state);
            return undefined;
        } else if (navType !== NavType.Scrollable.id && navPosition !== position) {
            return undefined;
        }

        let navView = undefined;
        if (navType === NavType.Scrollable.id) {
            let nav: any = undefined;
            if (position === NavPosition.Top.id) {
                nav = { id: 'Previous', label: '<', icon: Images.Icon.previous, action: this.NavTo.previous, };
            } else {
                nav = { id: 'Next', label: '>', icon: Images.Icon.next, action: this.NavTo.next, };
            }

            const float = position === NavPosition.Top.id ? 'left' : 'right';
            navView = (
                <UC.Button
                    styleClasses={`image-button scroller`}
                    styles={{ float: float, }}
                    action={nav.action}
                >
                    {nav.label}
                </UC.Button>
            );
        } else if (navType === NavType.Caraousel.id) {
            const navs = [
                { id: 'First', label: 'First', icon: Images.Icon.first, action: this.NavTo.first, },
                { id: 'Previous', label: 'Previous', icon: Images.Icon.previous, action: this.NavTo.previous, },
                { id: 'Next', label: 'Next', icon: Images.Icon.next, action: this.NavTo.next, },
                { id: 'Last', label: 'Last', icon: Images.Icon.last, action: this.NavTo.last, },
            ]

            navView = (
                <div
                    className='center noprint'
                    style={{ overflow: 'hidden', padding: '10px' }}
                >
                    <UC.Navigation
                        navs={navs}
                        isToolbar={true}
                        orientation='h'
                    />
                </div>
            );

        } else {
            navView = (
                <div className='flex-container noprint' key={Common.getUniqueKey()} >
                    <div className='flex-child' style={{ flexGrow: 1, }}>
                        <div className='float-right'
                            style={{
                                borderTop: '1px dotted', borderBottom: '1px dotted',
                            }}
                        >
                            {this.buildListRange()}
                            {this.buildPageNavigation()}
                            {this.buildPageLimitOptions()}
                            {this.buildExport()}
                        </div>
                    </div>
                </div>
            );
        }
        return navView;
    }

    buildListRange() {
        const result = this.state.result;
        const count = this.getTotalCount();
        if (count >= 0) {
            let from = 1 + (this.getCurrentPageNo() - 1) * this.getPageLimit();
            if (from > count) {
                from = count;
            }
            let to = from + (result ? result.length - 1 : 0);
            if (!result) { // no result on a page!
                from = 0; to = 0;
            }

            const view = (
                <span key={Common.getUniqueKey()}>
                    {`${from}-${to} of ${count}`}
                </span>
            );
            return view;
        } else {
            return undefined;
        }
    }

    buildPageNavigation() {
        const isToolbar = true;
        let targetPageNo = -1;
        let noOfPages = this.getNoOfPages();

        const navOpts: Array<any> = [
            // { id: 'First', icon: Images.Icon.first, action: this.NavTo.first,},
            { id: 'Go to Previous Page', icon: Images.Icon.previous, action: this.NavTo.previous, },
            { id: 'Go to Next Page', icon: Images.Icon.next, action: this.NavTo.next, },
            // { id: 'Last', icon: Images.Icon.last, action: this.NavTo.last,},
        ];

        const buildGotoPage = () => {
            return (
                <span key={Common.getUniqueKey()}>
                    <span>
                        Page &nbsp;
                        <input
                            defaultValue={this.getCurrentPageNo()}
                            type='number'
                            onChange={
                                (e) => {
                                    targetPageNo = Number.parseInt(e.target.value);
                                    // this.profiler.log('Current Page changed to : ', false, targetPageNo);
                                }}
                            style={{ width: '60px' }}
                        />
                        {(noOfPages > 0) ? ` of ${noOfPages} ` : ''}
                    </span>
                    <UC.Button action={
                        (inputs: any) => {
                            if (Common.checkType.Number(targetPageNo)) {
                                this.gotoPage(targetPageNo);
                            }
                        }
                    } label='GO' />
                </span>
            );
        }

        return (
            <>
                <UC.Navigation
                    key={Common.getUniqueKey()}
                    navs={navOpts}
                    orientation='h'
                    isToolbar={isToolbar}
                />
                {buildGotoPage()}
            </>
        );

    }

    buildPageLimitOptions() {
        const options: Array<any> = PageLimitOptions.map((opt) => {
            const optWithAction: any = { ...opt };
            optWithAction.action = this.setPageLimit;
            return optWithAction;
        });

        options.forEach(opt => {
            if (Number.parseInt(opt.id) === this.getPageLimit()) {
                opt.isSelected = true;
            }
        })
        const label = 'per page';
        return (
            <>  {' Show '}
                <UC.SelectBox
                    // key={Common.getUniqueKey()}
                    options={options}
                    label={label}
                />
                {label}
            </>
        );
    }


    buildExport = () => {
        const doExport = async (type: string = 'json') => {
            const name = window.prompt(`Enter the Name of the ${type} File`, Common.getUniqueKey('Filter Result - '));
            if (!name) {
                return;
            }
            const dlg = { content: `Export as ${type}`, id: 'export_filter_result', };
            Common.showDialog(dlg);
            const result = await TriggerService.send(
                {
                    type: type,
                    filters: this.state.filters,
                    result: this.state.result,
                    props: this.state.visibleProps,
                    name: name || Common.getUniqueKey('Filter Result - '),
                },
                Common.Event.FILTER_RESULT_EXPORT_REQUESTED,
                true
            );

            Common.hideDialog(undefined, dlg.id);
            console.log('Export Filter Result: ', result);

            if (result?.error) {
                Common.showError(result.error);
            } else if (result?.status) {
                Common.showMessage(Common.stringify(result.status));
            }
        }

        const navs: Array<any> = [];

        if (this.state.enableExport) {
            navs.push(
                {
                    id: 'export',
                    label: 'Export',
                    icon: Images.Icon.export,
                    children: [
                        {
                            id: 'xls',
                            label: 'XLS',
                            action: async () => {
                                return await doExport('xls');
                            }
                        },
                        {
                            id: 'csv',
                            label: 'CSV',
                            action: async () => {
                                return await doExport('csv');
                            }
                        }, {
                            id: 'json',
                            label: 'JSON',
                            action: async () => {
                                return await doExport('json');
                            }
                        },
                    ],
                }
            );
        }

        return (<UC.Navigation orientation='h' navs={navs} />);
    }

    setPageLimit = (opt: any, showMessage: boolean = true) => {
        const limit = Number.parseInt(opt.id);
        if (Common.checkType.Number(limit)) {
            if (showMessage) {
                Common.showMessage(`Changing page limit to ${opt.label}`, true, 5, true);
            }

            this.reRender({ pageLimit: limit }, this.loadResults);
            // this.pageLimit = limit;
            // this.loadResults();
        }
    }

    getNavType() {
        return Common.safeParse(this.state.navType);
    }

    showPageNav(position?: string) {
        if (this.state.noResult) {
            return false;
        } else if (new Boolean(this.state.noPageNav).valueOf()) {
            return false;
        } else if (this.getTotalCount() <= 0 || !this.state.result) {
            return false;
        } else {
            return true;
        }
    }

    getTotalCount = () => {
        return (this.state.totalCount !== undefined) ? this.state.totalCount : -1;
    }


    gotoPage = async (index: number, refresh: boolean = true) => {
        const noOfPages = this.getNoOfPages();
        if (index < 1) {
            index = 1;
        } else if (index > noOfPages) {
            index = noOfPages;
        }

        if (this.getCurrentPageNo() !== index) {
            this.setCurrentPageNo(index, async () => {
                Common.showMessage(`Load Page # ${this.getCurrentPageNo()} `, true, 5, false);
                const id = Common.getUniqueKey('page_');
                // Common.showDialog({ content: `Loading Page # ${this.currentPageNo} `, id: id });
                await this.loadResults();
                // Common.hideDialog(undefined, id);
            });
        }
    }

    loadResults = async () => {
        const doReload = this.state.doReload;
        if (doReload && Common.checkType.Function(doReload)) {
            await doReload(this.getCurrentPageNo(), this.getPageLimit());
        }
    }

    getNoOfPages() {
        let noOfPages = 1;
        const tc = this.getTotalCount();
        const pl = this.getPageLimit();
        if (tc > 0) {
            noOfPages = Math.floor(tc / pl);
            if (tc % pl > 0) { // not a perfect division
                noOfPages++;
            }
        }
        return noOfPages;
    }

    getCurrentPageNo() {
        return this.state.currentPageNo || 1;
    }

    setCurrentPageNo(index: number, clbk?: any) {
        this.reRender({ currentPageNo: index }, clbk);
    }

    getPageLimit() {
        const limit = Common.safeParse(this.state.pageLimit) || EntityConstants.Limit.EntitiesPerPage;
        return Number.parseInt('' + limit);
    }

}