import React from 'react';
import { DesignerConstants, UC, UnoComponent, Common, EM, Images } from '../../@uno/core';
import { AppInfo, AppInfoService } from '../service/app.info.service';
import { SessionManager } from '../service/session.service';
import { UnoAppBaseComp } from './uno-app.base.comp';

@UnoComponent({
    id: 'Profile',
    label: 'User Profile',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class Profile extends UnoAppBaseComp {
    private _EH_SignedUp: any;
    private _EH_SignedIn: any;
    private _EH_SignedOut: any;
    private _EH_SignOutRequested: any;

    // private session: any;

    private isSelfSignUpEnabled = false;
    private isSignInEnabled = false;

    componentDidMount() {
        super.componentDidMount();

        this.initComponent();

        this._EH_SignedOut = EM.register(Common.Event.USER_SESSION_CLEARED, (data: any) => {
            this.initComponent();
            this.setRedirectTo(this.getSinginRoute());
        });

        this._EH_SignedIn = EM.register(Common.Event.USER_SESSION_CREATED, (data: any) => {
            const queryParams = new URLSearchParams(window.location.search);
            const path = queryParams.get('path');
            if (path) {
                this.setRedirectTo(path);
            } else if (window.location.pathname === this.getSinginRoute()) { // On sign in page
                this.setRedirectTo(SessionManager.getHomeRoute(this.getAppID()));
            }
        });

        this._EH_SignOutRequested = EM.register(Common.Event.SIGN_OUT_REQUESTED, () => {
            Common.showDialog({
                comp: (
                    <>
                        <p>Do you really want to sign out?</p>
                    </>
                ),
                footer: (
                    <UC.Navigation
                        navs={[
                            {
                                id: 'Yes',
                                action: () => {
                                    EM.emit(Common.Event.SIGNED_OUT, { appID: this.getAppID(), sesssion: SessionManager.getSession(this.getAppID()) });
                                    Common.hideDialog();
                                },
                                icon: Images.Icon.yes,
                            },
                            {
                                id: 'No',
                                action: () => {
                                    Common.hideDialog();
                                },
                                icon: Images.Icon.no,
                            }
                        ]}
                        orientation='h'
                        isToolbar={false}
                        styles={{ float: 'right' }}
                    />
                ),
                onClose: () => { },
                title: 'Sign Out',
            });
            /*
            if (window.confirm(`Sure to Sign out?`)) {
                EM.emit(AppConstant.Event.SIGNED_OUT, {});
            }
            */
        });
        /*
        this._EH_SignedUp = EM.register(AppConstant.Event.SIGNED_UP, (cred: BaseEntity) => {
            this.redirectTo = SessionManager.getSignInRoute(this.getAppID());
            this.setState({});
        });
        */
    }

    private getSinginRoute(): string {
        return SessionManager.getSignInRoute(this.getAppID());
    }

    initComponent() {
        const appID = this.getAppID();
        if (appID) {

            AppInfoService.isSelfSignUpEnabled(appID).then(value => {
                this.isSelfSignUpEnabled = value;
                // console.log(appID, ` - Can Self Sign Up : `, this.isSelfSignUpEnabled);
                this.setState({});
            });

            AppInfoService.isSignInEnabled(appID).then(value => {
                this.isSignInEnabled = value;
                // console.log(appID, ` - Can Sign In : `, this.isSignInEnabled);
                this.setState({});
            });
        }
    }

    componentWillUnmount() {
        EM.unregister(this._EH_SignedUp);
        EM.unregister(this._EH_SignedIn);
        EM.unregister(this._EH_SignedOut);
        EM.unregister(this._EH_SignOutRequested);
    }

    buildComp() {
        let TheNav = undefined;
        if (this.getAppID()) {
            const session = SessionManager.getSession(this.getAppID());
            if (session) {
                const profileOptions: Array<any> = this.getProfileOptions();

                const profileName = session.name ? session.name : session.id;
                const profileDetail = {
                    id: `${session.id}`,
                    label: `${profileName}`,
                    icon: Images.Icon.profile,

                    buildView: (opt: any) => {
                        return (
                            <div className='active-person' title={profileName}>
                                <img src={Images.Icon.profile} className='tool-icon' alt={profileName} />
                                <span style={{ padding: '4px' }}>{profileName}</span>
                            </div>
                        );
                    },

                    children: profileOptions,
                };

                // show profile details
                const navs: Array<any> = [];
                navs.push(profileDetail);
                TheNav = (<UC.Navigation navs={navs} key={Common.getUniqueKey()} styles={{ backgroundColor: 'inherit' }} visibleChildCount={10} />)

            } else {
                const navs: Array<any> = [];
                if (this.isSignInEnabled) {
                    navs.push({ id: 'Sign In', icon: Images.Icon.signin, to: SessionManager.getSignInRoute(this.getAppID()) });
                } else {
                    // console.log(`Sign in DISABLED.`);
                }

                if (this.isSelfSignUpEnabled) {
                    navs.push({ id: 'Sign Up', icon: Images.Icon.signup, to: SessionManager.getSignUpRoute(this.getAppID()) });
                } else {
                    // console.log(`Self-Sign Up DISABLED.`);
                }
                TheNav = (<UC.Navigation navs={navs} key={Common.getUniqueKey()} orientation='v' styles={{ /* float: 'right'*/ }} />)

            }
        }
        return (
            <>
                {this.buildRedirectTo()}
                <span>{TheNav}</span>
            </>
        );
    }

    getProfileOptions() {
        const profileOptions: Array<any> = [];
        profileOptions.push({ id: `Home`, icon: Images.Icon.home, to: SessionManager.getHomeRoute(this.getAppID()) });
        profileOptions.push({ id: `View Profile`, icon: Images.Icon.viewProfile, to: SessionManager.getViewProfileRoute(this.getAppID()) });
        if (SessionManager.getEditPasswordRoute()) {
            profileOptions.push({ id: `Change Password`, icon: Images.Icon.editPassword, to: SessionManager.getEditPasswordRoute() });
        }
        profileOptions.push({
            id: `Sign Out`,
            icon: Images.Icon.signout,
            action: () => {
                EM.emit(Common.Event.SIGN_OUT_REQUESTED, SessionManager.activeSession);
            }
        });
        return profileOptions;
    }

    handleAppSwitched = async (app: AppInfo) => {
        // SessionManager.activeSession = SessionManager.getSession(app.id);
        this.setState({ app: app });
    }


}
