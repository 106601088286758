import React from 'react';
import { Link, Redirect, } from 'react-router-dom';
import { ASYNC, BaseEntity, EntityConstants } from '../../@uno/api';
import { UC, UnoComponent, Images, Common, EM } from '../../@uno/core';
import { AppInfoService } from '../service/app.info.service';
import { AppRouterService } from '../service/app.router.service';
import { EntityCategoryService } from '../service/entity.category.service';
import { SessionManager } from '../service/session.service';
import { Profiler, Router } from '../../@uno/api/common.service';
import { AppScreenService } from '../service/app.screen.service';

@UnoComponent({
    id: 'RouteContent',
    label: 'The Path Content',
    /*
    getPreview: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Path Content - PREVIEW</div>);
    },
    getDesign: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Path Content - DESIGN</div>);
    }
    */
})
export class RouteContent extends React.Component<{ route: string, title?: string, error?: any, hostname?: string }, any> {
    compID = Common.getUniqueKey('RouteContent_');
    profiler = Profiler.init('route-content');

    processing = true;
    actionComps: any = {
        default: 'EntityBaseComp',
        main: 'EntityMain',
        create: 'EntityEdit',
        edit: 'EntityEdit',
        view: 'EntityView',
        search: 'EntitySearch',
    };

    // route attributes
    route: string = this.props.route;
    appID?: string;
    appInfo?: any;
    categoryID?: string;
    category?: any;
    action?: string;
    entityID?: string;
    entity: BaseEntity | undefined;
    component?: any;
    layout?: any;
    error?: any;
    permitted = true;
    redirectTo: any;

    componentDidMount() {
        this.processRoute(this.getRoute(), this.props.hostname);
    }

    componentWillUnmount() {
        this.processing = false;
    }

    render() {
        if (this.redirectTo) {
            this.profiler.log('Redirect RouteContent To :', this.redirectTo);
            return this.buildRedirect();
        } else if (this.processing) {
            // show loading...
            return (<UC.Loading />);
        } else {
            // EM.emit(Common.Event.ROUTE_CHANGED, this.getRoute());
        }

        if (!this.permitted) {
            const path = this.getRoute();
            const needLogin = (!SessionManager.activeSession);
            const appID: any = this.getAppID();
            return (
                <>
                    <h1>{this.error ? this.error : 'Permission Denied!'}</h1>
                    <p>Path: {this.getRoute()}</p>
                    <br />
                    <Link to={`/${appID}`}>Go Home</Link>
                    {needLogin ? <> Or <Link to={`${SessionManager.getSignInRoute(appID)}?path=${path}`}> Sign In</Link></> : <UC.Empty />}
                </>
            );
        } else {
            // loading complete.
            return this.buildContent();
        }
    }

    buildRedirect() {
        if (this.redirectTo) {
            this.profiler.log(this.compID, 'Building Redirect to:', this.redirectTo);
            const redirectView = <Redirect to={this.redirectTo} push={true} />
            this.redirectTo = undefined;
            return redirectView;
        } else {
            return null;
        }
    }

    routeToDefaultApp() {
        const defApp = process.env.REACT_APP_DEFAULT_APP;
        if (defApp) {
            const defAppURL = `/${defApp}`;
            this.redirectTo = defAppURL;
            this.profiler.log(this.compID, 'Redirect to Default App: ', this.redirectTo);
            this.setState({});
        }
    }
    // render the page layout
    protected buildContent() {
        return (
            <>
                {this.buildCategoryContent()}
            </>
        );
    }

    getRoute(): string {
        if (!this.route) {
            this.route = this.props.route;
        }
        return this.route;
    }

    private processRoute(newRoute: string, hostname?: string) {
        ASYNC.series(
            [
                (clbk: Function) => { // Init Processing
                    this.initProcessing();
                    clbk(null, {});
                },
                (clbk: Function) => { // process path params.
                    AppRouterService.process(newRoute, hostname).then(
                        async (res) => {

                            this.route = res.route;
                            this.permitted = res.permitted;
                            this.error = res.error;

                            this.appID = res.appID;
                            this.redirectTo = res.redirectTo;

                            if (this.redirectTo) {
                                this.profiler.log(this.compID, this.appID, this.route, 'Redirect to: ', this.redirectTo, this.error,);
                                this.setState({});
                                return;
                            }


                            this.categoryID = res.categoryID;
                            this.action = res.action;
                            this.entityID = res.entityID;
                            if (this.entityID && this.categoryID && this.appID) {
                                this.entity = EntityConstants.buildEmpty(this.categoryID);
                                this.entity.setID(this.entityID);
                                this.entity.setAppID(this.appID);
                            }

                            this.appInfo = res.appInfo;
                            if (this.appInfo) {
                                this.appID = this.appInfo.id;
                                // Re-Load the active App.
                                AppInfoService.loadApp(this.appInfo.id);
                                if (!this.appInfo.logo) {
                                    this.appInfo.logo = Images.Icon.unoLogo;
                                }
                            }

                            this.category = res.category;
                            if (this.category) {
                                this.categoryID = this.category.id;
                                EntityCategoryService.addAppCategory(this.category, this.appID);
                            } else if (this.categoryID && this.appID) {
                                this.category = await EntityCategoryService.getCategory(this.categoryID, true, this.appID);
                            }

                            this.component = res.component;
                            this.layout = res.layout || await AppScreenService.findLayout(undefined, this.appID || '', this.categoryID, this.action || Router.CatAction.MAIN, this.entity);

                            if (!this.action) {
                                // this.profiler.log('No Action Route Result: ', res);
                            }

                            clbk(null, {});
                        }
                    );

                },
                (clbk: Function) => { // Finish Processing
                    this.finishProcessing();
                    clbk(null, {});
                },
            ],
            (err: any, res: any) => {
                let status = 'COMPLETED';
                if (err) {
                    status = 'ERRORED';
                }
                // this.profiler.log(`Route Processing ${status} for path - ${newPath}`);
            }
        )
    }

    private initProcessing() {
        this.processing = true;

        this.appID = undefined;
        this.appInfo = undefined;

        this.categoryID = undefined;
        this.category = undefined;

        this.entityID = undefined;
        this.action = undefined;

        this.component = undefined;
        this.error = undefined;
    }

    private finishProcessing() {
        if (this.processing) {
            this.processing = false;
            if (this.getAppID()) {
                this.setState({});
            } else {
                this.routeToDefaultApp();
            }
            EM.emit(Common.Event.ROUTE_CHANGED, this.getRoute());
        }
    }

    private getError() {
        return this.props.error;
    }

    private getAppInfo() {
        return this.appInfo;
    }

    private getStateError() {
        return this.error;
    }

    private getContentComponent() {
        return this.component;
    }

    private getContentLayout() {
        return this.layout;
    }

    private getEntityID() {
        return this.entityID;
    }

    private getEntityAction() {
        return this.action;
    }

    private getCategory() {
        return this.category;
    }

    private getAppID() {
        return this.appID;
    }

    buildCategoryContent() {
        const appID = this.getAppID();
        const categoryID = this.categoryID;
        const entityID = this.getEntityID();
        const action = this.getEntityAction();
        const componentID = this.getContentComponent();
        const layout = this.getContentLayout();
        const eCategory = this.getCategory();

        // this.profiler.log('The Content Component for route: ', this.getRoute(), appID, categoryID, entityID, action, componentID,);

        let Content = UC.Empty;
        if (componentID) {
            // this.profiler.log('The Content Component ID: ', componentID, this.getRoute());
            Content = UC[componentID];
        } else {
            Content = this.getComponentByAction(action);
        }

        const EXTRAs = (
            <>
                <div><b>App ID:</b> {appID}</div>
                <div><b>Category:</b> {JSON.stringify(eCategory)}</div>
                <div><b>Action:</b> {action}</div>
                <div><b>Layout:</b> {JSON.stringify(layout)}</div>
                <div><b>Access Permitted:</b> {JSON.stringify(this.state.permitted)}</div>
            </>
        );
        return (
            <>
                <Content
                    app={this.getAppInfo()}
                    appID={appID}
                    category={eCategory}
                    categoryID={categoryID}
                    entityID={entityID}
                    action={action}
                    layout={layout}
                // key={Common.getUniqueKey()}
                />
                {/* EXTRAs */}
            </>
        );
    }

    getComponentByAction(action: any) {

        let Component: any = undefined;
        const aComps: any = this.actionComps;
        if (aComps) {
            let compID = aComps[action ? action : 'main'];
            if (!compID) {
                compID = aComps.default;
            }
            if (compID) {
                Component = UC[compID];
            }
        }
        return Component ? Component : UC.Empty;
    }
}