import { UnoModule } from '../../@uno/core';
import { DicomViewerComp } from './components/dicom-viewer.comp';
// The Module...

@UnoModule(
    {
        id: 'DicomViewerModule',
        imports: [
        ],
        components: [
            DicomViewerComp,
        ],
    }
)
export default class DicomViewerModule {

}

