import { RemoteService } from '../../@uno/api';
import { Router } from '../../@uno/api/common.service';
import { SessionManager } from './session.service';


class AppRouterServiceImpl {

    process(route: string, hostname?: string): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                // try to find active session
                const appID = Router.parseParams(route)[Router.ParamType.APPLICATION];
                let session = SessionManager.activeSession;
                if (!session && appID) {
                    // console.log(`${route} - find session for appID ${appID}`);
                    session = SessionManager.getSession(appID);
                }

                // console.log(`Processing route :`, route, hostname, session);
                RemoteService.post('router/process', { route: route, session: session, hostname: hostname, appID: appID }).then(
                    res => {
                        res.json().then((routeProps: any) => {
                            resolve(routeProps);
                        });
                    }
                );
            }
        );
    }
}

export const AppRouterService = new AppRouterServiceImpl();