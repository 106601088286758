import { TriggerService } from '../../@uno-entity/service/entity-trigger.service';
import { EM, } from '../../@uno/api/event-mgmt.service';
import { Common, Router } from '../../@uno/api/common.service';

class SessionManagerImpl {
    private db = (Common.Screen.isSmall() || Common.Screen.isMobile()) ? window.localStorage : window.sessionStorage;
    private collection = 'sessions';

    constructor() {
        if (!this.getSessions()) {
            this.persist({});
        }

        EM.register(Common.Event.SIGNED_OUT, (data: any) => {
            SessionManager.clearSession(data.appID);
        });

        EM.register(Common.Event.SIGNED_IN, (data: any) => {
            SessionManager.setSession(data.appID, data.session);
        });

        EM.register(Common.Event.APP_SWITCHED, (data: any) => {
            // console.log('Session Manager: App Switched: ', data);
            // SessionManager.setSession(data.id, undefined, true);
            SessionManager.setSession(data.id, this.getSession(data.id), true);
        });

    }

    private aSession: any;
    private set activeSession(session: any) {
        this.aSession = session;
    }
    public get activeSession() {
        return this.aSession;
    }

    getSessions() {
        const data = this.db.getItem(this.collection);
        if (data) {
            return Common.safeParse(data);
        }
        return {};
    }

    async setSession(appID: string, session: any, updateOnly = false) {
        // this.activeSession = session;
        // trigger event.
        if (!updateOnly) {
            if (session) {
                // Common.showMessage('Ready to take off. Fasten your seat belt.', true, 100);
                const result = await TriggerService.send({ appID: appID, session: session, }, Common.Event.SIGNED_IN, false);
                if (result?.message) {
                    Common.showMessage(result.message);
                }
                session = result.session;
            } else {
                // Common.showMessage('Collecting the garbage...', true, 100);
                const result = await TriggerService.send({ appID: appID, session: this.activeSession, }, Common.Event.SIGNED_OUT, false);
                if (result?.message) {
                    Common.showMessage(result.message);
                }
            }
        }
        // update the session in local cache.
        const sessions = this.getSessions();
        sessions[appID] = session;
        this.persist(sessions);
        this.activeSession = session;

        // trigger user session event
        if (!updateOnly) {
            if (this.activeSession) {
                // Common.showMessage('Zooming up.');
                EM.emit(Common.Event.USER_SESSION_CREATED, { appID: appID, session: this.activeSession });
            } else {
                // Common.showMessage('Just Flushing');
                EM.emit(Common.Event.USER_SESSION_CLEARED, { appID: appID });
            }
        }

    }

    async updateSession(appID: string, session: any) {
        // update the session in local cache.
        const sessions = this.getSessions();
        sessions[appID] = session;
        this.persist(sessions);
        this.activeSession = session;
    }

    persist(sessions: any) {
        const data: any = Common.stringify(sessions);
        this.db.setItem(this.collection, data);
    }

    getSession(appID: string) {
        return this.getSessions()[appID];
    }

    clearSession(appID: string) {
        this.setSession(appID, undefined);
    }

    getRoute = (appID: string, path: string,) => {
        if (appID) {
            return `/${appID}/${path}`;
        } else {
            return '';
        }
    }

    getSignInRoute = (appID: string) => {
        return this.getRoute(appID, 'do/signin');
    }

    getSignUpRoute = (appID: string) => {
        return this.getRoute(appID, 'do/signup');
    }

    getHomeRoute = (appID: string) => {
        return this.getRoute(appID, '');
    }

    getViewProfileRoute = (appID: string) => {
        return Router.buildRoute(appID, Common.CategoryID.Person, Router.CatAction.VIEW, SessionManager.activeSession?.person?._id);
    }

    getEditPasswordRoute = () => {
        return this.activeSession?.edit;
    }
}

export const SessionManager = new SessionManagerImpl();
