import { App, AppOptions, ToolConfig, ViewConfig, } from 'dwv';
import React from 'react';
import { TriggerService } from '../../../@uno-entity/service/entity-trigger.service';
import { EntityConstants } from '../../../@uno/api';
import { Common, DesignerConstants, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';

@UnoComponent(
    {
        id: 'DicomViewer',
        label: 'Dicom Viewer',
        paletteable: true,
        props: [
            { groupID: 'Source', id: 'URLs', label: 'URLs', multiplicity: -1, },
            { groupID: 'Source', id: 'files', label: 'Files', dataType: EntityConstants.PropType.FILE, multiplicity: -1, },
            { groupID: 'Config', id: 'zoom', label: 'Support Zoom and Pan', dataType: EntityConstants.PropType.BOOLEAN, },
        ],
        group: DesignerConstants.PaletteGroup.Integration.id,
    }
)
export class DicomViewerComp extends UnoCoreBaseComp {
    private divID = Common.getUniqueKey('dwv_');

    buildComp() {
        return (
            <>
                <div
                    id={this.divID}
                    style={this.getStyles()}
                    className={this.getStyleClasses()}
                >

                </div>
            </>
        );
    }

    componentDidMount(): void {
        super.componentDidMount();

        this.loadView();
    }

    loadView = async () => {
        // create the dwv app
        try {
            const app = new App();
            // initialise
            const viewConfig0 = new ViewConfig(this.divID);
            const viewConfigs = { '*': [viewConfig0] };
            const options = new AppOptions(viewConfigs);
            options.tools = { ZoomAndPan: new ToolConfig() };
            app.init(options);
            // activate tool
            app.addEventListener('load', async () => {
                if (this.state.zoom) {
                    app.setTool('ZoomAndPan');
                }
                // app.render(0);
                // console.log('DWV App: ', app);
            });

            // load dicom data
            const urls = [...this.state.URLs];
            const files = [...this.state.files];
            /*
            for (let f of files) {
                console.log('Dicom File: ', f);

                f = await EntityConstants.build(f).reload();
                if (f?.downlink) {
                    const link = RemoteService.getFullURL(f.downlink);
                    urls.push(link);
                }
            }
            */

            if (urls?.length > 0) {
                app.loadURLs(urls);
                console.log('Load Dicom URLS: ', urls);
            } else if (files?.length > 0) {
                const images: Array<any> = [];
                for (let f of files) {
                    console.log('Dicom File: ', f);

                    /*
                    f = await EntityConstants.build(f).reload();
                    if (f?.downlink) {
                        const link = RemoteService.getFullURL(f.downlink);
                        const data = await RemoteService.getData(await RemoteService.get(link));
                        console.log('Dicom File Data: ', link, data);
                        urls.push(link);
                    }
                    */

                    const res = await TriggerService.send({ event: 'DOWNLOAD_FILE_CONTENT', file: f });
                    console.log('Dicom download: ', res);
                    const content = res?.content?.data;
                    if (content) {
                        console.log('Dowloaded File Content: ', f, content);
                        images.push(content);
                    }
                }
                app.loadImageObject(images);
                //app.lo(urls);
                // console.log('Load Dicom URLS: ', urls);
            }

            // 'https://raw.githubusercontent.com/ivmartel/dwv/master/tests/data/bbmri-53323851.dcm'

        } catch (e) {
            console.log('Error in DICOM Web Viewer: ', e);
        }

    }
}