import React from 'react';
import { EntityCategoryService } from '../../../@uno-app/service/entity.category.service';
import { EntityFilterService } from '../../../@uno-filter/service/entity-filter.service';
import { EntityCategory, EntityConstants, EntityProp } from '../../../@uno/api/entity.service';
import { Condition, ConditionGroup, FilterConstants } from '../../../@uno/api/filter.service';
import { DesignerConstants, UC, UnoComponent, Common, Images, UnoCoreBaseComp, EM } from '../../../@uno/core';
import { BasePropEditor } from '../prop-base.comp';

const isArrayProp = (eProp: EntityProp) => {
    return (eProp && eProp.multiplicity && eProp.multiplicity !== 1);
}

const isTextProp = (eProp: EntityProp) => {
    if (eProp) {
        switch (eProp.dataType) {
            case undefined:
            case EntityConstants.PropType.DEFAULT:

            case EntityConstants.PropType.STRING:
            case EntityConstants.PropType.HTML:
            case EntityConstants.PropType.MULTILINE:

            case EntityConstants.PropType.EMAIL:
            case EntityConstants.PropType.ENCRYPT:
            case EntityConstants.PropType.PASSWORD:

            case EntityConstants.PropType.FUNCTION:
            case EntityConstants.PropType.STANDARD_FUNCTION:
            case EntityConstants.PropType.JSON:
            case EntityConstants.PropType.LAYOUT:

                return true;
        }
    }
    return false;
}


const getKeywordIcon = (keyword: string) => {
    switch (keyword) {
        case FilterConstants.Keyword.AND.id:
            return Images.Icon.AND;
        case FilterConstants.Keyword.OR.id:
            return Images.Icon.OR;
        case FilterConstants.Keyword.NOR.id:
            return Images.Icon.NOR;
    }
}

@UnoComponent({
    id: 'FilterEditor',
    label: 'Entity Filter Editor',
    paletteable: true,
    props: [
        { id: 'defaultValue', label: 'Conditions', dataType: EntityConstants.PropType.JSON, },
    ],
    group: DesignerConstants.PaletteGroup.Entity.id,
})
export class FilterEditor extends BasePropEditor {
    private doToggleEvent = Common.getUniqueKey('filter_toggler_');
    protected conditions: Array<Condition | ConditionGroup> = [];
    protected orderByProps: Array<{ prop: string, order?: number }> = [];
    protected propsToView: Array<string> = [];

    constructor(props: any) {
        super(props);
        this.state = { ...this.state, showFilters: true }
        // console.log('FilterCondition Instance Created: ', this.state);
    }

    componentDidMount() {
        super.componentDidMount();
        this.initEditor();
    }

    buildComp() {
        if (!this.getCategory()) {
            return this.buildCategorySelector();
        } else {
            return (
                <div className='entity-view'>
                    {this.buildTitle()}
                    {this.buildFilterEditor()}
                </div>
            );
        }
    }

    buildTitle() {
        const oProps = this.getOtherProps();
        if (oProps?.hideTitle) {
            return undefined;
        } else if (oProps?.buildTitle) {
            return oProps.buildTitle(this.getEProp());
        } else { // default
            return undefined;
            /*
            return (
                <h4>
                    Find <i>{this.getCategory()?.label}</i>
                </h4>
            );
            */
        }
    }

    buildCategorySelector() {
        return (
            <UC.CategorySelector
                key={Common.getUniqueKey()}
                appID={this.getAppID()}
                entityProp={{ id: 'Select Category' }}
                otherProps={{
                    onPropChanged: (prop: EntityProp, catID: any) => {
                        this.loadCategoryDef(catID).then(category => {
                            // console.log(`Filter Category Selected: `, category);
                            this.initEditor(catID);
                        });
                    }
                }} />
        );
    }

    buildFilterEditor() {
        const ClearAll = (
            <UC.Button
                action={
                    () => {
                        // this.initEditor();
                        this.conditions = [];
                        this.setPropValue(this.conditions);
                        this.reRender({ category: undefined, conditions: undefined, });
                        alert('Filter is Reset');
                    }
                }>
                Clear All
            </UC.Button>
        );

        return (
            <>
                <div>
                    {/* this.buildResultToggler() */}
                    <span>
                        <b>{this.getCategory()?.label}  </b>
                        {this.getExtraParams()?.clearAll ? ClearAll : undefined}
                    </span>
                    {this.buildActionButtons()}
                    {this.buildConditionEditors()}
                    {/* this.buildOrderByEditor() */}
                    {/* this.buildPropsToViewEditor() */}
                </div >
                {this.buildResultViewer()}
            </>
        );
    }

    private buildResultToggler() {
        return <UC.Toggler
            toggleOn={this.doToggleEvent}
            title='Show/Hide Conditions'
            callback={(isHidden: boolean) => {
                isHidden ? this.showResult() : this.hideResult();
            }} />;
    }

    buildActionButtons() {
        const actions = [];
        actions.push({ id: 'Add Condition', action: () => { this.addChild() }, icon: Images.Icon.addCondition });

        const groupChoices = Object.values(FilterConstants.Keyword).map(keyword => {
            return {
                id: keyword.id,
                label: keyword.label,
                icon: getKeywordIcon(keyword.id),
                action: () => { this.addGroup(keyword.id); }
            };
        });

        actions.push({ id: 'Add Group', children: groupChoices, icon: Images.Icon.plus });
        actions.push({ id: 'Clear', action: () => { this.allClear(); }, icon: Images.Icon.clear });


        // actions.push({ id: 'Save Filter', icon: Images.Icon.save, action: () => { this.handleSaveFilter(); } });
        actions.push(
            {
                id: 'Search...', icon: Images.Icon.search, action: () => {
                    // EM.emit(this.doToggleEvent);
                    this.showResult();
                }
            }
        );
        return (
            <UC.VSection cols='1' >
                <UC.Navigation navs={actions} orientation='h' key={Common.getUniqueKey()} isToolbar={true} />
            </UC.VSection >
        )

    }

    buildConditionEditors() {
        if (!this.conditions || this.conditions.length === 0) {
            return (<div>No conditions specified!</div>);
        } else if (!Array.isArray(this.conditions)) {
            return (<div>Invalid conditions specified! <br /> ${Common.stringify(this.conditions)}</div>);
        }

        const category: any = this.getCategory() || { id: '' };
        const catProps = category.props;

        // add common props to category
        /*
        const commonProps = EntityConstants.getCommonProps();
        Object.keys(commonProps).forEach(cPropID => {
            if (!EntityConstants.getPropByID(catProps, cPropID)) {
                catProps.push(commonProps[cPropID]);
            }
        });
        */

        // build condition editor
        let conditionEditors: any = this.conditions?.map(
            (condition: any, index: number) => {
                let CondEditor: any = ConditionEditor;
                if (condition?.keyword) {
                    CondEditor = ConditionGroupEditor;
                }

                return (
                    <CondEditor
                        key={this.getUniqueKey()}
                        condition={condition}
                        onDelete={() => { this.deleteCondition(index) }}
                        onChangeCondition={() => { this.hideResult(); this.refresh(); }}
                        category={category}
                    />
                );
            }

        );

        return (
            <>
                {conditionEditors}
            </>
        );
    }

    buildOrderByEditor() {
        if (this.getOtherProps()?.canSelect || this.getOtherProps()?.onSelected) {
            return <UC.Empty />;
        }
        return (<OrderByEditor category={this.getCategory()} config={this.orderByProps} />);
    }

    buildPropsToViewEditor() {
        if (this.getOtherProps()?.canSelect || this.getOtherProps()?.onSelected) {
            return <UC.Empty />;
        }
        return (<PropsToViewEditor category={this.getCategory()} config={this.propsToView} />);
    }

    buildResultViewer() {
        const oProps = this.getOtherProps();
        oProps.canViewFull = true;
        oProps.canEdit = true;

        let resultViewer = <UC.Empty />;
        if (this.state.showResult === true && this.conditions.length >= 1) {
            // console.log('Show Results for Coditions: ', this.conditions);
            resultViewer = (
                <UC.Section title='Search Result'>
                    <UC.FilterResults
                        // title='Search Result'
                        appID={this.getAppID()}
                        conditions={this.conditions}
                        key={Common.getUniqueKey()}
                        otherProps={oProps}
                        onNoResult='No records found.'
                    />
                </UC.Section>
            );
        }
        return resultViewer;
    }

    loadCategoryDef = async (catID: any) => {
        const category = this.getCategory() || await EntityCategoryService.getCategory(catID, true, this.getAppID());
        return category;
    }

    initEditor = async (catID?: string) => {
        let val = this.getDefaultValue();
        if (val && Common.checkType.String(val)) {
            this.conditions = Common.safeParse(val);
        }
        // console.log('Initial filter conditions: ', Common.stringify(this.conditions), catID, val);

        if (!catID) {
            catID = this.getCategoryID();
        }

        if (this.conditions.length === 0 && catID) {
            this.conditions.push({
                operator: FilterConstants.Operator.EQUAL.id,
                propID: EntityConstants.Attr.CATEGORY,
                value: catID,
            });
            // add a default group to begin with.
            // this.addGroup();
            // this.setPropValue(this.conditions);
        }

        if (!catID && this.conditions.length > 0) {
            catID = FilterConstants.getCategoryID(this.conditions);
        }

        // console.log(`Filter Editor Component Mounted: `, catID, this.conditions, this.state,);

        // load category definition
        if (catID && !this.getCategory()) {
            const category = await this.loadCategoryDef(catID);
            if (category) {
                this.setState({ category: category });
            }
        } else if (this.conditions?.length > 0) {
            this.refresh();
        }
    }

    addGroup = (type = FilterConstants.Keyword.AND.id) => {
        const condition: ConditionGroup = {
            keyword: type,
        };
        this.conditions.push(condition);
        this.refresh();
    }

    addChild = (condition: Condition | ConditionGroup = {}) => {
        this.conditions?.push(condition);
        this.setState({});
    }

    allClear = () => {
        this.conditions.splice(0,);
        //this.setPropValue([]);
        this.initEditor(this.getCategoryID());
        this.hideResult();
    }

    deleteCondition(index: number) {
        this.conditions.splice(index, 1);
        this.refresh();
    }

    refresh = () => {
        this.setPropValue(this.conditions);
        this.setState({});
    }

    getTitle() {
        return '';
    }

    showResult = async () => {
        if (this.getOtherProps()?.onShowResult) {
            this.getOtherProps()?.onShowResult(this.conditions);
        } else {
            // console.log('Show Results: ', this.conditions);
            // console.log(`Criteria: `, await FilterConstants.buildCriteria(this.conditions, this.getAppID()))
            this.setState({ showResult: true });
        }
    }

    hideResult = () => {
        this.setState({ showResult: false });
    }

    handleSaveFilter = () => {
        const conditions: any = this.conditions
        // console.log('Saving Conditions: ', conditions);
        const filterName = prompt('Filter Name');
        if (filterName) {
            EntityFilterService
                .save(filterName, conditions)
                .then(res => { alert(`Filter Saved.`) });
        }
    }
}

class ConditionFieldSelector extends UnoCoreBaseComp {

    buildComp() {
        const selectedProp = this.props.eProp;
        //  <div className='input-label'>{selectedProp.label}&nbsp;</div>

        if (selectedProp) {
            return (
                <UC.PropLabel entityProp={selectedProp} />
            );
        } else {
            return (
                <UC.SelectBox
                    label='Select Property'
                    options={this.buildFilterPropOptions()}
                    onSelect={this.props.onSelect}
                />
            );
        }
    }

    buildFilterPropOptions() {
        let propsList: Array<any> = [];
        const catProps = this.props.category.props;
        const selectedProp = this.props.eProp;

        if (catProps) {
            catProps.forEach(
                (prop: EntityProp) => {
                    if (!prop.noSearch) {
                        const propOpt: any = { id: prop.id, extra: prop };
                        if (prop.label) {
                            propOpt.label = `${prop.label} - ${prop.id}`;
                        }
                        if (selectedProp && selectedProp.id === prop.id) {
                            // propOpt.isDefault = true;
                        }
                        propsList.push(propOpt);
                    }
                }
            );

            propsList.sort((a: any, b: any) => {
                return (a.label > b.label) ? 1 : -1;
            });
        }
        return propsList;
    }
}

class KeywordSelector extends UnoCoreBaseComp {

    buildComp() {
        const keywordOptions: Array<any> = [];
        let defaultKeyword = (this.props.default) ? this.props.default : FilterConstants.Keyword.AND;
        Object.values(FilterConstants.Keyword).forEach(
            (keyword: any) => {
                const opt: any = { id: keyword.id, extra: keyword, label: keyword.label, };
                if (keyword.id === defaultKeyword.id) {
                    opt.isDefault = true;
                }
                keywordOptions.push(opt);
            }
        );
        return <UC.SelectBox options={keywordOptions} onSelect={this.props.onSelect} />
    }
}

class OperatorSelector extends UnoCoreBaseComp {

    buildComp() {
        const eProp = this.state.eProp;

        const options: Array<any> = [];
        let defaultOperator = this.state.default;
        FilterConstants.getOperators(eProp)
            .forEach(
                (operator: any) => {
                    const opt: any = { id: operator.id, label: operator.label, isDefault: (operator.id === defaultOperator) };
                    // console.log('Operator: ', operator, defaultOperator, (operator.id === defaultOperator), opt);
                    options.push(opt);

                }
            );

        // console.log('Operators: ', options, defaultOperator);
        return (
            <UC.SelectBox
                options={options}
                onSelect={this.props.onSelect}
                label='Select Operator'
            />
        );
    }
}

class ConditionEditor extends UnoCoreBaseComp {
    operatorIsEmpty = FilterConstants.Operator.EMPTY.id;
    operatorIsNotEmpty = FilterConstants.Operator.NOT_EMPTY.id;

    _handlerOperatorSelected: any;

    category: EntityCategory;
    condition: Condition;

    constructor(props: any) {
        super(props);
        this.condition = this.props.condition;
        this.category = this.props.category;
    }

    componentDidMount(): void {
        super.componentDidMount();

        this._handlerOperatorSelected = EM.register('OPERATOR_SELECTED',
            (data: { old: string, now: string, condition: Condition }) => {
                // this.triggerChangeCondition();
                if (this.condition = data.condition) {
                    if (data.now === this.operatorIsEmpty || data.now === this.operatorIsNotEmpty) {
                        this.condition.value = undefined;
                        // this.reRender();
                    } else if (data.old === this.operatorIsEmpty) {
                        // this.reRender();
                    }
                }
            }
        );
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        EM.unregister(this._handlerOperatorSelected);
    }

    buildComp() {
        const condition = this.condition;
        const pID = condition?.propID;

        if (!condition || pID === EntityConstants.Attr.CATEGORY || condition.hidden) {
            return undefined;
        } else if (condition.readonly) {
            return (
                <div>
                    {condition.propID} {condition.operator} {Common.stringify(condition.value)}
                </div>
            );
        }

        const prop = this.getProp(pID);
        // Condition Field Selector
        const PropSelector = this.buildPropSelector(prop, condition,);
        // Operator Selector
        const OpSelector = this.builOperatorSelector(prop, condition);;
        // Prop Value Specifier
        if (condition.operator === FilterConstants.Operator.ORDER_BY && condition.value === undefined) {
            condition.value = FilterConstants.SortOptions.DESC.id;
        }
        const ValueSpecifier = this.buildValueSpecifier(prop, condition);
        // Action buttons
        const Actions = this.buildActions();

        let CondView = (
            <div
                style={
                    {
                        display: '',
                        justifyContent: '',
                        border: '1px solid gray',
                        margin: '2px 5px',
                        padding: '10px',
                        borderRadius: '10px'
                    }
                }
            >
                {Actions}
                {PropSelector}
                {(!condition.propID) ? undefined : OpSelector}
                {(!condition.propID || condition.operator === this.operatorIsEmpty || condition.operator === this.operatorIsNotEmpty) ? undefined : ValueSpecifier}
            </div >
        );
        /*
        CondView = (
            <UC.Chip onClose={this.handleOnClose}>
                {PropSelector}
                {(!condition.propID) ? undefined : OpSelector}
                {(!condition.propID || condition.operator === this.operatorIsEmpty) ? undefined : ValueSpecifier}
            </UC.Chip>
        )
        */
        return CondView;
    }

    buildActions() {
        return (
            <div className='float-right'>
                {/* 
                <UC.FileViewer
                    styles={{ width: '20px', height: 'auto' }}
                    defaultValue={Images.Icon.delete}
                    onClick={this.handleOnClose}
                />
                */}

                <UC.Navigation
                    navs={[
                        { id: 'delete', icon: Images.Icon.delete, action: this.handleOnClose }
                    ]}
                    orientation='h'
                    key={Common.getUniqueKey()}
                    isToolbar={true} />

            </div>
        );
    }

    buildPropSelector(prop: EntityProp, condition: Condition,) {
        return (
            <ConditionFieldSelector
                onSelect={
                    async (option: any) => {
                        if (option.extra && option.extra.id) {
                            condition.propID = option.extra.id;
                            this.triggerChangeCondition();
                        }
                    }}
                category={this.category}
                eProp={prop}
            />
        );
    }

    builOperatorSelector(prop: EntityProp, condition: Condition,) {
        if (!prop) {
            return undefined;
        }

        if (!condition.operator) { // set default operator
            condition.operator = (isArrayProp(prop) ?
                FilterConstants.Operator.IN.id : (
                    isTextProp(prop) ?
                        FilterConstants.Operator.REGEXP.id :
                        FilterConstants.Operator.EQUAL.id
                )
            );
        }

        const OpSelector = (
            <OperatorSelector
                onSelect={
                    async (option: any) => {
                        condition.operator = option.id;
                        this.triggerChangeCondition();
                    }}
                default={condition.operator}
                eProp={prop}
            />
        );
        return OpSelector;
    }

    buildValueSpecifier(prop: EntityProp, condition: Condition) {
        prop = { ...prop }; // duplicate the prop for further changes.
        // const propDataType = prop.dataType;
        let valIsArray = false;
        switch (condition.operator) {
            case FilterConstants.Operator.IN.id:
            case FilterConstants.Operator.NIN.id:
                valIsArray = true;
                break;
        }

        let ValueSpecifier = undefined;
        let valueChoices = undefined;

        if (!prop) {
            return ValueSpecifier;
        }

        const otherProps = {
            onPropChanged: (prop: EntityProp, value: any) => {
                switch (prop.dataType) {
                    case EntityConstants.PropType.ENTITY:
                    case EntityConstants.PropType.ENTITY_INLINE:
                        if (prop.multiplicity === undefined || prop.multiplicity === 1) {
                            value = Common.safeParse(value);
                            if (Common.checkType.Object(value) && prop.dataType === EntityConstants.PropType.ENTITY) {
                                // value = EntityConstants.truncate(value);
                            }
                        }
                        // console.log('Selected Field Entity: ', value, prop.id, prop.multiplicity);
                        break;
                    case EntityConstants.PropType.NUMBER:
                        if (value !== undefined) {
                            value = Number.parseFloat(value);
                        }
                        break;
                    case EntityConstants.PropType.DATE:
                    case EntityConstants.PropType.TIME:
                    case EntityConstants.PropType.DATETIME:
                        if (value !== undefined) {
                            // value = Number.parseInt(value);
                        }
                        break;
                }

                if (condition.operator === this.operatorIsEmpty || condition.operator === this.operatorIsNotEmpty) {
                    condition.value = undefined;
                } else {
                    condition.value = value;
                }
                this.triggerChangeCondition();
            },
            // eCategory: this.category,
        };
        // console.log('Entity Category for prop condition: ', { ...this.category }, { ...prop }, { ...this.condition });

        if (condition.operator === FilterConstants.Operator.ORDER_BY.id) {
            const options = [
                { ...FilterConstants.SortOptions.ASC, isDefault: (condition.value === FilterConstants.SortOptions.ASC.id), },
                { ...FilterConstants.SortOptions.DESC, isDefault: (condition.value === FilterConstants.SortOptions.DESC.id), }
            ];

            ValueSpecifier = (
                <UC.SelectBox
                    options={options}
                    onSelect={(opt: any) => {
                        // console.log('Sort By: ', condition, opt);
                        otherProps.onPropChanged(prop, opt.id);
                    }}
                />
            );
        } else {
            let ValueEditor = UC.PropValue;
            if (valIsArray) {
                ValueEditor = UC.PropMultipleEditor;
            }

            switch (prop.dataType) {
                case EntityConstants.PropType.JSON:
                case EntityConstants.PropType.FUNCTION:
                case EntityConstants.PropType.STANDARD_FUNCTION:
                case EntityConstants.PropType.LAYOUT:
                    prop.dataType = EntityConstants.PropType.MULTILINE;
                    break;
            }

            valueChoices = FilterConstants.getValueChoices(prop);
            let propCategory = undefined;
            if (prop.category) {
                propCategory = EntityCategoryService.getAppCategory(prop.category, this.getAppID());
                if (propCategory?.props) {
                    propCategory.props.forEach((p: any) => {
                        p.validators = [];
                    });
                }
            }

            ValueSpecifier =
                (
                    <ValueEditor
                        entityProp={prop}
                        categoryID={prop.category}
                        category={propCategory}
                        otherProps={otherProps}
                        defaultValue={condition.value}
                        key={Common.getUniqueKey()}
                        editing={true}
                        action='filter'
                        eCategory={this.category}
                    />
                );
        }

        if (!valueChoices || valueChoices.length === 0) {
            return ValueSpecifier;
        } else {
            const vsName = Common.getUniqueKey('value_specifier_');
            let checked = false;
            const ValueChoiceViews = valueChoices.map(vc => {
                return (
                    <li key={Common.getUniqueKey()} style={{ listStyle: 'none' }} >
                        <input
                            type='radio'
                            value={vc.label}
                            name={vsName}
                            checked={condition.value === vc.id}
                            onChange={(evt: any) => {
                                // console.log('Setting Value: ', vc.id, condition.value);
                                otherProps.onPropChanged(prop, vc.id);
                            }}
                        />
                        {vc.label} : {vc.id}
                    </li>
                );
            });

            return (
                <>
                    <ul>
                        {ValueChoiceViews}
                        {
                            <li key={Common.getUniqueKey()} style={{ listStyle: 'none' }}>
                                <input
                                    type='radio'
                                    name={vsName}
                                    checked={!checked}
                                    onChange={(evt: any) => {
                                        // console.log('UnSetting Value: ');
                                        otherProps.onPropChanged(prop, undefined);
                                    }}
                                />
                                Or specify below<br />
                                {ValueSpecifier}
                            </li>
                        }
                    </ul>
                </>
            );
        }
    }

    getProp(propID?: string): EntityProp {
        let prop: any = undefined;
        if (this.category?.props) {
            prop = EntityConstants.getPropByID(this.category.props, propID);
        }
        return prop;
    }

    handleOnClose = () => {
        const onDelete = this.props.onDelete;
        if (onDelete) {
            onDelete();
        }
    }

    triggerChangeCondition = (refresh = true) => {
        const onChangeCondition = this.props.onChangeCondition;
        if (onChangeCondition) {
            // console.log('onChangeCondition: ', onChangeCondition);
            onChangeCondition();
        }
        if (refresh) {
            this.reRender();
        }
    }

}

class ConditionGroupEditor extends UnoCoreBaseComp {
    category = this.props.category;
    condition: ConditionGroup = this.props.condition;

    constructor(props: any) {
        super(props);
    }

    buildComp() {
        if (this.condition.hidden) {
            return <UC.Empty />;
        }

        const keyword = this.condition.keyword;
        let title = `${keyword ? FilterConstants.getKeywordLabel(keyword) : 'Condition'} Group`;

        let conditionEditors: any = this.condition.children?.map(
            (condition: any, index: number) => {
                let CondEditor: any = ConditionEditor;
                if (condition.keyword) {
                    CondEditor = ConditionGroupEditor;
                }

                return (
                    <CondEditor
                        key={Common.getUniqueKey()}
                        condition={condition}
                        onDelete={() => { this.deleteCondition(index) }}
                        onChangeCondition={() => { this.triggerChangeCondition(); this.setState({}); }}
                        category={this.category}
                    />
                );
            }
        );

        return (
            <UC.Section
                resizable={true}
                onClose={this.condition.readonly ? undefined : this.handleOnClose}
                title={title}
                key={Common.getUniqueKey()}
                styles={{
                    border: '1px solid', marginBottom: '5px', padding: '5px',
                }}
            >
                <div className='entity-view'>
                    {this.buildActionButtons()}
                    {conditionEditors}
                </div>
            </UC.Section>
        );

    }

    addGroup = (type = FilterConstants.Keyword.AND.id) => {
        const condition: ConditionGroup = {
            keyword: type,
        };
        this.addChild(condition);
    }

    triggerChangeCondition = () => {
        const onChangeCondition = this.props.onChangeCondition;
        if (onChangeCondition) {
            onChangeCondition();
        }
    }

    buildActionButtons() {
        const actions = [];
        actions.push({ id: 'Add Condition', action: () => { this.addChild() }, icon: Images.Icon.addCondition });

        const groupChoices = Object.values(FilterConstants.Keyword).map(keyword => {
            return {
                id: keyword.id,
                label: keyword.label,
                icon: getKeywordIcon(keyword.id),
                action: () => { this.addGroup(keyword.id); }
            };
        });
        actions.push({ id: 'Add Group', children: groupChoices, icon: Images.Icon.plus });

        actions.push({ id: 'Clear', action: this.initChildren, icon: Images.Icon.clear });
        return (<UC.Navigation navs={actions} orientation='h' key={Common.getUniqueKey()} isToolbar={true} />);
    }

    addChild = (condition: Condition | ConditionGroup = {}) => {
        let children = this.condition?.children;
        if (!children) {
            children = [];
            this.condition.children = children;
        }
        children?.push(condition);
        this.setState({});
    }

    deleteCondition(index: number) {
        const deleted = this.condition?.children?.splice(index, 1);
        if (deleted) {
            this.setState({});
        }
    }

    initChildren = () => {
        this.condition.children = [];
        this.setState({});
    }

    handleOnClose = () => {
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    }

}

@UnoComponent(
    {
        id: 'TimestampRangeEditor',
        label: 'Time-Range Editor',
        paletteable: false,
        group: DesignerConstants.PaletteGroup.Editor.id,
    }
)
class TimestampRangeEditor extends BasePropEditor {
    editedValue: any = this.getDefaultValue() ? Common.safeParse(this.getDefaultValue()) : {};

    buildInput() {
        return (
            <>
                Or between <input type='text' onBlur={this.handleFirst} defaultValue={this.getEditedValue()?.first} />
                and <input type='text' onBlur={this.handleSecond} defaultValue={this.getEditedValue()?.second} />
            </>
        );
    }

    handleFirst = (event: any) => {
        const value = event?.target?.value?.trim();
        this.editedValue.first = (value && value.length > 0) ? value : undefined;
        // alert(Common.stringify(this.editedValue));
        this.setPropValue(this.getEditedValue());
    }

    handleSecond = (event: any) => {
        const value = event?.target?.value?.trim();
        this.editedValue.second = (value && value.length > 0) ? value : undefined;
        // alert(Common.stringify(this.editedValue));
        this.setPropValue(this.getEditedValue());
    }

}

class OrderByEditor extends UnoCoreBaseComp {

    buildComp() {
        return (
            <UC.Section title='Order By' minimized={true}>

            </UC.Section>
        );
    }
}

class PropsToViewEditor extends UnoCoreBaseComp {

    buildComp() {
        return (
            <UC.Section title='Properties to View' minimized={true}>

            </UC.Section>
        );
    }
}



/*
switch (prop.dataType) {
    case EntityConstants.PropType.DATETIME:
        const tsRangeEProp: EntityProp = { id: 'tsrange', editor: 'TimestampRangeEditor' }
        ValueSpecifier = (
            <>
                {ValueSpecifier}
                <UC.PropValue
                    entityProp={tsRangeEProp}
                    categoryID={prop.category}
                    otherProps={otherProps}
                    defaultValue={condition.value}
                    key={Common.getUniqueKey()}
                    editing={true}
                />
            </>
        );
        break;
    default:

}
*/