import React from 'react';
import { Common, EM } from '../../@uno/api';
import { UC, UnoComponent } from '../../@uno/core';
import { UnoSheetConstants } from '../services/workbook.service'

// https://support.microsoft.com/en-us/office/excel-specifications-and-limits-1672b34d-7043-467e-8e27-269d656771c3


const Event = UnoSheetConstants.Event;

export interface UnoCell {
    _id?: string,

    row?: number,
    column?: number,

    rowspan?: number,
    colspan?: number,

    value?: any,
    formula?: any,

    readonly?: boolean,
    style?: any,
}

export interface UnoSheetProps {
    id?: string,
    name?: string,

    width?: string,
    height?: string,
    columnWidth?: string,
    rowHeight?: string,

    columns?: number,
    rows?: number,

    columnNames?: Array<string>,
    data?: Array<UnoCell>,

    readonly?: boolean,

    onDataChange?: Function,
    onViewPortInit?: Function,
    onViewPortUpdate?: Function,
}

const getSheetContainerID = (sheetID: string): string => {
    return `div_${sheetID}`;
}

const getCellContainerID = (sheetID: string, column: number, row: number): string => {
    return `cell_${sheetID}_col_${column}_row_${row}`;
}

const isVisible = (container: any, element: any) => {
    const contWidth = container.offsetWidth;
    const contHeight = container.offsetHeight;
    const elemLeft = element.offsetLeft - container.scrollLeft;
    const elemTop = element.offsetTop - container.scrollTop;
    if (elemTop >= 0 && elemTop <= contHeight && elemLeft >= 0 && elemLeft <= contWidth) {
        return true;
    } else {
        return false;
    }
}

@UnoComponent({ id: 'UnoSheet', label: 'Uno Sheet' })
export class UnoSheet extends React.Component<UnoSheetProps, any> {
    sheetID = Common.getUniqueKey('UnoSheet');

    width = `${window.screen.width * 0.8}px`;
    height = '500px';
    columnWidth = '100px';
    rowHeight = '25px';

    // to be specified in the client.
    columns = UnoSheetConstants.DEFAULT_COLUMN_COUNT;
    rows = UnoSheetConstants.DEFAULT_ROW_COUNT;

    colNames: Array<string> = [];

    data: Array<UnoCell> = [];

    constructor(props: UnoSheetProps) {
        super(props);

        // initialize members
        this.sheetID = (this.props.id !== undefined) ? this.props.id : this.sheetID;

        this.width = (this.props.width !== undefined) ? this.props.width : this.width;
        this.height = (this.props.height !== undefined) ? this.props.height : this.height;
        this.columnWidth = (this.props.columnWidth !== undefined) ? this.props.columnWidth : this.columnWidth;
        this.rowHeight = (this.props.rowHeight !== undefined) ? this.props.rowHeight : this.rowHeight;

        this.columns = (this.props.columns !== undefined) ? this.props.columns : this.columns;
        this.rows = (this.props.rows !== undefined) ? this.props.rows : this.rows;

        this.colNames = (this.props.columnNames !== undefined) ? this.props.columnNames : [];
        if (this.columns < this.colNames.length) {
            this.columns = this.colNames.length;
        }

        this.data = (this.props.data !== undefined) ? this.props.data : [];
        if (this.rows < this.data.length) {
            this.rows = this.data.length;
        }
        // console.log('Sheet Data : ', this.data);
    }

    componentDidMount() {
        this.updateViewPort();
    }

    render() {
        const start = new Date().getTime();

        const sheetView = (
            <>
                <div
                    id={getSheetContainerID(this.sheetID)}

                    style={{
                        height: this.height,
                        width: this.width,
                        overflow: 'auto',
                        border: '2px solid black'
                    }}

                    onScroll={(evt) => {
                        this.updateViewPort();
                    }}

                    key={Common.getUniqueKey()}
                >
                    <table style={{ tableLayout: 'fixed' }}>
                        {this.buildHeader()}
                        {this.buildRows()}
                    </table>
                </div>
            </>
        );

        console.log(`Time to render a ${this.rows}X${this.columns} sheet: `, (new Date().getTime() - start), `ms`);
        return sheetView;
    }

    buildHeader = () => {
        const thCells = [];
        for (let i = 0; i <= this.columns; i++) {
            let columnLabel: any = <UC.Empty />;
            if (i > 0) {
                const columnID = UnoSheetConstants.getColumnLabel(i);
                const colName = this.colNames ? this.colNames[i - 1] : undefined;
                columnLabel = colName ? `${colName}  [${columnID}] ` : columnID;
            }
            // const columnLabel = (i === 0) ? '' : colName ? colName : `${i}`;
            // const columnLabel = (i === 0) ? '' : UnoSheetConstants.getColumnLabel(i);
            // add to colNames if needed.
            if (i > this.colNames.length) {
                this.colNames.push(columnLabel);
            }

            const thStyles: any = { top: '0', position: 'sticky', };
            if (i === 0) {
                thStyles.minWidth = '0px';
                thStyles.left = '0';
                thStyles.zIndex = '10';
            }

            thCells.push(
                <th
                    className='sheet-cell sheet-header'
                    style={thStyles}
                    key={Common.getUniqueKey('th')}
                >
                    {columnLabel}
                </th>
            );
        }

        return (<thead><tr>{thCells}</tr></thead>);
    }

    buildRow = (rowNum: number) => {
        const trCells = [];
        for (let colNum = 0; colNum <= this.columns; colNum++) {
            if (colNum === 0) {
                trCells.push(
                    <td className='sheet-cell sheet-header' style={{ minWidth: '0px', left: '0', position: 'sticky', }} key={Common.getUniqueKey('td')}>
                        {rowNum}
                    </td>
                );
            } else {
                let theCell = this.getUnoCell(colNum, rowNum);
                const cellID = theCell?._id;



                trCells.push(
                    <td
                        className='sheet-cell '
                        id={getCellContainerID(this.sheetID, colNum, rowNum)}
                        key={Common.getUniqueKey(cellID ? cellID : 'cell')}
                    >
                        <UnoSheetCell
                            sheetID={this.sheetID}
                            cellID={cellID}
                            row={rowNum}
                            column={colNum}
                            value={theCell ? theCell.value : ``}
                            formula={theCell?.formula}
                            readonly={this.props.readonly}

                            onChange={
                                (editedValue: any) => {
                                    console.log(`${colNum} - ${rowNum}: Edited Value: `, editedValue);
                                    if (!editedValue.cellID || !theCell) {
                                        theCell = {
                                            // _id: UnoSheetConstants.getCellID(this.sheetID, colNum, rowNum),
                                            row: rowNum,
                                            column: colNum,
                                        };
                                        this.data.push(theCell);
                                    }

                                    theCell.value = editedValue.value;
                                    theCell.formula = editedValue.formula;
                                    // theCell.style = editedValue.style;

                                    const onDataChangeHandler = this.props.onDataChange;
                                    if (onDataChangeHandler && editedValue.action) {
                                        onDataChangeHandler([{ cell: theCell, action: editedValue.action }]); // send only modified cells.
                                    }
                                }
                            }

                            key={Common.getUniqueKey()}
                        />
                    </td>
                );
            }
        }
        return (<tr key={Common.getUniqueKey('tr')}>{trCells}</tr>)
    }

    buildRows = () => {
        const tableRows = [];
        for (let i = 1; i <= this.rows; i++) {
            tableRows.push(this.buildRow(i));
        }
        return (<tbody>{tableRows}</tbody>);
    }

    getUnoCell = (column: number, row: number) => {
        for (let i: number = 0; i < this.data.length; i++) {
            const cell = this.data[i];
            if (cell.column === column && cell.row === row) {
                return cell;
            }
        }
        return undefined;
    }

    getUnoCellByID = (id: string) => {
        for (let i: number = 0; i < this.data.length; i++) {
            const cell = this.data[i];
            if (cell._id === id) {
                return cell;
            }
        }
        return undefined;
    }

    private testColumnLabel = () => {
        const data = [
            { label: 'A', index: 1 },
            { label: 'z', index: 26 },
            { label: 'Ab', index: (26 * 1 + 2) },
            { label: 'BA', index: (26 * 2 + 1) },
            { label: 'ZZZ', index: (26 * 26 * 26 + 26 * 26 + 26) },
        ];
        data.forEach(sData => {
            const index = sData.index;
            const expLabel = sData.label.toUpperCase();
            const calcLabel = UnoSheetConstants.getColumnLabel(index);

            console.log(`Index: ${index}, Expected Label: ${expLabel}, Calculated Label: ${calcLabel}, Pass: ${calcLabel === expLabel ? 'Yes' : 'No'}`)
        }
        )
    }

    private testColumnIndex = () => {
        const data = [
            { label: 'A', index: 1 },
            { label: 'z', index: 26 },
            { label: 'Ab', index: (26 * 1 + 2) },
            { label: 'BA', index: (26 * 2 + 1) },
            { label: 'ZZZ', index: (26 * 26 * 26 + 26 * 26 + 26) },
        ];
        data.forEach(
            sData => {
                const label = sData.label;
                const expIndex = sData.index;
                const calcIndex = UnoSheetConstants.getColumnIndex(label);

                console.log(`Label: ${label}, Expected Index: ${expIndex}, Calculated Index: ${calcIndex}, Pass: ${calcIndex === expIndex ? 'Yes' : 'No'}`)
            }
        );
    }


    private updateViewPort() {
        const viewPort = this.findViewPort();
        // console.log(`Sheet - ${this.sheetID}, viewport updated - `, viewPort);
        EM.emit(Event.onViewPortUpdated, { sheetID: this.sheetID, viewPort: viewPort });
    }

    private findViewPort() {
        const sheetContainer = document.getElementById(getSheetContainerID(this.sheetID));
        let topLeftCell, bottomRightCell;
        for (let column = 1; column < this.columns; column++) {
            for (let row = 1; row < this.rows; row++) {
                const cellContainer = document.getElementById(getCellContainerID(this.sheetID, column, row));
                if (isVisible(sheetContainer, cellContainer)) {
                    if (!topLeftCell) {
                        topLeftCell = { column: column, row: row };
                    } else {
                        bottomRightCell = { column: column, row: row };
                    }
                }
            }
        }
        return { min: topLeftCell, max: bottomRightCell };
    }
}

export interface UnoSheetCellProps extends UnoCell {
    sheetID?: any,
    cellID?: any,

    onChange?: Function
    onFocus?: Function
}

@UnoComponent({ id: 'UnoSheetCell', label: 'Uno Sheet Cell' })
export class UnoSheetCell extends React.Component<UnoSheetCellProps, any> {
    focusCellStyle = {
        borderTop: '2px solid black',
        borderRight: '2px solid black',
        borderBottom: '2px solid black',
        borderLeft: '2px solid black',
    };

    editedValue: any;
    //editedFormula: any;
    editing = false;

    private _handlerOnCellFocus: any;
    private _handlerOnCellEditStarted: any;
    private _handlerOnCellEditCompleted: any;
    private _handlerOnCellEditAborted: any;
    private _handlerOnSheetDataRefresh: any;

    constructor(props: UnoSheetCellProps) {
        super(props);
        this.state = { ...this.props };

        // handle on Cell Focus
        this._handlerOnCellFocus = EM.register(this.getEventID(Event.onCellFocus), this.handleOnCellFocus);

        // handle on Cell edit sratred
        this._handlerOnCellEditStarted = EM.register(this.getEventID(Event.onCellEditStarted), this.handleOnCellEditStarted);

        // handle on Cell edit completed
        this._handlerOnCellEditCompleted = EM.register(this.getEventID(Event.onCellEditCompleted), this.handleOnCellEditCompleted);

        // handle on Cell edit aborted
        this._handlerOnCellEditAborted = EM.register(this.getEventID(Event.onCellEditAborted), this.handleOnCellEditAborted);

        // handle on Sheet Data Refresh
        this._handlerOnCellEditAborted = EM.register(this.getEventID(Event.onSheetDataRefresh), this.handleOnSheetDataRefresh);
    }

    componentWillUnmount() {
        // unregister event handlers. CLEAN UP.
        EM.unregister(this._handlerOnCellFocus);
        EM.unregister(this._handlerOnCellEditStarted);
        EM.unregister(this._handlerOnCellEditCompleted);
        EM.unregister(this._handlerOnCellEditAborted);
        EM.unregister(this._handlerOnSheetDataRefresh);
    }

    render() {
        const readonly = this.state.readonly;

        const cStyle = this.state.style;
        const disabledStyle = (readonly) ? { backgroundColor: '#FFFFEE' } : undefined;

        return (
            <div
                contentEditable={!readonly}
                suppressContentEditableWarning={true}
                style={{ ...cStyle, ...disabledStyle, width: '100%', height: '100%', padding: '0px' }}
                title={this.getTooltip()}

                onClick={() => {
                    if (!readonly) {
                        EM.emit(this.getEventID(Event.onCellFocus), this.props);
                    }
                }}

                onBlur={
                    (evt) => {
                        this.editedValue = evt.target.innerText.trim();
                        console.log(`Lost focus: `, this.editedValue, this.props);
                        EM.emit(this.getEventID(Event.onCellEditCompleted));
                    }
                }
            >
                {(this.editing) ? this.editedValue : this.getValue()}
            </div>
        );
    }

    getValue = () => {
        const val = this.state.value;
        if (val && Common.checkType.Object(val)) {
            return val.value;
        } else {
            return val;
        }
    }

    getTooltip = () => {
        const val = this.state.value;
        if (val && Common.checkType.Object(val)) {
            return val.message;
        } else {
            return '';
        }

    }

    getEventID = (name: string) => {
        return Event.getEventID(name, this.props.sheetID);
    }

    getOnFocusStyle = () => {
        return { ...this.props.style, ...this.focusCellStyle };
    }

    getOffFocusStyle = () => {
        // console.log(`Off Focus Style: `, this.props.style);
        return this.props.style;
    }

    isThisCell = (p: UnoCell) => {
        if (p.row === this.props.row && p.column === this.props.column) {
            return true;
        }
        return false;
    }

    handleOnCellFocus = (p: UnoCell) => {
        if (this.isThisCell(p)) {
            // initialize edit if needed
            if (!this.state.readonly) { //Edit if not READ-ONLY
                this.editing = true;
                EM.emit(this.getEventID(Event.onCellEditStarted), this.props);
            }
            // set focus
            if (!this.state.hasFocus) {
                // this.setState({ style: this.getOnFocusStyle(), hasFocus: true });
                // console.log(`Focus set on : `, p);
            }
        } else {
            // unset focus
            if (this.state.editing) {
               //  EM.emit(this.getEventID(Event.onCellEditCompleted));
            }
            if (this.state.hasFocus) {
                this.setState({ style: this.getOffFocusStyle(), hasFocus: false });
                // console.log(`Focus removed from : `, p);
            }
        }
    }

    handleOnCellEditStarted = (p: UnoCell) => {
        if (this.isThisCell(p) && !this.props.readonly) {
            // set editing true
            if (this.state.formula) {
                this.editedValue = UnoSheetConstants.FORMULA_PREFIX + this.state.formula;
                // this.editedFormula = this.state.formula;
            } else {
                this.editedValue = this.getValue();
                // this.editedFormula = undefined;
            }
            this.setState({ editing: true, });
            // console.log(`Edit Started : `, p);
        }
    }

    handleOnCellEditCompleted = () => {
        let action: string | undefined = undefined;
        let editedFormula: any;

        if (this.state.editing) {
            this.editing = false;

            // set the action;
            if (this.getValue()) { // was not empty.
                if (this.getValue() === this.editedValue) { // same as old value. no change
                    action = undefined;
                } else if (!this.editedValue || this.editedValue.length === 0) { // now empty. deleted
                    action = UnoSheetConstants.Action.DELETE;
                } else { // different value now. changed
                    action = UnoSheetConstants.Action.UPDATE;
                }
            } else { // was empty.
                if (!this.editedValue || this.editedValue.length === 0) { // still empty. no change
                    action = undefined;
                } else { // some value now. created.
                    action = UnoSheetConstants.Action.CREATE;
                }
            }


            if (action) {
                if (this.editedValue) {
                    this.editedValue = new String(this.editedValue).trim();
                    if (this.editedValue.startsWith(UnoSheetConstants.FORMULA_PREFIX)) {
                        // edited value is a formula;
                        editedFormula = this.editedValue.substring(UnoSheetConstants.FORMULA_PREFIX.length).trim();
                        if (editedFormula.length === 0) {
                            editedFormula = undefined;
                        }
                        // TODO: also update the calculated value.
                    }
                }

                this.setState({ style: this.getOffFocusStyle(), hasFocus: false, editing: false, value: this.editedValue, formula: editedFormula });

                const onChangeHandler = this.props.onChange;
                if (onChangeHandler && action) {
                    onChangeHandler(
                        {
                            cellID: this.state.cellID,
                            value: this.editedValue,
                            formula: editedFormula,
                            style: this.state.style,
                            action: action,
                        }
                    );
                }
            } else { // unset editing state
                this.setState({ style: this.getOffFocusStyle(), hasFocus: false, editing: false });
            }

            // console.log(`Edit completed for action: `, action, this.props);
        }
    }

    handleOnCellEditAborted = (p: UnoCell) => {
        if (this.isThisCell(p)) {
            // set editing false
            this.setState({ editing: false, });
        }
    }

    handleOnSheetDataRefresh = (input: any) => {
        const sheetID = this.state.sheetID;
        if (input.sheetID === sheetID) {
            // console.log(`Sheet Data refreshed for sheetID - `, sheetID);
            const cells: Array<any> = input.cells;
            const cellRow = this.state.row;
            const cellColumn = this.state.column;
            const updatedCell = cells?.find((cell) => {
                return (cell.column === cellColumn && cell.row === cellRow);
            });
            if (updatedCell) {
                this.setState({ ...updatedCell });
            }
        }
    }

}