import React from 'react';
import { BaseEntity, Common, EntityConstants } from '../../../@uno/api';
import { DesignerConstants, Images, UC, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';
import { HistoryService } from '../../service/history.service';
import icon_version from './version-history.webp';
import { EntityCategoryService } from '../../../@uno-app/service/entity.category.service';
import { TriggerService } from '../../service/entity-trigger.service';
import versionCompLayout from './version.comp.layout.json';

// ENTITY - NEW, EDIT
@UnoComponent({
    id: 'VersionComp',
    label: 'Versions',
    props: [
        { id: 'entity', label: 'Entity', dataType: EntityConstants.PropType.JSON },
        { id: 'category', label: 'Category', dataType: EntityConstants.PropType.JSON },
    ],
    getDesign: () => { return (<div>Entity Version - Design</div>); }
})
export class VersionComp extends UnoCoreBaseComp {

    buildComp() {
        const layout = versionCompLayout
        const dialogComp = (
            <UC.LayoutRenderer
                config={layout}
                mode={DesignerConstants.Mode.LIVE}
                key={Common.getUniqueKey()}
            />
        );


        const navs = [
            {
                id: 'Versions',
                icon: icon_version,
                action: async () => {
                    const category = await this.getCategory();
                    const entity = await this.getEntity();
                    console.log('Show Versions - Category - Entity: ', category, entity);

                    const result = await TriggerService.send({ entity: entity, category: category }, 'FIND_ENTITY_VERSION');
                    const dlg = {
                        id: Common.getUniqueKey('versions_'),
                        title: 'Entity Versions',
                        comp: (
                            <UC.LayoutRenderer
                                key={Common.getUniqueKey()}
                                config={layout}
                                mode={DesignerConstants.Mode.LIVE}
                                moreProps={{
                                    versions: result.versions,
                                }}
                            />
                        ),
                        onClose: async () => {

                        }
                    };
                    Common.showDialog(dlg);

                    Common.hideDialog();
                }
            },
        ]
        return (
            <>
                <UC.Navigation
                    navs={navs}
                    orientation='h'
                />
            </>
        );

    }

    async getCategory() {
        let category = Common.safeParse(this.state.category);
        if (!category) {
            const entity = await this.getEntity();
            category = entity ? await EntityCategoryService.getCategory(entity.getCategoryID()) : undefined;
        }
        return category;
    }

    async getEntity() {
        const entity = this.state.entity;
        return (entity && entity._id) ? EntityConstants.build(entity) : entity;
    }

}