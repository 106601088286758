import { AppInfoService } from "../../@uno-app/service/app.info.service";
import { SessionManager } from "../../@uno-app/service/session.service";
import { Common, RemoteService } from "../../@uno/api";

export const UnoSheetConstants = {
    WSCellCategory: Common.CategoryID.WSCell,
    WSCellSheetID: 'wsID',
    FORMULA_PREFIX: '=',
    DEFAULT_COLUMN_COUNT: 30,
    DEFAULT_ROW_COUNT: 1000,
    Action: {
        CREATE: 'CREATE',
        READ: 'READ',
        UPDATE: 'UPDATE',
        DELETE: 'DELETE',
    },
    getActionID: (category: string, action: string) => {
        return `${category}_${action}`;
    },
    COLUMN_IDs:
        [
            'A', 'B', 'C', 'D',
            'E', 'F', 'G', 'H',
            'I', 'J', 'K', 'L',
            'M', 'N', 'O', 'P',
            'Q', 'R', 'S', 'T',
            'U', 'V', 'W', 'X',
            'Y', 'Z',
        ],

    getColumnLabel: (columnIndex: number) => {
        let label: string = '';
        const idBase = UnoSheetConstants.COLUMN_IDs.length;
        do {
            let pIndex = columnIndex % idBase;
            if (pIndex === 0) {
                pIndex = idBase;
            }
            let pID = UnoSheetConstants.COLUMN_IDs[pIndex - 1];
            label = pID + label;

            // move to next place
            columnIndex = columnIndex - pIndex;
            columnIndex = Math.floor(columnIndex / idBase);
        } while (columnIndex > 0);

        return label;
    },

    getColumnIndex: (columnLabel: string) => {
        columnLabel = columnLabel.toUpperCase();
        // console.log('Finding index of column label: ', columnLabel);
        let columnIndex = 0;
        for (let i = 1; i <= columnLabel.length; i++) {
            const id = columnLabel[columnLabel.length - i];
            let idVal = UnoSheetConstants.COLUMN_IDs.indexOf(id) + 1;
            const placeValue = (Math.pow(UnoSheetConstants.COLUMN_IDs.length, i - 1) * idVal);
            columnIndex += placeValue;
            // console.log(`Place: ${i}, ID: ${id}, Place Value: ${placeValue}`);
        }
        // console.log(`Column label: ${columnLabel}, Index : ${columnIndex}`);
        return columnIndex;
    },

    getCellID: (sheetID: string, colNum: number, rowNum: number) => {
        return `${sheetID}.UnoCell.${colNum}.${rowNum}`;
    },

    Event: {
        onCellFocus: 'ON_CELL_FOCUS',
        onCellEditStarted: 'ON_CELL_EDIT_STARTED',
        onCellEditCompleted: 'ON_CELL_EDIT_COMPLETED',
        onCellEditAborted: 'ON_CELL_EDIT_ABORTED',

        onViewPortUpdated: 'ON_VIEWPORT_UPDATED',
        onSheetDataRefresh: 'ON_SHEET_DATA_REFRESH',

        getEventID: (name: string, sheetID: string) => {
            return `${sheetID}_${name}`;
        }
    }
};

class WorkbookServiceImpl {
    private getActiveAppID = () => {
        return AppInfoService.getActiveApp()?.id;
    }

    functionList = async () => {
        const result = await RemoteService.getData(await RemoteService.get('workbook/functionList',));
        // console.log(`functionList : `, result);
        return result;

    }

    setActiveWB = async (wbID: string, sheetIDs: Array<any> = [], appID: any = this.getActiveAppID(), session = SessionManager.activeSession) => {
        const input = { workbookID: wbID, sheetIDs: sheetIDs, appID: appID, session: session };
        const result = await RemoteService.getData(await RemoteService.post('workbook/setActiveWB', { input: input, appID: appID, session: session }));
        // console.log(`WB: ${wbID} - active persons : `, result?.persons);
        return result?.persons;
    }

    saveCells = async (sheetID: string, records: Array<any>, appID: any = this.getActiveAppID(), viewPort?: any) => {
        // console.log(`saving cells : `, records, sheetID);
        const input = { sheetID: sheetID, records: records, appID: appID, viewPort: viewPort };
        const result = await RemoteService.getData(await RemoteService.post('workbook/saveCells', { input: input, appID: appID, session: SessionManager.activeSession }));
        // console.log(`saved cells : `, result);
        return result;

    }

    loadCells = async (sheetID: string, appID: any = this.getActiveAppID(), viewPort?: any) => {
        // console.log(`Loading cells : `, sheetID, appID);
        const input = { sheetID: sheetID, appID: appID, viewPort: viewPort };
        const result = await RemoteService.getData(await RemoteService.post('workbook/loadCells', { input: input, appID: appID, session: SessionManager.activeSession }));
        // console.log(`loaded cells : `, result);
        return result;
    }

    /*
    getCellRecord = async (sheetID: string, colNum: number, rowNum: number, appID: any = this.getActiveAppID()) => {
        let criteria = EntityConstants.buildEmpty(UnoSheetConstants.WSCellCategory);
        criteria.setValue(UnoSheetConstants.WSCellSheetID, sheetID);
        criteria.setValue('row', rowNum);
        criteria.setValue('column', colNum);
        criteria.setAppID(appID);

        const records = await EntityConstants.getEntityService(appID, sheetID).findRecords(criteria);
        return (records && records.length > 0) ? records[0] : undefined;
    }
    */

    assignDataSet = async (sheetID: string, dataSet: any, appID: any = AppInfoService.getActiveApp()?.id) => {
        // console.log(`Setting DS : `, sheetID, dataSet);
        const input = { sheetID: sheetID, appID: appID, dataSet: dataSet };
        const result = await RemoteService.getData(await RemoteService.post('workbook/setDS', { input: input, appID: appID, session: SessionManager.activeSession }));
        // console.log(`WS with DS : `, result);
        return result;
    }
}

export const WorkbookService = new WorkbookServiceImpl();

/*
WorkbookService.functionList().then(flist => {
    // console.log(`${flist.functions?.length} HF functions`);
});
*/