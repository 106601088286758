import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { DesignerConstants, UnoComponent, UnoCoreBaseComp } from "../../../@uno/core";
import { EntityConstants } from "../../../@uno/api";
import { Common, ExternalUser } from "../../../@uno/api/common.service";
import icon_gsign from './google-signin-button.png';
import { Source } from "../../../@uno/api/source.service";

function UnoGoogleLogin(props: { onSuccess: (data: ExternalUser) => void }) {
    const [user, setUser] = useState();
    const [profile, setProfile] = useState();

    const login = useGoogleLogin({
        onSuccess: (codeResponse: any) => {
            // console.log('Google Login Success: ', codeResponse);
            setUser(codeResponse);
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            const theUser: any = user;
            if (theUser) {
                // console.log('User: ', theUser);

                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${theUser.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${theUser.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        // console.log('User Profile: ', res.data);
                        const profileData = res.data;
                        setProfile(profileData);
                        // trigger action
                        const action = props.onSuccess;
                        if (action) {
                            //props.onSuccess({ mode: 'google', token: user, profile: profileData });
                            const inputs = { data: { mode: 'google', token: user, profile: profileData } };
                            if (Common.checkType.Function(action)) {
                                action(inputs.data);
                            } else if (Common.checkType.String(action)) {
                                const fn = Source.getFunction(action);
                                if (fn) {
                                    fn(inputs);
                                }
                            }
                        }
                    })
                    .catch((err) => console.log(err));

            }
        },
        [user]
    );

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(undefined);
    };

    const theProfile: any = profile;
    return (
        <div>
            {theProfile ? (
                <div>
                    {/*
                    <img src={theProfile.picture} alt="user image" />
                    <h3>User Logged in</h3>
                    <p>Name: {theProfile.name}</p>
                    <p>Email Address: {theProfile.email}</p>
                    <br />
                    <button onClick={logOut}>Log out</button>
                    */}
                    <button onClick={logOut}>Google Log out</button>
                </div>
            ) : (
                <img
                    title='Sign In with Google'
                    src={icon_gsign}
                    className=' signin signin-external '
                    onClick={() => login()}
                />
            )}
        </div>
    );
}

@UnoComponent({
    id: 'UnoGoogleLogin',
    label: 'Google Login',
    props: [
        { id: 'onSuccess', label: 'On Login Success', dataType: EntityConstants.PropType.FUNCTION, },
    ],
    getDesign: () => { return (<div><button>Sign in with Google 🚀 </button></div>); },
    getPreview: () => { return (<div><button>Sign in with Google 🚀 </button></div>); },
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class UnoGoogleLoginComp extends UnoCoreBaseComp {

    buildComp(): JSX.Element | null | undefined {
        const theProps: any = this.props;
        return <UnoGoogleLogin {...theProps} />
    }
}