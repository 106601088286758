import React, { useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Common, Images, UnoComponent, } from '../../../core';
import { UnoCoreBaseComp } from './../uno-core.base.comp';

const STT = (props: any) => {
    const [recording, setRecording] = useState(false);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        console.log('Browser doesnot support speech recognition.');
        return null;
    }

    const handleSTT = async (evt: any) => {
        if (listening) {
            setRecording(false);
            SpeechRecognition.stopListening();
        } else {
            setRecording(true);
            SpeechRecognition.startListening(props.options);
        }

        const onSubmit = props.onSubmit;
        if (listening && transcript && onSubmit && Common.checkType.Function(onSubmit)) {
            onSubmit(transcript);
            // resetTranscript();
        }

    }

    return (
        <div style={{ display: 'inline', margin: '5px' }}>
            <img
                src={recording ? Images.Icon.mic_on : Images.Icon.mic_off}
                title={recording ? 'Press to Stop' : `Press to turn it green. Speak. ${props.placeholder}`}
                className='tool-icon'
                onClick={handleSTT}
            />
            {/* <p>{transcript}</p> */}
        </div>
    );
};

// export default STT;

@UnoComponent({
    id: 'STT',
})
export class STTComp extends UnoCoreBaseComp {
    buildComp(): JSX.Element | null | undefined {
        return (<STT {...this.state} />);
    }
}