import { UnoModule } from '../../@uno/core';
import { D3VizComp } from './component/d3-viz.comp';
// The Module...

@UnoModule(
    {
        id: 'D3Module',
        imports: [
        ],
        components: [
            D3VizComp,
        ],
    }
)
export default class D3Module {

}

