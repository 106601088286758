import React, { useRef, useEffect } from 'react';
import * as iink from 'iink-js';

const WritePad = (props: any) => {
    let content: any = undefined;
    let editorEle: any = undefined;
    let editor: any = undefined;
    const editorRef = useRef(null);
    const editorStyle = {
        'minWidth': '100px',
        'minHeight': '100px',
        'width': '100vw',
        'height': 'calc(100vh - 190px)',
        'touchAction': 'none',
    };

    useEffect(() => {
        editorEle = editorRef.current;
        editor = iink.register(editorRef.current, {
            recognitionParams: {
                type: 'TEXT',
                protocol: 'WEBSOCKET',
                apiVersion: 'V4',
                server: {
                    scheme: 'https',
                    host: 'webdemoapi.myscript.com',
                    applicationKey: '1463c06b-251c-47b8-ad0b-ba05b9a3bd01',
                    hmacKey: '60ca101a-5e6d-4159-abc5-2efcbecce059',
                },
            },
        });

        editorEle.addEventListener('exported', (evt: any) => {
            if (evt) {
                const exports = evt['detail'].exports;
                if (exports && exports['text/plain']) {
                    content = exports['text/plain'];
                    // console.log('Written Input: ', content);
                }
            }
        });

        window.addEventListener('resize', () => {
            editor.resize()
        });

        return () => {
            window.removeEventListener('resize', () => { editor.resize() });
            editor.close();
        }
    }, []);

    return (
        <div>
            <button
                onClick={
                    () => {
                        const onSubmit = props.onSubmit;
                        if (onSubmit && content) {
                            onSubmit(content);
                        }
                    }
                }
            >
                Submit
            </button>
            <div style={editorStyle} ref={editorRef} touch-action="none">
            </div>
        </div>
    )
}

export default WritePad;