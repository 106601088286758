import { SessionManager } from '../../@uno-app/service/session.service';
import { Common, RemoteService } from '../../@uno/api';


export class GeoLocation {
    id?: string;
    seqId: number = -1;
    time: string = new Date().toUTCString();
    lat: number = 0;
    elevation: number = 0;
    latMeters: number = 0;
    long: number = 0;
    longMeters: number = 0;

    constructor(lat: number = 0, long: number = 0) {
        this.lat = lat;
        this.long = long;
        this.toMeters();
    }

    toString() {
        return `ID: ${this.id}, SeqID: ${this.seqId}, time: ${new Date(this.time).getTime()}, Latitude: ${this.lat} or ${this.latMeters} meters, Longitude: ${this.long} or ${this.longMeters} meters`;
    }

    toMeters() {
        // const newLocation: GeoLocation = Object.assign(new GeoLocation(), this);
        this.latMeters = Common.calculateDistance(this.lat, this.long, 0, this.long);
        this.longMeters = Common.calculateDistance(this.lat, this.long, this.lat, 0);
        // console.log(`Translated - ${this} to ${newLocation}`);
        return this;
    }

    static isSame(p1: GeoLocation | undefined, p2: GeoLocation | undefined) {
        if (p1 && p2 &&
            p1.long === p2.long &&
            p1.lat === p2.lat &&
            p1.elevation === p2.elevation) {
            return true;
        }
        return false;
    }

    static filterDuplicates(thePoints: GeoLocation[]) {
        let previous: any = undefined;
        const navPoints: Array<GeoLocation> = thePoints.filter(
            point => {
                let distinct = false;
                if (!previous || !GeoLocation.isSame(previous, point)) {
                    distinct = true;
                }
                previous = point;
                return distinct;
            }
        );

        console.log(`# of points = ${thePoints.length}, # of unique points = ${navPoints.length}`);
        return navPoints;
    }

}

export class ClosedArea {
    points: Array<GeoLocation> = [];
    area: number = 0;
    edgePoints: EdgePoints | undefined;
    grids: Array<GeoGrid> = [];
    pathLength: number = 0;
    avP2PDistance: number = 0;

}

export interface EdgePoints {
    left: GeoLocation;
    leftTop: GeoLocation,
    top: GeoLocation,
    rightTop: GeoLocation,
    right: GeoLocation,
    rightBottom: GeoLocation,
    bottom: GeoLocation,
    leftBottom: GeoLocation,
}

export class GeoGrid {
    leftTop: GeoLocation;
    rightTop: GeoLocation;
    leftBottom: GeoLocation;
    rightBottom: GeoLocation;

    locations: Array<GeoLocation> = [];

    constructor(lt: GeoLocation, rt: GeoLocation, lb: GeoLocation, rb: GeoLocation) {
        this.leftTop = lt;
        this.rightTop = rt;
        this.leftBottom = lb;
        this.rightBottom = rb;
    }

    contains(location: GeoLocation) {
        let latYes = false;
        let longYes = false;

        const left = this.leftTop.longMeters;
        const right = this.rightBottom.longMeters;
        const top = this.rightTop.latMeters;
        const bottom = this.leftBottom.latMeters;

        // console.log(`Grid:`, left, right, top, bottom);
        // console.log(`Location:`, location.latMeters, location.longMeters,);

        if (left <= location.longMeters && location.longMeters < right) {
            latYes = true;
        }

        if (top <= location.latMeters && location.latMeters < bottom) {
            longYes = true;
        }

        return (latYes && longYes);
    }

    add(location: GeoLocation) {
        if (this.contains(location)) {
            this.locations.push(location);
            return true;
        }
        return false;
    }

}

class GeoLocationServiceImp {
    private MIN_AREA: number = 25;

    public processPoints(input: GeoLocation[]) {
        return new Promise((resolve, reject) => {
            RemoteService.post('chc/process', { points: input, minArea: this.MIN_AREA, session: SessionManager.activeSession })
                .then(
                    res => {
                        res.json().then((json: any) => {
                            resolve(json);
                        });
                    }
                )
        });

    }

}

export const GeoLocationService = new GeoLocationServiceImp();

/*
const CATERGORY_GEO_LOCATION: EntityCategory = {
    label: 'Geo Location',
    description: 'The Geo-Location Entity',
    id: 'uno_geo_location',
}

const PROP_DEVICE_ID: EntityProp = {
    id: 'device_id',
    label: 'Device ID',
    dataType: EntityConstants.PropType.DEFAULT,
    validators: [],
};

const PROP_TIMESTAMP: EntityProp = {
    id: 'timestamp',
    label: 'Time Stamp',
    dataType: EntityConstants.PropType.DATETIME,
    validators: [],
};

const PROP_LATITUDE: EntityProp = {
    id: 'latitude',
    label: 'Latitude',
    dataType: EntityConstants.PropType.NUMBER,
    validators: [],
};

const PROP_LONGITUDE: EntityProp = {
    id: 'longitude',
    label: 'Longitude',
    dataType: EntityConstants.PropType.NUMBER,
    validators: [],
};

const PROP_ELEVATION: EntityProp = {
    id: 'elevation',
    label: 'Elevation',
    dataType: EntityConstants.PropType.NUMBER,
    validators: [],
};

export const GeoLocationConstants = {
    Props: {
        DEVICE_ID: PROP_DEVICE_ID,
        TIMESTAMP: PROP_TIMESTAMP,
        LATITUDE: PROP_LATITUDE,
        LONGITUDE: PROP_LONGITUDE,
        ELEVATION: PROP_ELEVATION,
    },
    Category: CATERGORY_GEO_LOCATION,

    getEntityDef: (): any => {
        return GeoLocationEntity;
    },

};

const GeoLocationComps: EntityComps = getBaseEntityComps();
GeoLocationComps.SEARCH = GeoLocationSearch;

export class GeoLocationEntity extends BaseEntityConfig {

    constructor(appConfig?: any) {
        super(
            GeoLocationConstants.Category,
            [
                GeoLocationConstants.Props.DEVICE_ID,
                GeoLocationConstants.Props.TIMESTAMP,
                GeoLocationConstants.Props.LATITUDE,
                GeoLocationConstants.Props.LONGITUDE,
                GeoLocationConstants.Props.ELEVATION,
            ],
            GeoLocationComps,
            appConfig,
        );

        this.setPropConfig(
            { id: EntityConstants.Attr.NAME, noNew: true, noEdit: true, noQV: true, noSearch: true, }
        );
        this.setPropConfig(
            { id: EntityConstants.Attr.DESCRIPTION, noNew: true, noEdit: true, noQV: true, noSearch: true, }
        );

    }

}
*/