import { FilterConstants as FC } from '../../@uno/api';
import { Common } from '../../@uno/api/common.service';
import { BaseEntity, EntityCategory, EntityConstants, } from '../../@uno/api/entity.service';
import { EM, RemoteService } from '../../@uno/core';
import { AppInfoService } from './app.info.service';
import { EntityCategoryService } from './entity.category.service';
import { SessionManager } from './session.service';

class AppScreenServiceImpl {
    UNKNOWN = 'UNKNOWN';
    appScreens: any = {};
    screenCache: any = {};

    constructor() {
        EM.register(Common.Event.APP_SWITCHED, (app: any) => {
            if (app?.id) {
                this.buildCache(app.id);
            }
        });
    }

    async buildCache(appID: string) {
        const filters = [
            FC.setCategory(Common.CategoryID.ScreenDef),
        ];
        const screens = await FC.find(filters, { limit: Number.MAX_SAFE_INTEGER }, appID);

        for (let i = 0; i < screens.length; i++) {
            const screen = screens[i];
            // console.log('Adding to Screen Cache: ', appID, (i + 1), screen._id);
            this.addAppScreen(appID, screen._id, Common.safeParse(screen.layout));
        }
    }

    async getActionScreen(appID: any, categoryID: string, action: string, entity?: any, useCache: boolean = true) {
        // console.log(`Find screen for app - ${appID}, cat - ${categoryID}, action - ${action} `)
        let layout: any = undefined;
        if (useCache) {
            // layout = await this.getScreen(appID, categoryID, action);
            let screenID = await this.getScreenID(categoryID, appID, action, entity);
            if (screenID) {
                if (this.UNKNOWN === screenID) {
                    console.log(`Unknown screen for app - ${appID}, cat - ${categoryID}, action - ${action} `, entity);
                    return undefined;
                } else {
                    layout = await this.getScreenByID(screenID, appID);
                }
            }
        }

        if (!layout) { // get from remote server...
            let screenID: any = undefined;
            let screenDef: any = undefined;

            const session = SessionManager.activeSession;
            const person = session?.person;
            const inputs = {
                appID: appID,
                categoryId: categoryID,
                action: action,
                entity: entity,
                session: session,
                person: person,
            };

            /*
            if (categoryID === 'uno_product') {
                console.log(`Screen not found in cache. Get from remote.`, inputs);
            }
            */
            const screen = await RemoteService.getData(await RemoteService.post('screen/find', inputs));
            if (screen) {// add to cache.
                screenID = screen.id;
                screenDef = screen.def;
                // console.log(`Screen Found on remote - ${screenID}`);
                layout = screen.layout;
                // add to appScreens
                if (Common.checkType.String(layout)) {
                    layout = Common.safeParse(layout);
                }
                // add to appScreens cache
                this.addAppScreen(appID, screenID, layout, screenDef)
            }

            // add to appCatActionScreen
            this.addAppCatActionScreen(
                screenID,
                appID,
                categoryID,
                action,
                screenDef ? screenDef['for-entity'] : undefined,
                screenDef ? screenDef['for-person'] : undefined,
            );
        } else {
        }
        return layout;
    }

    addAppCatActionScreen = (screenID: any, appID: any, categoryID: string, action: string, entity?: any, person?: any) => {
        // Add to screenCache
        const cacheID = this.getCacheID(appID, categoryID, action, entity, person);
        this.screenCache[cacheID] = screenID;
    }

    getScreenByID = async (id: string, appID: any = AppInfoService.getActiveApp()?.id) => {
        // first use cache.
        let layout: any = undefined;
        let screens = this.appScreens[appID];
        if (screens) {
            layout = screens[id]?.layout;
        }
        if (!layout) { // not found in cache. get from remote server
            // console.log(id, ` Screen layout not found in local cache. Get from remote`)
            layout = await RemoteService.getData(await RemoteService.post('screen/find/id', { appID: appID, id: id, session: SessionManager.activeSession }));
            // add to cache
            if (Common.checkType.String(layout)) {
                layout = Common.safeParse(layout);
            }
            this.addAppScreen(appID, id, layout);
        }
        return layout ? { ...layout } : layout;
    }

    addAppScreen = (appID: any, screenID: any, layout: any, def?: any) => {
        if (layout) {
            let screens = this.appScreens[appID];
            if (!screens) {
                screens = {};
                this.appScreens[appID] = screens;
            }
            screens[screenID] = { layout: layout, def: def };
        }
    }

    getScreen = async (appID: any, categoryID: any, action: any) => {
        let layout: any = undefined;
        let screenID = await this.getScreenID(categoryID, appID, action);

        if (screenID) {
            layout = await this.getScreenByID(screenID, appID);
        }
        return layout;
    }

    getScreenID = async (categoryID: any, appID: any, action: any, entity?: any, person?: any) => {
        let screenID = undefined;
        // from the category definition
        const category: EntityCategory = await EntityCategoryService.getCategory(categoryID, true, appID);
        if (category && category.screens) {
            let screenDef: BaseEntity = category.screens[action];
            if (screenDef) {
                screenDef = EntityConstants.build(screenDef);
                screenID = screenDef.getID();
                // console.log(`Screen ID from category definition - `, screenID);
            }
        }


        // from local cache
        if (!screenID) {
            // find exact match
            screenID = this.screenCache[this.getCacheID(appID, categoryID, action, entity, person)];
            // if not, upto entity
            if (!screenID || screenID === this.UNKNOWN) {
                screenID = this.screenCache[this.getCacheID(appID, categoryID, action, entity)];
            }
            // if not, upto action
            if (!screenID || screenID === this.UNKNOWN) {
                screenID = this.screenCache[this.getCacheID(appID, categoryID, action)];
            }
        }
        return screenID;
    }

    getCacheID(appID: any, categoryID: string, action: string, entity?: any, person?: any,) {
        entity = Common.safeParse(entity);
        const entityID = entity ? entity._id : undefined;
        person = Common.safeParse(person);
        const personID = person ? person._id : undefined;

        const cacheID = `${appID}${categoryID ? '-' + categoryID : ''}${action ? '-' + action : ''}${entityID ? '-' + entityID : ''}${personID ? '-' + personID : ''}`;
        return cacheID;
    }

    getHomeScreen(appID: any) {
        return new Promise<any>((resolve, reject) => {
            RemoteService.post('screen/home',
                { appID: appID, session: SessionManager.activeSession }
            ).then(json => {
                json.json().then((layout: any) => {
                    // console.log('Screen Layout : ', layout);
                    resolve(layout);
                }).catch((e: any) => {
                    resolve(undefined);
                });
            });
        });

    }

    async getAppLayout(appID: any) {
        const layout = await RemoteService.getData(await RemoteService.post('screen/layout', { appID: appID, session: SessionManager.activeSession }));
        console.log(`${appID} - App Layout found - `, (layout !== null));
        return layout;
    }

    async findLayout(entityScreen: any, appID: string, categoryID: any, action: string, entity: any) {
        let layout: any = undefined;
        if (!entityScreen) {
            const category = await EntityCategoryService.getCategory(categoryID, true, appID);
            const catScreens = category?.screens;
            entityScreen = catScreens ? catScreens[action] : undefined;
        }

        // this.profiler.log('Loading Layout: ', appID, categoryID, action, entity, entityScreen);
        if (entityScreen) {
            entityScreen = EntityConstants.build(entityScreen);
            layout = await AppScreenService.getScreenByID(entityScreen.getID(), entityScreen.getAppID());
        } else if (categoryID) {
            // this.profiler.log(`Finding Layout for category: ${this.getCategoryID()} and Action: ${this.getAction()}`);
            layout = await AppScreenService.getActionScreen(appID, categoryID, action, entity, true);
        }
        return layout;
    }
}

export const AppScreenService = new AppScreenServiceImpl();