import React from 'react';
import { Link } from 'react-router-dom';
import { EntityConstants } from '../../@uno/api';
import { DesignerConstants, RemoteService, UC, UnoComponent } from '../../@uno/core';
import { AppInfo } from '../service/app.info.service';
import { UnoAppBaseComp } from './uno-app.base.comp';

@UnoComponent({
    id: 'Header',
    label: 'App Header',
    props: [
        { groupID: 'General', id: 'app', label: 'App Info', dataType: EntityConstants.PropType.JSON },
        // { groupID: 'General', id: 'no_title_change', label: 'Do not change App Title', dataType: EntityConstants.PropType.BOOLEAN },
    ],
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class Header extends UnoAppBaseComp {

    buildComp() {
        const app: AppInfo = this.getAppInfo();
        // console.log(`Init Header: `, app);

        if (!app) {
            return <UC.Empty />;
        }

        let logo = undefined;
        if (app.logo) {
            const isImageData = app.logo && app.logo.startsWith('data:image');
            const src = isImageData ? app.logo : RemoteService.getFullURL(app.logo);
            logo = <img src={src} alt='App Logo' className='app-logo' />
            if (!isImageData && app.logo) {
                if (this.state.no_title_change !== true) {
                    // Common.AppSettings.setIcon(src);
                }
            }
        } else {
            // console.log(`App Logo not found`);
        }

        if (this.state.no_title_change !== true) {
            // Common.AppSettings.setTitle(this.getTitle(app));
        }

        let title = undefined;
        if (app.title) {
            const titleText = { __html: this.getTitle(app) }
            title = <div className='app-title' dangerouslySetInnerHTML={titleText}></div>
        }

        let subtitle = undefined;
        if (app.subtitle) {
            const subtitleText = { __html: app.subtitle }
            subtitle = <span className='app-subtitle' dangerouslySetInnerHTML={subtitleText}></span>
        }

        const appRoute = `/${app.id}`;
        return (
            <Link to={appRoute}>
                <div className='header row'>
                    {logo}
                    <div style={{ display: 'inline-block', position: 'relative', width: 'auto' }} className='app-logo'>
                        <div style={{ margin: 'auto', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                            {title}
                            {subtitle}
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

    getTitle(app: AppInfo): string {
        return (app.title) ? app.title : (app.id) ? app.id : 'Uno Platform';
    }

}
