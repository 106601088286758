import React from 'react';
import { withRouter } from 'react-router-dom';
import { EntityConstants } from '../../../api';
import { Common, Images, EM, UnoComponent, } from '../../../core';
import { UnoCoreBaseComp } from './../uno-core.base.comp';

/*
props:
{ title?: string, classes?: string, callback?: Function, toggleOn?: string, collapsed?: boolean }
*/
@UnoComponent(
    {
        id: 'Toggler',
        paletteable: true,
        props: [
            { groupID: 'General', id: 'title', label: 'Title', },
            { groupID: 'General', id: 'isCollapsed', label: 'Is Collapsed', dataType: EntityConstants.PropType.BOOLEAN },
            { groupID: 'General', id: 'collapseOnRouteChange', label: 'Collapse on Route Change', dataType: EntityConstants.PropType.BOOLEAN },
            { groupID: 'Event', id: 'toggleOn', label: 'Toggle On Event' },
            { groupID: 'Event', id: 'collapseOn', label: 'Collapse On Event' },
            { groupID: 'Event', id: 'expandOn', label: 'Expand On Event' },
        ]
    }
)
class Toggler extends UnoCoreBaseComp {
    private key = Common.getUniqueKey('toggler_');
    private clsToggle = 'tool-icon';
    private oldClasses: Array<any> = [];

    private _doToggleEventHandler: any = undefined;
    private _historyUnlisten: any = undefined;

    componentDidMount() {
        super.componentDidMount();

        if (this.state.toggleOn) {
            this._doToggleEventHandler = EM.register(this.state.toggleOn, this.doToggle);
        }

        if (this.state.collapseOn) {
            this._doToggleEventHandler = EM.register(this.state.toggleOn, () => { this.doToggle(true); });
        }

        if (this.state.expandOn) {
            this._doToggleEventHandler = EM.register(this.state.expandOn, () => { this.doToggle(false); });
        }

        this._historyUnlisten = EM.register(Common.Event.ROUTE_CHANGED, (location: string) => {
            const toCollapse = Boolean(this.state.collapseOnRouteChange);
            if (toCollapse) {
                // console.log('Collapse on route change -', location,);
                this.doToggle(true);
            }
        });

        if (this.isCollapsed()) {
            // this.doToggle(true);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        EM.unregister(this._doToggleEventHandler);
        EM.unregister(this._historyUnlisten);
    }

    buildComp() {
        const menuIcon = (this.isCollapsed()) ? Images.Icon.hMenu : Images.Icon.vMenu;

        return (
            <>
                <img
                    alt='|||'
                    src={menuIcon}
                    className={`${this.clsToggle} ${this.getStyleClasses()}`}
                    title={(this.props.title) ? this.props.title : 'Click to Toggle'}
                    onClick={(evt) => {
                        this.doToggle(!this.isCollapsed(),);
                    }}
                    id={this.key}
                />
            </>
        );
    }

    doToggle = (collapse?: boolean,) => {
        const tgt = document.getElementById(this.key);

        if (!tgt || tgt === null) {
            console.log(`Toggler element not found`);
            return;
        }

        const parent = tgt.parentElement;
        if (parent) {
            if (collapse === undefined) {
                collapse = !this.isCollapsed();
            }

            if (collapse) {
                // collapse
                this.doCollapse(parent);
            } else {
                // expand
                this.doExpand(parent);
            }

            if (this.props.callback) {
                // console.log('Is collapsed?  - ', this.isCollapsed);
                this.props.callback(this.isCollapsed);
            }
        } else {
            console.log('Parent of Toggler not found');
        }
    }

    private doCollapse(parentNode: any,) {
        if (!parentNode || this.isCollapsed()) {
            return;
        }
        const pClassList = parentNode?.classList;
        // console.log('To Collapse: ', parentNode, [...pClassList], [...this.oldClasses],);

        this.oldClasses = [...pClassList];

        // console.log(`Retaining Old Styles and Classes: `, this.oldStyles, pNodeStyle, this.oldClasses);
        while (pClassList.length > 0) {
            pClassList.remove(pClassList.item(0));
        }

        pClassList.add(this.clsToggle);

        this.reRender({ isCollapsed: true });
    }

    private doExpand(parentNode: any,) {
        if (!parentNode && !this.isCollapsed()) {
            return;
        }

        const pClassList = parentNode?.classList;
        // console.log('To Expand: ', parentNode, [...pClassList], [...this.oldClasses],);
        while (pClassList.length > 0) {
            pClassList.remove(pClassList.item(0));
        }

        this.oldClasses.forEach(oc => {
            pClassList.add(oc);
        });

        this.reRender({ isCollapsed: false });
    }

    private isCollapsed() {
        const isCollapsed = this.state.isCollapsed;
        // console.log('Is Collapsed: ', isCollapsed);
        return (isCollapsed !== undefined) ? isCollapsed : false;
    }
}

export default withRouter(Toggler);