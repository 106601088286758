import React from 'react';
import { UnoCoreBaseComp } from '../../@uno/core/components/uno-core.base.comp';
import { AppMenuService } from '../service/app.menu.service';
import { Common, EntityConstants } from '../../@uno/api';
import { UC, UnoComponent } from '../../@uno/core';
import { SessionManager } from '../service/session.service';

@UnoComponent({
    id: 'AccessControlledActions',
    label: 'Access Controlled Actions',
    paletteable: true,
    props: [
        { id: 'actions', label: 'Actions Definition', dataType: EntityConstants.PropType.JSON },
        { id: 'horizontal', label: 'Is Horizontal Layout?', dataType: EntityConstants.PropType.BOOLEAN },
        { id: 'isToolbar', label: 'Is a Toolbar', dataType: EntityConstants.PropType.BOOLEAN },
    ],

})

export class AccessControlledActions extends UnoCoreBaseComp {
    // actions: Array<any> = [], horizontal: boolean = false, isToolbar: boolean = false
    private accessPermissionChecked = false;

    componentDidMount(): void {
        super.componentDidMount();
        this.setVisibility();
    }

    buildComp() {
        if (!this.accessPermissionChecked) {
            return undefined;
        }
        const actions = this.getActions();
        const isHorizontal = this.state.horizontal;
        this.profiler.log('Building AC-Actions: ', actions, isHorizontal);

        let actionComp = null;
        if (isHorizontal) {
            actionComp = (<UC.Navigation navs={actions} orientation='h' isToolbar={this.state.isToolbar} key={Common.getUniqueKey('Actions__')} />)
        } else {
            actionComp = (<UC.SelectBox options={actions} key={Common.getUniqueKey('Actions__')} />);
        }
        return (
            <>
                {actionComp}
            </>
        );
    }

    getActions = () => {
        return Common.safeParse(this.state.actions);
    }

    // process nav actions for access permission
    setVisibility = async (actions = this.getActions()) => {
        if (!actions || actions.length === 0) {
            return;
        }
        const accessControlledActions = await AppMenuService.setVisibility(actions, SessionManager.activeSession);
        const restoredActions = await this.restoreAction(accessControlledActions, actions);
        this.profiler.log('Access Control Checked: ', actions, accessControlledActions, restoredActions);
        this.accessPermissionChecked = true;
        this.reRender({ actions: restoredActions });
    }

    restoreAction = async (nav: any, original: any) => {
        if (Array.isArray(nav) && Array.isArray(original)) {
            for (let i = 0; i < nav.length; i++) {
                const na = nav[i];
                const oa = original[i];
                await this.restoreAction(na, oa);
            }
        } else if (nav && original) {
            nav.action = original.action;
            await this.restoreAction(nav.children, original.children);
        }
        return nav;
    }


}