import { UC, UnoComponent } from '../../../@uno/core';
import { PropEntityEditor } from './prop-entity-editor.comp';

@UnoComponent({ id: 'PropEntitySelector' })
export class PropEntitySelector extends PropEntityEditor {

    getEditor () {
        return UC.EntitySelector;
    }

    getViewer() {
        return UC.EntityQuickView;
    }
}
