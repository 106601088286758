import React from 'react';
import { Common, DesignerConstants, UC, UnoComponent } from '../../core';
import { EntityConstants, Images } from '../../api';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { Source } from '../../api/source.service';

@UnoComponent({
    id: 'Chip',
    label: 'Chip',
    props: [
        { groupID: 'Events', id: 'onClose', label: 'onClose', dataType: EntityConstants.PropType.FUNCTION },
    ],
    paletteable: true,
    isContainer: true,
    group: DesignerConstants.PaletteGroup.Frequent.id,
})
export class Chip extends UnoCoreBaseComp {

    buildComp() {
        if (this.state.closed) {
            return <UC.Empty />;
        }

        let onClose: any = undefined;
        if (this.state.onClose) {
            onClose = async (e: any) => {
                const inputs = { theComp: this, target: e.target, evt: e };
                const action = this.state.onClose;
                if (Common.checkType.Function(action)) {
                    return await action(inputs);
                } else if (Common.checkType.String(action)) {
                    const fn = Source.getFunction(action);
                    if (fn) {
                        return await fn(inputs);
                    }
                }
            }
        }

        return (
            <div style={{ ...this.getStyles(), }} className={` chip ${this.getStyleClasses()} `}>
                { /* Close button */
                    (onClose && Common.checkType.Function(onClose)) ?
                        (
                            <div className=' float-right' style={{ padding: '0px 5px' }}>
                                <img
                                    src={Images.Icon.close}
                                    alt='X'
                                    className='tool-icon'
                                    onClick={async (evt) => { const result = await onClose(evt); this.reRender({ closed: (result !== false) }) }}
                                />
                            </div>
                        ) : undefined
                }
                <div style={{ overflow: 'auto' }}>
                    {this.props.children}
                </div>
            </div>
        );
    }

}
