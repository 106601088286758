
// The Module...

import { UnoModule } from '../@uno/core';
import { UnoPluginConfigEdit } from './plugin-config/components/plugin-config-edit.comp';
import { UnoPluginConfigView } from './plugin-config/components/plugin-config-view.comp';
import { UnoPluginStatusMain } from './plugin-config/components/plugin-status-main.comp';

@UnoModule(
    {
        id: 'UnoPluginModule',
        imports: [
        ],
        components: [
            UnoPluginConfigView,
            UnoPluginConfigEdit,
            UnoPluginStatusMain,
        ],
    }
)
export class UnoPluginModule { }


