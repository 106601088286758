import React from 'react';
import { Common, EntityConstants } from '../../api';
import { UnoComponent } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { Source } from '../../api/source.service';

@UnoComponent({
    id: 'PhotoCapture',
    label: 'Photo Capture',
    props: [
        {
            id: 'label',
            label: 'Label',
        },
        {
            id: 'quality',
            label: 'Quality Factor',
            description: 'Between 1 and 100 (%)',
            dataType: EntityConstants.PropType.NUMBER,
        },
        {
            id: 'action',
            label: 'onCapturePhoto Handler',
            dataType: EntityConstants.PropType.FUNCTION,
        },
    ],
    paletteable: true,
})
export class PhotoCaptureComp extends UnoCoreBaseComp {
    width = Math.min(window.screen.availWidth, window.screen.availHeight); // We will scale the photo width to this
    height = 0; // This will be computed based on the input stream

    streaming = false;
    stream: any;

    video: any = undefined;
    canvas: any = undefined;
    photo: any = undefined;
    startbutton: any = undefined;

    componentDidMount(): void {
        super.componentDidMount();
        this.startup();
    }

    componentWillUnmount(): void {
        this.stop();
        super.componentWillUnmount();
    }

    buildComp() {
        return (
            <div className='contentarea'>
                <div className='camera'>
                    <video id='video' autoPlay={true}>Video stream not available.</video>
                </div>
                <div><button id='startbutton' style={Common.safeParse(this.state.styles)}>{this.getLabel()}</button></div>
                <canvas id='canvas'></canvas>
                <div className='output'>
                    <img id='photo' alt='' />
                </div>
            </div>
        );
    }

    getLabel() {
        return (this.state.label ? this.state.label : 'Capture Photo');
    }

    getAction() {
        return Common.safeParse(this.props.action);
    }

    async startup() {
        const action = this.getAction();
        this.video = document.getElementById('video');
        this.startbutton = document.getElementById('startbutton');

        this.canvas = document.getElementById('canvas');
        this.photo = document.getElementById('photo');

        if (action) {
            if (this.canvas) {
                this.canvas.style.display = 'none';
            }
            if (this.photo) {
                this.photo.style.display = 'none';
            }
        }

        this.getCameraAccess()
            .then((stream) => {
                this.stream = stream;
                this.video.srcObject = this.stream;
                this.video.play();
            })
            .catch((err) => {
                console.log("An error occurred: " + err);
            });

        this.video.addEventListener('canplay', (ev: any) => {
            if (!this.streaming) {
                this.height = this.video.videoHeight / (this.video.videoWidth / this.width);

                if (isNaN(this.height)) {
                    this.height = this.width / (4 / 3);
                }

                this.video.setAttribute('width', this.width);
                this.video.setAttribute('height', this.height);
                this.canvas.setAttribute('width', this.width);
                this.canvas.setAttribute('height', this.height);
                this.streaming = true;
            }
        }, false);

        this.startbutton.addEventListener('click', (ev: any) => {
            this.takepicture();
            ev.preventDefault();
        }, false);

        this.clearphoto();
    }

    getCameraAccess() {
        return navigator.mediaDevices.getUserMedia(
            {
                video: true,
                audio: false
            }
        );
    }

    clearphoto() {
        const context = this.canvas.getContext('2d');
        context.fillStyle = "#AAA";
        context.fillRect(0, 0, this.canvas.width, this.canvas.height);

        const data = this.canvas.toDataURL('image/png');
        this.photo.setAttribute('src', data);
    }

    takepicture() {
        const context = this.canvas.getContext('2d');
        if (this.width && this.height) {
            this.canvas.width = this.width;
            this.canvas.height = this.height;
            context.drawImage(this.video, 0, 0, this.width, this.height);
            let quality = this.state.quality ? Number.parseInt(this.state.quality) : 100;

            const data = this.canvas.toDataURL('image/jpeg', quality / 100);
            // const data100 = this.canvas.toDataURL('image/jpeg', 1);
            // console.log('Image Data size:', data.length, data100.length);

            this.photo.setAttribute('src', data);

            let action = this.getAction();
            if (action) {
                const inputs = { theComp: this, data: data };
                // console.log('Function: ', Common.checkType.Function(action), ' String: ', Common.checkType.String(action));
                if (Common.checkType.Function(action)) {
                    action(inputs);
                } else if (Common.checkType.String(action)) {
                    const fn = Source.getFunction(action);
                    if (fn) {
                        fn(inputs);
                    }
                }
            }
        } else {
            this.clearphoto();
        }
    }

    async stop() {
        // console.log('Stopping Camera');
        if (this.video) {
            this.video.pause();
            this.video.src = undefined;
        }
        this.stopStream(this.stream);
    }

    stopStream = async (stream: MediaStream) => {
        stream?.getTracks().forEach(t => {
            t.enabled = false;
            t.stop();
            // console.log('Stopped Media track: ', t);
        });
    }

}