import React, { CSSProperties } from 'react';
import { Common } from '../../@uno/api';
import { UC, UnoComponent } from '../../@uno/core';
import { ClosedArea, GeoGrid, GeoLocation } from '../service/geo-location.service';

const getUniqueKey = Common.getUniqueKey;

interface CanvasProps {
    navigatedArea: ClosedArea;
    closedAreas?: Array<ClosedArea>;
}

@UnoComponent({id:'PolyCanvas'})
export class PolyCanvas extends React.Component<CanvasProps, any>{
    private CANVAS_LENGTH = 400; // (window && window.screen) ? (0.9 * window.screen.width) : 600;
    private border = 20;

    private navigatedArea: ClosedArea;
    private closedAreas: Array<ClosedArea> = [];

    private showClosedAreas = true;
    private showPath = true;
    private showGrids = false;

    private _svgContainerID = `_svgContainer_${getUniqueKey()}`;
    private _svgID = `_svg_${getUniqueKey()}`;

    constructor(props: any) {
        super(props);
        // extract props.
        this.navigatedArea = this.props.navigatedArea;

        if (this.props.closedAreas !== undefined) {
            this.closedAreas = this.props.closedAreas
        }
        console.log(`Poly Canvas Initialized with ${this.navigatedArea.points.length} points.`);
    }

    render() {
        return (
            <>
                {this.buildNav()}
                {this.buildCanvas()}
            </>
        );
    }

    buildCanvas() {
        const style: CSSProperties = {};
        style.border = 'none'; //'1px solid #000000';
        style.position = 'relative';
        style.left = '5%';
        // style.width = this.CANVAS_LENGTH + 'px';
        // style.height = this.CANVAS_LENGTH + 'px';
        style.overflow = 'auto';

        // drag handling
        let startX: number, startY: number = -1;

        const handleDragStart = (evt: any) => {
            startX = evt.clientX;
            startY = evt.clientY;
            // console.log(`Drag Started from : (${startX}, ${startY})`);
        }

        const handleDragEnd = (evt: any) => {
            const endX = evt.clientX;
            const endY = evt.clientY;

            const diffX = startX - endX;
            const diffY = startY - endY;

            // console.log(`Drag Ends at  : (${endX}, ${endY}). Difference: (${diffX}, ${diffY}).`);

            const draggable = evt.target;
            if (draggable) {
                const container = draggable.parentElement;
                const containerWidth = container.offsetWidth;
                const containerHeight = container.offsetHeight;

                // vertical scroll
                if (this.CANVAS_LENGTH > containerHeight) {
                    container.scrollTop = container.scrollTop + diffY;
                }

                // horizontal scroll
                if (this.CANVAS_LENGTH > containerWidth) {
                    container.scrollLeft = container.scrollLeft + diffX;
                }
            }
        }

        //  viewBox={`0 0 ${this.CANVAS_LENGTH -this.border} ${this.CANVAS_LENGTH - this.border}`}

        return (
            <div style={{ overflow: 'auto', width: '100%', height: '600px' }}>
                <div
                    style={{ width: '100%', height: '100%' }}
                    draggable={true} onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    id={this._svgContainerID}
                >
                    <svg
                        style={style}
                        viewBox={`0 0 ${this.CANVAS_LENGTH + this.border} ${this.CANVAS_LENGTH + this.border}`}
                        id={this._svgID}
                    >
                        {/* this.drawAxes() */}
                        {/* this.drawBoundary() */}
                        {this.drawGrids()}
                        {this.drawPath()}
                        {this.drawClosedLoops()}
                    </svg>
                </div>
            </div>
        );
    }

    buildNav() {
        const getLabelEnclosedAreas = () => `${this.showClosedAreas ? 'Hide ' : 'Show '} Enclosed Areas`;
        const getLabelPath = () => `${this.showPath ? 'Hide ' : 'Show '} Path Indices`;
        // const getLabelGrids = () => `${this.withDensityAnalysis ? 'Without ' : 'With '} Density Analysis`;

        const actions: Array<any> = [
            {
                id: 'Zoom-In',
                label: '+ (Zoom-In)',
                action: (evt: any, nav: any) => {
                    this.doZoom(true);
                }
            },
            {
                id: 'Zoom-Out',
                label: '- (Zoom Out)',
                action: (evt: any, nav: any) => {
                    this.doZoom(false);
                }
            },

            {
                id: getLabelPath(),
                action: (evt: any, nav: any) => {
                    this.showPath = !this.showPath;
                    nav.label = getLabelPath();
                    this.setState({});
                }
            },
            {
                id: getLabelEnclosedAreas(),
                action: (evt: any, nav: any) => {
                    this.showClosedAreas = !this.showClosedAreas;
                    nav.label = getLabelEnclosedAreas();
                    this.setState({});
                }
            },
        ];
        return (
            <UC.Navigation navs={actions} orientation='h' />
        );
    }

    doZoom(increase: boolean = true) {
        const zoomFactor = 1.2;
        if (increase) {
            this.CANVAS_LENGTH = this.CANVAS_LENGTH * zoomFactor;
        } else {
            this.CANVAS_LENGTH = this.CANVAS_LENGTH / zoomFactor;
        }
        const svgContainer = document.getElementById(this._svgContainerID);
        if (svgContainer) {
            svgContainer.style.width = `${this.CANVAS_LENGTH}px`;
            svgContainer.style.height = `${this.CANVAS_LENGTH}px`;
            // const svg = document.getElementById(this._svgID);
            //svg?.setAttribute('viewBox', `0 0 ${this.CANVAS_LENGTH - this.border} ${this.CANVAS_LENGTH - this.border}`);
        } else {
            this.setState({});
        }
    }

    drawAxes() {
        const style: CSSProperties = {};
        style.stroke = 'blue';
        style.strokeWidth = '1';
        style.strokeDasharray = '5,5';
        const axes = [];

        axes.push(<line
            x1='0'
            y1={this.CANVAS_LENGTH / 2}
            x2={this.CANVAS_LENGTH}
            y2={this.CANVAS_LENGTH / 2}
            style={style}
            key={getUniqueKey()}
        />);
        axes.push(<line
            x1={this.CANVAS_LENGTH / 2}
            y1='0'
            x2={this.CANVAS_LENGTH / 2}
            y2={this.CANVAS_LENGTH}
            style={style}
            key={getUniqueKey()}
        />);

        return axes;
    }

    getCanvasCords(loc: GeoLocation) {
        const canvasLength = this.CANVAS_LENGTH;

        if (!this.navigatedArea.edgePoints) {
            return { xCord: Number.MIN_VALUE, yCord: Number.MIN_VALUE };
        }

        const { left, top, right, bottom, leftTop } = this.navigatedArea.edgePoints;

        const maxWidth = Math.abs(right.long - left.long);
        const maxHeight = Math.abs(top.lat - bottom.lat);

        let scaleFactor = Math.max(maxWidth, maxHeight);
        if (scaleFactor > 0) {
            scaleFactor = (canvasLength - this.border * 2) / scaleFactor;
        } else {
            console.log('Error in scale factor: ', scaleFactor);
        }
        // console.log(`Zero point: ${JSON.stringify(leftTop)}, , Scale Factor: ${scaleFactor}`);

        const xCord = (loc.long - leftTop.long) * scaleFactor + this.border;
        const yCord = (leftTop.lat - loc.lat) * scaleFactor + this.border;

        if (isNaN(xCord) || isNaN(yCord)) {
            console.log(`Abnormal ${loc} == xCord: ${xCord}, yCord: ${yCord}`)
        }
        const cord = { xCord: xCord, yCord: yCord };

        // console.log(` Geo: ${JSON.stringify(loc)}, Canvas Cordinates: ${JSON.stringify(cord)}`);

        return cord;
    }

    drawPath() {
        const points = this.drawPoints(this.navigatedArea.points, undefined, 3, this.showPath);
        return points;
    }

    drawPolygon(
        geoPoints: Array<GeoLocation>,
        lineStyle: CSSProperties = { fill: 'none', stroke: 'black', strokeWidth: '3' },
        pointStyle: CSSProperties = { fill: 'red' },
    ) {
        const drawings = [];

        let points = '';
        let aPoint: { xCord: number, yCord: number } = { xCord: 0, yCord: 0 };

        for (let index = 0; index < geoPoints.length; index++) {
            aPoint = this.getCanvasCords(geoPoints[index]);
            points = `${points} ${aPoint.xCord}, ${aPoint.yCord}`;
        }

        const onMouseOver = (evt: any) => {
            if (evt && evt.target) {
                const wid = evt.target;
                //console.log('Over:', wid, wid.style);
                wid.style.fill = 'yellow';
            }
        }

        const onMouseOut = (evt: any) => {
            if (evt && evt.target) {
                const wid = evt.target;
                wid.style = lineStyle;
            }
        }

        drawings.push(
            <polygon
                points={points}
                style={lineStyle}
                key={getUniqueKey()}
            // onMouseOver={onMouseOver}
            // onMouseOut={onMouseOut}
            />
        );

        return drawings;
    }

    drawPoints(geoPoints: Array<GeoLocation>,
        pointStyle: CSSProperties = { fill: 'blue', },
        radius: number = 3,
        showIndex: boolean = false,
    ) {
        const drawings = [];

        for (let index = 0; index < geoPoints.length; index++) {
            const loc = geoPoints[index];
            const { xCord, yCord } = this.getCanvasCords(loc);
            drawings.push(<circle cx={xCord} cy={yCord} r={radius} style={pointStyle} key={getUniqueKey()} />);
            drawings.push(<text x={xCord} y={yCord} key={getUniqueKey()} visibility={showIndex ? 'visible' : 'hidden'}>{loc.seqId}</text>);
        }
        return drawings;
    }

    drawLabel(label: string, geoPoint?: GeoLocation, style?: any) {
        let pivot: { xCord: number, yCord: number } = { xCord: 0, yCord: 0 };
        if (geoPoint) {
            pivot = this.getCanvasCords(geoPoint);
        }
        return (<text x={pivot.xCord} y={pivot.yCord} textAnchor='start'>{label}</text>)
    }

    drawBoundary(points: Array<GeoLocation>, label?: string) {
        const comps: Array<any> = [];
        // console.log('Loop Points: ', loopPoints);
        comps.push(this.drawPolygon(points, { fill: 'gray', stroke: 'red', strokeWidth: '2' }, {},));
        comps.push(this.drawPoints(points, { fill: 'red', stroke: 'gray', strokeWidth: '1' }, 3));
        if (label) {
            comps.push(this.drawLabel(label, points[0]))
        }
        return comps;

    }

    drawClosedLoops() {
        if (!this.showClosedAreas) {
            return (<></>);
        }

        const loops = this.closedAreas.map(
            (cArea: ClosedArea, index: number) => {
                // console.log('Loop Points: ', loopPoints);
                const polyline = this.drawPolygon(cArea.points, { fill: 'none', stroke: 'red', strokeWidth: '2' }, {},);
                const points = this.drawPoints(cArea.points, { fill: 'red', stroke: 'gray', strokeWidth: '1' }, 3);
                const label = this.drawLabel(`Area ${index + 1}`, cArea.points[0]);
                return [polyline, points, label];
            }
        );
        return loops;
    }

    drawGrids() {
        if (!this.showGrids) {
            return (<></>);
        }

        const gridGraphs =
            this.navigatedArea.grids.map(
                (grid: GeoGrid, idx: number) => {
                    const fillColor = (idx % 2 === 0) ? 'gray' : 'white';
                    // console.log('Loop Points: ', loopPoints);
                    const polyline = this.drawPolygon(
                        [grid.leftTop, grid.rightTop, grid.rightBottom, grid.leftBottom],
                        { fill: fillColor, stroke: 'black', strokeWidth: '2' }
                    );
                    return polyline;
                }
            );
        return gridGraphs;
    }


}