import { UnoModule } from '../../@uno/core';
import { OpenViduConf } from './component/ov-conf.comp';
// The Module...

@UnoModule(
    {
        id: 'OpenViduModule',
        imports: [
        ],
        components: [
            OpenViduConf,
        ],
    }
)
export default class OpenViduModule {

}

