import React from 'react';
import { Common, EntityConstants, EntityProp } from '../../../@uno/api';
import { UC, UnoComponent } from '../../../@uno/core';
import { EntityViewTypes } from '../../service/entity-prop.service';
import { BasePropViewer } from '../prop-base.comp';

@UnoComponent({ id: 'PropMultipleViewer' })
export class PropMultipleViewer extends BasePropViewer {

    buildLabel(prop?: EntityProp) {
        return this.buildEmpty();
    }

    buildValue() {
        const eProp = Object.assign({}, this.getEProp());
        const defVal = Common.safeParse(this.getDefaultValue());
        let vals = defVal ? defVal : [];
        // console.log(`${this.getEProp().id} - Viewing Values...`, vals.length, eProp);
        if (!Array.isArray(vals)) {
            return this.buildEmpty();
        }

        eProp.multiplicity = 1;
        // const PropViewWidget = WidgetRegister.getViewWidget(eProp);
        const otherProps: any = {
            listViewType: this.getExtraParams()?.listViewType,
            ...this.getOtherProps(),
            hideLabel: true,
            canViewMain: false,
        };
        otherProps.extras = { ...this.getExtraParams(), ...this.getExtras(), }

        let ListViewContent = undefined;

        if (this.isEntityTypeProp()) {
            let canViewFull = (this.getExtraParams()?.canViewFull !== undefined) ? this.getExtraParams()?.canViewFull : undefined;
            let canEdit = (this.getExtraParams()?.canEdit !== undefined) ? this.getExtraParams()?.canEdit : undefined;
            // console.log('Viewing Prop - Multiple Entities: ', canViewFull, canEdit)

            if (this.isEntityInlineTypeProp()) {
                otherProps.viewType = EntityViewTypes.FULL;
                otherProps.canEdit = (canEdit !== undefined) ? Boolean(canEdit).valueOf() : false;
                otherProps.canViewFull = (canViewFull !== undefined) ? Boolean(canViewFull).valueOf() : false;
                otherProps.viewInline = true;
                otherProps.canPreview = true;
            } else {
                otherProps.viewType = EntityViewTypes.QUICK;
                otherProps.canViewFull = (canViewFull !== undefined) ? canViewFull : true;
                otherProps.canEdit = (canEdit !== undefined) ? canEdit : false;
            }
            const entities = vals.map(v => { return EntityConstants.build(v) });
            // console.log(`Viewing entity list - `, entities.length);
            ListViewContent = (
                <UC.MultiEntityView
                    appID={this.getAppID()}
                    categoryID={eProp.category}
                    entities={entities}
                    key={Common.getUniqueKey()}
                    otherProps={otherProps}
                />
            );
        } else {
            // console.log('Prop Data Type: ', propDataType, PropViewWidget);
            let LVC = undefined;
            switch (otherProps?.listViewType) {
                case EntityConstants.ListViewTypes.Table.id:
                    LVC = this.buildNonEntityListView(vals, eProp, otherProps);
                    break;
                case EntityConstants.ListViewTypes.Chips.id:
                default:
                    LVC = vals.map(v => {
                        return (
                            <UC.Chip>
                                <UC.PropValue
                                    defaultValue={v}
                                    entityProp={eProp}
                                    otherProps={otherProps}
                                />
                            </UC.Chip>
                        );
                    });
            }

            ListViewContent = (
                <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'auto' }}>
                    {LVC}
                </div>
            )

        }

        return ListViewContent;
    }


    private buildNonEntityListView(vals: any[], eProp: any, otherProps: any) {
        const ViewVals = vals.map(
            (v: any, ind: number, all: Array<any>) => {
                return (
                    <tr key={this.getUniqueKey()}>
                        <td>
                            <UC.PropValue
                                defaultValue={v}
                                entityProp={eProp}
                                otherProps={otherProps} />
                        </td>
                    </tr>
                );
            }
        );
        return (
            <table className='table table-striped'>
                <tbody>
                    {ViewVals}
                </tbody>
            </table>
        );
    }
}
