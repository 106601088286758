import { UnoModule } from '../@uno/core';
import { CHCHome } from './components/chc-home.comp';
import { PolyCanvas } from './components/poly-canvas.comp';
import { ClosedAreaFinder, Poly } from './components/poly.comp';

// The Module...

@UnoModule(
    {
        id:'CHCAppModule',
        imports: [
        ],
        components: [
            CHCHome,
            Poly, 
            PolyCanvas,
            ClosedAreaFinder,
        ],
    }
)
export default class CHCAppModule  {

}

