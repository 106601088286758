import React from 'react';
import Tesseract from 'tesseract.js';
import { Common, DesignerConstants, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';

@UnoComponent(
    {
        id: 'TextFromImage',
        label: 'Text from Image (OCR)',
        paletteable: true,
        props: [
        ],
        group: DesignerConstants.PaletteGroup.Integration.id,
    }
)
export class TextFromImageComp extends UnoCoreBaseComp {

    componentDidMount(): void {
        super.componentDidMount();
        this.setImage(this.state.image);
    }

    buildComp(): JSX.Element | null | undefined {
        let text = this.state.text || `No Image to find Text`;

        return (
            <pre>
                {text}
            </pre>
        );
    }

    setImage = async (img: any) => {
        console.log('Process Image and find Text: ', img);
        let text: string | undefined = undefined;
        if (img) {
            Tesseract.recognize(img,
                undefined,
                { logger: m => { Common.showDialog({ content: 'Processing' }); } }
            ).then(({ data: { text } }) => {
                Common.hideDialog(() => {
                    console.log('Processed Text ', text);
                    this.reRender({ image: img, text: text })
                });
            }).catch(e => {
                Common.hideDialog(() => {
                    Common.showError(Common.stringify(e));
                    this.reRender({ image: img, text: text })
                });
            });
        }
    }
}
