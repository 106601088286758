import React from 'react';
import { WhatsappIcon, WhatsappShareButton } from 'react-share';
import { DesignerConstants, UnoComponent } from '../../../core';
import { UnoCoreBaseComp } from '../uno-core.base.comp';

@UnoComponent({
    id: 'Share',
    label: 'Share',
    group: DesignerConstants.PaletteGroup.Other.id,
    props: [
        {
            id: 'link',
            label: 'Link',
            groupID: 'General',
        },
        {
            id: 'subject',
            label: 'Subject',
            groupID: 'General',
        },

    ],
    paletteable: true,
})
export class ShareComp extends UnoCoreBaseComp {

    buildComp(): JSX.Element | null | undefined {
        return (
            <>
                <WhatsappShareButton
                    url={this.getLink()}
                    title={this.getSubject()}
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </>
        );
    }

    getLink = () => {
        return this.state.link || '';
    }

    getSubject = () => {
        return this.state.subject || '';
    }

}