import React from 'react';
import { EntityConstants } from '../../api';
import { Source } from '../../api/source.service';
import { Common, UnoComponent } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';

@UnoComponent({
    id: 'Timer',
    label: 'Timer',
    props: [
        {
           groupID:'General', id: 'interval', label: 'Time Interval (in seconds)', dataType: EntityConstants.PropType.NUMBER,
        },
        {
            groupID:'General', id: 'timerFn', label: 'Timer Function', dataType: EntityConstants.PropType.FUNCTION,
        },
    ],
    paletteable: true,
    isContainer: true,
})
export class Timer extends UnoCoreBaseComp {
    private timerHandler: any;

    componentDidMount() {
        super.componentDidMount();
        this.initTimer();
    }

    componentWillUnmount() {
        this.cancelTimer();
        super.componentWillUnmount();
    }

    buildComp() {
        return (
            <span key={Common.getUniqueKey('timer_')}>
                {this.props.children}
            </span>
        );
    }


    initTimer(
        interval = Number.parseInt(Common.safeParse(this.state.interval)),
        timerFn = Common.safeParse(this.state.timerFn),
    ) {
        if (!this.isLiveMode()) {
            return;
        }
        this.cancelTimer();
        if (interval > 0) {
            this.timerHandler = setInterval(
                async () => {
                    if (Common.checkType.Function(timerFn)) {
                        await timerFn();
                    } else if (Common.checkType.String(timerFn)) {
                        const fn = Source.getFunction(timerFn);
                        if (fn) {
                            await fn({ theComp: this, interval: interval, });
                        }
                    }
                },
                1000 * interval
            );
        }
    }

    cancelTimer() {
        clearInterval(this.timerHandler);
    }

    toggle() {
        if (this.state.paused) {
            this.initTimer();
            this.setState({ paused: false });
        } else {
            this.cancelTimer();
            this.setState({ paused: true });
        }
    }
}
