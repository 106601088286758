import { AppInfoService } from '../../@uno-app/service/app.info.service';
import { SessionManager } from '../../@uno-app/service/session.service';
import { EM, RemoteService, Common, Router } from '../../@uno/api';


class EntityTriggerServiceImpl {

    send = async (input: any, event?: string, emitLocalEvent: boolean = false) => {
        const start = new Date().getTime();
        if (!input) {
            input = {};
        }

        if (event && (!input.event || input.event !== event)) {
            input.event = event;
        }

        if (!input.session) {
            input.session = SessionManager.activeSession;
        }

        if (!input.appID) {
            input.appID = AppInfoService.getActiveApp()?.id;
        }

        if (emitLocalEvent) {
            EM.emit(input.event, input);
        }

        // send a hint about screen size
        input.isSmallScreen = Common.isSmallScreen();

        const result = await RemoteService.getData(await RemoteService.post('trigger/send', { input: input, appID: input.appID, session: input.session ? input.input : SessionManager.activeSession }));
        // console.log(`Trigger result received in : ${(new Date().getTime() - start) / 1000} seconds.`, result.event, result.appID, result.category?.id);
        return result;
    }

    listFunctions = async (appID: string = Router.ANY) => {
        const result = await RemoteService.getData(await RemoteService.post('trigger/functions', { appID: appID, session: SessionManager.activeSession }));
        return result;
    }

    findFunction = async (functionID: any, appID: string = Router.ANY) => {
        const result = await RemoteService.getData(await RemoteService.post('trigger/functions/find', { fID: functionID, appID: appID, session: SessionManager.activeSession }));
        return result;
    }

}

export const TriggerService = new EntityTriggerServiceImpl();
