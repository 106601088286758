import { EntityFilterService } from '../../@uno-filter/service/entity-filter.service';
import { EntityConstants, EntityProp } from './entity.service';

export interface Condition {
    propID?: string;
    operator?: any;
    value?: any;
    // keyword?: string;
    readonly?: boolean;
    hidden?: boolean;
}

export interface ConditionGroup {
    keyword: string;
    children?: Array<Condition | ConditionGroup>;
    readonly?: boolean;
    hidden?: boolean;
}

// Utilities
export const FilterConstants = {
    Keyword: {
        AND: {
            id: 'AND', label: 'AND', notation: '$and',
            aliases: ['and', '&&', '$and'],
        },
        OR: {
            id: 'OR', label: 'OR', notation: '$or',
            aliases: ['or', '||', '$or'],
        },
        NOR: {
            id: 'NOR', label: 'NOR', notation: '$nor',
            aliases: ['nor', '!||', '$nor'],
        },
        // NOT: { id: 'NOT', label: 'NOT', notation: '$not' },
    },

    Operator: {
        ORDER_BY: {
            id: 'ORDER_BY', label: 'order by', notation: 'order_by',
            aliases: ['sort', 'sort by', 'order by', 'order'],
        },
        EQUAL: {
            id: 'EQUAL', label: 'is (=)', notation: '$eq',
            aliases: ['equal', '=', '$eq'],
        },
        NOT_EQUAL: {
            id: 'NOT_EQUAL', label: 'is not (!=)', notation: '$ne',
            aliases: ['not equal', '!=', '<>', '$ne'],
        },
        GREATERTHAN: {
            id: 'GREATERTHAN', label: 'is more than (>)', notation: '$gt',
            aliases: ['greater than', 'more than', '>', '$gt'],
        },
        GREATER_OR_EQUAL: {
            id: 'GREATER_OR_EQUAL', label: 'is more or equal to (>=)', notation: '$gte',
            aliases: ['greater or equal', 'more or equal', 'equal or more', '>=', '$gte'],
        },
        LESSTHAN: {
            id: 'LESSTHAN', label: 'is less than (<)', notation: '$lt',
            aliases: ['less than', '<', '$lt'],
        },
        LESS_OR_EQUAL: {
            id: 'LESS_OR_EQUAL', label: 'is less of equal to (<=)', notation: '$lte',
            aliases: ['less or equal', 'equal or less', '<=', '$lte'],
        },
        REGEXP: {
            id: 'REGEXP', label: 'is like', notation: '$regex',
            aliases: ['like', 'similar to', 'regex', 'regexp', '$regex'],
        },
        IN: {
            id: 'IN', label: 'is among', notation: '$in',
            aliases: ['in', 'among', '$in'],
        },
        NIN: {
            id: 'NIN', label: 'is not among', notation: '$nin',
            aliases: ['not in', 'not among', '$nin'],
        },
        EMPTY: {
            id: 'EMPTY', label: 'is empty', notation: '$eq',
            aliases: ['empty', 'is empty', 'is unknown'],
        },
        NOT_EMPTY: {
            id: 'NOT_EMPTY', label: 'is not empty', notation: '$ne',
            aliases: ['not empty', 'is not empty', 'is known'],
        },
    },

    SortOptions: {
        ASC: { id: 1, label: 'Ascending' },
        DESC: { id: -1, label: 'Descending' },
    },

    Value: {
        CURRENT_USER: {
            id: 'currentUser()',
            label: 'Current User',
        },

        TODAY: {
            id: 'today()',
            label: 'Today',
        },

        NOW: {
            id: 'now()',
            label: 'Right Now',
        },
    },

    getOperators: (prop: EntityProp) => {
        const dataType: string = prop.dataType ? prop.dataType : EntityConstants.PropType.DEFAULT;
        const isArray: boolean = (prop && prop.multiplicity && prop.multiplicity !== 1) ? true : false;
        const commonOperators = [
            FilterConstants.Operator.IN,
            FilterConstants.Operator.NIN,
            FilterConstants.Operator.EMPTY,
            FilterConstants.Operator.NOT_EMPTY,
            FilterConstants.Operator.ORDER_BY,
        ];

        /*
        if (isArray) {
            return [
                FilterConstants.Operator.IN,
                FilterConstants.Operator.NIN,
                ...commonOperators,
            ];
        }
        */

        switch (dataType) {
            case EntityConstants.PropType.ARRAY:
                return [
                    FilterConstants.Operator.IN,
                    FilterConstants.Operator.NIN,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.BOOLEAN:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.COLOR:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.DATE:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.LESSTHAN,
                    FilterConstants.Operator.LESS_OR_EQUAL,
                    FilterConstants.Operator.GREATERTHAN,
                    FilterConstants.Operator.GREATER_OR_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.DATETIME:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.LESSTHAN,
                    FilterConstants.Operator.LESS_OR_EQUAL,
                    FilterConstants.Operator.GREATERTHAN,
                    FilterConstants.Operator.GREATER_OR_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.DEFAULT:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.EMAIL:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.ENCRYPT:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.ENTITY:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.ENTITY_INLINE:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.FILE:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.FUNCTION:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.HTML:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.IMAGE:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.JSON:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.LAYOUT:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.MULTILINE:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.NUMBER:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.LESSTHAN,
                    FilterConstants.Operator.LESS_OR_EQUAL,
                    FilterConstants.Operator.GREATERTHAN,
                    FilterConstants.Operator.GREATER_OR_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.PASSWORD:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.STANDARD_FUNCTION:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.STRING:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.REGEXP,
                    ...commonOperators,
                ];
            case EntityConstants.PropType.TIME:
                return [
                    FilterConstants.Operator.EQUAL,
                    FilterConstants.Operator.NOT_EQUAL,
                    FilterConstants.Operator.LESSTHAN,
                    FilterConstants.Operator.LESS_OR_EQUAL,
                    FilterConstants.Operator.GREATERTHAN,
                    FilterConstants.Operator.GREATER_OR_EQUAL,
                    ...commonOperators,
                ];
            default:
                return Object.values(FilterConstants.Operator);
        }
    },

    getValueChoices: (prop: EntityProp) => {
        const choices: Array<any> = [];
        switch (prop.dataType) {
            case EntityConstants.PropType.ENTITY:
                if (prop.category === 'uno_person') {
                    choices.push(FilterConstants.Value.CURRENT_USER);
                }
                break;
            case EntityConstants.PropType.DATE:
            case EntityConstants.PropType.DATETIME:
                choices.push(FilterConstants.Value.TODAY);
                choices.push(FilterConstants.Value.NOW);
                break;
            case EntityConstants.PropType.TIME:
                choices.push(FilterConstants.Value.NOW);
                break;
        }

        return choices;
    },

    getKeywordNotation: (id: string) => {
        const keyword = FilterConstants.getKeyword(id);
        return keyword?.notation;
    },

    getKeyword: (id: string) => {
        return FilterConstants.getDef(id, FilterConstants.Keyword);
    },

    getOperatorNotation: (id: string) => {
        const operator = FilterConstants.getOperator(id);
        return operator?.notation;
    },

    getOperator: (id: string) => {
        return FilterConstants.getDef(id, FilterConstants.Operator);
    },

    getNotation: (id: string, definitions: any) => {
        let notation = id;
        const def = FilterConstants.getDef(id, definitions);
        if (def) {
            notation = def.notation;
        }

        return notation;
    },

    getKeywordLabel: (id: string) => {
        return FilterConstants.getLabel(id, FilterConstants.Keyword);
    },

    getOperatorLabel: (id: string) => {
        return FilterConstants.getLabel(id, FilterConstants.Operator);
    },

    getLabel: (id: string, definitions: any) => {
        let label = id;
        const def = FilterConstants.getDef(id, definitions);
        if (def) {
            label = def.label;
        }
        return label;
    },

    getDef: (id: string, definitions: any) => {
        let matchingDef: any = undefined;
        for (let key of Object.keys(definitions)) {
            const def = definitions[key];
            if (def.id.toLowerCase() === id.toLowerCase()) {
                matchingDef = def;
            } else if (def.aliases?.length > 0) {
                for (let a of def.aliases) {
                    if (a.toLowerCase() === id.toLowerCase()) {
                        matchingDef = def;
                    }
                }
            }
        }
        return matchingDef;
    },

    // A more convenient function over createCondition()
    setCategory: (category: string) => {
        return FilterConstants.create('category', category);
    },

    // primary utilities
    create: (
        propID: string,
        value?: any,
        operator?: any,
        readonly: boolean = false,
        hidden: boolean = false,
    ): Condition => {
        return FilterConstants.createCondition(propID, value, operator, readonly, hidden,);
    },

    createCondition: (
        propID: string,
        value?: any,
        operator?: any,
        readonly: boolean = false,
        hidden: boolean = false,
    ): Condition => {
        return {
            propID: propID,
            value: value,
            operator: operator ? operator : FilterConstants.Operator.EQUAL.id,
            hidden: hidden,
            readonly: readonly,
        }
    },

    createGroup: (
        type: string,
        children: Array<Condition | ConditionGroup> = [],
        readonly: boolean = false,
        hidden: boolean = false
    ): ConditionGroup => {
        return { keyword: type, children: children, readonly: readonly, hidden: hidden };
    },


    getCategoryID: (conditions: Array<any>, appID?: string) => {
        let catID: any = undefined;
        conditions?.forEach(cond => {
            if (cond?.propID === EntityConstants.Attr.CATEGORY) {
                catID = cond.value;
            }
        });

        return catID;
    },

    // convenience functions
    category: (category: string) => {
        return FilterConstants.setCategory(category);
    },

    // condition
    like: (propID: string, value: string, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, value, FilterConstants.Operator.REGEXP, readonly, hidden,);
    },

    is: (propID: string, value?: any, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, value, FilterConstants.Operator.EQUAL, readonly, hidden,);
    },

    isNot: (propID: string, value?: any, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, value, FilterConstants.Operator.NOT_EQUAL, readonly, hidden,);
    },

    isEmpty: (propID: string, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, undefined, FilterConstants.Operator.EMPTY, readonly, hidden,);
    },

    isNotEmpty: (propID: string, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, undefined, FilterConstants.Operator.NOT_EMPTY, readonly, hidden,);
    },

    more: (propID: string, value?: any, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, value, FilterConstants.Operator.GREATERTHAN, readonly, hidden,);
    },

    moreOrEqual: (propID: string, value?: any, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, value, FilterConstants.Operator.GREATER_OR_EQUAL, readonly, hidden,);
    },

    less: (propID: string, value?: any, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, value, FilterConstants.Operator.LESSTHAN, readonly, hidden,);
    },

    lessOrEqual: (propID: string, value?: any, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, value, FilterConstants.Operator.LESS_OR_EQUAL, readonly, hidden,);
    },

    in: (propID: string, value?: any, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, value, FilterConstants.Operator.IN, readonly, hidden,);
    },

    notIn: (propID: string, value?: any, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, value, FilterConstants.Operator.NIN, readonly, hidden,);
    },

    // order
    asc: (propID: string, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, FilterConstants.SortOptions.ASC, FilterConstants.Operator.ORDER_BY, readonly, hidden,);
    },

    desc: (propID: string, readonly: boolean = false, hidden: boolean = false,): Condition => {
        return FilterConstants.createCondition(propID, FilterConstants.SortOptions.DESC, FilterConstants.Operator.ORDER_BY, readonly, hidden,);
    },

    // Group 
    or: (children: Array<Condition | ConditionGroup> = [], readonly: boolean = false, hidden: boolean = false): ConditionGroup => {
        return { keyword: FilterConstants.Keyword.OR.id, children: children, readonly: readonly, hidden: hidden };
    },

    and: (children: Array<Condition | ConditionGroup> = [], readonly: boolean = false, hidden: boolean = false): ConditionGroup => {
        return { keyword: FilterConstants.Keyword.AND.id, children: children, readonly: readonly, hidden: hidden };
    },

    nor: (children: Array<Condition | ConditionGroup> = [], readonly: boolean = false, hidden: boolean = false): ConditionGroup => {
        return { keyword: FilterConstants.Keyword.NOR.id, children: children, readonly: readonly, hidden: hidden };
    },

    // execute query
    find: async (filterConditions: any, options: any, appID: any, session?: any) => {
        return await EntityFilterService.result(filterConditions, options, appID, undefined, session);
    },

    count: async (filterConditions: any, options: any, appID: any, session?: any) => {
        return await EntityFilterService.getTotalCount(filterConditions, options, appID, session,);
    },

    build: async (filterConditions: any, appID: any, session?: any) => {
        return await EntityFilterService.build(filterConditions, appID, session,);
    },

}