import React from 'react';
import { Common, DesignerConstants, UnoComponent } from '../../../@uno/core';
import { UnoCoreBaseComp } from '../../../@uno/core';
import { TableauAuthService } from '../service/tableau.auth.service';


@UnoComponent(
    {
        id: 'TableauViz',
        label: 'Tableau Vizualizer',
        paletteable: true,
        props: [
            { id: 'url', label: 'Workbook URL' },
            { id: 'width', label: 'Screen Width' },
            { id: 'height', label: 'Screen Height' },
        ],
        group: DesignerConstants.PaletteGroup.Integration.id,
    }
)
export class TableauViz extends UnoCoreBaseComp {
    containerID = Common.getUniqueKey('tabviz_container_');
    tableauVizID = Common.getUniqueKey('tabviz_');

    componentDidMount(): void {
        super.componentDidMount();
        this.buildVisual();
    }

    async buildVisual() {
        // add script
        const tableauScript = `${this.getVizHost()}/javascripts/api/tableau.embedding.3.latest.min.js`;
        // console.log('Adding Tableau Script: ', tableauScript);
        Common.addScript(tableauScript, 'module');
        // add component
        const src = this.getVizURL();
        if (src) {
            const container = document.getElementById(this.containerID);
            if (container) {
                const token = await TableauAuthService.getToken('', {});
                const vizEle = document.createElement('tableau-viz');
                // src - url
                vizEle.setAttribute('src', src);
                // id
                vizEle.setAttribute('id', this.tableauVizID);
                // size-dimension
                vizEle.setAttribute('width', this.getWidth());
                vizEle.setAttribute('height', this.getHeight());
                // hide-tabs
                vizEle.setAttribute('hide-tabs', 'true');
                // toolbar
                vizEle.setAttribute('toolbar', 'top');

                if (token) {
                    console.log('Tableau Viz Token: ', token);
                    vizEle.setAttribute('token', token);
                }

                // clear all children from container before adding new one.
                container.childNodes.forEach(ch => {
                    container.removeChild(ch);
                });

                container.appendChild(vizEle);
            }
        }
    }

    buildComp() {
        this.tableauVizID = Common.getUniqueKey('tabviz_');
        return (
            <div id={this.containerID}>
                <h2>Tableau Viz: {this.tableauVizID}</h2>
            </div>
        )
    }

    getWidth = () => {
        return this.state.width ? this.state.width : '1300';
    }

    getHeight = () => {
        return this.state.height ? this.state.height : '963';
    }

    getVizURL = (): string => {
        return this.state.url;
    }

    getVizHost = (): string => {
        const vizUrl = this.getVizURL() ? new URL(this.getVizURL()) : undefined;
        const vizHost = vizUrl ? `${vizUrl.protocol}//${vizUrl.host}${vizUrl.port ? ':' + vizUrl.port : ''}` : 'https://prod-uk-a.online.tableau.com';
        return vizHost;
    }
}
