import { UnoModule } from '../../@uno/core';
import { TableauViz } from './components/tableau-viz.comp';
// The Module...

@UnoModule(
    {
        id: 'TableauModule',
        imports: [
        ],
        components: [
            TableauViz,
        ],
    }
)
export default class TableauModule {

}

