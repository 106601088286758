import React from 'react';
import { Common, DesignerConstants, UC, UnoComponent, Images } from '../../../@uno/core';
import { BasePropEditor } from '../prop-base.comp';

@UnoComponent({ id: 'LayoutEditor' })
export class LayoutEditor extends BasePropEditor {

    constructor(props: any) {
        super(props);

        let designConfig: any = this.getDefaultValue();
        // console.log(typeof designConfig, ' :: ', designConfig);
        if (Common.checkType.String(designConfig)) {
            designConfig = Common.safeParse(designConfig);
        }
        this.state = { previewMode: true, ...this.state, config: designConfig, };
        // console.log('Layout Comp - Is Preview Mode: ', this.state.previewMode);
    }

    buildInput() {
        // console.log('Building Design Widget')

        let layoutContentConfig: any = this.state.config;
        let mode = this.getMode();
        let TheComp = <UC.Empty />;
        // console.log('Layout Comp - The Mode: ', mode, ' Content: ', layoutContentConfig);
        if (mode === DesignerConstants.Mode.DESIGN) {
            TheComp = (
                <UC.LayoutDesigner
                    onSave={this.handleSaveDesign}
                    config={layoutContentConfig}
                    paletteComps={this.getExtraParams()?.paletteComps}
                />
            );
        } else if (mode === DesignerConstants.Mode.LIVE || mode === DesignerConstants.Mode.PREVIEW) {
            TheComp = (
                <UC.LayoutRenderer
                    config={layoutContentConfig}
                    mode={mode}
                />
            );
            // TheComp = (<div>{this.getDefaultValue()}</div>);
        }

        return (
            <div className='input-field'>
                {this.buildDesignPropActions()}
                {TheComp}
            </div>
        );
    }

    clearDesigner = () => {
        const onClear = this.getOtherProps()?.extras?.onClear;
        if (onClear) {
            onClear();
        }
        this.handleSaveDesign(undefined);
    }

    loadDesigner = () => {
        this.setState({ previewMode: false });
    }

    handleSaveDesign = (config: any) => {
        this.propagateChange(config);
        this.setState({ config: config, previewMode: true });
    }

    propagateChange(config: any) {
        let designConfig: any = config;
        if (typeof designConfig !== Common.Datatype.String) {
            designConfig = Common.stringify(designConfig);
        }

        if (this.getPropChangedHandler()) { // designConfig && 
            this.getPropChangedHandler()(this.getEProp(), designConfig);
        }
    }

    buildDesignPropActions() {
        const actions: Array<any> = [];
        actions.push({ id: 'set', label: 'Edit', icon: Images.Icon.edit, action: this.loadDesigner });
        actions.push({ id: 'clear', label: 'Clear', icon: Images.Icon.clear, action: this.clearDesigner })

        return (
            <div className=''>
                <UC.Navigation navs={actions} orientation='h' isToolbar={false} key={Common.getUniqueKey('nav')} />
            </div>

        );
        /*
        return (
            <div>
                <button onClick={() => { this.loadDesigner(); }} key={Common.getUniqueKey()}>Edit</button>
                <button onClick={() => { this.clearDesigner(); }} key={Common.getUniqueKey()}>Clear</button>
            </div>
        )*/
    }


    getMode() {
        let mode = DesignerConstants.Mode.DESIGN;
        if (this.state?.previewMode) {
            mode = DesignerConstants.Mode.PREVIEW;
        }
        return mode;
    }
}

@UnoComponent({ id: 'LayoutViewer' })
export class LayoutViewer extends LayoutEditor {

    constructor(props: any) {
        super(props);
        this.state = { ...this.state }
    }

    buildComp() {
        // console.log('Viewing layout - ', this.getEProp().id);
        if (!this.getDefaultValue()) {
            return (<> </>);
        }
        return super.buildComp();
    }

    propagateChange(config: any) {
        // console.log('Block mapping completed.');
    }

    buildDesignPropActions() {
        return (<UC.Empty />);
    }

    getMode() {
        let mode = DesignerConstants.Mode.LIVE;
        if (this.getExtras()?.mode) {
            mode = this.getExtras().mode;
        }
        return mode;
    }

}