import React from 'react';
import { AppInfoService } from '../../@uno-app/service/app.info.service';
import { EntityCategoryService } from '../../@uno-app/service/entity.category.service';
import { Common, EM, EntityConstants, Images } from '../../@uno/api';
import { EntityProp } from '../../@uno/api/entity.service';
import { Source } from '../../@uno/api/source.service';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent, UnoCoreBaseComp } from '../../@uno/core';
import { EntityPropService } from '../service/entity-prop.service';
//import STT from './stt/stt.comp';

export const E_PROP_BASE_PROPS: Array<EntityProp> = [
    {
        groupID: 'Standard Definition',
        id: 'categoryID',
        label: 'Category',
        dataType: EntityConstants.PropType.STRING,
        editor: 'CategorySelector',
        viewer: 'CategoryViewer',
    },
    {
        groupID: 'Standard Definition',
        id: 'propID',
        label: 'Property',
        dataType: EntityConstants.PropType.STRING,
        extras: { catID: 'categoryID' },
        editor: 'PropertySelector',
        viewer: 'SelectedPropertyViewer',
    },
    { groupID: 'Custom Definition', id: 'entityPropDef', label: 'Property Definition', dataType: EntityConstants.PropType.ENTITY_INLINE, category: 'uno_prop_def', },
    { groupID: 'Custom Definition', id: 'entityProp', label: 'Property Configuration', description: 'DEPRECATED: Use Property Definition', dataType: EntityConstants.PropType.JSON, },
    // { groupID: 'Style', id: 'styles', label: 'Styles', dataType: EntityConstants.PropType.JSON, },
];
// Property Label
export const E_PROP_LABEL_PROPS: Array<EntityProp> = [
    { groupID: 'Label', id: 'hideLabel', label: 'Hide Prop Name', dataType: EntityConstants.PropType.BOOLEAN },
    {
        groupID: 'Label', id: 'labelPosition', label: 'Label Position', editor: 'OptionSelector', viewer: 'OptionViewer', extras: {
            options: Object.values(EntityConstants.Position).map(p => {
                return { id: p, label: p.toUpperCase() };
            })
        }
    },
];

export const PROP_EDITOR_PROPS: Array<EntityProp> = [
    { groupID: 'Edit Configs', id: 'input_id', label: 'Input ID', },
    { groupID: 'Edit Configs', id: 'stt_enabled', label: 'Enable STT?', dataType: EntityConstants.PropType.BOOLEAN, },
    { groupID: 'Edit Configs', id: 'writer_enabled', label: 'Enable Writer?', dataType: EntityConstants.PropType.BOOLEAN, },
    { groupID: 'Edit Configs', id: 'show_cleaner', label: 'Show Cleaner?', dataType: EntityConstants.PropType.BOOLEAN, },
    { groupID: 'Edit Configs', id: 'placeholder', label: 'Placeholder', },
    { groupID: 'Edit Configs', id: 'defaultValue', label: 'Initial Value', dataType: EntityConstants.PropType.MULTILINE },
    { groupID: 'Edit Configs', id: 'onPropChanged', label: 'onPropChanged Handler', dataType: EntityConstants.PropType.FUNCTION },
]

// Property Value
export const E_PROP_VALUE_PROPS: Array<EntityProp> = [
    { groupID: 'Edit Configs', id: 'editing', label: 'Is an Editor?', dataType: EntityConstants.PropType.BOOLEAN },
    ...PROP_EDITOR_PROPS,
    { groupID: 'Others', id: 'viewer', label: 'Viewer Component', dataType: EntityConstants.PropType.STRING, editor: 'ComponentSelector', },
    { groupID: 'Others', id: 'editor', label: 'Editor Component', dataType: EntityConstants.PropType.STRING, editor: 'ComponentSelector', },
    { groupID: 'Others', id: 'otherProps', label: 'Other Properties', dataType: EntityConstants.PropType.JSON, },
];


export class UnoPropBaseComp extends UnoCoreBaseComp {
    private PROP_CHANGE_HANDLER: any;
    //eProp: EntityProp | undefined;

    componentDidMount(): void {
        super.componentDidMount();
        this.PROP_CHANGE_HANDLER = EM.register(Common.Event.ENTITY_PROP_CHANGED, async (changedProp: any) => {
            if (Common.isEqual(changedProp.entity, this.getEntity())) {
                const changedPropID = changedProp.prop.id;
                /*
                if (this.getDependsOnProps()?.length > 0) {
                    console.log('Depends on Props: ', this.getDependsOnProps());
                }
                */
                if (this.getDependsOnProps().includes(changedPropID)
                    || this.getEProp()?.dependsOnProps?.includes(changedPropID)
                ) {
                    this.handleChangeInDependsOnProp(changedProp.prop.id, changedProp.from, changedProp.to);
                }
            }
        });
    }

    componentWillUnmount(): void {
        super.componentWillUnmount();
        EM.unregister(this.PROP_CHANGE_HANDLER);
    }

    getDependsOnProps(): Array<string> {
        return [];
    }

    // override to handle case-by-case
    handleChangeInDependsOnProp(pid: string, from: any, to: any) {
        this.setState({ changedProp: { id: pid, from: from, to: to } });
    }

    getLabel(prop = this.getEProp()) {
        if (prop) {
            if (!prop.id) {
                console.log('Invalid eProp: ', this.props);
            }
            // console.log('Getting prop label - ', prop, (prop.label ? prop.label : prop.id));
            return prop.label ? prop.label : prop.id;
        } else {
            return '';
        }
    }

    getLabelPosition(prop = this.getEProp()) {
        let position = EntityConstants.Position.TOP;
        if (this.hideLabel()) {
            position = EntityConstants.Position.NONE;
        } else if (this.state.labelPosition) {
            position = this.state.labelPosition;
        } else if (this.getOtherProps()?.labelPosition) {
            position = this.getOtherProps()?.labelPosition;
        } else if (prop?.extras?.labelPosition) {
            position = prop?.extras?.labelPosition;
        }
        return position;
    }

    getLabelAlign(prop = this.getEProp()) {
        let alignment = EntityConstants.Align.LEFT;
        if (this.getOtherProps()?.labelAlign) {
            alignment = this.getOtherProps()?.labelAlign;
        } else if (prop?.extras?.labelAlign) {
            alignment = prop?.extras?.labelAlign;
        }
        return alignment;
    }

    buildLabel(prop = this.getEProp()) {
        return this.hideLabel() ? (<UC.Empty />) : this.buildLabelAndDesc(prop);
    }

    buildLabelAndDesc(prop = this.getEProp()) {
        if (!prop) {
            return undefined;
        } else {
            const propLabel = `${this.getLabel(prop)}`;
            return (
                <>
                    {propLabel}{this.buildPropDesc()} &nbsp;
                </>
            );
        }
    }

    buildPropDesc(prop = this.getEProp()) {
        if (!prop) {
            prop = this.getEProp();
        }
        let propDesc = prop?.description;
        if (!propDesc) {
            return null;
        }
        // console.log('description : ', propDesc);
        return (
            <span className='input-description'>
                &nbsp;{propDesc}
            </span>
        );
    }

    getAppID() {
        return this.props.appID;
    }

    getEProp() {
        const category = this.getCategory();
        const propID = this.state.propID;

        let eProp = undefined;
        if (this.state.entityProp) {
            eProp = this.state.entityProp;
        } else if (this.state.entityPropDef) {
            eProp = EntityCategoryService.getEntityProp(this.state.entityPropDef);
        } else if (propID) {
            // TODO: review this prop-category vs entity category
            if (category?.props) {
                eProp = EntityConstants.getPropByID(category.props, propID);
            }
        } else if (this.props.entityProp) {
            eProp = this.props.entityProp;
        }

        eProp = Common.safeParse(eProp);

        if (!eProp && propID) {
            // console.log('No Cat Prop for eProp: ', propID, category, this.state);
        }
        return eProp;
    }

    getEPropID() {
        return this.state.propID;
    }

    getOtherProps() {
        return Common.safeParse(this.state.otherProps);
    }

    getCategoryID() {
        return this.state.categoryID || this.getEntity()?.category;
    }

    getCategory() {
        let category = this.props.category;
        const catID = this.getCategoryID();
        if (!category && catID) {
            category = EntityCategoryService.getAppCategory(catID, this.getAppID())
        }
        return category;
    }

    getEntity() {
        let entity = this.state.entity;
        if (entity) {
            entity = EntityConstants.build(Common.safeParse(entity));
        }
        return entity;
    }

    getEPropDataType() {
        let dataType = EntityConstants.PropType.DEFAULT;
        const eProp = this.getEProp();
        if (eProp) {
            dataType = EntityPropService.getDataType(eProp);
        }
        return dataType;
    }

    getEPropDefaultValue() {
        return this.getEProp()?.defaultValue;
    }

    getDefaultValue() {
        let defValue = this.state.defaultValue;
        if (!defValue) {
            defValue = this.getEPropDefaultValue();
        }
        // console.log(`Prop: ${this.getEProp().label}, INITIAL def Value - ${defValue} of type - ${typeof(defValue)}`);

        if (!defValue) {
            defValue = this.getPropValue();
        }
        // console.log(`Prop: ${this.getEProp().label}, CONFIRMED def Vallue - ${defValue} of type - ${typeof(defValue)}`);
        if (defValue instanceof Object) {
            defValue = Common.stringify(defValue);
        }
        return defValue;
    }

    setDefaultValue(value: any) {
        this.reRender({ defaultValue: value });
    }

    getPropValue() {
        const entity = this.getEntity();
        const eProp = this.getEProp();
        const propID = this.getEPropID();
        if (entity && eProp) {
            return EntityConstants.getValue(eProp, entity);
        } else if (entity && propID) {
            return entity[propID];
        }
        return undefined;
    }

    getStyles() {
        return super.getStyles(); // this.props.styles ? Common.safeParse(this.props.styles) : {};;
    }

    isEditing() {
        return (this.state.editing === true);
    }

    hideLabel() {
        if (this.state.hideLabel !== undefined) {
            // console.log('Hidden Prop Label: ', this.getEProp(), this.state.hideLabel, Common.checkType.String(this.state.hideLabel));
            return this.state.hideLabel;
        }
        return this.getOtherProps()?.hideLabel;
    }
}

// Property Label and Value
@UnoComponent(
    {
        id: 'PropComp',
        label: 'Property',
        props: [...E_PROP_BASE_PROPS, ...E_PROP_LABEL_PROPS, ...E_PROP_VALUE_PROPS],
        events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad, UnoCompEvents.onEnter],
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Frequent.id,
    }
)
export class PropComp extends UnoPropBaseComp {

    componentDidMount() {
        super.componentDidMount();
        const eProp = this.getEProp();
        if (!eProp) {
            EntityCategoryService.findEProp(this.getCategoryID(), this.getEPropID(), this.getAppID())
                .then(
                    eProp => {
                        if (eProp) {
                            // this.eProp = eProp;
                            console.log('Setting Category eProp: ', eProp);
                            this.setState({ entityProp: eProp });
                        }
                    }
                );
        }
    }

    buildComp() {
        const childState = {
            ...this.state,
            // remove comp definition related props, if any
            compID: undefined,
            compConfig: undefined,
            children: undefined,
            handlers: undefined,
        };

        const Label = (<UC.PropLabel {...childState} key={Common.getUniqueKey('PropLabel_')} />);
        const Value = (<UC.PropValue {...childState} key={Common.getUniqueKey('PropValue_')} />);

        const labelPosition = this.getLabelPosition();
        // console.log(`${this.getEProp()?.id} - Label position - ${labelPosition}`, this.props.otherProps);
        switch (labelPosition) {
            case EntityConstants.Position.NONE:
                return Value;
            case EntityConstants.Position.LEFT:
                return (
                    <div className='row'>
                        <div className='col col-sm-4 col-4' style={{ paddingRight: '5px' }}>
                            {Label}
                        </div>
                        <div className='col col-sm-8 col-8'>
                            {Value}
                        </div>
                    </div>
                );
            case EntityConstants.Position.TOP:
            default:
                return (
                    <div className='entity-prop'>
                        {Label}<br />{Value}
                    </div >
                );
        }
    }

}

@UnoComponent(
    {
        id: 'PropLabel',
        label: 'Property Label',
        props: [...E_PROP_BASE_PROPS, ...E_PROP_LABEL_PROPS],
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Frequent.id,
        events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    }
)
export class PropLabel extends PropComp {

    buildComp() {
        if (this.hideLabel()) {
            return (<></>);
        } else {
            const eProp = this.getEProp();
            if (!eProp) {
                return (<div>Loading Prop Config...</div>);
            }
            const mandatory = (this.isEditing() && eProp?.validators?.includes(EntityConstants.Validator.REQUIRED)) ? (<span style={{ color: 'red' }}> * </span>) : <UC.Empty />;
            const Label = this.buildLabel(eProp);

            return (
                <div className={`input-label ${this.getStyleClasses()}`} key={Common.getUniqueKey()} style={this.getStyles()}>
                    {Label}
                    {mandatory}
                </div>
            );
        }
    }
}

@UnoComponent(
    {
        id: 'PropValue',
        label: 'Property Value',
        props: [...E_PROP_BASE_PROPS, ...E_PROP_VALUE_PROPS],
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Frequent.id,
        events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    }
)
export class PropValue extends PropComp {
    _DUMMY_PROP = { id: 'DUMMY' };

    buildComp() {
        if (!this.getEProp()) {
            return (<div>Loading Prop Config...</div>);
        }
        return (
            <>{this.buildValueElement()}</>
        )
    }

    buildValueElement() {
        const eProp = this.getEProp();
        let props: any = { ...this.state, hideLabel: true, };
        let oProps = { ...this.getOtherProps(), hideLabel: true, }
        if (eProp && EntityConstants.isEntityType(eProp.dataType)) {
            props = {
                ...props,
                categoryID: eProp.category,
                category: EntityCategoryService.getAppCategory(eProp.category, this.getAppID()),
            }
        }
        const validationErrors = oProps.validationErrors;
        if (validationErrors) {
            oProps.errorMsg = validationErrors[this.getEPropID()];
        }

        // value element
        let ValueElem: any = this.isEditing() ? this.getEditor() : this.getViewer();

        if (ValueElem) {
            // console.log(`Editing - ${this.isEditing()}: ${this.getLabel()} : `, props, oProps, ValueElem);
            return (
                <ValueElem
                    {...props}
                    otherProps={oProps}
                    entityProp={{ ...eProp }}
                    key={Common.getUniqueKey()}
                />
            );
        } else {
            // console.log('Displaying DefaultValue in the PropValue Comp: ', this.state);
            return this.buildValue();
        }
    }

    buildValue() {
        return this.getDefaultValue();
    }

    getViewer() {
        let Viewer = UC[this.props.viewer];
        if (Viewer) {
            return Viewer;
        } else {
            const prop = this.getEProp();
            return EntityPropService.getViewer(prop ? prop : this._DUMMY_PROP);
        }
    }

    getEditor() {
        // console.log(`FInding editor for - `, this.getEProp(), this.props.editor);

        let Editor = UC[this.props.editor];
        if (Editor) {
            return Editor;
        } else {
            const prop = this.getEProp();
            return EntityPropService.getEditor(prop ? prop : this._DUMMY_PROP);
        }

    }

}


// Old Widget (Component) Structure

@UnoComponent({ id: 'PropBaseComp' })
export class PropBaseComp extends UnoPropBaseComp {
    // category: EntityCategory | undefined;
    // private _handlers: Array<any> = [];
    // private redirectTo: any;

    constructor(props: any) {
        super(props);
        this.state = { ...this.props };
        // this.category = this.props.category;
        // console.log(`Prop - ${this.getEProp().id},  Category: `, this.category);
        // this._handlers = Common.registerEventHandlers(this);
    }

    componentDidMount() {
        super.componentDidMount();
        if (!this.getEProp()) {
            // need to load eProp;
            const catID = this.getCategoryID();
            const propID = this.getEPropID();
            if (catID && propID) {
                EntityCategoryService.getCategory(catID, true, this.getAppID())
                    .then(
                        async (category) => {
                            if (category) {
                                const eProp = EntityConstants.getPropByID(category.props, propID);
                                this.profiler.log('Setting eProp for catID and propID:', eProp, category);
                                this.setState({ category: category, entityProp: eProp });
                                // this.category = category;
                                /*
                                this.category?.props?.forEach(prop => {
                                    if (prop.id === propID) {
                                        this.eProp = prop;
                                    }
                                });
                                */
                                await this.handleCategoryLoaded();
                            }
                        }
                    );
            }

        }
    }

    componentWillUnmount() {
        // Common.unregisterEventHandlers(this._handlers);
    }

    buildComp() {
        {
            const label = this.buildLabel(this.getEProp());

            return (
                <>
                    {label ?
                        (
                            <div className='input-label' >
                                {this.buildLabel(this.getEProp())}
                            </div>
                        ) :
                        undefined
                    }
                    <div className='input-field'>
                        {this.buildValue()}
                    </div>
                </>
            )
        }
    }

    async handleCategoryLoaded() {
        this.reRender();
    }

    buildActionBar(actions: Array<any> = [], horizontal: boolean = false, isToolbar: boolean = false) {
        if (horizontal) {
            if (actions.length > 10) {
                const choices = [
                    { id: 'Choices', children: actions, icon: Images.Icon.v3dots, },
                ]
                return (<UC.Navigation navs={choices} orientation='h' isToolbar={isToolbar} key={Common.getUniqueKey()} />)
            } else {
                return (<UC.Navigation navs={actions} orientation='h' isToolbar={isToolbar} key={Common.getUniqueKey()} />)
            }
        }
        return (<UC.SelectBox options={actions} key={Common.getUniqueKey()} />);
    }

    buildValue() {
        const value = this.getDefaultValue();
        return (
            <span
                style={this.getStyles()}
                className={this.getStyleClasses()}
                title={value}
            >
                {value}
            </span>
        );
    }

    protected buildEmpty() {
        return <UC.Empty />;
    }

    getUniqueKey(prefix?: string) {
        return Common.getUniqueKey(prefix);
    }

    getViewer() {
        const prop = this.getEProp();
        return EntityPropService.getViewer(prop);
    }

    getEditor() {
        const prop = this.getEProp();
        return EntityPropService.getEditor(prop);
    }

    getExtraParams() {
        const extras = this.getEProp()?.extras;
        return extras ? Common.safeParse(extras) : {};
    }

    getLabel(prop?: EntityProp, locale?: string) {
        if (!prop) {
            prop = this.getEProp();
        }
        if (prop) {
            // console.log('Getting prop label - ', prop, (prop.label ? prop.label : prop.id));
            return prop.label ? prop.label : prop.id;
        } else {
            return '';
        }
    }

    buildPropLabel(prop = this.getEProp()) {
        const propLabel = this.getLabel(prop) + ':';
        return (
            <span style={this.getStyles()}>
                {propLabel}
                {this.buildPropDesc()}
            </span>
        );
    }

    buildPropDesc(prop?: EntityProp) {
        if (!prop) {
            prop = this.getEProp();
        }
        let propDesc = prop?.description;
        if (!propDesc) {
            return null;
        }
        // console.log('description : ', propDesc);
        return (
            <span className='input-description'>
                {propDesc}
            </span>
        );
    }

    getAppID() {
        return (this.props.appID ? this.props.appID : AppInfoService.getActiveApp()?.id);
    }

    isEditing() {
        return (this.state.editing === true);
    }

    /*
    getEProp() {
        if (this.eProp) {
            return this.eProp;
        }
        return this.props.entityProp;
    }
    */

    getPropChangedHandler = () => {
        let onPropChanged = this.state.onPropChanged;
        if (Common.checkType.String(onPropChanged)) {
            const fn = Source.getFunction(onPropChanged);
            if (fn) {
                onPropChanged = async (prop: any, data: any) => {
                    return await fn({ prop: prop, data: data, theComp: this });
                }

            }
        }

        if (!onPropChanged) {
            onPropChanged = this.getOtherProps()?.onPropChanged;
        }

        if (!onPropChanged) {
            onPropChanged = this.state.baseComp?.handleOnPropChanged;
        }

        return onPropChanged;
    }

    getEPropID() {
        let propID: any = this.getEProp()?.id;
        if (!propID) {
            propID = this.state.propID || this.props.propID;
        }
        return propID;
    }

    getOtherProps() {
        let oProps = this.state.otherProps || this.props.otherProps;
        if (!oProps) {
            oProps = {};
        }
        return oProps;
    }

    getExtras() {
        return this.getOtherProps()?.extras;
    }


    getPropLayout() {
        let layout = this.getOtherProps()?.propLayout;
        if (!layout) {
            layout = this.getExtraParams()?.propLayout;
        }
        return layout;
    }

    getPropLabelPosition() {
        let position = this.getOtherProps()?.propLabelPosition;
        if (!position) {
            position = this.getExtraParams()?.propLabelPosition;
        }
        return position;
    }

    getStyles() {
        const extras = this.getExtraParams();
        let style: any = extras?.style || super.getStyles() || {};
        return style;
    }

    getEntity() {
        let entity = Common.safeParse(this.props.entity);
        if (entity) {
            entity = EntityConstants.build(entity);
        }
        return entity;
    }

    getCategoryID() {
        let catID = this.getCategory()?.id;
        if (!catID) {
            catID = this.getEProp()?.category;
        }
        if (!catID) {
            catID = this.state.categoryID;
        }
        if (!catID) {
            // extract from value;
            const val = Common.safeParse(this.getDefaultValue());
            if (val && Common.checkType.Object(val)) {
                catID = val.category
            }
        }

        if (catID && !this.getCategory()) {
            EntityCategoryService.getCategory(catID)
                .then(
                    category => {
                        this.setState({ category: category });
                    }
                );
        }

        return catID;
    }

    getCategory() {
        return Common.safeParse(this.state.category);
    }

    getEPropDefaultValue() {
        return this.getEProp()?.defaultValue;
    }

    getDefaultValue() {
        let defValue = this.getPropValue();
        if (defValue === undefined) {
            defValue = this.state.defaultValue;
        }
        // console.log(`Prop: ${this.getEProp().label}, INITIAL def Value - ${defValue} of type - ${typeof(defValue)}`);

        if (defValue === undefined) {
            defValue = this.getEPropDefaultValue();
        }
        // console.log(`Prop: ${this.getEProp().label}, CONFIRMED def Vallue - ${defValue} of type - ${typeof(defValue)}`);
        if (defValue instanceof Object) {
            defValue = Common.stringify(defValue);
        }
        defValue = (defValue !== undefined && defValue !== null) ? '' + defValue : defValue;
        return defValue;
    }

    getDisplayMode() {
        return this.props?.displayMode;
    }

    isEntityTypeProp() {
        const datatype = this.getEProp()?.dataType;
        return EntityConstants.isEntityType(datatype);
    }

    isEntityInlineTypeProp() {
        const datatype = this.getEProp()?.dataType;
        return (datatype === EntityConstants.PropType.ENTITY_INLINE);
    }

    getPropValue(locale?: string) {
        const entity = this.getEntity();
        const eProp = this.getEProp();
        if (entity && eProp) {
            let defValue = EntityConstants.getValue(eProp, entity);
            if (eProp.translate && locale) {
                let translated: any = undefined;
                const translations = entity.getValue(Common.I18N.getI18N(eProp.id));
                translations?.forEach((trans: any) => {
                    if (trans.locale === locale) {
                        translated = trans.value;
                    }
                });
                if (translated) {
                    defValue = translated;
                }
            }
            return defValue;
        }
        return undefined;
    }

}


@UnoComponent({ id: 'BasePropViewer' })
export class BasePropViewer extends PropBaseComp {

    getFormat() {
        let format = this.getOtherProps()?.format;
        if (!format) {
            format = this.getExtras()?.format;
        }
        if (!format) {
            format = this.getExtraParams()?.format;
        }
        return format;
    }

}


@UnoComponent({ id: 'BasePropEditor' })
export class BasePropEditor extends PropBaseComp {
    editedValue: any;

    buildValue() {
        this.profiler.log('Build Base Prop Editor: ', this.state);
        return (
            <>
                {this.buildErrorMsg()}
                {this.buildInput()}
                {/* this.buildTranslation() */}
            </>
        );
    }

    buildErrorMsg() {
        const errors = this.getOtherProps()?.validationErrors;
        const errorMsg = this.getOtherProps()?.errorMsg || (errors ? errors[this.getEPropID()] : undefined);
        if (errorMsg) {
            // console.log('Display Prop Error: ', this.getEPropID(), errorMsg);
            return (<span style={{ color: 'red' }}>{`${errorMsg.error}`}</span>);
        } else {
            return undefined;
        }
    }

    buildInput() {
        return (
            <input
                type='text'
                className='input-wid'
                onBlur={this.handleChange}
                defaultValue={this.getDefaultValue()}
                style={this.getStyles()}
            />
        );
    }

    buildTranslation() {
        const entityPropI18N = { ...this.getEProp() };
        entityPropI18N.id = Common.I18N.getI18N(this.getEProp().id);
        const entityPropI18N_Value = Common.safeParse(this.getEntity()?.getValue(entityPropI18N.id));

        if (this.getEProp().translate) {
            return (
                <UC.Translation
                    entityProp={entityPropI18N}
                    defaultValue={entityPropI18N_Value}
                    otherProps={
                        {
                            onPropChanged: (eProp: EntityProp, value: any) => {
                                console.log('Translations: ', value);
                                const changeHandler = this.getPropChangedHandler();
                                if (changeHandler) {
                                    console.log(`Propogate translated value: `, value);
                                    changeHandler(entityPropI18N, value);
                                }
                            }
                        }
                    }
                />
            );
        } else {
            return <UC.Empty />;
        }
    }

    buildSTT() {
        if (this.isSTTEnabled()) {
            const onSTT = async (transcript: string) => {
                const inputEle: any = Common.Window.document.getElementById(this.getInputID());
                if (inputEle) {
                    transcript = (inputEle.value || '') + transcript;
                    inputEle.value = transcript;
                    inputEle.focus();
                }
                if (this.getEProp()) {
                    this.setPropValue(transcript);
                }
            }

            return <UC.STT key={this.getUniqueKey()} onSubmit={onSTT} placeholder={this.getPlaceholder()} />;
        } else {
            return undefined;
        }
    }

    isSTTEnabled = () => {
        let enabled = this.state.stt_enabled;
        if (enabled !== true && this.getEProp()) {
            // console.log('Prop With Extras: ', this.getEProp());
            enabled = this.getExtraParams()?.stt_enabled;
        }
        return enabled;
    }

    buildWriter() {
        if (this.isWriterEnabled()) {
            const onWrite = async (transcript: string) => {
                const inputEle: any = Common.Window.document.getElementById(this.getInputID());
                if (inputEle) {
                    transcript = (inputEle.value || '') + transcript;
                    inputEle.value = transcript;
                    inputEle.focus();
                }
                if (this.getEProp()) {
                    this.setPropValue(transcript);
                }
            }

            return <UC.Writer key={this.getUniqueKey()} onSubmit={onWrite} placeholder={this.getPlaceholder()} />;
        } else {
            return undefined;
        }
    }

    isWriterEnabled = () => {
        let enabled = this.state.writer_enabled;
        if (enabled !== true && this.getEProp()) {
            // console.log('Prop With Extras: ', this.getEProp());
            enabled = this.getExtraParams()?.writer_enabled;
        }
        return enabled;
    }

    showCleaner = () => {
        let show_cleaner = this.state.show_cleaner;
        if (show_cleaner !== true && this.getEProp()) {
            // console.log('Prop With Extras: ', this.getEProp());
            show_cleaner = this.getExtraParams()?.show_cleaner;
        }
        return show_cleaner;
    }

    buildCleaner = (title: string = 'Clear', forced: boolean = false,) => {
        if (this.showCleaner()) { // (forced || this.isSTTEnabled() || this.isWriterEnabled() || this.showCleaner())
            const doClean = async () => {
                const value = '';
                const inputEle: any = Common.Window.document.getElementById(this.getInputID());
                if (inputEle) {
                    inputEle.value = value;
                    inputEle.focus();
                }
                if (this.getEProp()) {
                    this.setPropValue(value);
                }
            }

            const Cleaner = (
                <div onClick={doClean} style={{ display: 'inline', margin: '5px' }}>
                    <img src={Images.Icon.clear} alt={title} className='tool-icon' title={title} />
                </div>
            );

            return Cleaner;
        } else {
            return undefined;
        }
    }

    getInputName = () => {
        return this.state.input_name || this.getInputID();
    }

    getPlaceholder() {
        return this.state.placeholder;
    }

    handleChange = (event: any) => {
        let val = this.getInputValue(event);
        if (Common.checkType.String(val)) {
            // console.log('String val: ', val, val.length, val.trim().length)
            val = val.trim();
            if (val.length === 0) {
                val = undefined;
            }

            if (val !== undefined) {
                switch (this.getEPropDataType()) {
                    case EntityConstants.PropType.NUMBER:
                        try {
                            val = Number.parseFloat(val);
                        } catch (e) { }
                        break;
                    case EntityConstants.PropType.BOOLEAN:
                        // val = (val.toLowerCase() === 'true');
                        break;
                }
            }
        }

        this.setPropValue(val);
    }

    setPropValue(newValue: any) {
        const oldValue = this.getDefaultValue();
        // console.log(`E-Prop: ${this.getEProp()?.label}, old value: ${JSON.stringify(oldValue)}, new value: ${JSON.stringify(newValue)}`);
        // console.log(`Props: `, this.props);
        // console.log(`Other Props: `, this.getOtherProps());
        if (oldValue !== newValue) {
            // console.log('Changed Value: ', newValue != undefined, newValue, this.getEProp());
            this.editedValue = newValue;
            const changeHandler = this.getPropChangedHandler();
            if (changeHandler) {
                // console.log(`Propogate edited value: `, newValue);
                changeHandler(this.getEProp(), newValue);
            }
        }
    }

    getInputValue(event: any) {
        return event?.target?.value;
    }

    getEditedValue() {
        return this.editedValue ? this.editedValue : this.getDefaultValue();
    }

    getDefaultValue() {
        const defValue = super.getDefaultValue();
        if (defValue &&
            this.getPropValue() &&
            defValue !== Common.stringify(this.getPropValue())
        ) {
            // set the prop value;
            // this.setPropValue(defValue);
        }

        return defValue;
    }
}


@UnoComponent({ id: 'Translation' })
export class Translation extends BasePropEditor {

    editing: boolean = false;

    buildComp() {
        return this.editing ? this.buildEditor() : this.buildAction();
    }

    buildAction() {
        const actions: any = [
            {
                id: 'Translate',
                action: this.toggleEditing,
            }
        ];
        return this.buildActionBar(actions, true, true);
    }

    buildEditor() {
        const label: string = `${this.getEPropID()} - ${this.getLabel()}`;
        // set multiplicity to infinite
        const multiEProp: EntityProp = { ...this.getEProp(), multiplicity: -1, translate: false, editor: 'LocaleTransEditor' }
        return (
            <>
                <UC.Dialog onClose={this.toggleEditing} title={label}>
                    <UC.PropEditor
                        entityProp={multiEProp}
                        otherProps={{
                            hideLabel: true,
                            onPropChanged: (eProp: EntityProp, entity: any) => {
                                this.setPropValue(entity);
                            }
                        }}
                        defaultValue={Common.safeParse(this.getDefaultValue())}
                    />
                </UC.Dialog>
            </>
        );
    }

    toggleEditing = () => {
        this.editing = !this.editing;
        this.setState({});
    }

}

@UnoComponent({ id: 'LocaleTransEditor' })
export class LocaleTransEditor extends BasePropEditor {
    locales: Array<any> = Common.I18N.Locales;

    localeVal = { locale: undefined, value: undefined };

    buildComp() {
        const defVal = Common.safeParse(this.getDefaultValue());
        if (defVal) {
            this.localeVal.locale = defVal.locale;
            this.localeVal.value = defVal.value;
        }

        this.locales.forEach(loc => {
            if (loc.id === this.localeVal.locale) {
                loc.isSelected = true;
            } else {
                loc.isSelected = false;
            }
        });

        const singleEProp: any = { ...this.getEProp(), multiplicity: 1, editor: undefined, translate: false };
        return (
            <>
                <UC.TypeAhead
                    inputTitle='Locale'
                    options={this.locales}
                    onSelect={this.handleOnLocaleSelect}
                    key={Common.getUniqueKey()}
                />
                <UC.PropEditor
                    entityProp={singleEProp}
                    otherProps={{
                        onPropChanged: this.handleOnValueChange,
                    }}
                    defaultValue={this.localeVal.value}
                />
            </>
        )
    }

    handleOnLocaleSelect = (val: any) => {
        this.localeVal.locale = val.id;
        this.setLocaleValue();
    }

    handleOnValueChange = (eProp: EntityProp, entity: any) => {
        this.localeVal.value = entity;
        this.setLocaleValue();
    }

    setLocaleValue = () => {
        this.setPropValue(this.localeVal);
    }
}