import React from 'react';
import { Common, EntityConstants, RemoteService } from '../../api';
import { UnoComponent } from '../../core';
import { UnoCoreBaseComp } from './uno-core.base.comp';
import { Source } from '../../api/source.service';

@UnoComponent({
    id: 'GeoLocation',
    label: 'Geo Location',
    props: [
        {
            id: 'action',
            label: 'onGetPosition Handler',
            dataType: EntityConstants.PropType.FUNCTION,
        },
    ],
    paletteable: true,
})
export class GeoLocationComp extends UnoCoreBaseComp {

    componentDidMount(): void {
        super.componentDidMount();
        if (navigator.geolocation) {
            // Common.showMessage(`Loading Geo Location`, false, 0, true);
            const watchID = navigator.geolocation
                .watchPosition(
                    this.showPosition,
                    (err: GeolocationPositionError) => {
                        Common.showMessage(`Problem loading Geo Location : ${err.message}`, false, 0, true);
                        this.reRender({ msg: err.message });
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 5000,
                    }
                );

            setTimeout(() => {
                navigator.geolocation.clearWatch(watchID);
            }, (1000 * 5));
        } else {
            this.reRender({ msg: 'Geo Location not supported on this device' });
        }
    }

    buildComp() {
        return (
            <>{this.state.msg ? this.state.msg : 'Getting Geo Location'}</>
        );
    }

    showPosition = async (position: GeolocationPosition) => {
        // Common.showMessage(`Geo Location: ${Common.stringify(position.coords.latitude)}, ${Common.stringify(position.coords.longitude)}`, false, 0, true);

        const coords = { lat: position.coords.latitude, long: position.coords.longitude }

        const addressAPI = '/geo/reverse';
        console.log('Current Geo Location: ', addressAPI);

        // const addressRes = await RemoteService.getData(await RemoteService.get(addressAPI, {}));
        const addressRes = await RemoteService.getData(await RemoteService.post(addressAPI, { geo: coords, }));

        const action = this.getAction();
        if (action) {
            const inputs = { theComp: this, data: position, address: { ...addressRes }, };
            // console.log('Function: ', Common.checkType.Function(action), ' String: ', Common.checkType.String(action));
            if (Common.checkType.Function(action)) {
                await action(inputs);
            } else if (Common.checkType.String(action)) {
                const fn = Source.getFunction(action);
                if (fn) {
                    await fn(inputs);
                }
            }
        }

        this.reRender({
            msg: (addressRes?.display_name || addressRes?.formatted)
                + ' Latitude: ' + coords.lat + ', Longitude: ' + coords.long,
        });

    }

    getAction = () => {
        return Common.safeParse(this.props.action);
    }

}