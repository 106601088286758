import React from 'react';
import { Common, EM, Images, UC, UnoComponent } from '../../../core';
import { UnoCoreBaseComp } from '../uno-core.base.comp';
import './reader-styles.css';

@UnoComponent({
    id: 'ScreenReader',
    label: 'Screen Reader',
    paletteable: true,
    props: [
        { id: 'language', label: 'Language' },
    ],
})
export class ScreenReaderComp extends UnoCoreBaseComp {
    private reader = window.speechSynthesis;
    private cancelTimer: any;

    buildComp(): JSX.Element | null | undefined {
        if (this.reader) {
            return (
                <>
                    <UC.Button
                        key={Common.getUniqueKey('button_')}
                        styleClasses={'image-button'} // `image-button`
                        styles={
                            {
                                background: this.isEnabled() ? 'green' : 'none',
                                padding: '5px',
                                margin: '0px 5px'
                            }
                        }
                        action={
                            async () => {
                                this.reRender({ enabled: !this.isEnabled() }, () => {
                                    if (this.isEnabled()) {
                                        window.document.body.addEventListener('click', this.read);
                                    } else {
                                        window.document.body.removeEventListener('click', this.read);
                                        if (this.reader.speaking) {
                                            this.reader.cancel();
                                        }
                                    }
                                });
                            }
                        }
                        label='Reader'
                    >
                        <img
                            src={Images.Icon.reader}
                            alt='reader'
                            title={`Click to ${this.isEnabled() ? 'De-activate' : 'Activate'} the Reader`}
                            className={`tool-icon `}
                        />
                        <span></span>
                    </UC.Button>
                </>
            );
        } else {
            return (<></>);
        }
    }

    speak = (text: string, voice?: SpeechSynthesisVoice) => {
        const utter = new window.SpeechSynthesisUtterance();
        utter.lang = this.getLanguage();
        utter.text = text;

        if (voice) {
            utter.voice = voice;
        }

        if (this.reader.speaking) {
            this.reader.cancel();
            if (this.cancelTimer) {
                clearTimeout(this.cancelTimer);
            }

            this.cancelTimer = window.setTimeout(() => {
                this.reader.speak(utter);
            }, 100);
        } else {
            this.reader.speak(utter);
        }
    }

    isEnabled = () => {
        return this.state.enabled ? true : false;
    }

    read = (evt: any) => {
        if (this.isEnabled()) {
            const selection = window.getSelection()?.toString();
            const text = selection || evt.target?.innerText;
            // console.log(this.getLanguage(), ' - TO Read: ', text);
            if (text) {
                this.speak(text);
            }
        }
    }

    private getLanguage(): string {
        let lang = this.state.language || Common.Language.getDefaultLanguage(this.getAppID());
        if (['bn', 'hi', 'gu', 'kn', 'ml', 'mr', 'ta', 'te'].includes(lang)) {
            lang = 'hi';
        } else {
            lang = 'en';
        }

        return lang;
    }
}