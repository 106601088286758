import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { CSSProperties } from 'react';
import { AppInfoService } from '../../../@uno-app/service/app.info.service';
import { EntityCategoryService } from '../../../@uno-app/service/entity.category.service';
import { TriggerService } from '../../../@uno-entity/service/entity-trigger.service';
import { BaseEntity, EM, EntityCategory, EntityConstants, EntityProp, FilterConstants, RemoteService, ValidatorRegistry } from '../../../@uno/api';
import { Source } from '../../../@uno/api/source.service';
import { CalendarOps, Common, ComponentDef, DesignerConstants, Images, Router, UC, UnoCompEvents, UnoComponent, UnoComponentManager, UnoCoreBaseComp } from '../../../@uno/core';
import { BasePropEditor, PROP_EDITOR_PROPS } from '../prop-base.comp';
import Editor from '@monaco-editor/react';
import { SessionManager } from '../../../@uno-app/service/session.service';
import { DialogConstants } from '../../../@uno/core/components/containers/dialog.comp';
import { Option } from '../../../@uno/core/components/select-box/selectbox.comp';
import { AppScreenService } from '../../../@uno-app/service/app.screen.service';

let MonacoEditorAutoCompleteDisposable: any;

@UnoComponent({ id: 'PropEditor' })
export class PropEditor extends BasePropEditor {

    buildInput() {
        let ValueElem = this.getEditor();
        const props = { ...this.props };
        const oProps = { ...this.getOtherProps(), hideLabel: true, };
        return (<ValueElem {...props} otherProps={oProps} key={this.getUniqueKey()} />);
    }
}

/*
class ImgLoader extends React.Component<{ onloadImage?: any, sizeLimit?: number, }, {}> {
    inputID = Common.getUniqueKey();
    previewImage: any = null;
    imgData: any = null;
    imgFile: any = null;
    sizeLimit = 100;

    constructor(props: any) {
        super(props);
        if (this.props.sizeLimit) {
            this.sizeLimit = this.props.sizeLimit;
        }

        this.state = {};
    }

    loadImage = async (e: any) => {
        e.preventDefault();
        // this.clearImage();
        const imgFile = e.target.files[0];

        if ((imgFile.size / 1000) > this.sizeLimit) {
            alert('Limit the file size to ' + this.sizeLimit + 'KB');
            this.clearImage();
            return;
        }

        const reader = new FileReader();
        reader.onload = async (e: any) => {
            const text = (e.target.result);
            // console.log(text)
            // alert(text)
            this.imgFile = imgFile;
            this.setImgData(text);
        };
        reader.readAsDataURL(imgFile)
    }

    setImgData = (imgData: any) => {
        this.imgData = imgData;
        this.setState({});
        if (this.props.onloadImage) {
            this.props.onloadImage(this.imgData);
        }
    }

    setPreviewImg = (imgData: string) => {
        this.previewImage = (
            <div>
                Preview: <img src={imgData} width='100' height='100' alt=''></img>
            </div>
        )
        this.setState({});
    }

    showPreview = () => {
        const previewTitle = 'Preview - ' + this.imgFile.name;
        this.previewImage = (
            <UC.Dialog onClose={this.closePreview} title={previewTitle}>
                <img src={this.imgData} alt=''></img>
            </UC.Dialog>
        )
        // console.log(this.previewImage);
        this.setState({});
    }

    closePreview = () => {
        this.previewImage = null;
        this.setState({});
    }

    clearImage = () => {
        // console.log('Clear Image');
        const inputEle = document.getElementById(this.inputID);
        if (inputEle) {
            // alert(inputEle.getAttribute('value'));
            inputEle.setAttribute('value', '');
        }
        this.imgData = null;
        this.imgFile = null;
        this.imgData = null;
        if (this.props.onloadImage) {
            this.props.onloadImage(this.imgData);
        }
        this.setState({});
    }

    buildPreviewLink = () => {
        let previewLink = (null);
        if (this.imgData) {
            previewLink = (
                <span>
                    <button onClick={this.showPreview}>Preview</button>
                    &nbsp; <button onClick={this.clearImage}>Clear</button>
                </span>
            );
        }

        return previewLink;

    }

    buildComp() {
        return (
            <div>
                <input
                    type='file'
                    onChange={(e) => this.loadImage(e)}
                    accept='image/*' id={this.inputID}
                />
                {this.buildPreviewLink()}
                {this.previewImage}
            </div>
        )
    }

}
*/

@UnoComponent({
    id: 'FileLoader',
    label: 'File Loader',
    paletteable: true,
    props: [
        // { id: 'appID', label: 'App ID', },
        { id: 'fileTypes', label: 'File Types', },
        { id: 'sizeLimit', label: 'Size Limit', dataType: EntityConstants.PropType.NUMBER },
        { id: 'onLoadFile', label: 'onLoadFile Handler', dataType: EntityConstants.PropType.FUNCTION },
        { id: 'hidePicker', label: 'Hide Pick-up a File tab?', dataType: EntityConstants.PropType.BOOLEAN, },
        { id: 'hideUploader', label: 'Hide Upload a File tab?', dataType: EntityConstants.PropType.BOOLEAN, },
        { id: 'showAudio', label: 'Show Audio tab?', dataType: EntityConstants.PropType.BOOLEAN, },
        { id: 'showVideo', label: 'Show Video tab?', dataType: EntityConstants.PropType.BOOLEAN, },
    ],
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class FileLoader extends UnoCoreBaseComp {
    inputID = Common.getUniqueKey('file_upload_');
    sizeLimit = 1 * 1024;

    constructor(props: any) {
        super(props);

        let sizeLimitProp: any = this.state.sizeLimit;
        if (sizeLimitProp) {
            if (Common.checkType.String(sizeLimitProp)) {
                try {
                    sizeLimitProp = Number.parseInt(sizeLimitProp);
                } catch (e) { }
            }
            this.sizeLimit = sizeLimitProp;
        }
    }

    buildComp() {
        const styles = { padding: '10px' };
        const tabs = [];
        const tabContents = [];
        // console.log('Build File Loader: ', this.state);
        // Pick a File Tab
        if (this.state.hidePicker !== true) {
            tabs.push({ name: 'Pick One', });
            tabContents.push((
                <UC.VSection styles={styles} >
                    <UC.EntitySearch
                        categoryID='uno_file'
                        appID={this.getAppID()}
                        otherProps={{
                            hideTitle: true,
                            testViewAccess: false,
                            onSelected: (file: any) => {
                                // console.log('File Selected: ', file);
                                if (file) {
                                    this.setFileContent(file);
                                }
                            },
                            extras: {
                                filters: [
                                    // restrict to creator of the file
                                    FilterConstants.createGroup(FilterConstants.Keyword.OR.id, [
                                        FilterConstants.create(EntityConstants.Attr.CREATED_BY_ID, SessionManager.activeSession?.person),
                                        FilterConstants.create(EntityConstants.Attr.CREATED_BY_ID, null),
                                        FilterConstants.create(EntityConstants.Attr.CREATED_BY_ID, undefined, FilterConstants.Operator.EMPTY.id),
                                        FilterConstants.create('is_public', true),
                                    ]),
                                ],
                            }
                        }}
                    />
                </UC.VSection>
            ));
        }

        // File Upload Tab
        if (this.state.hideUploader !== true) {
            tabs.push({ name: 'Upload New', });
            tabContents.push((
                <UC.Section styles={styles} >
                    <input
                        type='file'
                        id={this.inputID}
                        accept={this.state.fileTypes}
                    />
                    <button onClick={(e) => { this.loadFile(document.getElementById(this.inputID)); }}>Upload</button>
                </UC.Section>
            ));
        }

        // File Upload Tab
        if (this.state.showAudio) {
            tabs.push({ name: 'Record Audio', });
            tabContents.push((
                <UC.VSection styles={styles} >
                    <UC.VoiceRecorder
                        onUpload={
                            async (file: any) => {
                                return await this.loadFile(undefined, file);
                            }
                        }
                    />
                </UC.VSection>
            ));
        }

        // File Upload Tab
        if (this.state.showVideo) {
            tabs.push({ name: 'Record Video', });
            tabContents.push((
                <UC.VSection styles={styles} >
                    <UC.VideoRecorder
                        onUpload={
                            async (file: any) => {
                                return await this.loadFile(undefined, file);
                            }
                        }
                    />
                </UC.VSection>
            ));
        }

        return (
            <div key={Common.getUniqueKey()}>
                <UC.FileViewer
                    defaultValue={this.state.content}
                    onClick={
                        (inputs: any) => {
                            if (inputs.src) {
                                Common.showDialog(
                                    {
                                        id: Common.getUniqueKey('file_preview_'),
                                        comp: (<><img src={inputs.src} style={{ maxHeight: '100%', maxWidth: '100%', overflow: 'auto' }} /></>),
                                        onClose: () => { }
                                    }
                                );
                            }
                        }
                    }
                />
                <UC.TabbedPane tabs={tabs}>
                    {tabContents}
                </UC.TabbedPane>
            </div>
        )
    }

    loadFile = async (target?: any, fileData?: any) => {
        const limit = this.sizeLimit * 1000; // in bytes

        let file: File = target?.files[0] || fileData;
        const quality = file.size / limit;
        // console.log('File: ', quality, file.size, limit, file.name, file.type);
        if (quality > 1) {
            if (file.type.startsWith('image/')) {
                file = await this.doCompressImage(file, { quality: 1 / Math.sqrt(quality), type: file.type });
                //console.log('Compressed image: ', file.size, limit, file.name, file.type);
            } else {
                Common.showError(`Limit the file size to ${this.sizeLimit}KB of type ${file.type}`);
                return false;
            }
        }

        const appID = this.getAppID();
        const session = SessionManager.activeSession;
        if (target) { // it is an upload from local file system
            const fName = window.prompt('Save As', file.name);
            if (fName && fName.trim().length > 0) {
                file = new File([await file.arrayBuffer()], fName.trim(), { type: file.type, });
            }
        }
        const dlg = {
            id: Common.getUniqueKey('upload_file_'),
            content: `Uploading - ${file.name}`,
        };

        Common.showDialog(dlg);
        const content = await RemoteService.getData(await RemoteService.upload(file, {}, appID, session));
        Common.hideDialog(undefined, dlg.id);

        if (content) {
            Common.showMessage(`File Uploaded: ${content.name}`);
            this.setFileContent(content);
        }
        return true;
    }

    setFileContent(content: any) {
        let onLoadFileFn = this.state.onLoadFile;
        if (content && onLoadFileFn) {
            content = EntityConstants.truncate(content);
            onLoadFileFn = Common.safeParse(onLoadFileFn);

            if (Common.checkType.String(onLoadFileFn)) {
                onLoadFileFn = Source.getFunction(onLoadFileFn);
                onLoadFileFn({ theComp: this, file: content });
            } else if (Common.checkType.Function(onLoadFileFn)) {
                onLoadFileFn(content);
            }
            // console.log('specified a File: ', content, onLoadFileFn);
            this.setState({ content: content });
        }
        return content;
    }

    getAppID() {
        if (this.state.appID) {
            return this.state.appID;
        } else {
            return AppInfoService.getActiveApp()?.id;
        }
    }

    doCompressImage = async (file: File, { quality = 1, type = file.type }) => {
        // Get as image data
        const imageBitmap = await createImageBitmap(file);

        // Draw to canvas
        const canvas = document.createElement('canvas');
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(imageBitmap, 0, 0);

        // Turn into Blob
        const blob: any = await new Promise((resolve) =>
            canvas.toBlob(resolve, type, quality)
        );

        // Turn Blob into File
        return new File([blob], file.name, {
            type: blob.type,
        });
    };
}

const TEXT_EDITOR_PROPS: Array<EntityProp> = [
    {
        groupID: 'General', id: 'input_type', label: 'Input Type', editor: 'OptionSelector', viewer: 'OptionViewer',
        extras: {
            options: [
                { id: 'text', label: 'Text Box' },
                { id: 'password', label: 'Password' },
                { id: 'radio', label: 'Radio Button' },
                { id: 'checkbox', label: 'Check Box' },
                { id: 'button', label: 'Button' },
                { id: 'number', label: 'Number' },
                { id: 'color', label: 'Color' },
                { id: 'file', label: 'File' },
                { id: 'date', label: 'Date' },
                { id: 'time', label: 'Time' },
                { id: 'datetime-local', label: 'Date-Time' },
            ]
        },
    },
    { groupID: 'General', id: 'input_id', label: 'ID', },
    { groupID: 'General', id: 'input_name', label: 'Input Name', },
    { groupID: 'General', id: 'stt_enabled', label: 'Enable Speech-to-Text?', dataType: EntityConstants.PropType.BOOLEAN, },
    { groupID: 'General', id: 'writer_enabled', label: 'Enable Write-Pad?', dataType: EntityConstants.PropType.BOOLEAN, },
    { groupID: 'General', id: 'show_cleaner', label: 'Show Cleaner?', dataType: EntityConstants.PropType.BOOLEAN, },
    { groupID: 'Edit Configs', id: 'onInput', label: 'onInput Handler', dataType: EntityConstants.PropType.FUNCTION, },
    ...PROP_EDITOR_PROPS,
];

@UnoComponent({
    id: 'TextEditor',
    label: 'Input',
    props: [...TEXT_EDITOR_PROPS],
    paletteable: true,
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad, UnoCompEvents.onEnter],
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class TextEditor extends BasePropEditor {
    type = 'text';

    buildInput() {
        const MARGIN = 40;
        let space = 0;

        const STT = this.buildSTT();
        if (STT) {
            space += MARGIN;
        }

        const Writer = this.buildWriter();
        if (Writer) {
            space += MARGIN;
        }

        let Cleaner = this.buildCleaner();
        if (Cleaner) {
            space += MARGIN;
        }

        const width = `calc(100% - ${space}px)`;
        return (
            <>
                <span>{STT}</span>
                <span>{Writer}</span>
                <span>
                    <input
                        key={this.getUniqueKey('input_')}
                        id={this.getInputID()}
                        name={this.getInputName()}
                        type={this.getType()}
                        className={`input-wid ${this.getStyleClasses()}`}
                        onBlur={this.handleChange}
                        onInput={this.handleOnInput}
                        defaultValue={this.getDefaultValue()}
                        {...this.getExtraParams()}
                        style={{ width: width, ...this.getStyles(), }}
                        placeholder={this.getPlaceholder()}
                        onKeyUp={
                            (evt: any) => {
                                if (evt?.key === 'Enter' || evt?.keyCode === 13) {
                                    Common.notifyEvent(this, UnoCompEvents.onEnter, { evt: evt });
                                }
                            }
                        }
                    />
                </span>
                <span>{Cleaner}</span>
            </>
        );
    }

    getType = () => {
        return this.state.input_type || this.type;
    }

    handleOnInput = async (event: any) => {
        let data = event.target.value;
        if (Common.checkType.String(data) && data.trim().length === 0) {
            data = undefined;
        }


        let onInput = this.state.onInput;
        if (Common.checkType.String(onInput)) {
            onInput = Source.getFunction(onInput);
        }
        // console.log(this.getInputID(), ' Input Changed: ', data, event, onInput);

        if (Common.checkType.Function(onInput)) {
            await onInput({ evt: event, data: data, theComp: this });
        }
    }

}

@UnoComponent(
    {
        id: 'EmailEditor',
        label: 'Email Editor',
        paletteable: false,
        events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
        group: DesignerConstants.PaletteGroup.Editor.id,
    }
)
export class EmailEditor extends TextEditor {
    handleChange = (event: any) => {
        let value: any = this.getInputValue(event);
        // console.log(`JSON Editor - set Prop: `, value);
        const validatorJSON = ValidatorRegistry.find(EntityConstants.Validator.EMAIL);
        if (validatorJSON) {
            const error = validatorJSON(value);
            if (error) {
                Common.showError(error);
            } else {
                this.setPropValue(Common.safeParse(value));
            }
        }

        /*
        if (value && Common.checkType.String(value)) {
            const errMsg = `${this.getLabel()} - must be a JSON`;
            try {
                const parsedValue = Common.parse(value);
                if (parsedValue && Common.checkType.Object(parsedValue)) {
                    this.setPropValue(Common.stringify(parsedValue));
                } else {
                    alert(errMsg);
                }
            } catch (e) {
                alert(errMsg);
            }
        } else {
            this.setPropValue(value);
        }
        */
    }
}

const viewMultiline = (config?: any, clbk?: any, buildChildren?: Function, mode: string = DesignerConstants.Mode.PREVIEW) => {
    // console.log('preview Multiline: ', config);
    const sectionProp: any = { id: config.compID };
    const compProps = UnoComponentManager.getProps(config, mode);
    let contentText = UnoComponentManager.inflate(config.content, [compProps], mode);
    // console.log('preview Multiline. Content - ', compProps);
    return (
        <>
            <UC.MultilineViewer
                {...compProps}
                entityProp={sectionProp}
                defaultValue={contentText}
                key={EntityConstants.getUniqueKey()}
                otherProps={{ hideLabel: true }}
            />
            {/* buildChildren ? buildChildren('preview') : <UC.Empty /> */}
        </>);
}

const designMultiline = (config?: any, clbk?: any, buildChildren?: Function) => {
    // console.log(`Multiline Design Config: `, config);
    const TheEditor: any = UC[config.compID];
    const handleContentChange = (prop: any, content: any) => {
        // console.log(`Handle Multiline content change - `, content, clbk);
        config.content = content;
        if (clbk) {
            clbk();
        }
    }

    const sectionProp: any = { id: config.id, }
    const otherProps: any = {};
    otherProps.hideLabel = true;
    otherProps.onPropChanged = handleContentChange;
    return (<>
        <TheEditor
            entityProp={sectionProp}
            otherProps={otherProps}
            defaultValue={config.content}
            onPropChanged={handleContentChange}
            key={EntityConstants.getUniqueKey()}
        />
        {/* buildChildren ? buildChildren('design') : <UC.Empty /> */}
    </>);
}

const liveMultiline = (config?: any, clbk?: any, buildChildren?: Function) => {
    // console.log(`Live Multiline -`, config);
    return viewMultiline(config, clbk, buildChildren, DesignerConstants.Mode.LIVE);
}

@UnoComponent(
    {
        id: 'MultilineEditor',
        label: 'Multiline Editor',
        icon: Images.Icon.text,
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Frequent.id,

        getPreview: viewMultiline,
        getDesign: designMultiline,
        getLive: liveMultiline,

        props: [
            { groupID: 'General', id: 'isLatex', label: 'Is a LaTeX content?', dataType: EntityConstants.PropType.BOOLEAN, },
            { groupID: 'General', id: 'stt_enabled', label: 'Enable STT?', dataType: EntityConstants.PropType.BOOLEAN, },
            { groupID: 'General', id: 'writer_enabled', label: 'Enable Writer?', dataType: EntityConstants.PropType.BOOLEAN, },
            { groupID: 'General', id: 'show_cleaner', label: 'Show Cleaner?', dataType: EntityConstants.PropType.BOOLEAN, },
        ],
    }
)
export class MultilineEditor extends BasePropEditor {
    handleChange = (event: any) => {
        let val = this.getInputValue(event);
        if (val) {
            val = val.trim();
            if (val.length === 0) {
                val = undefined;
            }
        }
        const sanitizedVal = Common.sanitize(val);
        this.setPropValue(sanitizedVal);
    }

    buildInput() {
        return (
            <>
                <textarea
                    id={this.getInputID()}
                    className='input-wid'
                    onBlur={this.handleChange}
                    defaultValue={this.getEditedValue()}
                    {...this.getExtraParams()}
                />
                {this.buildSTT()}
                {this.buildWriter()}
                {this.buildCleaner()}
            </>
        );
    }
}

@UnoComponent(
    {
        id: 'HTMLEditor',
        label: 'HTML Editor',
        icon: Images.Icon.HTML,
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Frequent.id,
        events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
        getPreview: viewMultiline,
        getDesign: designMultiline,
        getLive: liveMultiline,
        props: [
            { groupID: 'General', id: 'isLatex', label: 'Is a LaTeX content?', dataType: EntityConstants.PropType.BOOLEAN, },
            { groupID: 'General', id: 'stt_enabled', label: 'Enable STT?', dataType: EntityConstants.PropType.BOOLEAN, },
            { groupID: 'General', id: 'writer_enabled', label: 'Enable Writer?', dataType: EntityConstants.PropType.BOOLEAN, },
            { groupID: 'General', id: 'show_cleaner', label: 'Show Cleaner?', dataType: EntityConstants.PropType.BOOLEAN, },
        ],
    }
)
export class HTMLEditor extends MultilineEditor {
    buildInput() {
        const editor = (
            <>
                <CKEditor
                    editor={ClassicEditor}
                    data={this.getEditedValue()}
                    onReady={(editor: any) => {
                        // You can store the 'editor' and use when it is needed.
                        // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event: any, editor: any) => {
                        // console.log({ event, editor, data });

                    }}
                    onBlur={(event: any, editor: any) => {
                        let data = editor.getData();
                        if (data) {
                            data = data.trim();
                            if (data.length === 0) {
                                return;
                            }
                            // console.log({ event, editor, data });
                            const sanitizedVal = Common.sanitize(data);
                            this.setPropValue(sanitizedVal);
                        }
                        // this.setPropValue(data);
                    }}
                    onFocus={(event: any, editor: any) => {
                        //console.log('Focus.', editor);
                    }}

                />
            </>
        );

        // console.log(`HTML Editor: `, editor);
        return editor;
    }
}

@UnoComponent(
    {
        id: 'SourceCodeEditor',
        label: 'Source Code Editor',
        props: [
            {
                groupID: 'General',
                id: 'type', label: 'Type',
                viewer: 'OptionViewer', editor: 'OptionSelector',
                extras: {
                    options: [
                        { id: 'javascript', label: 'JavaScript' },
                        { id: 'json', label: 'JSON' },
                        { id: 'css', label: 'CSS' },
                        { id: 'xml', label: 'XML' },
                        { id: 'html', label: 'HTML' },
                    ]
                }
            },
        ],
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Editor.id,
        getPreview: viewMultiline,
        getDesign: designMultiline,
        getLive: liveMultiline,
    }
)
export class SourceCodeEditor extends BasePropEditor {
    private id = Common.getUniqueKey('code_editor_');
    private editor: any = undefined;

    protected setChangedPropValue(value: any) {
        // console.log(`Function Editor - val: `, value);
        const error = this.validateValue(value);

        if (error) {
            Common.showError(error);
        } else {
            this.setPropValue(value);
        }
    }

    protected validateValue(value: any): string | undefined {
        const isValid = Source.validate(value);
        if (!isValid) {
            return 'Invalid JavaScript code.';
        }
        return undefined;
    }

    getEditorLanguage() {
        return (this.state.type || 'javascript').toLowerCase();
    }

    buildInput() {
        return this.buildEditor(this.getEditedValue());
    }

    buildEditor(value: string, readonly: boolean = !this.canEdit()) {
        const fontSize = 12;
        const style: CSSProperties = { fontSize: `${fontSize}px` };

        const MAX_ROWS = 50;
        let rowCount = 10;
        const setRowCount = (textValue: string | undefined) => {
            if (textValue) {
                const lines = textValue.split('\n')?.length;
                // console.log(`Lines in function editor: `, lines, value);
                if (lines > rowCount || rowCount > MAX_ROWS) {
                    rowCount = (lines > MAX_ROWS) ? MAX_ROWS : lines + 1;
                }
            }
        }

        if (this.state.fullScreen) {
            rowCount = MAX_ROWS;
        } else {
            setRowCount(value);
        }

        const editorLang = this.getEditorLanguage();

        const input = (
            <Editor
                height={`${fontSize * rowCount}px`}
                defaultLanguage={editorLang}
                defaultValue={value}
                beforeMount={this.initMonaco}
                onMount={(editor, monaco) => {
                    this.editor = editor;
                    this.doFormat();
                }}
                options={{ fontSize: fontSize, readOnly: readonly, minimap: { enabled: false }, }}
                onChange={(v, ev) => {
                    // setRowCount(v);
                    // this.setState({});
                }}

            />
        );

        const EditorComp = (
            <div
                id={this.id}
                key={Common.getUniqueKey('code_editor_')}
                onBlur={
                    (evt) => {
                        // console.log(this.id, evt);
                        if (this.editor) {
                            this.setChangedPropValue(this.editor.getValue());
                            this.doFormat();
                        }
                    }
                }
            >
                {this.getEditorActions(readonly)}
                {input}
            </div>
        )

        if (this.state.fullScreen) {
            return (
                <UC.Dialog
                    title={this.getLabel()}
                    screenMode={DialogConstants.Screen.FULL}
                    onClose={() => { this.setState({ fullScreen: false }); }}
                >
                    {EditorComp}
                </UC.Dialog>
            );

        } else {
            return EditorComp;
        }
    }

    doFormat = () => {
        this.editor?.getAction('editor.action.formatDocument').run();
    }

    canEdit() {
        return true;
    }

    initMonaco = (monaco: any) => {
        const editorLang = this.getEditorLanguage();
        const langs = monaco.languages;
        langs.html.registerHTMLLanguageService('xml', {}, { documentFormattingEdits: true });

        if (MonacoEditorAutoCompleteDisposable) {
            MonacoEditorAutoCompleteDisposable.dispose();
        }

        MonacoEditorAutoCompleteDisposable =
            langs.registerCompletionItemProvider(editorLang,
                {
                    provideCompletionItems: (model: any, position: any, context: any, token: any) => {
                        const textUntilPosition = model.getValueInRange({ startLineNumber: position.lineNumber, startColumn: 1, endLineNumber: position.lineNumber, endColumn: position.column })
                        const word = model.getWordUntilPosition(position)
                        const range = {
                            startLineNumber: position.lineNumber,
                            endLineNumber: position.lineNumber,
                            startColumn: word.startColumn,
                            endColumn: word.endColumn
                        }

                        const suggestionOptions = {
                            API: {
                                label: 'API',
                                kind: langs.CompletionItemKind.Text,
                                insertText: `API`,
                                range: range,
                                // command: { id: 'editor.action.insertLineAfter', title: 'insertLineAfter' }
                            },
                            ChildOfAPI: [
                                {
                                    label: 'Entity',
                                    kind: langs.CompletionItemKind.Text,
                                    insertText: `Entity`,
                                    range: range,
                                    // command: { id: 'editor.action.insertLineAfter', title: 'insertLineAfter' }
                                },
                                {
                                    label: 'Category',
                                    kind: langs.CompletionItemKind.Text,
                                    insertText: `Category`,
                                    range: range,
                                    // command: { id: 'editor.action.insertLineAfter', title: 'insertLineAfter' }
                                },],
                        }

                        let suggestions: Array<any> = [];
                        const theInput = word.word;

                        // case sensitive
                        switch (theInput) {
                            case 'API':
                                suggestionOptions.ChildOfAPI.forEach(opt => {
                                    suggestions.push(opt);
                                })
                                break;
                        }

                        // case insensitive
                        switch (theInput.toLowerCase()) {
                            case 'a':
                            case 'ap':
                                suggestions.push(suggestionOptions.API);
                                break;
                        }

                        if (suggestions.length > 0) {
                            // console.log('Monaco Auto: Word - ', word, textUntilPosition, position, range, suggestions,);
                        }

                        return {
                            suggestions: suggestions,
                        }
                    },
                }
            );

    }

    getEditorActions = (readonly: boolean = !this.canEdit()) => {
        return (
            <UC.Navigation
                navs={
                    [
                        {
                            id: 'copy',
                            label: 'Copy',
                            icon: Images.Icon.copy,
                            action: async () => {
                                const val = this.editedValue || this.getDefaultValue();
                                if (val) {
                                    await navigator.clipboard.writeText(val);
                                    Common.showMessage('Copied');
                                } else {
                                    Common.showError('Unable to Copy');
                                }
                            }
                        },
                        {
                            id: 'paste',
                            label: 'Paste',
                            icon: Images.Icon.paste,
                            hidden: readonly,
                            action: async () => {
                                const readText = await navigator.clipboard.readText();
                                if (readText) {
                                    this.setChangedPropValue(Common.safeParse(readText));
                                    this.reRender();
                                    Common.showMessage('Pasted');
                                } else {
                                    Common.showError('Unable to Paste');
                                }
                            }
                        },
                        {
                            id: 'fullscreen',
                            label: 'Full Screen',
                            action: () => { this.setState({ fullScreen: true }); },
                            icon: Images.Icon.maximize,
                            hidden: this.state.fullScreen,
                        },

                    ]
                }
                orientation='h'
                styles={{ float: 'right' }}
            />
        );
    }

}

@UnoComponent(
    {
        id: 'JSONEditor',
        label: 'JSON Editor',
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Editor.id,

        getPreview: viewMultiline,
        getDesign: designMultiline,
        getLive: liveMultiline,
    }
)
export class JSONEditor extends SourceCodeEditor {
    protected setChangedPropValue(value: any) {
        // console.log(`JSON Editor - set Prop: `, value);
        const validatorJSON = ValidatorRegistry.find(EntityConstants.Validator.JSON);
        if (validatorJSON) {
            const error = validatorJSON(value);
            if (error) {
                const errMsg = `${this.getLabel()} - must be a JSON. ${error}`;
                Common.showError(errMsg);
            } else {
                this.setPropValue(Common.stringify(Common.safeParse(value)));
            }
        }
    }

    getEditorLanguage() {
        return 'json';
    }

}

@UnoComponent(
    {
        id: 'CSSEditor',
        label: 'CSS Editor',
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Editor.id,

        getPreview: viewMultiline,
        getDesign: designMultiline,
        getLive: liveMultiline,
    }
)
export class CSSEditor extends SourceCodeEditor {
    getEditorLanguage() {
        return 'css';
    }

}

@UnoComponent(
    {
        id: 'XMLEditor',
        label: 'XML Editor',
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Editor.id,

        getPreview: viewMultiline,
        getDesign: designMultiline,
        getLive: liveMultiline,
    }
)
export class XMLEditor extends SourceCodeEditor {
    getEditorLanguage() {
        return 'xml';
    }

}


@UnoComponent({
    id: 'PasswordEditor',
    label: 'Password Editor',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class PasswordEditor extends TextEditor {
    type = 'password';

    buildComp(): JSX.Element {
        if ((this.state.show === true)) {
            this.type = 'text'
        } else {
            this.type = 'password'
        }

        const Viz = super.buildComp();
        const showPassWidth = this.canViewPwd() ? '50px' : '0px';
        return (
            <>
                <UC.Section styles={{ display: 'inline-block', width: `calc(100% - ${showPassWidth})` }}>
                    {Viz}
                </UC.Section>
                <UC.Section styles={{ display: this.canViewPwd() ? 'inline-block' : 'none', width: `calc(${showPassWidth})` }}>
                    <UC.FileViewer
                        key={Common.getUniqueKey('button_show_pwd_')}
                        styles={{ width: '24px', cursor: 'pointer' }}
                        onClick={
                            async () => {
                                this.reRender(
                                    {
                                        show: (this.state.show === true) ? false : true
                                    }
                                );
                            }
                        }

                        defaultValue={Images.Icon[this.state.show ? 'hide_pwd' : 'show_pwd']}
                    />
                </UC.Section>
            </>
        )
    }

    canViewPwd() {
        return true;
    }
}

@UnoComponent({
    id: 'NumberEditor',
    label: 'Number Editor',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class NumberEditor extends TextEditor {
    type = 'number';
}

@UnoComponent({
    id: 'BooleanEditor',
    label: 'Boolean Editor',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class BooleanEditor extends TextEditor {
    constructor(props: any) {
        super(props)
        const defVal = this.getDefaultValue();
        const checked: boolean = (defVal === undefined || defVal === 'false') ? false : true;

        this.state = { ...this.state, checked: checked };
        // console.log(`Boolean Editor: `, checked, defVal);
    }

    handleChange = (event: any) => {
        const checked = event.target.checked;
        if (this.getPropChangedHandler()) {
            this.getPropChangedHandler()(this.getEProp(), checked);
        }
        this.setState({ checked: checked })
    }

    buildInput() {
        const checked = this.state.checked
        return (
            <input
                type='checkbox'
                onChange={this.handleChange}
                checked={checked}
                style={this.getStyles()}
            />
        );
    }



}

@UnoComponent({
    id: 'DateTimeEditor',
    label: 'Date-Time Editor',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class DateTimeEditor extends TextEditor {
    type = 'datetime-local';

    getDefaultValue() {
        const ts: number = Number.parseInt(super.getDefaultValue());
        // console.log('The DateTime Value: ', ts, super.getDefaultValue());
        if (isNaN(ts)) {
            return undefined;
        } else {
            const val = new Date(ts);
            // console.log('The Date-Time Value: ', val, super.getDefaultValue(), Common.checkType.String(val));
            return CalendarOps.formatSQLDateTime(new Date(val));
        }
    }

    setPropValue(value: any) {
        if (value) {
            const valMS = new Date(value).getTime();
            // console.log('Setting date-time: ', value, valMS);
            super.setPropValue(valMS);
        } else {
            super.setPropValue(undefined);
        }
    }

}

@UnoComponent({
    id: 'DateEditor',
    label: 'Date Editor',
    props: [...PROP_EDITOR_PROPS],
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class DateEditor extends TextEditor {

    type = 'date';

    getDefaultValue() {
        const ts: number = Number.parseInt(super.getDefaultValue());
        // console.log('The Date Value: ', ts, super.getDefaultValue());
        if (isNaN(ts)) {
            return undefined;
        } else {
            const val = new Date(ts);
            // console.log('The Date Value: ', val, super.getDefaultValue(), Common.checkType.String(val));
            return CalendarOps.formatSQLDate(new Date(val));
        }
    }

    setPropValue(value: any) {
        if (value) {
            const valMS = CalendarOps.toStartTimeOfDay(new Date(value));
            // console.log('Setting Date: ', value, valMS);
            super.setPropValue(valMS);
        } else {
            super.setPropValue(undefined);
        }
    }

}

@UnoComponent({
    id: 'TimeEditor',
    label: 'Time Editor',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class TimeEditor extends TextEditor {
    type = 'time';

    getDefaultValue() {
        const ts: number = Number.parseInt(super.getDefaultValue());
        // console.log('The Time Value: ', ts, super.getDefaultValue());
        if (isNaN(ts)) {
            return undefined;
        } else {
            return CalendarOps.msToTime(ts, false);
        }
    }

    setPropValue(value: any) {
        if (value) {
            const valMS = CalendarOps.timeToMS(value);
            // console.log('Set Time Val:', value, valMS);
            super.setPropValue(valMS);
        } else {
            super.setPropValue(undefined);
        }
    }

}

@UnoComponent({
    id: 'MD5EncryptedEditor',
    label: 'Encrypted Input',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class MD5EncryptedEditor extends PasswordEditor {
    handleChange = (event: any) => {
        const val = event.target.value;
        const p = this.props.entityProp;
        if (this.getPropChangedHandler()) {
            this.getPropChangedHandler()(p, Common.encrypt(val));
        }
    }

    canViewPwd(): boolean {
        return false;
    }
}

@UnoComponent({
    id: 'ColorEditor',
    label: 'Color Palette',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class ColorEditor extends TextEditor {
    type = 'color';


    componentDidMount() {
        super.componentDidMount();
        document.getElementById(this.getInputID())?.addEventListener('input', this.inputHandler);
    }

    componentWillUnmount() {
        document.getElementById(this.getInputID())?.removeEventListener('input', this.inputHandler);
        super.componentWillUnmount();
    }

    inputHandler = (e: any) => {
        this.handleChange(e);
    }

}

@UnoComponent({
    id: 'FileEditor',
    label: 'File Editor',
    paletteable: false,
    props: [
        { groupID: 'General', id: 'fileTypes', label: 'SupportednFile Types' },
        { groupID: 'General', id: 'size', label: 'Size Limit', dataType: EntityConstants.PropType.NUMBER },
        { groupID: 'Visiblity', id: 'hidePicker', label: 'Hide Pick-up a File tab?', dataType: EntityConstants.PropType.BOOLEAN, },
        { groupID: 'Visibility', id: 'hideUploader', label: 'Hide Upload a File tab?', dataType: EntityConstants.PropType.BOOLEAN, },
        { groupID: 'Visibility', id: 'showAudio', label: 'Show Audio tab?', dataType: EntityConstants.PropType.BOOLEAN, },
        { groupID: 'Visibility', id: 'showVideo', label: 'Show Video tab?', dataType: EntityConstants.PropType.BOOLEAN, },
    ],
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class FileEditor extends BasePropEditor {

    fileData: any = null;
    constructor(props: any) {
        super(props)
        const val = this.getDefaultValue();
        if (val) {
            this.fileData = val;
        }
    }

    handleOnLoadFile = (fileData: any) => {
        // alert('Loaded:' + fileData);
        Common.notifyEvent(this, 'onUpload', { prop: this.getEProp(), data: fileData });

        const propChangedHandler = this.getPropChangedHandler();
        // console.log('Loaded File:', fileData, propChangedHandler);
        if (propChangedHandler && Common.checkType.Function(propChangedHandler)) {
            propChangedHandler(this.getEProp(), fileData);
        }

        const extraOnLoadFile = Common.parseFn(this.getExtraParams()?.onLoadFile);
        if (extraOnLoadFile && Common.checkType.Function(extraOnLoadFile)) {
            extraOnLoadFile(this.getEProp(), fileData);
        }
        this.fileData = fileData;
        // console.log('File Data: ', fileData);
        this.reRender();
    }

    buildInput() {
        const propChangedHandler = this.getPropChangedHandler();
        if (!propChangedHandler) {
            console.log('No propChangedHandler in File Editor: ', this.state);
        }
        // console.log('Build File Editor: ', this.fileData, );
        return (
            <span key={Common.getUniqueKey()}>
                {this.fileData ? <UC.Button action={() => { this.handleOnLoadFile(undefined) }}>Clear</UC.Button> : undefined}
                <UC.FileLoader
                    onLoadFile={this.handleOnLoadFile}
                    appID={this.getAppID()}
                    fileTypes={this.getFileType()}
                    sizeLimit={this.getSizeLimit()}
                    content={this.fileData}
                    hidePicker={this.state.hidePicker || this.getOtherProps()?.hidePicker}
                    hideUploader={this.state.hideUploader || this.getOtherProps()?.hideUploader}
                    showAudio={this.state.showAudio || this.getOtherProps()?.showAudio}
                    showVideo={this.state.showVideo || this.getOtherProps()?.showVideo}
                />
            </span>
        );
    }

    getFileType() {
        const props: any = this.props;
        const fileTypes = this.getExtraParams().fileTypes;
        return props.fileTypes || fileTypes || '*/*';
    }

    getSizeLimit() {
        const props: any = this.props;
        return props.size ? Number.parseInt(props.size) : 1024 * 50; // 50MB
    }
}

@UnoComponent({
    id: 'ImageEditor',
    label: 'Image Editor',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class ImageEditor extends FileEditor {
    getFileType(): string {
        return 'image/*';
    }
    /*
    getSizeLimit(): number {
        return 1024 * 5;
    }
    */
}
/*
export class ImageEditor extends BasePropEditor {
    imgData: any = null;
    constructor(props: any) {
        super(props)
        const val = this.getDefaultValue();
        if (val) {
            this.imgData = val;
        }
    }

    handleOnLoadImage = (img: any) => {
        // alert('Loaded:' + img);
        if (img) {
            if (this.getPropChangedHandler()) {
                this.getPropChangedHandler()(this.getEProp(), img);
            }
            this.imgData = img;
            this.setState({});
        }
    }

    buildPreview = () => {
        const otherProps = {
            ...this.props.otherProps,
            hideLabel: true,
        };
        if (this.imgData) {
            // const PropViewWidget = WidgetRegister.getViewWidget(EntityConstants.PropType.IMAGE);
            return (
                <span>
                    <span onClick={() => { this.imgData = undefined; this.setPropValue(undefined) }} className='clickable'>
                        Clear
                    </span>
                    <UC.PropValue
                        defaultValue={this.imgData}
                        entityProp={this.getEProp()}
                        otherProps={otherProps}
                    />
                </span>
            );
        }
        return (<></>);
    }

    buildInput() {
        return (
            <span>
                <ImgLoader onloadImage={this.handleOnLoadImage}></ImgLoader>
                {this.buildPreview()}
            </span>
        );
    }
}
*/

@UnoComponent({
    id: 'OptionSelector',
    label: 'Option Selector',
    props: [
        { groupID: 'General', id: 'options', label: 'Options', dataType: EntityConstants.PropType.JSON },
        { groupID: 'General', id: 'multi_select', label: 'Can Select Multiple?', dataType: EntityConstants.PropType.BOOLEAN },
        { groupID: 'General', id: 'canTypeahead', label: 'Can Typeahead?', dataType: EntityConstants.PropType.BOOLEAN },
        { groupID: 'Event', id: 'onSelect', label: 'On Select Handler', dataType: EntityConstants.PropType.FUNCTION },
    ],
    paletteable: true,
    // events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad],
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class OptionSelector extends BasePropEditor {

    buildInput() {
        let options: Array<any> = this.getOptions() || [];
        // console.log(this.constructor.name, ' Building Options: ', options);

        if (this.canTypeahead()) {
            const typeOptions = options.map(opt => {
                opt = { ...opt };
                if (opt.originalAction) {
                    opt.action = opt.action;
                }
                opt.isSelected = false;
                return opt;
            });

            const onTypeSelect = (opt: Option) => {
                const option = options.filter(orgOpt => {
                    if (orgOpt.id = opt.id) {
                        orgOpt.isSelected = opt.isSelected;
                        return true;
                    } else {
                        return false;
                    }
                })[0];

                // console.log('Type Selected:', option);
                this.handleOnSelect(option);
                this.reRender({ option: options });
            }

            const typeAhead: Option | undefined = {
                id: 'finder',
                label: 'Finder',
                canSelect: false,
                buildView: () => {
                    return (
                        <UC.TypeAhead
                            options={typeOptions}
                            onSelect={onTypeSelect}
                            key={this.getUniqueKey()}
                        />
                    )
                }
            }

            if (Array.isArray(options)) {
                options.unshift(typeAhead);
            } else {
                options = [typeAhead];
            }
        }

        return (
            <UC.SelectBox
                options={options}
                onSelect={this.handleOnSelect}
                multiSelect={this.isMultiSelect()}
                key={Common.getUniqueKey()}
            />
        );
    }

    handleOnSelect = (opt: any) => {
        // console.log('Selected Option: ', opt);
        let selectedVals: any = undefined;
        if (this.isMultiSelect()) {
            let selections: Array<any> | undefined = [];
            for (let o of opt) {
                if (o.id) {
                    selections?.push(o.id);
                } else {
                    selections = undefined;
                    break;
                }
            }
            selectedVals = selections;
        } else {
            selectedVals = opt.id;
        }

        const action = this.state.onSelect;
        // console.log('Selected Option: ', selectedVals, action);

        if (action) {
            const inputs = { theComp: this, data: selectedVals };
            if (Common.checkType.Function(action)) {
                action(inputs);
            } else if (Common.checkType.String(action)) {
                const fn = Source.getFunction(action);
                if (fn) {
                    fn(inputs);
                }
            }
        } else {
            this.setPropValue(selectedVals);
        }
    }

    getOptions() {
        // console.log('Selected Vals: ', this.getDefaultValue(), selectedVals);
        let optVals = Common.safeParse(this.state.options);
        if (!optVals || !Array.isArray(optVals)) {
            optVals = this.getExtraParams()?.options;
        }

        let options: Array<any> = optVals?.map(
            (opt: any) => {
                if (!Common.checkType.Object(opt)) {
                    opt = { id: opt, label: opt };
                }
                return {
                    id: opt.id,
                    label: opt.label ? opt.label : opt.id,
                    isSelected: this.isSelected(opt.id),
                }
            }
        );

        return options || [];
    }

    canTypeahead(): boolean {
        return this.state.canTypeahead || false;
    }

    isSelected(opt: any) {
        let selectedVals: Array<any> = [];
        let defVal = this.getDefaultValue();
        if (defVal) {
            defVal = Common.safeParse(defVal);
            if (this.isMultiSelect() && Array.isArray(defVal)) {
                selectedVals = defVal;
            } else {
                selectedVals.push(defVal);
            }
        }

        const matchIndex = selectedVals.findIndex((v, i, arr) => {
            if (Common.isEqual(opt, v)) {
                return true;
            }
        });
        // console.log('Is Selected: ', matchIndex, opt, defVal);
        return (matchIndex >= 0);
    }

    isMultiSelect() {
        const canMultiSelect = this.state.multi_select ?
            Boolean(this.state.multi_select).valueOf() :
            this.getExtraParams()?.multiSelect;
        return (canMultiSelect !== undefined) ? canMultiSelect : false;
    }

    sort(options: Array<any>, fieldName: string = 'label') {
        options.sort((a: any, b: any) => {
            return (a[fieldName] < b[fieldName]) ? -1 : 1;
        });
        return options;
    }
}

@UnoComponent({
    id: 'TypeAheadEditor',
    label: 'Type-Ahead Editor',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class TypeAheadEditor extends OptionSelector {
    buildInput() {
        return <UC.TypeAhead
            options={this.getOptions()}
            onSelect={this.handleOnSelect}
            multiSelect={this.isMultiSelect()}
            key={this.getUniqueKey()}
        />
    }
}

@UnoComponent({
    id: 'DatatypeSelector',
    label: 'Datatype Selector',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class DatatypeSelector extends OptionSelector {
    getOptions() {
        const propTypes: any = EntityConstants.PropType;
        const options: Array<any> = Object.keys(propTypes).map(
            (pType: string) => {
                const pTypeVal = propTypes[pType];
                const option: any = { id: pTypeVal, label: pType };
                option.isSelected = this.isSelected(option.id);

                return option;
            }
        );
        this.sort(options);
        return options;
    }

    canTypeahead(): boolean {
        return true;
    }

}

@UnoComponent({
    id: 'ActionSelector',
    label: 'Action Selector',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class ActionSelector extends OptionSelector {
    getOptions() {
        const options: Array<any> = Object.values(Router.CatAction).map(
            (action: any) => {
                return {
                    id: action,
                    label: action.toUpperCase(),
                    isSelected: this.isSelected(action),
                }
            }
        );

        options.unshift({ id: Router.ANY, label: 'ANY', isSelected: this.isSelected(Router.ANY), });
        return options;
    }

    canTypeahead(): boolean {
        return true;
    }
}

@UnoComponent({
    id: 'CategorySelector',
    label: 'Category Selector',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class CategorySelector extends /*TypeAheadEditor*/ OptionSelector {
    // loading = true;
    // categories: Array<EntityCategory> | undefined;

    componentDidMount() {
        super.componentDidMount();

        this.loadCategories().then(cats => {
            // this.categories = cats;
            // console.log(`App Categories loaded: `, this.getCategoryAppID(), cats);
            // this.loading = false;
            this.reRender({ loading: false, categories: cats });
        });
    }

    loadCategories = async () => {
        let appID = this.getCategoryAppID();
        let categories: Array<EntityCategory> = Object.values(EntityCategoryService.getAppCategories(appID));
        if (categories?.length === 0) {
            categories = await EntityCategoryService.reloadCategories(appID);
        }
        return categories;
    }

    getOptions() {
        let options: Array<Option> = [
            { id: undefined, label: `Not Specified` },
            { id: Router.ANY, label: 'All', isSelected: this.isSelected(Router.ANY), },
        ];

        const buildOptions = (cats: Array<EntityCategory> = [], groupName: string | undefined = undefined) => {
            let choices: Array<any> = cats.map(
                (cat: EntityCategory) => {
                    const id = cat.id;
                    return {
                        id: id,
                        label: `${cat.label} - ${cat.id}`,
                        isSelected: this.isSelected(id),
                    }
                }
            );

            choices = this.sort(choices);

            if (groupName) {
                choices.unshift({
                    id: undefined,
                    label: groupName,
                    canSelect: false,
                    buildView: (nav: any) => {
                        return (
                            <>
                                <div style={{ fontWeight: 'bold', textDecoration: 'underline', textAlign: 'center' }}>
                                    {nav.label}
                                </div>
                            </>
                        );
                    }
                });
            }

            return choices;
        }

        // console.log(`Categories :`, this.categories);
        const categories: Array<any> | undefined = this.state.categories;
        if (categories) {
            const nonCoreCategories = categories.filter(c => {
                return (c.isCore !== true);
            });

            options = options.concat(buildOptions(nonCoreCategories, 'App Categories'));

            const coreCategories = categories.filter(c => {
                return (c.isCore === true);
            });

            options = options.concat(buildOptions(coreCategories, 'Core Categories'));
        }

        return options;
    }

    canTypeahead(): boolean {
        return true;
    }

    getCategoryAppID() {
        let appID = this.getAppID();
        const appPropID = this.getExtraParams()?.appID;
        if (appPropID) {
            let app = this.getEntity()?.getValue(appPropID);
            app = Common.safeParse(app);
            if (Common.checkType.String(app)) {
                appID = app;
            } else {
                appID = EntityConstants.build(app).getID();
            }
        }
        return appID;
    }

    buildInput() {
        if (this.state.loading) {
            return <UC.Loading target={this.reRender} />;
        } else {
            return super.buildInput();
        }
    }
}

@UnoComponent({
    id: 'PropertySelector',
    label: 'Property Selector',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class PropertySelector extends /* TypeAheadEditor*/ OptionSelector {
    getCatPropID = () => {
        return this.getExtraParams()?.catID;
    }
    getDependsOnProps(): string[] {
        const propIDs: Array<string> = [];
        if (this.getCatPropID()) {
            propIDs.push(this.getCatPropID());
        }
        return propIDs;
    }

    handleChangeInDependsOnProp(pid: string, from: any, to: any): void {
        if (pid === this.getCatPropID()) {
            this.reRender();
        }
    }

    getOptions() {
        let options: Array<any> = [];
        // console.log(`Prop Selector - EProp - `, this.getEProp(), this.getEntity());
        let catID = this.getEProp()?.category;
        if (!catID) {
            const catPropID = this.getCatPropID();
            const entity = this.getEntity();
            if (catPropID && entity) {
                catID = entity.getValue(catPropID);
            }
        }

        let category: EntityCategory | undefined = undefined;
        if (catID) {
            category = EntityCategoryService.getAppCategory(catID);
        }

        // console.log(`Prop Selector Category : `, catID, category, category?.props);
        if (category && category.props) {
            options = category.props.map(
                (prop: EntityProp) => {
                    const id = prop.id;
                    return {
                        id: id,
                        label: `${prop.label} - ${prop.id}`,
                        isSelected: this.isSelected(id),
                    }
                }
            );
            this.sort(options);

            options.unshift({
                id: undefined, label: `Not Specified`,
                action: (opt: any) => { this.setPropValue(opt.id) }
            });
        }

        return options;
    }
}

@UnoComponent({
    id: 'ComponentSelector',
    label: 'Component Selector',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class ComponentSelector extends TypeAheadEditor {

    getOptions() {
        const comps = UnoComponentManager.getDefs().map(comp => {
            return {
                id: comp.id,
                label: comp.label ? comp.label : comp.id,
                comp: comp,
                isSelected: this.isSelected(comp.id),
            }
        });
        this.sort(comps);
        return comps;
    }

    handleRefreshOptions = (hint: string, clbk: Function) => {
        const matches: Array<any> = [];
        if (hint && clbk) {
            const hintRE = new RegExp(hint.trim(), 'gi');
            Object.keys(UnoComponentManager.UNO_COMPS).forEach(
                (id: any) => {
                    const comp: ComponentDef = UnoComponentManager.getDef(id);
                    if (comp && comp?.getDesign && (comp.id?.match(hintRE) || comp.label?.match(hintRE))) {
                        const option: any = {
                            id: comp.id,
                            label: comp.label ? comp.label : comp.id,
                            buildView: () => {
                                return comp.label;
                            },
                            comp: comp,
                        }
                        matches.push(option);
                    }
                }
            );
        }
        clbk(matches);
    }

    buildInput() {
        const typeAhead = super.buildInput();

        return (
            <>
                {typeAhead}
                <UC.Button action={() => { this.setPropValue(undefined); this.reRender(); }} >Clear</UC.Button >
            </>
        );
    }

}

@UnoComponent({
    id: 'AppSelector',
    label: 'Application Selector',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class AppSelector extends OptionSelector {
    getOptions() {
        const appOptions: Array<any> = [
            { id: Router.ANY, label: 'ANY', isSelected: this.isSelected(Router.ANY), },
        ];

        const activeApp = AppInfoService.getActiveApp();
        if (activeApp) {
            appOptions.unshift({ id: activeApp.id, label: activeApp.title ? activeApp.title : activeApp.id });
        }

        return appOptions;
    }

}

@UnoComponent({
    id: 'StandardFunctionSelector',
    label: 'Standard Function Selector',
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class FunctionSelector extends BasePropEditor {
    loadingComplete: boolean = false;

    functions: Array<any> | undefined;
    selectedFn: any = undefined;
    defVal: any;

    handleOnSelect = (selectedFn: any) => {
        console.log(selectedFn)
        this.functions?.forEach((fn) => {
            if (fn.id === selectedFn.id) {
                this.selectedFn = fn;
                this.defVal = { ...this.selectedFn };
                this.setPropValue(this.defVal);
                this.setState({});
            }
        });
    }

    handleArgsSet = (eProp: EntityProp, entity: any) => {
        // console.log(`Setting function args: `, entity);
        this.defVal = { ...this.selectedFn, args: entity };
        this.setPropValue(this.defVal);
        // console.log(`function args: `, this.defVal);
        this.setState({});
    };

    componentDidMount() {
        super.componentDidMount();
        this.defVal = Common.safeParse(this.getDefaultValue());
        const appID = this.getAppID() ? this.getAppID() : AppInfoService.getActiveApp()?.id;
        // console.log(`Loading functions for app: ${appID}`);
        TriggerService.listFunctions(appID).then(
            fnList => {
                if (fnList && fnList.length > 0) {
                    this.functions = fnList;
                    this.loadingComplete = true;
                    this.setState({});
                }
            }
        );

    }

    buildInput() {
        if (this.loadingComplete) {
            return (
                <>
                    {this.buildSelector()}
                    {this.buildFunctionArgsEditor(this.selectedFn?.props, this.defVal?.args)}
                </>
            );
        } else {
            return (
                <UC.Loading target={this.reRender} />
            );
        }
    }

    buildSelector() {
        let options: Array<any> = [];
        if (this.functions) {
            options = this.functions.map(
                (fnDef: any) => {
                    const id = fnDef.id;
                    const label = `${fnDef.name}${fnDef.description ? `: ${fnDef.description}` : ''}`;

                    let selected = (this.selectedFn?.id === id || this.defVal?.id === id);
                    if (selected) {
                        this.selectedFn = fnDef;
                    }
                    // console.log(`Function Selector - Default Value:`, this.defVal);


                    return {
                        id: id,
                        label: label,
                        isSelected: selected,
                    };
                }
            );
        }

        return (<UC.TypeAhead options={options} onSelect={this.handleOnSelect} key={Common.getUniqueKey()} />)
    }

    buildFunctionArgsEditor = (props?: Array<any>, defaultValue?: BaseEntity) => {
        if (!props || props.length === 0) {
            // console.log(`No Fn Args to set.`)
            return null;
        } else {
            // console.log(`Build Funtion Args Editor. Default Value: `, defaultValue);
        }

        const labelFnArgs = this.selectedFn.name ? this.selectedFn.name : this.selectedFn.id;
        const catLabel = `Arguments - '${labelFnArgs}' ${this.selectedFn.description ? ` W- ${this.selectedFn.description}` : ''}`;

        const fnArgsCategory: EntityCategory = {
            id: 'uno_function_arguments',
            label: catLabel,
            props: props,
        };
        const eProp: EntityProp = {
            id: 'args',
            label: 'Arguments',
            dataType: EntityConstants.PropType.ENTITY_INLINE,
            category: fnArgsCategory.id,
        };

        if (defaultValue) {
            defaultValue = EntityConstants.build(defaultValue);
        } else {
            defaultValue = EntityConstants.buildEmpty(fnArgsCategory.id);
        }

        return (
            <UC.PropEditor
                category={fnArgsCategory}
                entityProp={eProp}
                defaultValue={defaultValue}
                otherProps={{
                    onPropChanged: this.handleArgsSet,
                    canViewMain: false,
                    hideTitle: true,
                }}
            />
        );

        /*
        return (
            <UC.Section title={fnArgsCategory.label} >
                                                                                        {
                                                                                            <UC.PropEditor
                                                                                                category={fnArgsCategory}
                                                                                                entityProp={eProp}
                                                                                                defaultValue={defaultValue}
                                                                                                otherProps={{
                                                                                                    onPropChanged: this.handleArgsSet,
                                                                                                    canViewMain: false,
                                                                                                    hideTitle: true,
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    </UC.Section>
                                                                                    );
                                                                                    */
    }

}

@UnoComponent({
    id: 'CustomPropEditor',
    label: 'Custom Property Editor',
    props: [
        { groupID: 'General', id: 'screen', label: 'Screen Entity', dataType: EntityConstants.PropType.ENTITY, category: 'uno_screen_def' },
    ],
    paletteable: false,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class CustomPropEditor extends BasePropEditor {
    componentDidMount(): void {
        super.componentDidMount();
        this.loadCustomScreen();
    }

    buildInput(): JSX.Element {
        let layout = this.state.layout;
        if (layout) {
            // layout = { ...layout }; // duplicate to avoid effect of caching.
            const props = {
                entityPropOriginal: { ...this.getEProp() },
                ...this.state,
                entityProp: undefined,
                defaultValue: this.getDefaultValue(),
                onValueChanged: async (prop: EntityProp, value: any) => { this.setPropValue(value); },
                baseEditor: this,
            };
            // console.log('Custom Prop Editor: ', props);

            layout.props = props;
            return (
                <>
                    <UC.LayoutRenderer
                        config={layout}
                        mode={DesignerConstants.Mode.LIVE}
                        key={Common.getUniqueKey('custom_prop_edit_')}
                    />
                </>
            );
        } else {
            return super.buildInput();
        }
    }

    async loadCustomScreen(screen?: any) {
        screen = screen || this.state.screen || this.getOtherProps()?.editorScreen || this.getExtras()?.editorScreen || this.getExtraParams()?.editorScreen;

        const screenDef = Common.safeParse(screen);
        if (screenDef) {
            const screen: any = Common.checkType.String(screenDef) ? { _id: screenDef, } : EntityConstants.build(screenDef);
            const appID = screen.app_id || this.getAppID();
            // console.log('Loading Screen for property editing: ', screen, appID);
            const layout = await AppScreenService.getScreenByID(screen._id, appID);
            this.reRender({ layout: layout });
        }
    }
}