import React from 'react';
import { Common, DesignerConstants, UC, UnoComponent, } from '../../../core';
import { NavStyleClasses } from '../navigations/navigation.comp';
import { Option, SELECT_BOX_PROPS, SelectBox } from './selectbox.comp';
import { UnoCoreBaseComp } from './../uno-core.base.comp';
import { EntityConstants } from '../../../api';
import { Source } from '../../../api/source.service';

export interface TypeAheadOption extends Option {
    doesMatch?: Function;
}

const DEFAULT_STYLE_CLASSES: NavStyleClasses = {
    appNavContent: ' typeahead-content ',
    appNavIcon: ' typeahead-icon ',
    appNavList: ' typeahead-list ',
    itemToggle: ' float-right ',
    orientationH: ' typeahead-horizontal',
    orientationV: ' typeahead-vertical',
    appNavLink: ' typeahead-link ',
    navLinkActive: ' typeahead-link-active ',
    navLinkInactive: ' typeahead-link-inactive ',
}

const DELAY_DEFAULT = 1;

@UnoComponent({
    id: 'TypeAhead',
    label: 'Type Ahead',
    props: [
        { groupID: 'Type Ahead', id: 'inputTitle', label: 'Title (Hint)' },
        { groupID: 'Type Ahead', id: 'delay', label: 'Wait for input', dataType: EntityConstants.PropType.NUMBER, },
        { groupID: 'Type Ahead', id: 'refreshOptions', label: 'On Refresh Options ', dataType: EntityConstants.PropType.FUNCTION, },
        { groupID: 'Type Ahead', id: 'onSelect', label: 'On Select', dataType: EntityConstants.PropType.FUNCTION, },
        ...SELECT_BOX_PROPS,
        { groupID: 'Edit Options', id: 'stt_enabled', label: 'Enable STT?', dataType: EntityConstants.PropType.BOOLEAN, },
        { groupID: 'Edit Options', id: 'writer_enabled', label: 'Enable Writer?', dataType: EntityConstants.PropType.BOOLEAN, },
    ],
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Editor.id,
})
export class TypeAhead extends SelectBox {
    inputID = Common.getUniqueKey();
    timerHandle: any = undefined;

    canProfile() {
        return false;
    }

    buildComp() {
        this.profiler.log(`Building TypeAhead. Selected - `, this.state.selected);
        return (
            <>
                {this.buildLabel()}
                {this.buildTypeahead()}
            </>
        );
    }

    buildLabel() {
        let labelView = null;
        const label = this.getLabel();
        if (label) {
            labelView = (
                <span >
                    {label}
                </span>
            );
        }
        return labelView;
    }

    private getLabel() {
        return this.state.label;
    }

    buildTypeahead() {
        const selectedView = this.buildSelected();
        if (selectedView) {
            return selectedView;
        } else {
            this.profiler.log('Nothing is pre-selected.');
            // this.profiler.log('Style Classes: ', this.getStyleClasses());
            const options = this.state.matchedOptions;
            let optionsView = null;
            if (options && options.length > 0) {
                optionsView = <UC.Navigation
                    navs={options}
                    key={Common.getUniqueKey()}
                    styleClasses={this.getStyleOverrides()}
                    howNavBar={true}
                />;
            }

            let defVal: string | undefined = this.state.hint;
            if (defVal && defVal.trim().length === 0) {
                defVal = undefined;
            }
            const tooltip = this.state.inputTitle ? this.state.inputTitle : this.getLabel();
            return (
                <span>
                    <span>
                        <input
                            id={this.getInputID()}
                            type='text'
                            onInput={this.handleTypeAhead}
                            defaultValue={defVal}
                            style={{ width: '100%', ...this.getStyles() }}
                            title={tooltip}
                            placeholder={tooltip}
                            className={` ${this.getStyleClasses()}`}
                        />
                        {this.buildSTT()}
                        {this.buildWriter()}
                    </span>
                    {optionsView}
                </span>
            );
        }
    }

    getInputID = () => {
        return this.state.input_id || this.inputID;
    }

    buildSelected() {
        // get first selected item
        let selected = this.state.selected;
        if (selected) {
            if (Array.isArray(selected)) {
                selected = { ...selected[0] };
            } else {
                selected = { ...selected };
            }
        }

        if (selected) {
            selected.action = this.clearSelect;
            this.profiler.log('Building TypeAhead for selected: ', selected,);
            return (
                <span>
                    <UC.Navigation
                        navs={[selected]}
                        key={Common.getUniqueKey()}
                        styleClasses={this.getStyleOverrides()}
                    />
                </span>
            );
        } else {
            return null;
        }
    }

    buildSTT() {
        if (this.isSTTEnabled()) {
            const onSTT = async (transcript: string) => {
                const inputEle: any = Common.Window.document.getElementById(this.getInputID());
                if (inputEle) {
                    inputEle.value = transcript;
                    this.updateOnHint(transcript);
                }
            }

            return <UC.STT onSubmit={onSTT} />;
        } else {
            return undefined;
        }
    }

    isSTTEnabled = () => {
        let enabled = this.state.stt_enabled;
        return enabled;
    }

    buildWriter() {
        if (this.isWriterEnabled()) {
            const onWrite = async (transcript: string) => {
                const inputEle: any = Common.Window.document.getElementById(this.getInputID());
                if (inputEle) {
                    inputEle.value = transcript;
                    this.updateOnHint(transcript);
                }
            }

            return <UC.Writer onSubmit={onWrite} />;
        } else {
            return undefined;
        }
    }

    isWriterEnabled = () => {
        let enabled = this.state.writer_enabled;
        return enabled;
    }


    getDefaultStyleClasses(): NavStyleClasses {
        return DEFAULT_STYLE_CLASSES;
    }

    clearSelect = () => {
        if (this.state) {
            const stateObj = { matchedOptions: undefined, selected: undefined, hint: undefined };
            this.reRender(stateObj);
            this.doOnSelect(undefined);
            this.profiler.log('Clear Selection.');
        }
    }

    handleTypeAhead = (event: any) => {
        let hint: string = event?.target?.value;
        // this.profiler.log('Finding matches for - ', hint);
        this.updateOnHint(hint);
    }

    getDelay = () => {
        return parseFloat((this.state.delay !== undefined) ? this.state.delay : DELAY_DEFAULT) * 1000;
    }

    updateOnHint(hint: string) {
        this.clearTimer();
        this.timerHandle = setTimeout(async () => {
            await this.updateMatches(hint);
            // this.profiler.log('Updated typeahead matches');
            this.clearTimer();
        }, this.getDelay());
    }

    clearTimer() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            // this.profiler.log('Cleared typeahead timer');
        }
        this.timerHandle = undefined;
    }

    async updateMatches(hint: any) {
        // this.profiler.log('Update matches: ', hint);
        let refreshOptions = this.state.refreshOptions;
        if (Common.checkType.String(refreshOptions)) {
            const fn = Source.getFunction(refreshOptions);
            if (fn) {
                refreshOptions = async (hint: any, clbk: any) => {
                    return await fn({ hint: hint, callback: clbk, theComp: this });
                }

            }
        }

        if (refreshOptions) {
            refreshOptions(hint,
                async (options: Array<TypeAheadOption>) => {
                    // this.attachOnSelectAction(options);
                    await this.updateMatchedOptions(hint, options);
                }
            );
        } else {
            const options = this.state.options;
            const matchingOptions = options?.filter(
                (opt: TypeAheadOption) => {
                    if (opt.doesMatch) {
                        return opt.doesMatch(hint, opt);
                    } else if (hint) {
                        hint = hint.trim();
                        const label: string = opt.label ? opt.label : opt.id.toString();
                        if (label.match(new RegExp(hint, 'gi'))) {
                            return true;
                        }
                    } else {
                        return false;
                    }
                }
            );
            // this.profiler.log('Updating Matched options: ', matchingOptions);
            await this.updateMatchedOptions(hint, matchingOptions);
        }
    }

    async updateMatchedOptions(hint: any, options?: any) {
        this.profiler.log('Refresh options. Hint - ', hint);
        this.reRender({ matchedOptions: await this.preProcessOptions(options), hint: hint, selected: undefined });
    }
}
