import React from 'react';
import { EntityConstants } from '../../@uno/api';
import { Common, DesignerConstants, UC, UnoComponent } from '../../@uno/core';
import { AppMenuService } from '../service/app.menu.service';
import { SessionManager } from '../service/session.service';
import { UnoAppBaseComp } from './uno-app.base.comp';

@UnoComponent({
    id: 'Menu',
    label: 'The Menu',
    props: [
        { id: 'orientation', label: 'Orientation' },
        { id: 'noTree', label: 'Not A Tree?', dataType: EntityConstants.PropType.BOOLEAN },
        { id: 'menuID', label: 'Menu ID' },
        { id: 'menu', label: 'Menu', dataType: EntityConstants.PropType.ENTITY, category: Common.CategoryID.MenuDef },
        { id: 'navs', label: 'Menu Items', dataType: EntityConstants.PropType.JSON, },
        { id: 'visibleChildCount', label: 'No of Visible Children', dataType: EntityConstants.PropType.NUMBER },
    ],
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class MenuComp extends UnoAppBaseComp {

    constructor(props: any) {
        super(props);
        this.state = { ...props };
    }

    componentDidMount() {
        super.componentDidMount();
        this.loadMenu();
    }

    buildComp() {
        const orientation: string = this.props.orientation;
        const isHorizontal = () => {
            return (orientation === 'h');
        }
        let navs = Common.safeParse(this.state.navs);
        // console.log(`Building ${this.navsList.length} primary navs`);
        let menuViews = undefined;
        if (navs?.length > 0) {
            menuViews = (
                <div key={Common.getUniqueKey()} style={{ display: (isHorizontal() ? 'inline-block' : 'block'), marginTop: '2px' }}>
                    <UC.Navigation
                        navs={navs}
                        orientation={orientation}
                        key={Common.getUniqueKey()}
                        isTree={(this.state.noTree !== undefined) ? !this.state.noTree : !isHorizontal()}
                        visibleChildCount={this.state.visibleChildCount}
                    />
                </div>
            );
        } else {
            menuViews = (<UC.Empty />);
        }

        return (
            <>
                {menuViews}
            </>
        );
    }

    private async loadMenu(appID: string = this.getAppID()) {
        const session = SessionManager.activeSession;
        if (appID === undefined) {
            return;
        }
        const menuID = this.state.menuID;
        const menu = this.state.menu;
        // console.log('Refresh menu for app - ', appID, session?.id, menuID, menu);
        if (menuID || menu) {
            const navs = await AppMenuService.findMenu(appID, menuID, Common.safeParse(menu), session);
            this.setState({ navs: navs });
        }
    }

}
