import { RemoteService } from "../../../@uno/api";

export const TableauAuthService = {
    getToken: async (appID: string, session: any) => {
        const postReq = await RemoteService.post('tableau/auth/token', { appID: appID, session: session });
        const tokenRes = await RemoteService.getData(postReq);
        console.log('Tableau Auth Token: ', tokenRes);
        if (tokenRes) {
            return tokenRes.token;
        } else {
            return undefined;
        }
    }
};