import { UnoModule } from '../@uno/core';
import { AggregatorFieldEditor, AggregatorFieldViewer, EntityAggregator } from './components/entity-aggregator.comp';
import { FilterResults } from './components/entity-filter-result.comp';

// The Module...
@UnoModule(
    {
        id: 'EntityFilterModule',
        imports: [

        ],
        components: [
            FilterResults,
            EntityAggregator,

            AggregatorFieldViewer, AggregatorFieldEditor,
        ],
    }
)
export class EntityFilterModule { }


