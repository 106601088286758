import { SessionManager } from '../../@uno-app/service/session.service';
import { BaseEntity, EntityConstants } from '../../@uno/api/entity.service';
import { RemoteService } from '../../@uno/api/remote.service';

class AuthorizationServiceImpl {

    find(input: { appID: any, categoryID: any, action: any, entityID: any }) {

        return new Promise<BaseEntity | undefined>(
            (resolve, reject) => {
                RemoteService
                    .post('auth/find', { selector: input, session: SessionManager.activeSession, appID: input.appID })
                    .then(result => {
                        result.json().then((permission: any) => {
                            resolve(permission);
                        }).catch(e => {
                            resolve(undefined);
                        });
                    }).catch(e => {
                        resolve(undefined);
                    });
            }
        );
    }

    getPermission = (entity: BaseEntity, action: string) => {
        return this.find({ appID: entity.getAppID(), categoryID: entity.getCategoryID(), entityID: entity.getID(), action: action, });
    }
}

export const AuthorizationService = new AuthorizationServiceImpl();
