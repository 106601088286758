import React from 'react';
import { EntityCategoryService } from '../../@uno-app/service/entity.category.service';
import { SessionManager } from '../../@uno-app/service/session.service';
import { PropMultipleViewer } from '../../@uno-entity-prop/components/viewers/prop-multiple-viewer.comp';
import { BaseEntity, EntityConstants, Common } from "../../@uno/api";
import { UC, UnoComponent } from "../../@uno/core";

// Worksheets - VIEW
@UnoComponent({
    id: 'WorksheetsView',
    label: 'View Worksheets',
    props: [],
    getDesign: () => { return (<div>View Worksheets - Design</div>); },
    getPreview: () => { return (<div>View Worksheets - Preview</div>); }
})
export class WorksheetsView extends PropMultipleViewer {

    sheets: Array<any> = [];
    activeSheetID: any;

    constructor(props: any) {
        super(props);
        this.sheets = this.getPropValue();
        if (!this.sheets) {
            this.sheets = [];
        } else {
            // this.sheets = Common.safeParse(this.sheets);
        }

        console.log('Opening Workbook - ', this.getEntity(), ' with sheets - ', this.sheets);
    }

    render() {
        if (!this.getEntity()) {
            return <UC.Empty />;
        }
        const worksheetTabs: Array<any> = this.getWorksheetTabs();
        return (
            <>
                <UC.TabbedPane
                    tabs={worksheetTabs}
                    position='bottom'
                    key={Common.getUniqueKey()}
                />
            </>
        );
    }

    addNewSheet = (sheetName?: string) => {
        if (!sheetName) {
            sheetName = 'Sheet' + (this.sheets.length + 1);
        }
        const newWS = EntityConstants.buildEmpty(this.getEProp().category);
        newWS.setName(sheetName);
        newWS.save(this.getAppID(), SessionManager.activeSession)
            .then(
                (ws: BaseEntity) => {
                    this.handleSheetAdded(ws);
                }
            );
    }

    private getWorksheetTabs = () => {
        if (!this.sheets || this.sheets.length === 0) {
            this.addNewSheet();

            return [{
                name: 'Loading...',
                id: 'loading',
                comp: (
                    <>
                        <UC.Loading  target={this.reRender} />
                    </>
                ),
            }];
        } else {
            // console.log('Sheets: ', this.sheets);
            const sheetTabs: Array<any> = this.sheets.map((sheet: BaseEntity, index) => {
                sheet = EntityConstants.build(sheet);
                if (!this.activeSheetID && index === 0) {
                    this.activeSheetID = sheet.getID();
                }
                const appID = this.getAppID()
                const sheetCatID = this.getEProp().category;
                // const sheetCategory = EntityCategoryService.getAppCategory(sheetCatID?sheetCatID:'', appID);
                return {
                    name: sheet.getName(),
                    id: sheet.getID(),
                    active: (sheet.getID() === this.activeSheetID),
                    comp: (
                        <>
                            <UC.WorksheetView
                                key={Common.getUniqueKey(sheet.getID())}
                                appID={appID}
                                categoryID={sheetCatID}
                                entityID={sheet.getID()}
                            />
                        </>
                    )
                }

            });

            // Add (+) to create a new sheet
            sheetTabs.push({
                name: '(+)',
                id: 'add',
                comp: (
                    <>
                        <button onClick={() => {
                            const sheetName = window.prompt('What is the name of the sheet?', `Sheet${this.sheets?.length + 1}`);
                            if (sheetName) {
                                this.addNewSheet(sheetName);
                                this.setState({});
                            }
                        }}><h1>Add a New Sheet</h1></button>
                    </>
                ),
            });
            return sheetTabs;
        }
    }


    handleSheetAdded = async (ws: BaseEntity) => {
        console.log('WS is Added - ', ws.getID());
        // alert(`Attach WS [${ws.toString()}] to Workbook`);
        let entity = this.getEntity();
        if (entity) {
            this.sheets = [...this.sheets, EntityConstants.buildTruncatedEntity(ws)];
            entity.setValue(this.getEPropID(), this.sheets);
            console.log('Attaching WS...')
            entity.save(entity.getAppID(), SessionManager.activeSession).then(
                () => {
                    // alert(`Attached. Refreshing.`);
                    this.activeSheetID = ws.getID();
                    this.setState({});
                }
            );
        } else {
            console.log(' No parent book to attach to.')
        }
    }
}