import { OpenVidu } from 'openvidu-browser';
import React from 'react';
import { Common, RemoteService } from '../../../@uno/api';
import { DesignerConstants, UC, UnoComponent, UnoCoreBaseComp } from '../../../@uno/core';

@UnoComponent(
    {
        id: 'OpenViduConf',
        label: 'OpenVidu Conference',
        paletteable: true,
        props: [
            { id: 'label', label: 'Label' },
            { id: 'confID', label: 'Conference ID', },
        ],
        group: DesignerConstants.PaletteGroup.Integration.id,
    }
)
export class OpenViduConf extends UnoCoreBaseComp {
    private OPENVIDU_SERVER_URL = 'https://vidu.iprimed.com';
    private OPENVIDU_SERVER_SECRET = '377068707416';

    private OV: OpenVidu | undefined;

    constructor(props: any) {
        super(props);
        const ovStates = {
            mySessionId: 'SessionA',
            myUserName: 'Participant' + Math.floor(Math.random() * 100),
            session: undefined,
            mainStreamManager: undefined, // Main video of the page. Will be the 'publisher' or one of the 'subscribers'
            publisher: undefined,
            subscribers: [],
        };
        this.state = { ...this.state, ...ovStates };
    }

    buildComp(): JSX.Element | null | undefined {
        const poweredBy = (
            <div style={{ fontSize: '8px', textDecoration: 'italic' }}>
                Powered By: <a href='https://openvidu.io/'> Open Vidu </a>
            </div>
        );

        const startViz = (
            <UC.Button
                action={() => {
                    // this.init();
                    const confID = this.getConfID() || prompt('Please Enter the conference ID', Common.getUniqueKey('conf_'));
                    if (confID) {
                        // const url = `https://demos.openvidu.io/openvidu-call/#/${confID}`;
                        const url = `https://uno-openvidu.centralindia.cloudapp.azure.com/#/${confID}`;
                        window.open(url, '_blank');
                    }
                }}
            >
                {this.getLabel()}
            </UC.Button>
        );
        const confViz = (
            <>
                <div>{this.state.mySession}</div>
            </>
        )
        return (
            <>
                {this.getSession() ? confViz : startViz}
                {poweredBy}
            </>
        );
    }

    getLabel = () => {
        return this.state.label || 'Start';
    }

    getConfID = () => {
        return this.state.confID;
    }

    init() {
        // --- 1) Get an OpenVidu object ---

        this.OV = new OpenVidu();

        // --- 2) Init a session ---

        this.reRender(
            {
                session: this.OV.initSession(),
            },
            async () => {
                // See next step

                var mySession = this.getSession();
                if (!mySession) {
                    console.error('Session not found');
                    return;
                }
                // --- 3) Specify the actions when events take place in the session ---

                // On every new Stream received...
                mySession.on('streamCreated', (event: any) => {
                    // Subscribe to the Stream to receive it. Second parameter is undefined
                    // so OpenVidu doesn't create an HTML video by its own
                    var subscriber = mySession.subscribe(event.stream, undefined);

                    //We use an auxiliar array to push the new stream
                    var subscribers = this.state.subscribers;

                    subscribers.push(subscriber);

                    // Update the state with the new subscribers
                    this.setState({
                        subscribers: subscribers,
                    });
                });

                // On every Stream destroyed...
                mySession.on('streamDestroyed', (event: any) => {
                    event.preventDefault();

                    // Remove the stream from 'subscribers' array
                    // this.deleteSubscriber(event.stream.streamManager);
                });

                // On every asynchronous exception...
                mySession.on('exception', (exception: any) => {
                    console.warn(exception);
                });

                // --- 4) Connect to the session with a valid user token ---

                // Get a token from the OpenVidu deployment
                const token = await this.getToken();
                if (token) {
                    // First param is the token got from the OpenVidu deployment. Second param can be retrieved by every user on event
                    // 'streamCreated' (property Stream.connection.data), and will be appended to DOM as the user's nickname
                    mySession.connect(token, { clientData: this.state.myUserName })
                        .then(async () => {
                            if (!this.OV) {
                                return;
                            }
                            // --- 5) Get your own camera stream ---

                            // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
                            // element: we will manage it on our own) and with the desired properties
                            let publisher = await this.OV.initPublisherAsync(undefined, {
                                audioSource: undefined, // The source of audio. If undefined default microphone
                                videoSource: undefined, // The source of video. If undefined default webcam
                                publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                                publishVideo: true, // Whether you want to start publishing with your video enabled or not
                                resolution: '640x480', // The resolution of your video
                                frameRate: 30, // The frame rate of your video
                                insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                                mirror: false, // Whether to mirror your local video or not
                            });

                            // --- 6) Publish your stream ---

                            mySession.publish(publisher);

                            // Obtain the current video device in use
                            var devices = await this.OV.getDevices();
                            var videoDevices = devices.filter(device => device.kind === 'videoinput');
                            var currentVideoDeviceId = publisher.stream.getMediaStream().getVideoTracks()[0].getSettings().deviceId;
                            var currentVideoDevice = videoDevices.find(device => device.deviceId === currentVideoDeviceId);

                            // Set the main video in the page to display our webcam and store our Publisher
                            this.setState({
                                currentVideoDevice: currentVideoDevice,
                                mainStreamManager: publisher,
                                publisher: publisher,
                            });
                        })
                        .catch((error: any) => {
                            console.log('There was an error connecting to the session:', error.code, error.message);
                        });
                }

            }
        );
    }

    getSession() {
        return this.state.session;
    }

    async getToken() {
        const sessionId = await this.createSession(this.state.mySessionId);
        return await this.createToken(sessionId);
    }

    async createSession(sessionId: any) {
        console.log('Creating Session for : ', sessionId);
        const response = await RemoteService.post(this.OPENVIDU_SERVER_URL + 'api/sessions', { customSessionId: sessionId },
            {
                'Authorization': 'Basic ' + btoa('OPENVIDUAPP:' + this.OPENVIDU_SERVER_SECRET),
                'Content-Type': 'application/json',
            }
        );
        console.log('Response for Creating Session: ', sessionId, response);
        return await RemoteService.getData(response) // The sessionId
    }

    async createToken(sessionId: any) {
        console.log('Creating token for session ID: ', sessionId, this.state);
        const response = await RemoteService.post(this.OPENVIDU_SERVER_URL + 'api/sessions' + sessionId + '/connections', {}, {
            headers: { 'Content-Type': 'application/json', },
        });
        console.log('Token creation reponse: ', sessionId, response);
        return await RemoteService.getData(response) // The sessionId
    }

    leaveSession() {

        // --- 7) Leave the session by calling 'disconnect' method over the Session object ---

        const mySession = this.state.session;

        if (mySession) {
            mySession.disconnect();
        }

        // Empty all properties...
        this.OV = undefined;
        this.reRender({
            mySessionId: 'SessionA',
            myUserName: 'Participant' + Math.floor(Math.random() * 100),
            session: undefined,
            mainStreamManager: undefined,
            publisher: undefined,
            subscribers: [],
        });
    }
}

class UserVideoComponent extends UnoCoreBaseComp {
    constructor(props: any) {
        super(props);

        this.handleVideoClicked = this.handleVideoClicked.bind(this);
    }

    getNicknameTag() {
        // Gets the nickName of the user
        return JSON.parse(this.props.streamManager.stream.connection.data).clientData;
    }

    handleVideoClicked(event: any) {
        // Triggers event for the parent component to update its main video display (other UserVideoComponent)
        if (this.props.mainVideoStream) {
            this.props.mainVideoStream(this.props.streamManager);
        }
    }
}

class OpenViduVideoComponent extends UnoCoreBaseComp {
    videoRef: any;

    constructor(props: any) {
        super(props);

        this.videoRef = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props && !!this.videoRef) {
            this.props.streamManager.addVideoElement(this.videoRef.current);
        }
    }

    buildComp() {
        return (
            <video autoPlay={true} ref={this.videoRef} />
        );
    }
}