import React from 'react';
import { SessionManager } from '../../../@uno-app/service/session.service';
import { Common } from '../../../@uno/api/common.service';
import { BaseEntity } from '../../../@uno/api/entity.service';
import { DesignerConstants, UnoComponent, Images } from '../../../@uno/core';
import { EntityEdit } from '../entity-edit.comp';

// ENTITY - NEW, EDIT
@UnoComponent({
    id: 'SignUp',
    label: 'Sign Up',
    props: [
    ],
    getDesign: () => { return (<div>Sign Up - Design</div>); },
    // paletteable:true,
    group: DesignerConstants.PaletteGroup.Layout.id,
})
export class SignUp extends EntityEdit {

    getTitle() {
        return 'Sign Up';
    }

    getSaveIcon() {
        return Images.Icon.signup;
    }

    getSaveTitle(): string {
        return this.getTitle();
    }

    getCategoryID(): any {
        return Common.CategoryID.Credential;
    }
    
    getPostSaveRedirect(entity: BaseEntity) {
        const appID: any = this.getAppID();
        return SessionManager.getSignInRoute(appID);
    }
 
}