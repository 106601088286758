import * as html2pdf from 'html2pdf.js';

export const printPDF = async (id: string | undefined, fileName: string = 'printout', element: any = undefined, options: any = { filename: fileName }) => {
  var viewElement: any = element ? element : (id ? document.getElementById(id) : undefined);
  if (!viewElement || viewElement === null) {
    return undefined;
  }

  var opt = {
    margin: 0.1,
    filename: fileName,
    image: { type: 'jpeg', quality: 0.20 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    ...options,
  };

  var worker = html2pdf().from(viewElement).set(opt).save();
  return worker;
}
