import { EntityPropModule } from '../@uno-entity-prop/entity-prop.module';
import { UnoModule } from '../@uno/core';
import { Permissions } from './components/auth/permissions.comp';
import { SignIn } from './components/auth/signin.comp';
import { SignUp } from './components/auth/signup.comp';
import { EntityEdit } from './components/entity-edit.comp';
import { EntityMain } from './components/entity-main.comp';
import { EntitySearch } from './components/entity-search.comp';
import { EntityView } from './components/entity-view.comp';
import { EntityCaraouselView } from './components/list-view/entity-caraousel.view.comp';
import { EntityListView } from './components/list-view/entity-list.view.comp';
import { MultiEntityView } from './components/list-view/multi-entity.view.comp';
import { EntityTableView } from './components/list-view/entity-table.view.comp';
import { MultiEntityEdit } from './components/list-edit/multi-entity.edit.comp';
import { EntityListEdit } from './components/list-edit/entity-list.edit.comp';
import { EntityCaraouselEdit } from './components/list-edit/entity-caraousel.edit.comp';
import { EntityTableEdit } from './components/list-edit/entity-table.edit.comp';
import { EntityFilterModule } from '../@uno-filter/entity-filter.module';
import { EntityTreeView } from './components/list-view/entity-tree.view.comp';
import { ChangeHistory } from './components/history/history.comp';
import { EntityQuickView } from './components/entity-quickview.comp';
import { EntitySelector } from './components/entity-selector.comp';
import { EntityChipsView } from './components/list-view/entity-chips.view.comp';
import { UnoGoogleLoginComp } from './components/auth/google.auth.comp';
import { UnoMicrosoftLoginComp } from './components/auth/ms.auth.comp';
import { UnoFacebookLoginComp } from './components/auth/facebook.auth.comp';
import { VersionComp } from './components/version-mgmt/version.comp';

// The Module...

@UnoModule(
    {
        id: 'EntityModule',
        imports: [
            EntityPropModule,
            EntityFilterModule,
        ],
        components: [
            EntityMain,
            EntityEdit,
            EntityView,
            EntityQuickView,
            EntitySearch,
            EntitySelector,

            Permissions,
            ChangeHistory,
            VersionComp,

            MultiEntityView,
            EntityListView,
            EntityCaraouselView,
            EntityTableView,
            EntityTreeView,
            EntityChipsView,

            MultiEntityEdit,
            EntityListEdit,
            EntityCaraouselEdit,
            EntityTableEdit,

            SignUp,
            SignIn,
            UnoGoogleLoginComp,
            UnoMicrosoftLoginComp,
            UnoFacebookLoginComp,
        ],
    }
)
export class EntityModule { }


