import React from 'react';
import { EntityFilterService } from '../../@uno-filter/service/entity-filter.service';
import { BaseEntity, Common, EntityConstants, Router, EntityCategory } from '../../@uno/api';
import { Condition, ConditionGroup, FilterConstants } from '../../@uno/api/filter.service';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent } from '../../@uno/core';
import { EntityViewTypes } from '../service/entity-view.service';
import { EntityBaseComp, ENTITY_COMP_PROPS } from './entity-base.comp';
import { Source } from '../../@uno/api/source.service';
import { AppMenuService } from '../../@uno-app/service/app.menu.service';
import { SessionManager } from '../../@uno-app/service/session.service';
// import { RouteService } from '../../@uno/api/route.service';

export const SearchEvent = {
    onSelected: 'onSelected',
}

// ENTITY - SEARCH
@UnoComponent({
    id: 'EntitySearch',
    label: 'Entity Search',
    paletteable: true,
    props: [
        ...ENTITY_COMP_PROPS,
        { groupID: 'Search', id: 'quickSearch', label: 'Quick Search?', dataType: EntityConstants.PropType.BOOLEAN, },
        { groupID: 'Search', id: 'hideSwitcher', label: 'Hide Switcher?', dataType: EntityConstants.PropType.BOOLEAN, },
        { groupID: 'Search', id: 'placeholder', label: 'Placeholder' },
        {
            groupID: 'Search',
            id: 'optionLayout', 'label': 'Option Layout',
            dataType: EntityConstants.PropType.ENTITY, category: Common.CategoryID.ScreenDef,
        },
        {
            groupID: 'Search',
            id: 'getNoMatchLabel',
            label: 'Get NoMatch Label',
            dataType: EntityConstants.PropType.FUNCTION,
        },

    ],
    events: [UnoCompEvents.onLoad, UnoCompEvents.onUnLoad, SearchEvent.onSelected],
    group: DesignerConstants.PaletteGroup.Entity.id,

})
export class EntitySearch extends EntityBaseComp {

    constructor(props: any) {
        super(props);
        this.state = { ...this.state, otherProps: { ...this.getOtherProps(), showFilters: true } };

        this.getOtherProps().onSearchResult = this.hanldleSearchResult;
        this.getOtherProps().onSaveFilter = this.handleSaveFilter;
        this.profiler.log('Entity Search Props: ', props, this.state);
        // this.profiler.log('StyleClasses: ', this.getStyleClasses());
    }

    canProfile() {
        return false;
    }

    buildContent() {
        // this.conditions = [...this.getDefaultFilters()]; // this.state.entities ? this.state.entities : [...this.getDefaultFilters()];
        this.getOtherProps().conditions = this.getDefaultConditions();

        let searchView: any = null;
        const layout = this.buildDesigner();

        if (layout) {
            searchView = layout;
        } else if (this.doingQuickSearch()) {
            searchView = this.buildQuickSearch();
        } else {
            searchView = this.buildAdvancedSearch();
        }
        return (
            <UC.Section title={this.getTitle()} styles={this.getStyles()} styleClasses={this.getStyleClasses()}>
                {searchView}
            </UC.Section>
        )
    }

    getDefaultConditions() {
        return [...this.getDefaultFilters()];
    }

    handleSaveFilter = (conditions: any) => {
        const filterName = prompt('Filter Name');
        if (filterName) {
            EntityFilterService
                .save(filterName, conditions)
                .then(res => { alert(`Filter Saved.`) });
        }
    }

    hanldleSearchResult = (conditions: any) => {
        this.profiler.log('Load Results : ', conditions);
        const theFilterResult: any = this.refs['theFilterResult'];
        if (theFilterResult && theFilterResult.setFilterCondition) {
            this.profiler.log('Setting filters : ', conditions);
            theFilterResult.setFilterCondition(conditions);
        }
    }

    getAction(): string {
        return Router.CatAction.SEARCH;
    }

    buildQuickSearch() {
        const handleOnSelect = (opt: any) => {
            const entity = opt ? EntityConstants.build(opt.id) : undefined;
            // this.profiler.log('Notify onSelected: ', entity, this.props);

            const onSelected = this.getOtherProps()?.onSelected;
            if (this.state[SearchEvent.onSelected]) {
                Common.notifyEvent(this, SearchEvent.onSelected, { entity: entity, data: entity });
            } else if (onSelected) {
                onSelected(entity);
            } else if (entity) {
                this.setRedirectTo(Router.getViewRoute(entity));
            }
        }


        const refreshOptions = async (hint: string, refreshClbk?: Function) => {
            this.profiler.log('Refreshing options, with callback - ', hint, (refreshClbk !== undefined));
            let newOptions: Array<any> = [];
            const QUICK_RESULT_LIMIT = 20; //EntityConstants.Limit.EntitiesPerPage

            if (refreshClbk) {
                if (!hint || hint.trim().length < 2) {
                    refreshClbk(newOptions);
                } else {
                    const beginOp = '^';
                    const opREGEXP = FilterConstants.Operator.REGEXP.id;
                    const opIN = FilterConstants.Operator.REGEXP.id;
                    const opORDER_BY = FilterConstants.Operator.ORDER_BY.id;

                    const buildNewOption = async (theHint: string) => {
                        const filterCondition: Array<any> = [...this.getDefaultConditions()];

                        // match with name or ID
                        const quickSearchProps: Array<any> = [
                            FilterConstants.create(EntityConstants.Attr.NAME, FilterConstants.SortOptions.ASC.id, opORDER_BY),
                            FilterConstants.create(EntityConstants.Attr.ID, FilterConstants.SortOptions.ASC.id, opORDER_BY),

                            FilterConstants.create(EntityConstants.Attr.NAME, theHint, opREGEXP),
                            FilterConstants.create(EntityConstants.Attr.ID, theHint, opREGEXP),
                        ];

                        const eCategory: EntityCategory = this.getCategory();
                        eCategory?.props?.forEach(p => {
                            if (p.quickSearch) {
                                const op = (p.multiplicity && p.multiplicity !== 1) ? opIN : opREGEXP;
                                const cond = FilterConstants.create(p.id, theHint, op);
                                quickSearchProps.push(cond);
                            }
                        });

                        filterCondition.push(
                            {
                                keyword: FilterConstants.Keyword.OR.id,
                                children: quickSearchProps,
                            }
                        );

                        if (eCategory.id === 'uno_user_role') {
                            this.profiler.log('User Role - Refresh options with conditions: ', filterCondition, eCategory);
                        }

                        const entities = await EntityFilterService.result(
                            filterCondition,
                            {
                                limit: QUICK_RESULT_LIMIT,
                            },
                            this.getAppID(),
                            eCategory
                        );

                        if (entities && entities.length > 0) {
                            entities.forEach(
                                (e: BaseEntity) => {
                                    e = EntityConstants.build(e);
                                    // avoid duplicate
                                    let duplicate = false;
                                    for (let opt of newOptions) {
                                        if (Common.isEqual(opt.id, e)) {
                                            duplicate = true;
                                            break;
                                        }
                                    }

                                    if (!duplicate) {
                                        newOptions.push(this.createOption(e));
                                    }
                                }
                            );
                        }

                        this.profiler.log('Refresh options : ', hint, newOptions.length, filterCondition);
                    }

                    // prepare hints
                    hint = hint.trim();

                    let beginHint: string | undefined = undefined;
                    if (!hint.startsWith(beginOp)) {
                        beginHint = beginOp + hint;
                        await buildNewOption(beginHint);
                    }

                    await buildNewOption(hint);

                    // test for access, if required
                    if (this.getOtherProps()?.testViewAccess) {
                        let accessOptions = newOptions.map(no => {
                            return { ...no, to: Router.getViewRoute(no.id) };
                        });

                        accessOptions = await AppMenuService.setVisibility(accessOptions, SessionManager.activeSession);

                        newOptions = newOptions.filter(no => {
                            for (let opt of accessOptions) {
                                if (Common.isEqual(opt.id, no.id)) {
                                    return (opt.hidden !== true);
                                }
                            }
                            return true;
                        })

                        console.log('Quick Search Options - Verified Access: ', newOptions);
                    }

                    // No match found?
                    if (newOptions.length === 0) {
                        newOptions.push({
                            id: undefined,
                            label: await this.getNoMatchLabel(hint),
                            canSelect: false,
                        });
                    }

                    refreshClbk(newOptions);
                }
            }
        }

        const options: Array<any> = [this.getSelected()];
        this.profiler.log('Entity Search with Selected: ', this.getSelected());
        return (
            <>
                <UC.TypeAhead
                    options={options}
                    selected={this.getSelected()}
                    refreshOptions={refreshOptions}
                    key={Common.getUniqueKey()}
                    onSelect={handleOnSelect}
                    inputTitle={this.getPlaceholder()}
                    delay={1}
                    styles={this.getStyles()}
                    styleClasses={this.getStyleClasses()}

                    stt_enabled={this.state.stt_enabled || this.getOtherProps()?.stt_enabled}
                    writer_enabled={this.state.writer_enabled || this.getOtherProps()?.writer_enabled}
                />
                {this.buildSwitcher()}

            </>
        );
    }

    buildAdvancedSearch() {
        const thisOtherProps = this.getOtherProps();
        const oProps: any = {
            hideTitle: true,
            onShowResult: thisOtherProps?.onShowResult
        };
        const oPropOnSelectedFn = thisOtherProps?.onSelected;
        const propOnSelected = this.getCompProp(SearchEvent.onSelected);
        // this.profiler.log('Building Advanced Search: ', propOnSelected, oPropOnSelectedFn);
        if (propOnSelected) {
            oProps.onSelected = (entity: any) => {
                oProps.canSelect = true;
                Common.notifyEvent(this, SearchEvent.onSelected, { entity: entity, data: entity });
                if (oPropOnSelectedFn) {
                    oPropOnSelectedFn(entity);
                }
            }
        }

        if (!oProps.onSelected && oPropOnSelectedFn) {
            oProps.onSelected = oPropOnSelectedFn;
            oProps.canSelect = true; // this.getOtherProps().canSelect;
        }

        return (
            <>
                {this.buildSwitcher()}
                <UC.FilterEditor
                    appID={this.getAppID()}
                    categoryID={this.getCategoryID()}
                    category={this.getCategory()}
                    otherProps={oProps}
                    defaultValue={this.getOtherProps()?.conditions}
                />
            </>
        );
    }

    buildSwitcher = () => {
        if (this.getOtherProps()?.hideSwitcher || this.state.hideSwitcher) {
            return undefined;
        }
        return (
            <button
                className='clickable'
                onClick={(e) => {
                    e.preventDefault();
                    this.setState({ quickSearch: !this.doingQuickSearch() });
                }}
            >
                Switch to {this.doingQuickSearch() ? 'Advanced' : 'Quick'} Search
            </button>
        );
    }

    async getNoMatchLabel(hint: string,) {
        let label = `No matching ${this.getCategory().label} found with - ${hint}`;
        let fn = this.state.getNoMatchLabel || this.getOtherProps()?.getNoMatchLabel || this.getExtras()?.getNoMatchLabel;
        if (Common.checkType.String(fn)) {
            fn = Source.getFunction(fn);
        }
        this.profiler.log('No Match Label Function: ', fn);
        if (Common.checkType.Function(fn)) {
            label = await fn({ hint: hint, theComp: this, });
        }
        return label;
    }

    doingQuickSearch() {
        const quickSearch = this.state.quickSearch;
        return (quickSearch != undefined) ? Common.safeParse(quickSearch) : true;
    }

    getPlaceholder() {
        const placeholder = Common.safeParse(this.state.placeholder);
        return placeholder ? placeholder : 'Type in here to find matches...';
    }

    createOption = (entity: BaseEntity) => {
        return {
            id: entity,
            label: entity.getName(),
            buildView: this.buildOptionView,
        }
    }

    buildOptionView = (opt?: any) => {
        if (!opt || !opt.id) {
            return null;
        }

        const entityScreen = Common.safeParse(this.getOptionLayout());
        const OptionView = entityScreen ? UC.EntityView : UC.EntityQuickView;
        return (
            <OptionView
                entity={opt.id}
                category={this.getCategory()}
                otherProps={{
                    viewType: EntityViewTypes.QUICK,
                    canViewFull: false,
                }}
                noLink={true}
                entityScreen={entityScreen}
            />
        );
    }

    getSelected() {
        let selected = Common.safeParse(this.state.selected);
        if (selected) {
            selected = this.createOption(EntityConstants.build(selected));
        }
        this.profiler.log('Entity Search - Selected: ', selected);
        return selected;
    }

    getOptionLayout() {
        return this.state.optionLayout || this.getOtherProps()?.optionLayout || this.getExtras()?.optionLayout;
    }
}
