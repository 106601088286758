import React, { useCallback, useState } from 'react';
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  Panel,
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';

const initialNodes = [
  {
    id: '1',
    type: 'input',
    data: { label: 'Input Node' },
    position: { x: 250, y: 25 },
    style: { backgroundColor: '#6ede87', color: 'white' },
  },

  {
    id: '2',
    // you can also pass a React component as a label
    data: { label: <div>Default Node</div> },
    position: { x: 100, y: 125 },
    style: { backgroundColor: '#ff0072', color: 'white' },
  },
  {
    id: '3',
    type: 'output',
    data: { label: 'Output Node' },
    position: { x: 250, y: 250 },
    style: { backgroundColor: '#6865A5', color: 'white' },
  },
];

const initialEdges = [
  { id: 'e1-2', source: '1', target: '2' },
  { id: 'e2-3', source: '2', target: '3', animated: true },
];

export default function App() {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [variant, setVariant] = useState(BackgroundVariant.Cross);

  const onConnect = useCallback(
    (params: any) => {
      setEdges((eds) => addEdge(params, eds));
      // console.log('Connected: ', params, edges);
    },
    [setEdges],
  );

  const nodeColor = (node: any) => {
    switch (node.type) {
      case 'input':
        return '#6ede87';
      case 'output':
        return '#6865A5';
      default:
        return '#ff0072';
    }
  };

  return (
    <div style={{ width: '100%', height: '50vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}

        onNodesChange={
          (data) => {
            onNodesChange(data);
            // console.log('Node Changed: ', data);
          }
        }

        onEdgesChange={
          (data) => {
            onEdgesChange(data);
            // console.log('Edge Changed: ', data);
          }
        }

        onConnect={
          (data) => {
            onConnect(data);
            // console.log('Edge Connected: ', data);
          }
        }

        fitView
      >
        <Controls />
        <MiniMap
          nodeColor={nodeColor}
          nodeStrokeWidth={3}
          zoomable
          pannable
        />
        <Background
          color="#ccc"
          variant={variant}
        // gap={12}
        // size={1}
        />
        <Panel>
          <div>variant:</div>
          <button onClick={() => setVariant(BackgroundVariant.Dots)}>dots</button>
          <button onClick={() => setVariant(BackgroundVariant.Lines)}>lines</button>
          <button onClick={() => setVariant(BackgroundVariant.Cross)}>cross</button>
        </Panel>
      </ReactFlow>
    </div>
  );
}
