import * as ReactDOM from 'react-dom';
import VM from 'vm-browserify';
import { CalendarOps } from './calendar.service';
import { Common, Router, Images } from './common.service';
import { EntityConstants } from './entity.service';
import { EM } from './event-mgmt.service';
import { RemoteService } from './remote.service';
import { TriggerService } from '../../@uno-entity/service/entity-trigger.service';
import { SessionManager } from '../../@uno-app/service/session.service';
import { FilterConstants } from '../api';
import { AuthorizationService } from '../../@uno-entity/service/auth.service';
import { EntityCategoryService } from '../../@uno-app/service/entity.category.service';
import { AppInfoService } from '../../@uno-app/service/app.info.service';

const AppGlobals: any = {};

class SourceImpl {
    validate = (code: string) => {
        try {
            const script = new VM.Script(code);
            if (script) {
                // console.log(`VALID source code: `, script);
                return script;
            } else {
                throw `Unable to create a valid script`;
            }
        } catch (e) {
            console.log(`INVALID source code: \n`, code);
            return undefined;
        }
    }

    execute = async (code: string, context: any = {}) => {
        try {
            const script = this.validate(code);
            if (script) {
                context.console = console;
                // VM.createContext(newContext);
                const returnValue = await script.runInNewContext(context);
                return { result: returnValue };
            }
        } catch (e) {
            console.log(`Problem executing code: `, code, e);
        }
        return;
    }

    getFunction = (logic: any = '') => {
        let fn: Function | undefined = undefined;
        if (logic && Common.checkType.String(logic)) {
            const logicFn =
                `
                logicFn = async ()=>{
                    ${logic}
                }

                logicFn();
                `;
            fn = async (inputs: any = {}) => {

                const fnContext = {
                    pathname: window.location.pathname,
                    ...inputs,
                    API: Source.API,
                }
                let outcome: any = undefined;
                try {
                    outcome = await this.execute(logicFn, fnContext);
                } catch (e: any) {
                    outcome = e.message;
                }

                if (outcome) {
                    const result = outcome.result;
                    inputs = fnContext.inputs;
                    // console.log(`Function executed: `, result, inputs,);
                    return Common.checkType.Object(result) ? undefined : result;
                } else {
                    return undefined;
                }
            }
        }
        return fn;
    }

}

let _instance: any = undefined;
const getInstance = (): SourceImpl => {
    if (!_instance) {
        _instance = new SourceImpl();
    }
    return _instance;
}
export const Source = {
    API: {
        EM: EM,
        Common: Common,
        Icon: Images.Icon,
        Auth: AuthorizationService,
        Router: Router,
        Entity: EntityConstants,
        Category: EntityCategoryService,
        Filter: FilterConstants,
        Calendar: CalendarOps,
        Remote: RemoteService,
        Trigger: { send: TriggerService.send },
        Session: SessionManager,
        Globals: AppGlobals,
        DOM: ReactDOM,
        AppInfo: AppInfoService,
        Source: {
            // validate: getInstance().validate,
            // execute: getInstance().execute,
            getFunction: getInstance().getFunction,
        }
    },
    validate: getInstance().validate,
    execute: getInstance().execute,
    getFunction: getInstance().getFunction,
}