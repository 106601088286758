import React from 'react';
import { AppInfoService } from '../../@uno-app/service/app.info.service';
import { EntityCategoryService } from '../../@uno-app/service/entity.category.service';
import { SessionManager } from '../../@uno-app/service/session.service';
import { EntityViewTypes } from '../../@uno-entity-prop/service/entity-prop.service';
import { BaseEntity, Common, Condition, EntityConstants, FilterConstants } from '../../@uno/api';
import { DesignerConstants, UC, UnoCompEvents, UnoComponent } from '../../@uno/core';
import { PageLimitOptions, PageNavigation } from '../../@uno/core/components/page-nav.comp';
import { UnoCoreBaseComp } from '../../@uno/core/components/uno-core.base.comp';
import { EntityFilterService } from '../service/entity-filter.service';
import { Source } from '../../@uno/api/source.service';
import { AppScreenService } from '../../@uno-app/service/app.screen.service';

const getListViewChoices = () => {
    const choices: Array<any> = [
        {
            id: undefined, label: '-- Not Specified --',
        }
    ];
    Object.values(EntityConstants.ListViewTypes)
        .forEach(vt => {
            choices.push({ id: vt.id, label: vt.label, });
        });
    return choices;
}

const NavPosition = {
    Top: { id: 'top', label: 'Above the List' },
    Bottom: { id: 'bottom', label: 'Below the List' },
}

const NavType = {
    Numbered: { id: 'numbered', label: 'Numbered', },
    Caraousel: { id: 'caraousel', label: 'Caraousel', },
    Scrollable: { id: 'scroll', label: 'Scrollable', },
}

@UnoComponent({
    id: 'FilterResults',
    label: 'Entity Filter Result',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Entity.id,
    props: [
        {
            groupID: 'Conditions', id: 'filter', label: 'The Named Filter',
            dataType: EntityConstants.PropType.ENTITY, category: Common.CategoryID.FilterDef,
        },
        {
            groupID: 'Conditions', id: 'conditions', label: 'Conditions',
            dataType: EntityConstants.PropType.JSON, editor: 'FilterEditor', extras: { clearAll: true },
        },
        { groupID: 'Conditions', id: 'visibleProps', label: 'Visible Properties', dataType: EntityConstants.PropType.JSON, },
        { groupID: 'Conditions', id: 'enableExport', label: 'Enable Export', dataType: EntityConstants.PropType.BOOLEAN, },
        { groupID: 'Labels', id: 'noTitle', label: 'Hide Title?', dataType: EntityConstants.PropType.BOOLEAN, },
        { groupID: 'Labels', id: 'title', label: 'Title', dataType: EntityConstants.PropType.STRING, },
        { groupID: 'Labels', id: 'onNoResult', label: 'Label on No Result ', },

        {
            groupID: 'Pagination', id: 'pageLimit', label: 'Page Limit', dataType: EntityConstants.PropType.NUMBER,
            viewer: 'OptionViewer', editor: 'OptionSelector', extras: { options: [...PageLimitOptions] },
        },

        { groupID: 'Pagination', id: 'noPageNav', label: 'Hide Navigation Options?', dataType: EntityConstants.PropType.BOOLEAN },
        {
            groupID: 'Pagination', id: 'pageNavPosition', label: 'Place Navigations', editor: 'OptionSelector',
            extras: { options: Object.values(NavPosition), },
        },
        {
            groupID: 'Pagination', id: 'navType', label: 'Navigation Type', editor: 'OptionSelector',
            extras: { options: Object.values(NavType), },
        },

        {
            groupID: 'Listing Config', id: 'entityScreen', label: 'Entity Screen',
            dataType: EntityConstants.PropType.ENTITY, category: Common.CategoryID.ScreenDef,
        },
        {
            groupID: 'Listing Config', id: 'listViewType', label: 'List View Type', editor: 'OptionSelector',
            extras: {
                options: getListViewChoices(),
            }
        },
        {
            groupID: 'Listing Config', id: 'cols', label: 'Items per row ', description: '(for view as Grid)',
            dataType: EntityConstants.PropType.STRING, editor: 'OptionSelector',
            extras: {
                options: [
                    { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 },
                    { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }, { id: 11 }, { id: 12 },
                ],
            }
        },

        { groupID: 'Others', id: 'otherProps', label: 'Other Properties', dataType: EntityConstants.PropType.JSON },
        { groupID: 'Others', id: 'globalSort', label: 'Sort on entire data', dataType: EntityConstants.PropType.BOOLEAN },
        { groupID: 'Others', id: 'onSort', label: 'On Sort Handler', dataType: EntityConstants.PropType.FUNCTION },

    ],
    getDesign: () => { return (<div>Filter Result - Design</div>); },
    getPreview: () => { return (<div>Filter Result - Preview</div>); }

})
export class FilterResults extends UnoCoreBaseComp {
    // navPositionDefault: any = NavPosition.Bottom.id;
    currentPageNo = 1;
    pageLimit = 0;
    entityLayout = undefined;

    // totalCount = -1;
    // handlers: any;

    constructor(props: any) {
        super(props);
        const otherProps = this.props.otherProps ? Common.safeParse(this.props.otherProps) : {};
        this.state = { ...this.state, otherProps: otherProps };
        this.profiler.log(`Initialized FilterResults for : `, { ...this.getFilterConditions() });
        this.pageLimit = Number.parseInt('' + (this.state.pageLimit || EntityConstants.Limit.EntitiesPerPage));
    }

    canProfile(): boolean {
        return false;
    }

    componentDidMount(): void {
        super.componentDidMount();
        // initialize other props;
        this.getOtherProps();
    }

    buildComp() {
        let result: any = null;
        let title = this.state.title;

        let appID = this.getAppID();
        // this.profiler.log('Showing filter results...: ', title, appID)


        const conditions: Array<Condition> = this.getFilterConditions();
        // this.profiler.log(`filterCondition: `, conditions);
        const filterResult: Array<BaseEntity> | undefined = this.state.result;
        // this.profiler.log(`filterResult: `, filterResult);

        if (this.state.noResult) {
            result = (<div>{this.state.noResult}</div>);
        } else if (filterResult) {
            this.profiler.log(`buildResultViewer - filterResult: `, this.state);
            result = this.buildResultViewer(filterResult, appID);
            // title = (title) ? title : this.buildTitle(filterResult);
        } else if (conditions && conditions.length > 0) {
            this.loadResults(conditions, appID);
            result = this.showLoading();
        } else if (this.state.filter) {
            let filter = this.state.filter;
            if (filter && Common.checkType.String(filter)) {
                filter = Common.parse(filter.toString());
            }
            const filterEntity: BaseEntity = EntityConstants.build(filter);
            this.loadFilterDefinition(filterEntity.getID());
            result = this.showLoading();
        } else {
            const filterID = this.state.filterID;
            if (filterID) {
                this.loadFilterDefinition(filterID);
                result = this.showLoading();
            } else {
                result = <div>Not enough information about the filter.</div>;
            }
        }

        return (
            <>
                {Boolean(this.state.noTitle).valueOf() ? undefined : (title ? (<div className='input-label'>{title}</div>) : undefined)}
                {this.buildPageNav(NavPosition.Top.id)}
                {this.getNavType() === NavType.Scrollable.id ? (
                    <div
                        style={
                            {
                                display: 'inline-block',
                                width: 'calc(100% - 100px)',
                                margin: 'auto',
                            }
                        }
                    >
                        {result}
                    </div>
                ) : result}
                {this.buildPageNav(NavPosition.Bottom.id)}
            </>
        );
    }

    buildTitle(filterResult: BaseEntity[]) {
        const catID = EntityConstants.build(filterResult[0]).getCategoryID();
        return 'Result : ' + catID;
    }

    buildResultViewer(result: BaseEntity[], appID?: string) {
        const onNoResult = this.getOnNoResult();
        if ((!result || result.length === 0) && onNoResult) {
            return (
                <div dangerouslySetInnerHTML={{
                    __html: Common.sanitize(onNoResult),
                }} />
            );
        }

        const ResultView = this.getViewer();
        if (!ResultView) {
            return <UC.Empty />;
        }

        const firstEntity = EntityConstants.build(result[0]);
        let category = EntityCategoryService.getAppCategory(firstEntity.getCategoryID(), firstEntity.getAppID()) || this.state?.category;
        const catID = firstEntity.getCategoryID() || category?.id;
        this.profiler.log(`Viewing ${result.length} results with category - ${catID}`, category);
        const otherProps: any = this.getOtherProps();
        otherProps.conditions = this.getFilterConditions();
        this.profiler.log('Other Props: ', otherProps);

        return (
            <>
                <ResultView
                    appID={appID}
                    categoryID={catID}
                    category={category}
                    entities={result}
                    key={Common.getUniqueKey()}
                    otherProps={otherProps}
                // styles={{ ...this.state.styles }}
                />
            </>
        );
    }

    getOnNoResult = () => {
        return Common.safeParse(this.state.onNoResult);
    }

    showLoading = () => {
        return (<UC.Loading target={this.reRender} />);
    }

    buildPageNav(position?: string) {
        this.profiler.log('Build Nav: ', position, this.state);
        return (
            <PageNavigation
                key={Common.getUniqueKey('page_nav_')}
                position={position}
                noPageNav={this.state.noPageNav}
                pageNavPosition={this.state.pageNavPosition}
                currentPageNo={this.currentPageNo}
                pageLimit={this.getPageLimit()}
                enableExport={this.state.enableExport}

                filters={this.getFilterConditions()}
                totalCount={this.getTotalCount()}
                result={this.state.result}

                doReload={async (currentPageNo: number, limit: number) => {
                    this.currentPageNo = (this.getPageLimit() !== limit) ? 1 : currentPageNo;
                    this.pageLimit = limit;
                    this.loadResults();
                }}
            />
        );
    }

    getNavType() {
        return Common.safeParse(this.state.navType);
    }

    getTotalCount = () => {
        return (this.state.totalCount !== undefined) ? this.state.totalCount : -1;
    }

    getAppID() {
        return this.state.appID ? this.state.appID : AppInfoService.getActiveApp()?.id;
    }

    protected getFilterConditions() {
        // this.profiler.log('Raw Filter Conditions:', this.state.conditions);
        let conditions: any = Common.safeParse(this.state.conditions) || [];
        return conditions;
    }

    protected loadFilterDefinition(filterID: any) {
        EntityFilterService.find(filterID)
            .then((filter: BaseEntity) => {
                if (filter) {
                    filter = EntityConstants.build(filter);
                    const def = filter.getValue('definition');
                    const title = this.state.title ? this.state.title : filter.getName();
                    this.reRender({ conditions: def, title: title });
                } else {
                    this.reRender({ noResult: 'A matching filter record not found.' });
                }
            });
    }

    protected async loadResults(filterCondition: Condition[] = this.getFilterConditions(), appID: any = this.getAppID(), session = SessionManager.activeSession) {
        const category = await EntityCategoryService.getCategory(FilterConstants.getCategoryID(filterCondition), true, appID);
        const filterResult: any = { noResult: undefined, result: [], category: category, totalCount: this.getTotalCount(), };

        if (filterResult.totalCount < 0) { // first attempt
            filterResult.totalCount = await EntityFilterService.getTotalCount(filterCondition);
        }

        if (filterResult.totalCount > 0) {
            const filterOptions = { pageNumber: this.currentPageNo, limit: this.getPageLimit(), fields: this.getFields(category) };
            const entities = await EntityFilterService.result(filterCondition, filterOptions, appID);
            filterResult.result = entities || [];
        }

        this.profiler.log(`Filter Result: `, filterResult);
        this.reRender(filterResult);
    }

    getFields(category: any,) {
        let fields: any = {};
        if (category) {
            const oProps = this.getOtherProps();
            if (oProps.visibleProps?.length > 0) {
                for (let p of oProps.visibleProps) {
                    fields[p] = 1;
                }
            } else if (oProps.viewType === EntityViewTypes.QUICK) {
                for (let p of category.props) {
                    if (p.noQV || p.hidden) {
                        // this.profiler.log('Not considered for quick view: ', p);
                        // fields[p.id] = 0;
                    } else {
                        fields[p.id] = 1;
                    }
                }
            }
        }
        // this.profiler.log('Fields for the conditions: ', fields, this.getFilterConditions());
        return fields;
    }

    getPageLimit() {
        return this.pageLimit;
    }

    getViewer() {
        return UC[this.getViewID()];
    }

    getViewID() {
        return 'MultiEntityView';
    }

    getOtherProps() {
        let otherProps: any = { ...this.state.otherProps };
        otherProps.viewType = otherProps.viewType ? otherProps.viewType : EntityViewTypes.QUICK;
        // otherProps.entityScreen = this.state.entityScreen;
        otherProps.entityLayout = this.getEntityLayout();
        otherProps.listViewType = this.state.listViewType ? this.state.listViewType : otherProps?.listViewType;
        otherProps.cols = this.state.cols;
        otherProps.visibleProps = Common.safeParse(this.state.visibleProps);

        // onSort handler
        let onSort = this.state.onSort;
        const globalSort = this.state.globalSort;

        if (Common.checkType.Function(onSort)) {
            onSort = onSort;
        } else if (Common.checkType.String(onSort) && onSort.trim().length > 0) {
            this.profiler.log('onSort fn body: ', onSort);
            onSort = Source.getFunction(onSort);
        } else if (globalSort) {
            onSort = (data: any) => {
                this.profiler.log('Sort Order', data);
                const originalConditions = (this.getOtherProps().originalConditions || this.getFilterConditions());
                const conditionsWithSort = [...originalConditions];

                let propID = data.prop?.id;
                let order = data.order;

                if (propID && order !== 0) {
                    conditionsWithSort.push(FilterConstants.create(propID, order, 'ORDER_BY'));
                }

                const sortConfig = { prop: propID, order: order };
                this.setOtherProps({ sortConfig: sortConfig, originalConditions: originalConditions });

                this.profiler.log('Filters with global sort: ', conditionsWithSort);
                this.setFilterConditions(conditionsWithSort);
            }
        }

        otherProps.onSort = onSort;

        // otherProps = { ...otherProps, ...this.state.otherProps };

        // this.profiler.log(`Filter Result Other Props `, otherProps);
        return otherProps;
    }

    getEntityLayout() {
        let entityScreen = this.state.entityScreen;
        if (!this.entityLayout && entityScreen) {
            entityScreen = EntityConstants.build(entityScreen);
            AppScreenService
                .getScreenByID(entityScreen.getID(), entityScreen.getAppID())
                .then(
                    layout => {
                        if (layout) {
                            // this.reRender({ entityLayout: layout });
                            this.entityLayout = layout;
                        }
                    }
                );
        }
        return this.entityLayout;
    }

    setOtherProps(oProps: any) {
        this.reRender({ otherProps: { ...this.getOtherProps(), ...oProps } });
    }

    setFilterConditions = (conditions: any) => {
        this.profiler.log(`Setting Filter Condition - `, conditions);
        this.reRender({ conditions: conditions, result: undefined, noResult: undefined, totalCount: undefined });
        // this.loadResults(conditions);
    }

    /**
     * @deprecated Use setFilterConditions
     * @param conditions 
     */
    setFilterCondition = (conditions: any) => {
        this.setFilterConditions(conditions);
    }

    reload() {
        this.setFilterConditions(this.getFilterConditions());
    }

}
