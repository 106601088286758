interface ModuleDef {
    id: string;
    imports?: Array<any>;
    components?: Array<any>;
    bootstrap?: Array<string|Function>;
}

function unoModule(config: ModuleDef) {
    return (target: any) => {
        const moduleId = config.id;
        // console.log('Configuring Module: ', moduleId); // target, 

        // imports 
        config.imports?.forEach(
            TheImport => {
                // load imported modules
                new TheImport();
            }
        );

        // register module
        UnoModuleManager.register(target, config,);

    }

}

class UnoModuleManagerImpl {
    private moduleList: Array<ModuleDef> = [];

    private bootModule: ModuleDef | undefined;

    setBootModule(module: any) {
        this.bootModule = module;
    }

    getBootModule() {
        return this.bootModule;
    }

    register(target: any, config?: ModuleDef,) {
        if (!config) {
            config = { id: target.name };
        }
        if (!config.id) {
            config.id = target.name;
        }
        const id = config.id ? config.id : target.name;
        // console.log('Registering Module: ', config.id, target.name);
        let mDef: ModuleDef | undefined = this.find(id);
        if (!mDef) {
            mDef = config;
            this.moduleList.push(mDef);
        }

        // remeber the last registered module as boot module.
        // this.bootModule = mDef;
        this.setBootModule(mDef);

        return mDef;
    }

    find(id: string) {
        for (let i = 0; i < this.moduleList.length; i++) {
            const m = this.moduleList[i];
            if (m.id === id) {
                return m;
            }
        }
        return undefined;
    }

    listIDs() {
        return this.moduleList.map(
            m => {
                return m.id;
            }
        );
    }

}

export const UnoModuleManager = new UnoModuleManagerImpl();
export const UnoModule = unoModule;