import { UnoComponent } from '../../../@uno/core';
import layout from '../layouts/plugin-config-edit-layout.json';
import { EntityEdit } from '../../../@uno-entity/components/entity-edit.comp';

// PluginConfig - VIEW
const ID = 'UnoPluginConfigEdit';
@UnoComponent({
    id: ID,
    label: 'Plugin Configuration - Editor'
})
export class UnoPluginConfigEdit extends EntityEdit {

    constructor(props: any) {
        super(props);


        this.state = {
            ...this.state,
            layout: layout,
            otherProps: { ...this.state.otherProps, noActions: false, },
        }
        // console.log('Plugin Config Edit - Layout: ', layout);
        this.updateBaseComp();
    }

    updateBaseComp = async () => {
        const baseComp = this.state.baseComp;
        const baseOP = baseComp?.getOtherProps();
        if (baseComp && baseOP && !baseOP.noActions) {
            baseOP.noActions = true;
            baseComp.reRender({});
        }
    }

    canProfile(): boolean {
        return false;
    }
}