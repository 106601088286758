import React from 'react';
import { Link } from 'react-router-dom';
import { EM } from '../../../api/event-mgmt.service';
import { Common, DesignerConstants, Images, UC, UnoCompEvents, UnoComponent, UnoComponentManager } from '../../../core';
import { UnoCoreBaseComp } from './../uno-core.base.comp';

import { EntityConstants, EntityProp } from '../../../api';
import { Source } from '../../../api/source.service';

export interface NavItemDef {
    id: any,
    label?: string;
    labelOn?: boolean,
    icon?: string,
    tooltip?: string,
    hidden?: boolean;
    to?: string;
    action?: any,
    doShow?: any,
    extra?: any,
    component?: any;
    children?: Array<NavItemDef>;
    buildView?(route: NavItemDef): any,
}

export const NavigationConstants = {
    Orientation: {
        VERTICAL: 'v',
        HORIZONTAL: 'h',
    },
    Event: {
        NAVIGATED: 'navigated',
    },

    getItems: (props: NavProps) => {
        let items: any = Common.safeParse(props.navs);
        if (!items || !Array.isArray(items) || items.length === 0) {
            items = undefined;
        }

        return items;
    },

    getStyles: (props: NavProps) => {
        return (props.styleClasses) ? props.styleClasses : DEFAULT_STYLE_CLASSES;
    },

    isHorizontal: (props: NavProps) => {
        if (props.orientation) {
            return props.orientation === NavigationConstants.Orientation.HORIZONTAL
        } else if (NavigationConstants.isToolBar(props)) {
            return true
        } else {
            return false;
        }
    },

    isTreeLayout: (props: NavProps) => {
        let isTree: any = props.isTree;
        return (isTree !== undefined) ? JSON.parse(isTree) : false;
    },

    isToolBar: (props: NavProps) => {
        let isToolbar: any = props.isToolbar;
        return (isToolbar !== undefined) ? JSON.parse(isToolbar) : false;
    }
}

export interface NavStyleClasses {
    appNavContent?: string;
    appNavIcon?: string;
    appNavList?: string;
    itemToggle?: string;
    orientationH?: string;
    orientationV?: string;
    appNavWrapper?: string;
    appNavLink?: string;
    appNavLinkIcon?: string;
    navLinkActive?: string;
    navLinkInactive?: string;
}

export const DEFAULT_STYLE_CLASSES: NavStyleClasses = {
    appNavContent: ' app-nav-content ',
    appNavIcon: ' app-nav-icon ',
    appNavList: ' app-nav-list ',
    itemToggle: ' float-right ',
    orientationH: ' app-nav-horizontal',
    orientationV: ' app-nav-vertical',
    appNavLink: ' app-nav-link ',
    appNavWrapper: ' app-nav-wrapper',
    appNavLinkIcon: ' app-nav-link-icon ',
    navLinkActive: ' app-nav-link-active ',
    navLinkInactive: ' app-nav-link-inactive ',
}

export interface NavProps {
    navs?: Array<NavItemDef>;
    orientation?: string;
    isToolbar?: boolean,
    isTree?: boolean,
    styleClasses?: NavStyleClasses;
    showNavBar?: boolean;
    showToggle?: boolean;
    depth?: number;
    visibleDepth?: number;
    visibleChildCount?: number;
    styles?: any
}

export const NAV_COMP_PROPS: Array<EntityProp> = [
    { groupID: 'General', id: 'navs', label: 'Items', dataType: EntityConstants.PropType.JSON },
    {
        groupID: 'General',
        id: 'navDef',
        label: 'Menu Definition',
        dataType: EntityConstants.PropType.ENTITY_INLINE,
        category: 'uno_menu'
    },
    {
        groupID: 'General',
        id: 'orientation',
        label: 'Orientation',
        editor: 'OptionSelector',
        extras: {
            options: [
                { id: NavigationConstants.Orientation.HORIZONTAL, label: 'Horizontal', isDefault: true, },
                { id: NavigationConstants.Orientation.VERTICAL, label: 'Vertical', },
            ]
        }
    },
    {
        groupID: 'General',
        id: 'isToolbar', label: 'Is Toolbar?',
        editor: 'OptionSelector',
        extras: {
            options: [
                { id: 'true', label: 'Yes', },
                { id: 'false', label: 'No', isDefault: true, },
            ]
        }
    },
    {
        groupID: 'General',
        id: 'isTree', label: 'Is a Tree?',
        editor: 'OptionSelector',
        extras: {
            options: [
                { id: 'true', label: 'Yes', },
                { id: 'false', label: 'No', isDefault: true, },
            ]
        }
    },
    { groupID: 'Style', id: 'styleClasses', label: 'Style Classes', dataType: EntityConstants.PropType.JSON, },
];

const renderNav = (config?: any, clbk?: any, buildChildren?: Function) => {
    const mode = DesignerConstants.Mode.LIVE;
    const props = UnoComponentManager.getProps(config, mode);
    const navs = props?.navs;
    if (Common.checkType.String(navs)) {
        const inflatedNavs = UnoComponentManager.inflate(navs, [props], mode);
        props.navs = inflatedNavs;
    }
    // this.profiler.log('Navigation: ', config, props);

    return (
        <>
            <Navigation {...props} />
        </>
    );
}

@UnoComponent(
    {
        id: 'Navigation',
        props: NAV_COMP_PROPS,
        paletteable: true,
        group: DesignerConstants.PaletteGroup.Frequent.id,
        getLive: renderNav,
        getPreview: renderNav,
        getDesign: renderNav,
    }
)
export class Navigation extends UnoCoreBaseComp {
    menuIcon = Images.Icon.hMenu;

    constructor(props: any) {
        super(props);

        let styleClasses = DEFAULT_STYLE_CLASSES;
        const stateStyleClasses = Common.safeParse(this.state.styleClasses);
        if (stateStyleClasses) {
            styleClasses = { ...styleClasses, ...stateStyleClasses, };
        }

        this.state = { ...this.state, showNavBar: this.getShowNavBar(), styleClasses: styleClasses };
    }

    canProfile(): boolean {
        return false;
    }

    buildComp() {
        let navs = NavigationConstants.getItems(this.state);
        if (!navs || !Array.isArray(navs) || navs.length === 0) {
            return null;
        }

        const styles = NavigationConstants.getStyles(this.props);

        let appNavIconStyle = (this.props.showToggle === undefined) ? styles.appNavIcon : ' app-nav-toggle ';
        appNavIconStyle = `${appNavIconStyle} tool-icon `;
        appNavIconStyle = `tool-icon`;

        const toggler = (
            <img
                alt='|||'
                src={this.menuIcon}
                className={appNavIconStyle}
                title='Click for Options'
                onClick={this.toggleNavBarView}

            />
        );

        return (
            <div className={styles.appNavContent} style={Common.safeParse(this.props.styles)}>
                {this.props.showToggle ? <>{toggler}</> : <UC.Empty />}
                {this.buildNavBar(navs)}
            </div>
        );
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        window.removeEventListener('resize', this.handleWidowResize);
    }

    handleWidowResize = () => {
        this.setState({ showNavBar: this.getShowNavBar() });
    }

    getShowNavBar() {
        let showNavBar = true;
        if (this.props.showNavBar !== undefined) {
            showNavBar = this.props.showNavBar;
        }
        return showNavBar;
    }

    toggleNavBarView = () => {
        this.menuIcon = (this.menuIcon === Images.Icon.hMenu) ? Images.Icon.vMenu : Images.Icon.hMenu;
        this.setState({ showNavBar: !this.state.showNavBar });
        this.profiler.log(`Toggle Nav Bar View`);
    }

    getNavListComp(): any {
        return NavList;
    }

    buildNavBar(navs?: any) {
        let navBar = null;
        const NavListComp: any = this.getNavListComp();
        if (this.state.showNavBar) {
            // this.profiler.log(`As Toolbar : `, this.state.isToolbar);
            const stateCopy = { ...this.state, onLoad: undefined, onUnLoad: undefined, };
            navBar =
                <NavListComp
                    {...stateCopy}
                    theNavComp={this}
                    navs={navs ? navs : NavigationConstants.getItems(this.state)}
                    depth={0}
                />;
        }
        return navBar;
    }

}

export class NavList extends UnoCoreBaseComp {
    canProfile(): boolean {
        return false;
    }

    buildComp() {
        const styles = NavigationConstants.getStyles(this.state);
        const navs = NavigationConstants.getItems(this.state);
        if (!navs || !Array.isArray(navs) || navs.length === 0) {
            return null;
        }
        this.profiler.log('Build Nav List: ', [...navs], { ...this.state });
        const isHorizontal = NavigationConstants.isHorizontal(this.props);

        const NavItemComp: any = this.getNavItemComp();
        const navComps: Array<any> = [];
        navs?.forEach(
            (nav: NavItemDef) => {
                if (nav?.hidden !== true) {
                    navComps.push(
                        (
                            <div
                                style={
                                    {
                                        alignSelf: (isHorizontal) ? 'flex-start' : 'inherit',
                                    }
                                }
                                key={Common.getUniqueKey()}
                                className={`${styles.appNavWrapper} `}
                            >
                                <NavItemComp
                                    {...this.state}
                                    navs={undefined}

                                    key={Common.getUniqueKey()}
                                    nav={nav}
                                    depth={this.getDepth()}
                                />
                            </div>
                        )
                    );
                }
            }
        );

        if (navComps.length > 0) {
            const flexDirection = isHorizontal ? 'row' : 'column';
            const maxHeight = (isHorizontal) ? 'auto' : `${30 * this.getVisibleChildCount()}px`;
            const maxWidth = (isHorizontal) ? `${150 * this.getVisibleChildCount()}px` : 'auto';
            const overflow = (navComps.length > this.getVisibleChildCount()) ? 'scroll' : 'visible';

            return (
                <div
                    className={styles.appNavList}
                    style={{
                        flexDirection: flexDirection,
                        maxHeight: maxHeight,
                        maxWidth: maxWidth,
                        overflowX: (isHorizontal) ? overflow : 'visible',
                        overflowY: (!isHorizontal) ? overflow : 'visible'
                    }}
                >
                    {navComps}
                </div>
            );
        } else {
            return <UC.Empty />;
        }
    }

    isTree() {
        return NavigationConstants.isTreeLayout(this.props);
    }

    getNavItemComp(): any {
        return NavItem;
    }

    getDepth() {
        return (this.state.depth) ? this.state.depth : 0;
    }

    getVisibleChildCount() {
        if (this.isTree()) {
            return Number.MAX_SAFE_INTEGER;
        }
        return this.state.visibleChildCount ? this.state.visibleChildCount : 5;
    }
}

export class NavItem extends UnoCoreBaseComp {
    navigatedHandler: any = undefined;
    navID: string = Common.getUniqueKey();
    // nav: any;

    constructor(props: any) {
        super(props);
        this.state = { ...this.state, showChildren: this.isVisibleChildren(), navs: undefined, }
        // this.nav = { ...this.state.nav };
    }

    canProfile(): boolean {
        return false;
    }

    componentDidMount() {
        super.componentDidMount();
        if (!this.isTree()) {
            window.addEventListener('click', this.clickHandler);
        }
        this.navigatedHandler = EM.register(NavigationConstants.Event.NAVIGATED,
            (nav: any) => {
                // this.profiler.log('Navigated: ', { ...nav });
                if (!this.isTree()) {
                    this.setState({ showChildren: this.isVisibleChildren() });
                }
            }
        );

        const checkDoShow = async () => {
            // const nav: NavItemDef = this.state.nav;
            const nav: NavItemDef = this.state.nav;
            if (nav && nav.doShow) {
                const doShow = await this.execFn(nav.doShow, nav, undefined);
                // this.profiler.log('Show nav: ', doShow, nav);
                if (!doShow) {
                    nav.hidden = true;
                    this.reRender({ nav: nav });
                    // this.reRender();
                }
            }
        }

        checkDoShow();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        EM.unregister(this.navigatedHandler, (id: any) => {
            // this.profiler.log('Unregistered - ', id);
            this.navigatedHandler = undefined;
        });
        window.removeEventListener('click', this.clickHandler);
    }

    buildComp() {
        // const nav: NavItemDef = this.state.nav;
        const nav: NavItemDef = this.state.nav;
        if (!nav || nav.hidden) {
            return undefined;
        }
        const isTree = this.isTree();
        // this.profiler.log('Create a nav item: ', { ...this.state });
        const navItemClasses = this.getNavItemClass();
        const depth = this.getDepth();
        let children = this.buildChildren(nav)
        if (children) {
            const styles: any = isTree ? {
                position: 'relative',
                top: '98%',
                left: '1rem',
            } : {
                position: 'absolute',
                // position: 'relative',
                zIndex: (depth + 1),
                minWidth: '100%',
                left: '0.1rem',
            }

            children = (
                <div style={styles}>
                    {children}
                </div>
            );
        }

        // build clickable nav label
        let toggleLink = this.buildChildrenToggle(nav);
        const navActions = this.getNavActions(nav);
        let navLabel = (
            <div onClick={(evt: any) => { this.handleNavItemClick(evt, nav, navActions); }}>
                {this.buildNavLabel(nav)}
            </div>
        );

        if (nav.to) {
            const title = this.getLabel(nav);
            const toLower = nav.to.toLowerCase();
            if (toLower.startsWith('http://') || toLower.startsWith('https://') || toLower.startsWith('mailto:')) {
                navLabel = (
                    <a
                        href={nav.to}
                        // target='_blank'
                        title={title}
                    >
                        {navLabel}
                    </a>
                );
            } else {
                navLabel = (
                    <Link
                        to={nav.to}
                        title={title}
                    >
                        {navLabel}
                    </Link>
                );
            }
        }

        return (
            <div key={this.navID} id={this.navID} >
                <div
                    className={navItemClasses}
                    title={this.getTooltip(nav)}
                    style={
                        isTree ?
                            { display: 'flex', flexDirection: 'row', }
                            : toggleLink ?
                                { display: 'flex', flexDirection: 'row' }
                                : undefined
                    }
                >
                    {isTree ? toggleLink : undefined}
                    <div style={{ alignSelf: 'left', flex: 1, }}>{navLabel}</div>
                    {!isTree ? toggleLink : undefined}
                </div>
                {children}
            </div>
        );
    }

    isTree() {
        return NavigationConstants.isTreeLayout(this.props);
    }

    clickHandler = (event: any) => {
        const navEle = document.getElementById(this.navID);
        if (!navEle) {
            // this.profiler.log(`Unable to find navEle for  - `, this.props.nav.id);
            return;
        }

        let ele = event.target;
        while (ele !== null) {
            if (ele === navEle) {
                // this.profiler.log('Clicked the NavItem or a descendent ', this.props.nav.id);
                return;
            } else {
                ele = ele.parentElement;
            }
        }
        // reached here means, outside the nav. So, close children
        // this.profiler.log('Clicked outside', this.props.nav.id);
        this.setState({ showChildren: false });
    }

    isVisibleChildren() {
        if (this.getVisibleDepth() > this.getDepth()) {
            return true;
        }
        return false;
    }

    toggleChildren = () => {
        if (!this.isVisibleChildren()) {
            this.setState({ showChildren: !this.state.showChildren });
        }
    }

    canToggle(nav: NavItemDef) {
        const children: any = nav.children;
        if (children && Array.isArray(children) && children.length > 0) {
            return true;
        }
        return false;
    }

    getToggleLink(nav: NavItemDef) {
        if (!this.canToggle(nav)) {
            return undefined;
        }

        const toggleClass = (this.state.showChildren) ? 'arrow-down' : (this.isTree() ? ' arrow-right ' : ' arrow-up ');
        let toggleLink = (
            <div
                onClick={this.toggleChildren}
                className={toggleClass}
            />
        );
        return toggleLink;
    }

    getOrientationStyle() {
        const styles = NavigationConstants.getStyles(this.props);
        let orientationStyle = NavigationConstants.isHorizontal(this.props) ? styles.orientationH : styles.orientationV;

        if (NavigationConstants.isToolBar(this.props)) {
            orientationStyle = ` ${orientationStyle}`;
        }
        return orientationStyle;
    }

    handleNavigated(evt: any, nav: NavItemDef) {
        // this.profiler.log('Navigated ', nav.to);
        EM.emit(NavigationConstants.Event.NAVIGATED, nav);
    }


    handleNavItemClick = async (evt: any, nav: NavItemDef, actions?: Array<Function>) => {
        let perfNext: any = await this.execFn(nav.action, nav, evt);

        actions?.forEach(
            (nextAction: Function) => {
                if (perfNext || perfNext === undefined) {
                    perfNext = nextAction(nav, evt,);
                }
            }
        );

        if (perfNext === false && evt) {
            evt.preventDefault();
        }
    };

    async execFn(navFn: any, nav: NavItemDef, evt: any) {
        let perfNext: any = true;
        if (navFn) {
            if (Common.checkType.Function(navFn)) {
                // this.profiler.log('Navigation clicked - ', nav,);
                perfNext = navFn(nav, evt);
            } else {
                try {
                    const theComp: any = this.props.theNavComp;
                    const session = theComp?.props.session;
                    // this.profiler.log(`Executing Action: `, action);
                    const inflatedFn = async (nav: any, evt: any) => {
                        const actions = (Array.isArray(navFn)) ? navFn : [navFn];
                        const actionsSrc = actions.join('\n\n');
                        const fn = Source.getFunction(actionsSrc);
                        if (fn) {
                            const result = await fn({ theNav: nav, theComp: theComp, session: session, API: Source.API, window: window });
                            // this.profiler.log('Nav Fn Result: ', result, navFn, nav);
                            return (Common.checkType.Boolean(result)) ? result : (result === undefined);
                        }
                        // const result = await Source.execute(actionsSrc, { theNav: nav, theComp: theComp, session: session, API: Source.API });
                        return true;
                    }

                    perfNext = await inflatedFn(nav, evt);
                    // this.profiler.log(`Nav action executed: `, perfNext);
                } catch (e) {
                    // console.error(`Problem executing action : `, action, e);
                }
            }
        }
        return perfNext;
    }

    getLabel(nav: NavItemDef) {
        return nav.label || nav.id || nav.to;
    }

    getTooltip(nav: NavItemDef) {
        return nav.tooltip ? nav.tooltip : this.getLabel(nav);
    }

    getNavListComp() {
        return NavList;
    }

    buildNavLabel(nav: NavItemDef) {
        let navLabelView: any = null;
        if (nav.buildView) {
            navLabelView = nav.buildView(nav);
        } else {
            let navLabel: any = this.getLabel(nav);
            let navIcon: any = undefined;

            const isToolbar = NavigationConstants.isToolBar(this.props);
            if (isToolbar === true && nav.icon === undefined) {
                nav.icon = Images.Icon.tool;
            }
            if (nav.icon) {
                const navIconStyle = ` ${NavigationConstants.getStyles(this.props).appNavLinkIcon} tool-icon `;
                navIcon = (<img src={nav.icon} alt={navLabel} className={navIconStyle} title={navLabel} />)
            }
            navLabelView = (<>{navIcon} {(!isToolbar || nav.labelOn) ? navLabel : ''}</>);
        }
        return navLabelView;
    }

    getDepth() {
        return (this.props.depth) ? this.props.depth : 0;
    }

    getVisibleDepth() {
        return (this.props?.visibleDepth) ? this.props.visibleDepth : 0;
    }

    buildChildren(nav: NavItemDef) {
        const NavListComp = this.getNavListComp();
        let children = null;
        if (this.state.showChildren && nav.children && Array.isArray(nav.children)) {
            this.profiler.log('Build children navs of :', { ...nav }, { ...this.state });
            children = (
                <NavListComp
                    {...this.state}
                    navs={nav.children}
                    depth={this.getDepth() + 1}
                    orientation={NavigationConstants.Orientation.VERTICAL}
                />
            );
        }
        return children;
    }

    getNavItemClass() {
        let navItemClasses = '';
        const styles = NavigationConstants.getStyles(this.props);
        if (styles) {
            navItemClasses += styles.appNavLink;
            const activeLink = (this.state.showChildren) ? styles.navLinkActive : styles.navLinkInactive;
            navItemClasses += activeLink;
        }

        return navItemClasses;
    }

    getNavActions(nav: NavItemDef) {
        let actions = [this.handleNavigated];
        if (this.canToggle(nav)) {
            actions.push(this.toggleChildren);
        }
        return actions;
    }

    buildChildrenToggle(nav: NavItemDef) {
        const toggleClass = '' // (NavigationConstants.isTreeLayout(this.props)) ? ' float-left ' : ' float-right ';
        const link = this.getToggleLink(nav);
        if (!link && NavigationConstants.isHorizontal(this.props)) {
            return undefined;
        } else {
            return (
                <>
                    <div className={toggleClass} style={{ width: '24px', padding: '0px 5px' }}>{link}</div>
                    { /* <div className={toggleClass} style={{ color: 'brown', padding: '0px 5px' }}>{link ? '|' : ''}</div> */}
                </>
            );
        }
    }
}
