import React from 'react';
import { Common, UC, UnoComponent, UnoCoreBaseComp } from '../../@uno/core';

@UnoComponent({ id: 'App' })
export class App extends UnoCoreBaseComp {

    buildComp() {
        const apps: Array<any> = [
            //{ id: 'Find Apps...', to: '/uno/uno_app_def/search' },
        ];

        const AppChoices = <UC.Navigation navs={apps} orientation='h' />
        return (
            <>
                {AppChoices}
                {this.buildLayout()}
                <UC.ShowDialog />
                <UC.ShowMessage />
           </>
        );
    }

    buildLayout() {
        return <UC.AppLayout />
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log('ERROR: ', error, errorInfo);
        Common.showError('Random Error: ' + error);
    }
}

@UnoComponent({ id: 'Hello' })
export class Hello extends UnoCoreBaseComp {
    buildComp() {
        return <h1>Hello!!!</h1>
    }
}