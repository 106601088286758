import React from 'react';
import { Common } from '../../../api';
import { UnoComponent } from '../../../core';

// CSV Upload
@UnoComponent({
    id: 'CsvUpload',
    label: 'Upload CSV',
    props: [],
})
export class CsvUpload extends React.Component<any, any>{
    inputID = Common.getUniqueKey();
    cellDelim = ',';
    rowDelim = '\r\n';

    render() {

        return (
            <>
                {this.getTitle()}
                <input
                    type='file'
                    onChange={(e) => this.handleLoadCSV(e)}
                    accept='.csv' id={this.inputID} />
            </>
        );
    }

    handleLoadCSV = (e: any) => {
        e.preventDefault();

        const fReader = new FileReader();
        fReader.onload = async (e: any) => {
            const content: string = e.target.result;
            await this.processContent(content);
        }

        const csvFile = e.target.files[0];
        fReader.readAsText(csvFile);
    }

    getTitle = () => {
        return 'CSV File Upload';
    }

    loadRawData = async (content: string) => {
        const rows = content.split(this.rowDelim);
        const sheetData = rows.map(rowData => {
            const cells = rowData.split(this.cellDelim);
            return cells;
        });
        return sheetData;
    }

    buildHeaders = async (sheetData: string[][], headerIdx: number = 0) => {
        const columnNames = sheetData[headerIdx].map(h => {
            return h.trim();
        });
        return columnNames;
    }


    buildRecords = async (sheetData: string[][], headers: string[], headerIdx: number = 0) => {
        const dataObjects = [];
        for (let i = headerIdx + 1; i < sheetData.length; i++) {
            const rowData = sheetData[i];
            const rowObj: any = {};
            for (let j = 0; j < headers.length; j++) {
                rowObj[headers[j]] = rowData[j];
            }
            dataObjects.push(rowObj);
        }
        return dataObjects;
    }

    processContent = async (content: string) => {
        const headerIdx = 0;
        const sheetData = await this.loadRawData(content);
        const headers = await this.buildHeaders(sheetData, headerIdx);
        if (this.props.onHeaders) {
            this.props.onHeaders(headers);
        }
        const records = await this.buildRecords(sheetData, headers, headerIdx);
        if (this.props.onRecords) {
            this.props.onRecords(records);
        }
    }
}
