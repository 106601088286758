import React from 'react';
import { DesignerConstants, UC, UnoComponent, CalendarOps } from '../../@uno/core';
import { AppInfo } from '../service/app.info.service';
import { UnoAppBaseComp } from './uno-app.base.comp';

@UnoComponent({
    id: 'Footer',
    label: 'App Footer',
    paletteable: true,
    group: DesignerConstants.PaletteGroup.Layout.id,
    /*
    getPreview: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Footer - PREVIEW</div>);
    },
    getDesign: (config?: any, clbk?: any, buildChildren?: Function) => {
        return (<div>The Footer - DESIGN</div>);
    }
    */
})
export class Footer extends UnoAppBaseComp {

    buildComp() {
        // this.test();
        //console.log(`Init Header Props: `, this.props.app);
        const app: AppInfo = this.getAppInfo();
        if (!app) {
            return <UC.Empty />;
        }
        const contact = app.contact;
        const mailTo = 'mailto:' + contact;
        // console.log('Mail to: ', mailTo);
        const refreshTitle = 'Reload Screen';
        return (
            <div className='app-footer '>
                <UC.VSection cols='3' style={{ backgroundColor: 'inherit' }}>
                    <div></div>
                    <p>
                        {contact ? (<span className='app-wm-contact'>Contact: <a href={mailTo}>{contact}</a></span>) : <UC.Empty />}
                    </p>
                    <div className='center'>
                        {<UC.Refresh title={refreshTitle} />} {refreshTitle}
                    </div>

                </UC.VSection>
            </div>
        );
    }

    test() {
        const ages = [
            '5 11',
            '1 10 12',
            '1. 10. 12',
            '1   10      12',
            '1.10      12',
            '1y.10d      12',
            '2y',
            '21Y 25D',
            '3m 5D',
            '5 6 10',
        ];
        ages.forEach(age => {
            const dob = CalendarOps.getDOB(age);
            if (dob) {
                const ageToday = CalendarOps.getDifference(new Date(dob), new Date());
                console.log(age, dob, ageToday);
            }
        });
    }

}
