import React from 'react';
import { SessionManager } from '../../../@uno-app/service/session.service';
import { TriggerService } from '../../../@uno-entity/service/entity-trigger.service';
import { Common, UC, UnoComponent } from '../../../@uno/core';
import { BasePropViewer } from '../prop-base.comp';

@UnoComponent(
    {
        id: 'PaymentHandler',
    }
)
export class PaymentHandler extends BasePropViewer {
    private PayGateways: any = Common.Payments.Gateways;
    private gateways: any = [];

    async handleCategoryLoaded() {
        // console.log('Load Applicable Gateways: ', this.getEntity());
        // find_payment_gateways
        const result = await TriggerService.send(
            { entity: this.getEntity(), category: this.getCategory(), appID: this.getAppID(), },
            Common.Payments.Event.FIND_PAYMENT_GATEWAYS,
            false,
        );

        if (result?.gateways?.length > 0) {
            // console.log('Identified Gateways: ', result.gateways);
            this.gateways = result.gateways.map(
                (agw: any) => {
                    return {
                        gw: this.PayGateways[agw.id],
                        cred: agw.cred,
                    }
                }
            );

            // console.log('The Payment Gateways: ', this.gateways);
            this.reRender();
        } else {
            // console.log('No applicable payment gateways identified: ', result);
        }
    }

    buildValue() {
        if (!this.getEProp()) {
            return <UC.Loading  target={this.reRender} />
        } else {
            return this.buildPaymentOptions();
        }
    }

    buildPaymentOptions() {
        return (
            <UC.VSection >
                <div>{this.buildApplicableGateways()}</div>
            </UC.VSection>
        );
    }

    buildApplicableGateways() {
        let navs: Array<any> = [];

        const pVal = this.getPropValue();
        if (pVal) {
            const gatewayIDs = Object.keys(this.PayGateways);
            if (gatewayIDs.includes(pVal)) {
                const payGateway = this.PayGateways[pVal];
                const navOption = {
                    id: payGateway.id,
                    label: `Paid via ${payGateway.label}`,
                    icon: Common.Payments.getGatewayIcon(pVal),
                    action: undefined,
                }
                navs = [navOption];
            } else {
                // console.log(`${pVal} not found in `, gatewayIDs);
                return <UC.Empty />;
            }
        } else {
            navs = this.gateways.map(
                (agw: any) => {
                    const payGateway: any = agw.gw;
                    return {
                        id: payGateway.id,
                        label: `Pay via ${payGateway.label}`,
                        icon: Common.Payments.getGatewayIcon(payGateway.id),
                        action: async () => {
                            this.initPayment(payGateway, agw.cred);
                        }
                    }
                }
            );
        }
        return <UC.Navigation navs={navs} key={Common.getUniqueKey('pgs_')} />
    }

    initPayment = async (payGateway: any, cred: any) => {
        const paymentObject = this.getEntity();
        if (paymentObject) {
            // console.log('Init Payment for - ', paymentObject);
            const inputs = { amount: paymentObject.getValue('amount', 0), currency: paymentObject.getValue('currency', 'INR') };
            if (inputs.amount <= 0) {
                Common.showMessage('Payment can be processed only for a non-zero amount.');
                return;
            }
            // create order
            const order = await payGateway.createOrder(inputs, cred);
            if (!order) {
                Common.showMessage(`Problem initiating a payment via ${payGateway.label}.`);
            } else {
                // now, initiate actual payment
                // console.log(`Created ${payGateway.label} order: `, order);
                const payOptions = { ...payGateway.paymentOptions };
                payOptions.key = order.key;
                payOptions.order_id = order.order.id;
                payOptions.amount = order.order.amount;
                payOptions.handler = async (result: any) => {
                    // console.log(result);
                    paymentObject.setValue('status', 'completed');
                    paymentObject.setValue('pay_channel', payGateway.id);
                    paymentObject.setValue('channel_refdata', result);
                    let updatedEntity = await paymentObject.save(paymentObject.getAppID(), SessionManager.activeSession);


                    const triggerInputs = { entity: updatedEntity, category: this.getCategory(), appID: this.getAppID(), }
                    const triggerResult = await TriggerService.send(triggerInputs, 'edit-save-completed', false);
                    if (triggerResult?.error) {
                        Common.showMessage(triggerResult?.error);
                    } else {
                        // console.log('Payment Completed: ', triggerResult);
                        this.setRedirectTo(window.location?.pathname);
                    }
                }

                // invoke pay gatway.
                const initPayGateway = payGateway.initPayment;
                if (initPayGateway) {
                    await initPayGateway(payOptions);
                }
            }
        }
    }

}