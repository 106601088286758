import React from 'react';
import { EntityCategory, EntityConstants, EntityProp } from '../../../@uno/api';
import { UC, Images, UnoComponent } from '../../../@uno/core';
import { EntityBaseComp } from '../entity-base.comp';


// initialize icons;
const Icons: any = {};
const initIcons = () => {
    Icons[EntityConstants.ListViewTypes.Carousel.id] = Images.Icon.caraousel;
    Icons[EntityConstants.ListViewTypes.Table.id] = Images.Icon.table;
    Icons[EntityConstants.ListViewTypes.Grid.id] = Images.Icon.grid;
}
initIcons();

// MULTI ENTITY - MULTI EDIT 
@UnoComponent({ id: 'MultiEntityEdit' })
export class MultiEntityEdit extends EntityBaseComp {
    private seletingProps = false;
    private selProps: Array<string> = [];
    private listEditType: string = EntityConstants.ListEditTypes.Grid.id;

    constructor(props: any) {
        super(props);
        const oProps = this.getOtherProps();
        if (oProps) {
            if (oProps.listEditType) {
                this.listEditType = oProps.listEditType;
            }
        }
    }

    async doLoadMore() {
        if (this.state.entity) {
            await this.loadEntities(this.state.entity);
        }
    }

    render() {
        const eList = this.getEntityList()
        if (eList) {
            if (eList.length === 0) {
                return (<>0 records found</>);
            }

            const categoryCopy: EntityCategory = { ...this.getCategory() };
            if (this.selProps?.length > 0) {
                categoryCopy.props?.forEach(prop => {
                    const pID = prop.id;
                    if (this.selProps.includes(pID)) {
                        prop.hidden = false;
                    } else {
                        prop.hidden = true;
                    }
                });
            }

            // console.log('Listing - ', eList, ' entities using - ', this.listEditType, categoryCopy);

            const ListEditComp = UC[this.listEditType];
            return (
                <>
                    {this.buildSettings()}

                    <ListEditComp
                        key={this.getUniqueKey()}
                        category={categoryCopy}
                        entities={eList}
                        appID={this.getAppID()}
                    />
                </>
            );
        } else {
            return <UC.Loading target={this.reRender} />
        }

    }

    private buildSettings() {
        const settingChoices: Array<any> = [];
        /*
        settingChoices.push({
            id: 'selProps',
            label: 'Properties',
            icon: icon_prop_selector,
            action: () => { this.seletingProps = true; this.setState({}); }
        });
        */

        const editChoices = this.getEditChoices();
        if (editChoices?.length > 0) {
            settingChoices.push({
                id: 'editAs',
                label: 'Edit Using',
                icon: Images.Icon.viewAs,
                children: editChoices,
            });
        }

        return (
            <>
                {this.buildPropSelector()}
                <UC.Navigation
                    navs={settingChoices}
                    orientation='h'
                    isToolbar={true}
                />
            </>
        );
    }

    getPrinterComp() {
        return null;
        // return MultiEntityEdit;
    }

    setListEditType = (editType: any) => {
        if (this.listEditType !== editType) {
            this.listEditType = editType;
            this.setState({});
        }
    }

    private getEditChoices(): any[] {
        if (this.getOtherProps()?.listEditType) {
            return [];
        }
        return Object.values(EntityConstants.ListEditTypes).map(vt => {
            return {
                id: vt.id,
                label: vt.label,
                icon: Icons[vt.id],
                isSelected: (vt.id === this.listEditType),
                action: (opt: any) => {
                    this.setListEditType(opt.id);
                }
            };
        });
    }

    buildPropSelector() {
        if (this.seletingProps) {
            const entityProp: EntityProp = {
                id: 'selectedProps',
                label: 'Properties',
                dataType: EntityConstants.PropType.STRING,
                category: this.getCategoryID(),
                extras: { multiSelect: true, },
            };

            return (
                <UC.Dialog
                    onClose={() => {
                        this.seletingProps = false;
                        this.setState({});
                    }}
                    title={entityProp.label} >
                    <UC.PropertySelector
                        entityProp={entityProp}
                        defaultValue={this.selProps}
                        otherProps={
                            {
                                onPropChanged: (eProp: EntityProp, selProps: any) => {
                                    // console.log(`Selected props: `, selProps);
                                    this.selProps = selProps;
                                    // this.setState({});
                                },
                            }
                        }
                    />
                </UC.Dialog>
            );
        } else {
            return <UC.Empty />;
        }
    }

}
